import { Component, Input, ElementRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, SimpleChanges, Output, EventEmitter, Inject } from '@angular/core';
import { PushMessageSelection } from '../../providers/pushMessage/pushMessageSelection';
import { UIElement, UIGroup, UIPopup } from '../../models';
import { RegularPopupComponent, BackdropComponent } from '../../../../shared/components';
import { ManagedSubject, ManagedSubscription } from '../../../../shared';
import * as Immutable from "immutable";
import { PushMessageStore } from '../../providers/pushMessage';
import { BaseComponent } from '../../baseComponent';
import { ImageSets } from '../../../../shared/utils';

@Component({
  selector: 'push-message',
  templateUrl: './pushMessageComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PushMessageComponent extends BaseComponent {

  @ViewChild(BackdropComponent) protected backdropComponent: BackdropComponent;

  @Input()
  public uiPopup: UIPopup;

  @Input()
  public sessionId: number;

  @Output()
  action = new EventEmitter<PushMessageSelection>();

  @ViewChild(RegularPopupComponent)
  private _popup: RegularPopupComponent;

  public visible = false;

  public title = "";

  public icon = "";

  public width: string;

  public height: string;

  public actions = [];

  public leadParagraph = "";

  public paragraph = "";

  public cssClass = "";

  public modal = true;

  public uiElement: UIElement;

  public uiElementId: number;

  public imageSet: string;

  constructor(
    @Inject(PushMessageStore) public pushMessageStore: PushMessageStore,
    public cd: ChangeDetectorRef
  ) {

    super();

    if (this.backdropComponent)
      this.backdropComponent.add(this._popup);
  }

  ngOnInit() {
    this.imageSet = this.getImageSet(ImageSets.PopupHeader);
    super.ngOnInit();

    this.listenChanges();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.uiPopup) {
      this.title = this.uiPopup.header;
      this.icon = this.uiPopup.icon;
      this.leadParagraph = this.uiPopup.leadParagraph;
      this.paragraph = this.uiPopup.paragraph;
      this.cssClass = this.uiPopup.cssClass;
      this.modal = this.uiPopup.modal;

      if (this.uiPopup.uiElements) {
        this.uiElement = this.pushMessageStore.getElement(this.uiPopup.uiElements.first(), this.sessionId);

        if (!this.uiElement)
          return;

        this.uiElementId = this.uiElement.renderId;
        this.cd.markForCheck();
      }

      if (this.uiPopup.width > 0)
        this.width = this.uiPopup.width + "px";
      else
        this.width = null;

      if (this.uiPopup.height > 0)
        this.height = this.uiPopup.height + "px";
      else
        this.height = null;

      this.visible = true;
    }

  }

  public show(): void {

    if (this.modal) {
      this.backdropComponent.add(this.popup);
      this.backdropComponent.visible = true;
      this.backdropComponent.updateBackDropStyle();
    }

    setTimeout(() => {
      this.popup.open();
      this.cd.detectChanges();
    }, 0);
  }

  public close(): void {
    this.popup.close();
    this.visible = false;
    this.cd.detectChanges();
  }

  public get popup(): RegularPopupComponent {
    let popupComponent = this._popup;
    if (popupComponent == null) {
      this.visible = true;
      this.cd.detectChanges();
      popupComponent = this._popup;
    }
    return popupComponent;
  }

  public onAction(event: PushMessageSelection) {
    this.action.emit(event);
  }

  public getCssClass(uiElementId2: number) {

    let uiElement2 = this.pushMessageStore.getElement(uiElementId2, this.sessionId);

    if (!uiElement2)
      return "";

    // Do not effect buttons.
    if (uiElement2.className == "UIInput") {
      return "";
    }

    // Do not effect UIGroup rendered with a div.
    if (uiElement2.className == "UIGroup") {
      let uiGroup = uiElement2 as UIGroup;
      if (uiGroup.includeDiv) {
        return "";
      }
    }

    return uiElement2.cssClass;
  }

  public listenChanges() {

    this.pushMessageStore.onElementChange(this.sessionId, this.uiElementId, (uiElement: UIElement): void => {

      this.uiElement = uiElement;
      this.uiElementId = uiElement.renderId;

      this.cd.markForCheck();

    }).unsubscribeOn(this.unsubscribeSubject);
  }
}