<div #visualObjectContainer>
  <div class="visual-object-margin price-list-decoration decoration" [style.max-width.%]="'100'" >
    <gridview>
      <!-- Force header now to display the settings button. This should be revisited if we move the button to the popup.
      <gridview-header *ngIf="!(decoration?.title | isNullOrWhiteSpace)">
        -->
      <gridview-header classes="decoration-header">
        <header-columntemplate classes="header-image" size="auto">
          <co-image width="24" height="24" type="svg" key="price" [imageSet]="imageSet"></co-image>
        </header-columntemplate>
        <header-columntemplate classes="header-title">
          {{decoration.title}}           
        </header-columntemplate>
        <header-columntemplate *ngIf="showSettings" classes="text-right setting-icon header-image" size="auto" title="{{strings.Settings}}">
          <a role="button" class="px-2" (click)="onPriceSettingsClick($event)"><co-image width="20" height="20" type="svg" key="settings" [imageSet]="imageSet"></co-image> </a>
        </header-columntemplate>        
      </gridview-header>

      <gridview-row *ngIf="confPriceValue != null && confPriceValue.priceListCategoryId" classes="py-2">
        <columntemplate classes="col">
          {{confPriceValue?.priceListCategoryName}}
        </columntemplate>
        <columntemplate classes="col text-right">          
          <a *ngIf="PriceListStatus == StatusEnumObject.Effective" class="detail" role="button" title="{{strings.Details}}" (click)="onPriceDetailClick($event)">{{confPriceValue?.price}}</a>
          <ng-template *ngIf="PriceListStatus == StatusEnumObject.Expired" ><span class="text-danger">{{strings.Expired}}</span></ng-template>
        </columntemplate>
      </gridview-row>

    </gridview>
  </div>
</div>