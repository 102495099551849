import { Component, ElementRef, ViewChild, Input, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy, SimpleChanges, HostListener, OnInit, AfterViewChecked, NgZone, ChangeDetectorRef } from '@angular/core';

import { VisualizationDecoration, ConfVisualizationValue, RequestViews, EventType } from '../../../shared/models';
import { NotificationService, NotificationInfo, NotificationType, SizeUtility } from '../../../../shared/components';
import { VisualizationPluginManager, Visualization, VisualizationPlugin, JavaScriptEventResult } from '../../../../shared/providers';
import { Strings } from '../../../shared/providers/globalData';
import { PageStore } from '../../../shared/providers/page';
import { RouteNames } from '../../../shared/providers';
import { VisualObjectHelper } from '../../parameters/shared';
import { Subscription } from 'rxjs';
import { BaseComponent } from '../../../shared';
import { ConfiguratorStore, ConfPageSessionService } from '../../providers';

enum VisualizationAction {
  UPDATE = 0,
  RESIZE
}

@Component({
  selector: 'visualization-plugin',
  templateUrl: './visualizationPluginComponent.html',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisualizationPluginComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild('rendererCanvas', { static: true })
  public rendererCanvas: ElementRef<HTMLCanvasElement>;

  @Input()
  public decoration: VisualizationDecoration;

  @Input()
  public confVisualizationValue: ConfVisualizationValue;

  @ViewChild('container', { read: ElementRef })
  public readonly containerElement;  
  protected baseFilePath = "api/configurator/visualization/file/";
  protected ngZoneSubscription: Subscription;
  protected visualization: Visualization;
  protected isVisualizationReady = false;
  protected isMarkedForDispose = false;
  protected isSummaryView: boolean = false;
  protected isResizeActionPending: boolean = false;
  protected visualizationActions: VisualizationAction[] = [];

  public canvasWidth: number;
  public canvasHeight: number;

  public constructor(
    public visualizationPluginManager: VisualizationPluginManager,
    public notificationService: NotificationService,
    public pageStore: PageStore,
    public visualObjectHelper: VisualObjectHelper,
    public cd: ChangeDetectorRef,    
    private _ngZone: NgZone,
    public confStore: ConfiguratorStore,
    public confPageSessionService: ConfPageSessionService
  ) {
    super();
  }

  ngOnInit() {

    this.isSummaryView = this.pageStore.activeRouteName === RouteNames.Summary;    
    this.ngZoneSubscription = this._ngZone.onStable.subscribe(x => {

      if (this.containerElement.nativeElement) {
        const offsetWidth = this.containerElement.nativeElement.offsetWidth;
        const offsetHeight = this.containerElement.nativeElement.offsetHeight;

        if (offsetWidth != this.canvasWidth || offsetHeight != this.canvasHeight) {
          this.setCanvasSize();

          this.addAndExecuteAction(VisualizationAction.RESIZE);
        }
      }
    });
  }

  ngAfterViewInit() {

    this.isResizeActionPending = true;        
  }
 
  ngAfterContentInit(): void {
   
    this.visualizationPluginManager.loadPlugin(`${this.decoration.indexFilePath}`, (visualizationPlugin: VisualizationPlugin) => {

      visualizationPlugin.create(this.rendererCanvas.nativeElement, this.canvasWidth, this.canvasHeight, `${this.baseFilePath}${this.decoration.indexFileDirPath}`, this.getCodeFileValue()).then((visualization) => {

        this.visualizationInstanceCreated(visualization);

      }, (error) => this.handleError(this.decoration.indexFilePath, error));

    }, (error: any, url: string) => this.handleError(url, error));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['confVisualizationValue'] && !changes['confVisualizationValue'].firstChange) {
      this.addAndExecuteAction(VisualizationAction.UPDATE);
    }
  }

  ngAfterViewChecked(): void {
    
    if (this.isResizeActionPending) {

      this.isResizeActionPending = false;
      this.addAndExecuteAction(VisualizationAction.RESIZE);
    }
  }

  ngOnDestroy() {

    this.isMarkedForDispose = true;

    if (this.visualization && this.isVisualizationReady) {
      this.visualization.dispose();
    }

    if (this.ngZoneSubscription)
      this.ngZoneSubscription.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isResizeActionPending = true;    
  }

  visualizationInstanceCreated(visualization: Visualization) {

    this.visualization = visualization;
    this.isVisualizationReady = true;
    this.addAndExecuteAction(VisualizationAction.UPDATE);

    if (this.confPageSessionService.activeRoute == RouteNames.Editor)
      this.visualization.addMessageHandler((eventType, json) => this.handleMessage(eventType, json));
  }

  // Handler for the JavaScript event
  public handleMessage(eventType: EventType, jsonData: string): Promise<JavaScriptEventResult> {
    return this.confStore.javaScriptAction(this.confPageSessionService.activeConfigurationId, this.confPageSessionService.confSessionId, eventType, this.decoration.longId, jsonData, RequestViews.Editor).then(x =>
      Promise.resolve({ Success: true, Result: 'Handled successfully' }));
  }

  addAndExecuteAction(action: VisualizationAction) {

    if (this.visualizationActions.indexOf(action) < 0)             
      this.visualizationActions.push(action);

    this.executeNextAction();
  }

  executeNextAction() {

    if (!this.visualization || !this.isVisualizationReady)
      return;

    if (this.isMarkedForDispose) {
      this.visualization.dispose();
      return;
    }

    if (this.visualizationActions.length == 0)
      return;

    let action = this.visualizationActions.shift();
    if (action == VisualizationAction.UPDATE)
      this.update();
    else if (action == VisualizationAction.RESIZE)
      this.resize();
  }

  update(): void {

    if (!this.visualization || !this.isVisualizationReady)
      return;

    this.isVisualizationReady = false;

    try {

      this.visualization.update(this.getCodeFileValue()).then(() => {

        this.isVisualizationReady = true;
        this.executeNextAction();

      },
        (error) => {

          this.isVisualizationReady = true;
          this.handleError(`${this.baseFilePath}${this.decoration.indexFilePath}`, error);

          this.executeNextAction();
        });

    } catch (ex) {

      this.isVisualizationReady = true;
      this.handleError(`${this.baseFilePath}${this.decoration.indexFilePath}`, ex);

      this.executeNextAction();
    }
  }

  resize(): void {

    this.setCanvasSize();    
    if (!this.visualization || !this.isVisualizationReady)
      return;

    this.isVisualizationReady = false;

    try {

      this.visualization.resize(this.canvasWidth, this.canvasHeight).then(() => {

        this.isVisualizationReady = true;
        this.executeNextAction();

      },
        (error) => {

          this.isVisualizationReady = true;
          this.handleError(`${this.baseFilePath}${this.decoration.indexFilePath}`, error);

          this.executeNextAction();
        });

    } catch (ex) {

      this.isVisualizationReady = true;
      this.handleError(`${this.baseFilePath}${this.decoration.indexFilePath}`, ex);

      this.executeNextAction();
    }
  }

  setCanvasSize() {

    if (this.containerElement && this.containerElement.nativeElement) {

      let container = this.containerElement.nativeElement;

      this.canvasWidth = container.offsetWidth;      
      this.canvasHeight = container.offsetHeight;

      if (!this.canvasHeight) {
        this.canvasHeight = this.decoration.height ? this.decoration.height : SizeUtility.sizeAsNumber(this.uiSettings.configurator.decoration.minHeight);
      }

      // ...then set the internal size to match
      container.width = this.canvasWidth;
      container.height = this.canvasHeight;

      this.rendererCanvas.nativeElement.width = this.canvasWidth;
      this.rendererCanvas.nativeElement.height = this.canvasHeight;

      this.cd.markForCheck();
    }
  }

  handleError(url, error: any) {

    this.notificationService.notify(<NotificationInfo>{
      title: this.strings.Error,
      message: `${this.decoration.title}: ${error}`,
      detail: url,
      rawInfo: error,
      type: NotificationType.Error,
      selfClose: false
    });
  }

  protected getCodeFileValue(): string {

    if (this.confVisualizationValue && this.confVisualizationValue.codeFileValue && this.confVisualizationValue.codeFileValue.length > 0)
      return this.confVisualizationValue.codeFileValue;

    return "{}";
  }

}