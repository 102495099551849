import { Component, Input } from "@angular/core";
import {  InputViewModel } from "../../shared";
import { RadioButtonComponent } from "../radioButtonComponent";
import { BaseComponent } from "../../../../pages/shared/baseComponent";
import { ImageSets } from "../../../utils";

@Component({
  selector: 'image-radio-style',
  templateUrl: './imageRadioStyleComponent.html'
})
export class ImageRadioStyleComponent extends BaseComponent {

  @Input()
  view: InputViewModel;

  @Input()
  selected: boolean = false;

  public imageSet: string;

  ngOnInit() {
    this.imageSet = this.getImageSet(ImageSets.ControlImageOptionChecked);
    super.ngOnInit();
  }

  public get imageStyle(): string {
    let imageStateCss: string = "rounded-image-corner radio-border";

    if (this.selected) {
      imageStateCss += " checked";
    }
    return imageStateCss;
  }
}