import { Injectable, EventEmitter, Inject } from "@angular/core";
import { HubConnection, HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr';
import { ServerPushMessage, ClientPushMessage } from "../../models";
import { ApiResponseBuilder } from "../apiResponseBuilder";
import { PushMessageActionCreator } from "./pushMessageActionCreator";
import { random } from "core-js/fn/number";
import { SessionStorageService } from "../sessionStorageService";
import { GlobalDataStore } from "../globalData";

@Injectable({
  providedIn: "root"
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;
  signalReceived = new EventEmitter<ServerPushMessage>();
  connecting = new EventEmitter<number>();

  temporaryId: number;

  constructor(public sessionStorageService: SessionStorageService,
    @Inject(ApiResponseBuilder) public apiResponseBuilder: ApiResponseBuilder,
    @Inject(PushMessageActionCreator) public pushMessageActionCreator: PushMessageActionCreator,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
  ) {
    //this.buildConnection();
    //this.startConnection();
  }

  get tempConnectionId(): number {

    if (!this.temporaryId) {
      this.temporaryId = new Date().valueOf() * 10000 + Math.floor(1000 + Math.random() * 9000);
    }

    return this.temporaryId
  }

  get connectionId(): string {

    if (this.hubConnection)
      return this.hubConnection.connectionId;

    return "";
  }

  private buildConnection() {

    let baseUrl = this.globalDataStore.getGlobalData().webSettings.baseUrl;

    let accessToken = this.sessionStorageService.getData()?.accessToken;
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${baseUrl}/pushMessageHub`, { accessTokenFactory: () => accessToken })
      .withAutomaticReconnect()
      .build();
  }

  public isConnected() {

    return this.hubConnection && (this.hubConnection.state != HubConnectionState.Disconnected && this.hubConnection.state != HubConnectionState.Disconnecting)

  }

  public stopConnection() {
    //console.log("???????????????????????????????????????????????????????????????? stop.");
    if (this.hubConnection && this.hubConnection.state != HubConnectionState.Disconnected) {
      this.temporaryId = null;
      this.hubConnection.stop();
    }
  }

  public startConnection() {
    //console.log("???????????????????????????????????????????????????????????????? start.");
    if (this.hubConnection && this.hubConnection.state != HubConnectionState.Disconnected) {
      //this.temporaryId = null;
      this.hubConnection.stop().then(() => this.startConnectionInternal());
    }
    else {
      this.buildConnection();
      this.startConnectionInternal();
    }
  }

  private startConnectionInternal() {
    //console.log("???????????????????????????????????????????????????????????????? start internal.");
    this.hubConnection
      .start()
      .then(() => {
        console.log("SignalR Connection Started.");
        this.registerSignalEvents();

        // Send notification to the server so that the temporary id can be removed.
        this.connecting.emit(this.temporaryId);

        this.temporaryId = null;
      })
      .catch(err => {
        console.log("Error while starting the SignalR connection: " + err);

        // Try again if there was an error.
        setTimeout(function () {
          () => {
            this.startConnection();
          };
        }
        , 3000);
      });
  }

  public send(message: ClientPushMessage) {

    this.hubConnection.invoke("Send", message);

  }

  private registerSignalEvents() {
    this.hubConnection.on("ServerPushMessage", (data: ServerPushMessage) => {
      this.signalReceived.emit(data);

      this.apiResponseBuilder.build(data);
      this.pushMessageActionCreator.dispatchSetServerPushMessage(data);
    });
  }
}