import { Injectable, Inject, forwardRef, Host } from "@angular/core";
import { Unsubscribe } from "redux";
import { Subscription } from "rxjs";

import { AppStore } from "../state/appStore";
import { ManagedSubject } from "../../../shared/managedSubject";
import { GlobalLogger } from "../../../shared/providers";

@Injectable()
export class AppStoreSubscriptionManager {

  public storeSubjectByKey: Map<any, ManagedSubject<any>> = new Map<any, ManagedSubject<any>>();

  public appStoreUnsubscribe: Subscription;

  public appStoreCycleId: number = 0;

  public keyPrefix: string = "internal_key_";
  public keyCounter: number = 1;

  constructor(
    @Inject(AppStore) public appStore: AppStore,
    @Inject(GlobalLogger) public globalLogger: GlobalLogger) {
    this.appStoreUnsubscribe = appStore.subscribe(() => this.onAppStoreChange());    
  }

  createSubject<T>(observable: (appStore: AppStore) => T, oneTime = false, buffer = 1, completeOnZeroObserver = true): ManagedSubject<T> {
    let key = this.createNewKey();
    return this.createStoreSubjectByKey(key, observable, oneTime, buffer, completeOnZeroObserver);
  }

  createStoreSubjectByKey<T>(key: any, observable: (appStore: AppStore) => T, oneTime = false, buffer = 1, completeOnZeroObserver = true): ManagedSubject<T> {

    let managedSubject = this.createSubjectInternal<T>(observable, oneTime, buffer, completeOnZeroObserver);
    this.storeSubjectByKey.set(key, managedSubject);
    return managedSubject;
  }

  getOrCreateStoreSubject<T>(key: any, observable: (appStore: AppStore) => T, oneTime = false, buffer = 1, completeOnZeroObserver = true) {
    if (this.hasStoreSubject(key))
      return this.getStoreSubject<T>(key);

    return this.createStoreSubjectByKey<T>(key, observable, oneTime, buffer, completeOnZeroObserver);    
  }
  
  public getStoreSubject<T>(key: any): ManagedSubject<T> {
    return this.storeSubjectByKey.get(key);
  }

  hasStoreSubject(key: any): boolean {
    return this.storeSubjectByKey.has(key) && !this.storeSubjectByKey.get(key).isComplete;
  }

  public createSubjectInternal<T>(observable: (appStore: AppStore) => T, oneTime = false, buffer = 1, completeOnZeroObserver = true, executeObserverOnSubscribe = true): ManagedSubject<T> {

    // Inject AppStore intho observer function
    let appStoreObservable = () => observable(this.appStore);
    return new ManagedSubject<T>(appStoreObservable, oneTime, buffer, completeOnZeroObserver);
  }

  public createNewKey() {
    return `${this.keyPrefix}${this.keyCounter++}`;
  }

  // Called when AppStore has changed
  public onAppStoreChange() {

    let cycleId = ++this.appStoreCycleId;

    // Remove completed subjects
    for (let key of Array.from(this.storeSubjectByKey.keys())) {

      // If new app store change cycle has started then terminate old change cycles
      if (this.appStoreCycleId > cycleId) {
        return;
      }

      let managedSubject = this.storeSubjectByKey.get(key);

      if (managedSubject.isComplete)
        this.storeSubjectByKey.delete(key);
      else
        managedSubject.triggerNext();
    }
  }

  dispose() {

    this.appStoreUnsubscribe.unsubscribe();

    this.storeSubjectByKey.forEach((subject, key) => subject.complete());
    this.storeSubjectByKey.clear();
  }
}