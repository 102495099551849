<div *ngIf="tabs.length > 0" class="px-0 h-100 ">
  <div class="col tab-root-container px-0" [class.accordion-tabs]="mobileMode" [ngSwitch]="!mobileMode">
    <ng-template [ngSwitchCase]="true">
      <tabs *ngIf="tabModels.length > 0" [tabModels]="tabModels" 
            [defaultSelectedTabId]="activeTab.longId"
            (selected)="selectedTab($event)">
        <tab>
          <visual-object-container *ngIf="domReady" [tab]="activeTab"                                    
                                   [attr.id]="activeTab.longId"
                                   [configurationId]="configurationId"></visual-object-container>
        </tab>
      </tabs>
    </ng-template>

    <ng-template [ngSwitchCase]="false">
      <nested-accordion-tabs [tabs]="tabs"
                             [selectedTabId]="selectedTabId"
                             [configurationId]="configurationId"                             
                             containerId="normal-tabs"
                             (selected)="selectedTab($event)"></nested-accordion-tabs>

    </ng-template>
  </div>
</div>  