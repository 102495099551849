export class PushMessageSelection {

  sessionId: number;

  // Should the session be cleared?
  clearSession: boolean;

  // The key of the control.
  key: string;

  // The value to set in the session, for the control.
  value: string;

  // Should it trigger an action?
  triggerAction: boolean;
}