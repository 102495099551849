import { Injectable } from "@angular/core"
import { Schema, schema } from 'normalizr';
import { UIElementSchema } from "../uiElementSchema";

@Injectable()
export class ConfDataSchema extends UIElementSchema {
  
  // Configuration schema
  configurationSchemaArray: schema.Array;
  public configurationSchema: schema.Entity = new schema.Entity('Conf', {}, { idAttribute: 'longId' });
  public confBoolValueSchema: schema.Entity = new schema.Entity('ConfBoolValue', {}, { idAttribute: 'longId' });
  public confLookupValueSchema: schema.Entity = new schema.Entity('ConfLookupValue', {}, { idAttribute: 'longId' });
  public confIntValueSchema: schema.Entity = new schema.Entity('ConfIntValue', {}, { idAttribute: 'longId' });
  public confDoubleValueSchema: schema.Entity = new schema.Entity('ConfDoubleValue', {}, { idAttribute: 'longId' });
  public confMultiChoiceValueSchema: schema.Entity = new schema.Entity('ConfMultiChoiceValue', {}, { idAttribute: 'longId' });
  public confMultiSetValueSchema: schema.Entity = new schema.Entity('ConfMultiSetValue', {}, { idAttribute: 'longId' });
  public confStringValueSchema: schema.Entity = new schema.Entity('ConfStringValue', {}, { idAttribute: 'longId' });
  public confAttributeValueSchema: schema.Entity = new schema.Entity('ConfAttributeValue', {}, { idAttribute: 'longId' });
  public confDataSelectorValueSchema: schema.Entity = new schema.Entity('ConfDataSelectorValue', {}, { idAttribute: 'longId' });
  public graphicsDecorationSchema: schema.Entity = new schema.Entity('GraphicsDecoration', {}, { idAttribute: 'longId' });
  public confGraphicValuesSchema: schema.Entity = new schema.Entity('ConfGraphicValue', {}, { idAttribute: 'longId' });  
  public confCodeValueSchema: schema.Entity = new schema.Entity('ConfCodeValue', {}, { idAttribute: 'longId' });
  public confVisualizationValueSchema: schema.Entity = new schema.Entity('ConfVisualizationValue', {}, { idAttribute: 'longId' });
  public confFormValueSchema: schema.Entity = new schema.Entity('ConfFormValue', {}, { idAttribute: 'longId' });

  // Configuration info schema
  confInfoSchemaArray: schema.Array;
  public confInfoSchema: schema.Entity = new schema.Entity('ConfInfo', {}, { idAttribute: 'longId' });

  constructor() {
    super();
    this.setupConfigurationSchema();
  }

  public setupConfigurationSchema(): void {

    this.configurationSchemaArray = new schema.Array(this.configurationSchema);

    // An array representing the different class types of Configuration values
    let confValueSchemaType = {
      ConfLookupValue: this.confLookupValueSchema,
      ConfBoolValue: this.confBoolValueSchema,
      ConfIntValue: this.confIntValueSchema,
      ConfDoubleValue: this.confDoubleValueSchema,
      ConfMultiChoiceValue: this.confMultiChoiceValueSchema,
      ConfStringValue: this.confStringValueSchema
    };

    // A Configuration has an array of configuration values.
    this.configurationSchema.define({
      children: this.configurationSchemaArray,
      confValues: new schema.Array(confValueSchemaType, 'className'),      
      attributeValues: new schema.Array(this.confAttributeValueSchema),
      dataSelectorValues: new schema.Array(this.confDataSelectorValueSchema),
      graphicsValues: new schema.Array(this.confGraphicValuesSchema),
      codeValues: new schema.Array(this.confCodeValueSchema),
      visualizationValues: new schema.Array(this.confVisualizationValueSchema),
      formValues: new schema.Array(this.confFormValueSchema)
    });

    // A multi choice value has an array of multi set values.
    this.confMultiChoiceValueSchema.define({
      value: new schema.Array(this.confMultiSetValueSchema)
    });

    this.confInfoSchemaArray = new schema.Array(this.confInfoSchema);
    // A ConfigurationInfo has an array of ConfigurationInfos.
    this.confInfoSchema.define({
      children: this.confInfoSchemaArray
    });

    this.confFormValueSchema.define({
      uiElements: new schema.Array(this.uiElementSchemaType, 'className')
    });
  }  
}