import { Injectable } from "@angular/core";
import { schema } from "normalizr";
import { UIElementSchema } from "../uiElementSchema";


@Injectable()
export class PushMessageSchema extends UIElementSchema {

  // NOTE! This is not used.
  //public serverPushMessageSchema: schema.Entity = new schema.Entity('ServerPushMessage', {}, { idAttribute: 'id' });

  public uiActionSchemaArray: schema.Array;
  public uiActionSchema: schema.Entity = new schema.Entity('UIAction', {}, { idAttribute: 'renderId' });
  public uiActionUIPopupSchema: schema.Entity = new schema.Entity('UIPopup', {}, { idAttribute: 'renderId' });
  public uiActionUIEventOperationSchema: schema.Entity = new schema.Entity('UIEventOperation', {}, { idAttribute: 'renderId' });
  public uiActionUINotificationSchema: schema.Entity = new schema.Entity('UINotification', {}, { idAttribute: 'renderId' });

  public uiPopupSchemaArray: schema.Array;

  constructor() {
    super();
    this.setupServerPushMessageSchema();
  }

  public setupServerPushMessageSchema(): void {

    let uiActionSchemaType = {
      UIPopup: this.uiActionUIPopupSchema,
      UIEventOperation: this.uiActionUIEventOperationSchema,
      UINotification: this.uiActionUINotificationSchema
    };

    this.uiActionSchemaArray = new schema.Array(uiActionSchemaType, 'className');

    this.uiActionSchema.define({
      uiActions: new schema.Array(uiActionSchemaType, 'className')
    });

    this.uiActionUIPopupSchema.define({
      uiElements: new schema.Array(this.uiElementSchemaType, 'className')
    });

  }
}