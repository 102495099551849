export class PageActions {

  static CREATE_PAGE_UI_DATA = "@CREATE_PAGE_UI_DATA";
  static CREATE_SELECTOR_UI_DATA = "@CREATE_SELECTOR_UI_DATA";
  static CREATE_CONFIGURATOR_PAGE_UI_DATA = "@CREATE_CONFIGURATOR_PAGE_UI_DATA";
  static CREATE_CONF_UI_DATA = "@CREATE_CONF_UI_DATA";
  static UPDATE_CONF_UI_DATA = "@UPDATE_CONF_UI_DATA";
  static INCREMENT_PAGE_IDENTITY = "@INCREMENT_PAGE_IDENTITY";

  static CONFIGURATION_SELECT = "@CONFIGURAION_SELECT";
  static CONFIGURAION_UI_INIT = "@CONFIGURAION_UI_INIT";
  static COMPOSITE_UI_CHANGE = "@COMPOSITE_UI_CHANGE";
  static TABS_ACCORDION_UI_CHANGE = "@TABS_ACCORDION_UI_CHANGE";
  static SEPARATED_UI_TAB_CHANGE = "@SEPARATED_UI_TAB_CHANGE";    
  static CONF_ACTIVE_ROUTE_NAME = "@CONF_ACTIVE_ROUTE_NAME";

  static SET_SELECTED_ASSORTMENTS = "@SET_SELECTED_ASSORTMENTS";

  static SEARCH_RECENT_USER_CONF_REQUEST = "@SEARCH_RECENT_USER_CONF_REQUEST";
  static SEARCH_RECENT_USER_CONF_LOADED = "@SEARCH_RECENT_USER_CONF_LOADED";
  static SEARCH_RECENT_WORKGROUP_CONF_REQUEST = "@SEARCH_RECENT_WORKGROUP_CONF_REQUEST";
  static SEARCH_RECENT_WORKGROUP_CONF_LOADED = "@SEARCH_RECENT_WORKGROUP_CONF_LOADED";

  static SEARCH_UNSAVED_USER_CONF_REQUEST = "@SEARCH_UNSAVED_USER_CONF_REQUEST";

  static COMPARISON_CONFS_REQUEST = "@COMPARISON_CONFS_REQUEST";
}