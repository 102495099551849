import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { Routing } from "../../shared/route/routeDecorator";
import { BaseComponent } from "../shared";
import { AppStoreSubscriptionManager, RouteRedirector } from "../shared/providers";
import { AccountDataMessageProvider, AccountDataStore } from "../shared/providers/accountData";
import { SessionStorageService } from "../shared/providers/sessionStorageService";


@Routing({ path: 'logout' })
@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: './logoutComponent.html',
  providers: [AppStoreSubscriptionManager]
})
export class LogoutComponent extends BaseComponent {

  constructor(
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    public accountMessageProvider: AccountDataMessageProvider,
    public sessionStorageService: SessionStorageService
  ) {
    super();
  }

  ngOnInit() {
    this.logout();
    this.routeRedirector.redirectAfterLogout();
  }

  logout() {
    this.accountStore.logOutUser();
  }

  ngOnDestroy() {
    this.appStoreSubscriptionManager.dispose();
    super.ngOnDestroy();
  }
}