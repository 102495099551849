import { Component, Inject } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { BaseComponent } from "../shared";
import { ConfPageSessionService, ConfRouteParams, ConfiguratorLayoutManager, ConfiguratorUIStore, ConfigurationSessionManager, ConfDataResolveResponse, ConfiguratorStore } from "./providers";
import { RouteRedirector, RouteNames } from "../shared/providers";
import { ProductDataStore, ProductDataActionCreator } from "../shared/providers/productData";
import { ConfDataActionCreator } from "../shared/providers/configurationData";
import { PageStore } from "../shared/providers/page";
import { CompositeActionService, CompositeActions } from "./actions/composite/compositeActionService";
import { Product, ConfInfo, ConfDataResponse } from "../shared/models";

@Component({
  selector: 'conf-view-redirector',
  templateUrl:'./confViewRedirectorComponent.html',
})
export class ConfViewRedirectorComponent extends BaseComponent {

  constructor(
    @Inject(ConfigurationSessionManager) public confSessionManager: ConfigurationSessionManager,    
    @Inject(Router) public router: Router,
    @Inject(ActivatedRoute) public activatedRoute: ActivatedRoute,
    @Inject(ConfPageSessionService) public confSessionService: ConfPageSessionService,
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(ConfiguratorLayoutManager) public uiNormalizer: ConfiguratorLayoutManager,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(PageStore) public pageStore: PageStore,
    @Inject(ProductDataActionCreator) protected productActionCreator: ProductDataActionCreator,
    @Inject(ConfDataActionCreator) public confActionCreator: ConfDataActionCreator,    
    @Inject(CompositeActionService) public compositeAction: CompositeActionService,        
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector) {
    super();
  }

  ngOnInit() {

    // If the process is terminated then configuration component would be destroyed and it would set the pageId null.
    if (!this.confSessionService.pageId || !this.activatedRoute.snapshot.data || this.activatedRoute.snapshot.data.length == 0)
      return;

    let resolvedResponse: ConfDataResolveResponse = this.activatedRoute.snapshot.data[0] as ConfDataResolveResponse;

    let params = new ConfRouteParams();

    let confResponse = resolvedResponse.confData.data as ConfDataResponse;

    let rootConfInfo: ConfInfo = confResponse.confInfos.entities.get(resolvedResponse.conf.rootId);
    let subConfId = +this.routeRedirector.readFromRouteUrl(ConfRouteParams.SUB_CONF_ID);

    params.id = resolvedResponse.conf.longId;
    params.confSessionId = resolvedResponse.confSessionId;
    params.view = resolvedResponse.view;
        
    if (subConfId)
      params.subConfId = subConfId;

    if (rootConfInfo.showChildrenInAccordionTabs && resolvedResponse.conf.longId != rootConfInfo.longId && params.view != RouteNames.Summary) {

      // As requested configuration Id is not root configuration id. 
      // System redirects to rootId and moves the requested conf id to subConfId.
      params.id = rootConfInfo.longId;
      params.subConfId = resolvedResponse.conf.longId;

      // TODO: We might need to pass on the query params(extraArgs) from active route.
      this.routeRedirector.redirectToDataLoaderComponent(params, {});
      return;

    }    

    if (resolvedResponse.productData) {
      this.productActionCreator.dispatchProductData(resolvedResponse.productData);
    }

    if (resolvedResponse.confData) {      
      this.confActionCreator.dispatchConfData(resolvedResponse.confData);
    }

    // Set UI data to store.
    this.confUIStore.setConfId(params.id, this.confSessionService.pageId);
    this.confUIStore.setPageConfIdentifier(this.confSessionService.pageId, resolvedResponse.conf.longId, resolvedResponse.confSessionId);
    this.uiNormalizer.initLayoutSettings(this.confSessionService.activeConfigurationId, this.confSessionService.confSessionId, this.confSessionService.pageId);

    // Active view must be updated to store after data load.
    this.pageStore.updateActiveRoute(resolvedResponse.view);

    this.routeRedirector.redirectToConfViewAfterDataLoad(params, { replaceUrl: resolvedResponse.isFirstLoad }).then(x =>
    this.compositeAction.send(CompositeActions.NavigationEndedAfterDataLoad));
    
  }
}