import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from "../../../../shared/providers";
import { ConfAttachmentRequest, ConfDocumentRequest } from "../../models";
import { ConfCodeRequest } from "../../models/requests/confCodeRequest";
import { ConfDataRequest } from "../../models/requests/confDataRequest";
import { ConfGraphicsRequest } from "../../models/requests/confGraphicsRequest";
import { ConfReportRequest } from "../../models/requests/confReportRequest";
import { ApiResponse } from "../../models/responses";
import { ApiResponseBuilder } from "../apiResponseBuilder";
import { BaseController } from '../baseController';
import { GlobalDataStore } from "../globalData/globalDataStore";

// MOVE THIS CLASS TO PAGES/SHARED/PROVIDERS

@Injectable()
export class ConfDataController extends BaseController {

  public configuratorControllerUrl = 'api/configurator';

  constructor(
    @Inject(HttpService) public httpService: HttpService,
    public apiResponseBuilder: ApiResponseBuilder,
    public globalDataStore: GlobalDataStore
  ) {
    super(httpService, apiResponseBuilder);
  }

  public getConfigurationData(requestModel: ConfDataRequest): Observable<ApiResponse> {
    return this.httpService.get(this.configuratorControllerUrl, requestModel).pipe(this.buildResponseObject);
  }
  
  public postConfigurationData(requestModel: ConfDataRequest): Observable<ApiResponse> {
    return this.httpService.post(this.configuratorControllerUrl, requestModel).pipe(this.buildResponseObject);
  }

  public postConfigurationDataExpectOptionalDownload(requestModel: ConfDataRequest): Observable<ApiResponse> {
    return this.httpService.postExpectOptionalDownload(this.configuratorControllerUrl, requestModel).pipe(this.buildResponseObject);
  }
  
  public getConfigurationReportUrl(model: ConfReportRequest ) {
    return this.httpService.createUrl(`${this.configuratorControllerUrl}/report`, model);
  }

  public getConfigurationCodeUrl(model: ConfCodeRequest) {
    return this.httpService.createUrl(`${this.configuratorControllerUrl}/code`, model);
  }

  public getConfigurationAttachmentUrl(model: ConfAttachmentRequest) {
    return this.httpService.createUrl(`${this.configuratorControllerUrl}/attachment`, model);
  }

  public getConfigurationDocumentUrl(model: ConfDocumentRequest) {
    return this.httpService.createUrl(`${this.configuratorControllerUrl}/document`, model);
  }

  public getConfigurationGraphicUrl(model: ConfGraphicsRequest) {
    return this.httpService.createUrl(`${this.configuratorControllerUrl}/graphics`, model);
  }

  public getConfigurationGraphicViewerUrl(model: ConfGraphicsRequest) {

    let baseUrl = this.globalDataStore.getGlobalData().webSettings.baseUrl;

    let queryString = this.httpService.createUrl(`${baseUrl}/${this.configuratorControllerUrl}/graphics`, model);

    return `${baseUrl}/contents/svg-viewer.html?${queryString}`;
  }

  public getControllerUrl(): string {
    return this.configuratorControllerUrl;
  }

}