import { Injectable } from '@angular/core';
import * as Immutable from "immutable";

import { AppAction } from "../shared/appAction";
import { PushMessageState } from "../shared/pushMessageState";
import { Actions } from "../shared/actions";
import { ServerPushMessage, UIAction, UIElement } from "../../models";
import { PushMessageSelection } from '../../providers/pushMessage/pushMessageSelection';
import { PushMessageData } from '../../models/entities/pushMessageData';
import { PushData } from '../shared/pushData';

interface IUIEntityRepository<T> {
  oldUIEntities: Immutable.Map<number, T>,
  newUIEntities: Immutable.Map<number, T>,
  result?: Immutable.Map<number, T>
}

interface IValuesRepository {
  oldUIEntities: Immutable.Map<string, string>,
  newUIEntities: Immutable.Map<string, string>,
  result?: Immutable.Map<string, string>
}

@Injectable()
export class PushMessageReducer {

  public getReducer() {

    let defaultState = new PushMessageState();
    defaultState = defaultState.setDataBySessionId(Immutable.Map<number, PushData>());

    return (state = defaultState, action: AppAction<any>): PushMessageState => {

      if (action.type == Actions.CLEAR_CLIENT_CACHE) {
        return defaultState;
      }

      if (action.type == Actions.PUSH_MESSAGE_SELECTION) {

        if (!(action.payload instanceof PushMessageSelection))
          return state;

        let pushMessageSelection = action.payload as PushMessageSelection;

        let pushData = state.dataBySessionId.get(pushMessageSelection.sessionId);

        let pushMessageSessionData = pushData.values;

        if (pushMessageSelection.clearSession) {
          pushMessageSessionData = Immutable.Map<string, string>();
        }

        // Don't set a value without a key.
        if (pushMessageSelection.key) {

          if (!pushMessageSessionData) {
            pushMessageSessionData = Immutable.Map<string, string>();
          }

          pushMessageSessionData = pushMessageSessionData.set(pushMessageSelection.key, pushMessageSelection.value);
        }

        pushData = pushData.setValues(pushMessageSessionData);

        let newPushData = state.dataBySessionId.set(pushMessageSelection.sessionId, pushData);
        state = state.setDataBySessionId(newPushData);
      }
      else if (action.type == Actions.PUSH_MESSAGE_LOADED) {

        if (!(action.payload.data instanceof PushMessageData))
          return state;

        //if (this.number > 0)
        //  console.log("Needs lock.");
        //this.number = this.number + 1;

        state = this.updatePushMessageData(action.payload.data, state);

        /*        this.number = this.number - 1;*/
      }
      else if (action.type == Actions.PUSH_MESSAGE_REFRESHING) {
        state = state.setRefreshing(action.payload);
      }
      else if (action.type == Actions.PUSH_MESSAGE_REDIRECTING) {
        state = state.setRedirecting(action.payload);
      }

      return state;
    }
  }

  updatePushMessageData(pushMessageData: PushMessageData, state: PushMessageState): PushMessageState {

    if (!state.dataBySessionId.has(pushMessageData.interactionSessionId)) {
      let pushData = new PushData();

      let serverPushMessage = new ServerPushMessage();

      if (pushMessageData.uiActions)
        pushData = pushData.setUiActionById(pushMessageData.uiActions);

      if (pushMessageData.uiElements)
        pushData = pushData.setUiElementById(pushMessageData.uiElements);

      if (pushMessageData.values)
        pushData = pushData.setValues(pushMessageData.values);

      serverPushMessage = serverPushMessage.setExtraArgs(pushMessageData.extraArgs);
      serverPushMessage = serverPushMessage.setInteractionSessionId(pushMessageData.interactionSessionId);
      serverPushMessage = serverPushMessage.setLongId(pushMessageData.longId);
      serverPushMessage = serverPushMessage.setStartUIAction(pushMessageData.startUIAction);
      serverPushMessage = serverPushMessage.setUiActionStructureById(pushMessageData.uiActionStructureById);

      pushData = pushData.setServerPushMessage(serverPushMessage);

      let newPushData = state.dataBySessionId.set(pushMessageData.interactionSessionId, pushData);
      state = state.setDataBySessionId(newPushData);
    }
    else {
      let pushData = state.dataBySessionId.get(pushMessageData.interactionSessionId);

      let serverPushMessage = pushData.serverPushMessage;

      if (serverPushMessage.extraArgs != pushMessageData.extraArgs)
        serverPushMessage = serverPushMessage.setExtraArgs(pushMessageData.extraArgs);

      if (serverPushMessage.longId != pushMessageData.longId)
        serverPushMessage = serverPushMessage.setLongId(pushMessageData.longId);

      if (serverPushMessage.startUIAction != pushMessageData.startUIAction)
        serverPushMessage = serverPushMessage.setStartUIAction(pushMessageData.startUIAction);

      if (pushMessageData.uiElements) {

        let oldUIElementsById = pushData.uiElementById;
        let newUIElementsById = pushMessageData.uiElements;

        let uiEntityRepository: IUIEntityRepository<UIElement> = <IUIEntityRepository<UIElement>>{
          oldUIEntities: oldUIElementsById,
          newUIEntities: newUIElementsById
        };

        this.initUIEntityDataSynchronization(uiEntityRepository);
        pushData = pushData.setUiElementById(uiEntityRepository.result);
      }

      if (pushMessageData.values) {

        let oldUIElementsById2 = pushData.values;
        let newUIElementsById2 = pushMessageData.values;

        let valuesRepository: IValuesRepository = <IValuesRepository>{
          oldUIEntities: oldUIElementsById2,
          newUIEntities: newUIElementsById2
        };

        this.initValuesSynchronization(valuesRepository);
        pushData = pushData.setValues(valuesRepository.result);
      }

      if (pushMessageData.uiActions) {

        let oldUIActionsById = pushData.uiActionById;
        let newUIActionsById = pushMessageData.uiActions;

        let uiEntityRepository: IUIEntityRepository<UIAction> = <IUIEntityRepository<UIAction>>{
          oldUIEntities: oldUIActionsById,
          newUIEntities: newUIActionsById
        };

        this.initUIEntityDataSynchronization(uiEntityRepository);
        pushData = pushData.setUiActionById(uiEntityRepository.result);
      }

      if (pushMessageData.uiActionStructureById) {

        let oldUIActionStructureById = serverPushMessage.uiActionStructureById;
        let newUIActionStructureById = pushMessageData.uiActionStructureById;

        if (!oldUIActionStructureById)
          serverPushMessage = serverPushMessage.setUiActionStructureById(newUIActionStructureById);
        else {

          let result = oldUIActionStructureById;

          newUIActionStructureById.forEach((newUIActionStructure, key) => {

            if (!result.has(key))
              result = result.set(key, newUIActionStructure);

            if (!this.isEqual(result.get(key), newUIActionStructure))
              result = result.set(key, newUIActionStructure);

          });

          // Remove old ui actions.
          oldUIActionStructureById.keySeq().toArray().forEach((key) => {

            if (!newUIActionStructureById.has(key))
              result = result.remove((key));
          });

          serverPushMessage = serverPushMessage.setUiActionStructureById(result);
        }

      }

      pushData = pushData.setServerPushMessage(serverPushMessage);

      let newPushData = state.dataBySessionId.set(pushMessageData.interactionSessionId, pushData);
      state = state.setDataBySessionId(newPushData);
    }

    return state;
  }

  initValuesSynchronization(repository: IValuesRepository): void {

    if (!repository.oldUIEntities) {
      repository.result = repository.newUIEntities;
      return;
    }

    this.synchronizeValues(repository);

  }

  synchronizeValues(repository: IValuesRepository): void {

    if (!repository.result)
      repository.result = repository.oldUIEntities;

    let newUIEntitiesIds = repository.newUIEntities.keySeq().toArray();
    newUIEntitiesIds.forEach(id => {

      let oldEntity = repository.oldUIEntities.get(id);
      let newEntity = repository.newUIEntities.get(id);

      if (!this.isEqual(oldEntity, newEntity))
        this.mergeValues(repository, oldEntity, newEntity, id);

    });

    if (repository.oldUIEntities) {
      this.removeOldValues(repository);
    }

  }

  mergeValues(repository: IValuesRepository, oldUIEntity: string, newUIEntity: string, id: string): void {

    if (!oldUIEntity) {
      repository.result = repository.result.set(id, newUIEntity);
      return;
    }

    repository.result = repository.result.set(id, newUIEntity);
  }

  removeOldValues(repository: IValuesRepository) {

    if (!repository.oldUIEntities)
      return;

    let keys = repository.oldUIEntities.keySeq().toArray();
    keys.forEach(key => {

      // If old is not in the new then delete from result.
      if (!repository.newUIEntities.has(key))
        repository.result = repository.result.remove(key);

    });

  }

  initUIEntityDataSynchronization<T>(repository: IUIEntityRepository<T>): void {

    if (!repository.oldUIEntities) {
      repository.result = repository.newUIEntities;
      return;
    }

    this.synchronizeUIEntityData(repository);

  }

  synchronizeUIEntityData<T>(repository: IUIEntityRepository<T>): void {

    if (!repository.result)
      repository.result = repository.oldUIEntities;

    let newUIEntitiesIds = repository.newUIEntities.keySeq().toArray();
    newUIEntitiesIds.forEach(id => {

      let oldEntity = repository.oldUIEntities.get(id);
      let newEntity = repository.newUIEntities.get(id);

      if (!this.isEqual(oldEntity, newEntity))
        this.merge(repository, oldEntity, newEntity, id);

    });

    if (repository.oldUIEntities) {
      this.removeOldUIEntities(repository);
    }

  }

  merge<T>(repository: IUIEntityRepository<T>, oldUIEntity: T, newUIEntity: T, id: number): void {

    if (!oldUIEntity) {
      repository.result = repository.result.set(id, newUIEntity);
      return;
    }

    repository.result = repository.result.set(id, newUIEntity);
  }

  removeOldUIEntities<T>(repository: IUIEntityRepository<T>) {

    if (!repository.oldUIEntities)
      return;

    let keys = repository.oldUIEntities.keySeq().toArray();
    keys.forEach(key => {

      // If old is not in the new then delete from result.
      if (!repository.newUIEntities.has(key))
        repository.result = repository.result.remove(key);

    });

  }

  isEqual(oldEntity: any, newEntity: any): boolean {
    return JSON.stringify(oldEntity) === JSON.stringify(newEntity);
  }
}