import { Injectable, Inject } from "@angular/core";
import * as Immutable from 'immutable';
import { Conf, RequestViews, GetCommand, ConfDataResponse, ApiResponse, ConfInfo, ProductCommand, ProductDataResponse, TabOptions, VisualObjectOptions, CreateCommand, Product } from "../../shared/models";
import { ConfiguratorStore } from "./configuratorStore";
import { ProductDataStore, ProductDataController } from "../../shared/providers/productData";
import { ConfRouteParams } from "./confRouteParams";
import { ConfDataResolveResponse } from "./confDataResolveResponse";
import { PageActionCreator } from "../../shared/providers/page";
import { ConfiguratorUIStore } from "./configuratorUIStore";
import { PageStore } from "../../shared/providers/page/pageStore";
import { ConfDataController } from "../../shared/providers/configurationData";
import { ModelFactory, RouteNames, RouteRedirector } from "../../shared/providers";
import { GlobalDataStore } from "../../shared/providers/globalData";
import { ConfPageSessionService } from "./confPageSessionService";
import { param } from "jquery";

@Injectable()
export class ConfigurationSessionManager {

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(PageActionCreator) public pageActionCreator: PageActionCreator,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(ConfDataController) public controller: ConfDataController,
    @Inject(ConfPageSessionService) public confSessionService: ConfPageSessionService,    
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(PageStore) public pageStore: PageStore,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(ProductDataController) public productController: ProductDataController,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector
  ) { }


  public async setupLazyLoadingData(params: ConfRouteParams): Promise<ConfDataResolveResponse> {

    if (params.id) {
      const response = await this.getLoadConfigurationPromise(params);
      return await this.getResolvedDataModel(response, params);
    }

    if (params.productId) {
      const newConfResponse = await this.getCreateConfigurationPromise(params);
      return await this.getResolvedDataModel(newConfResponse, params);
    }

    // Handle reuse case
    if (params.reuse) {
      const reuseResponse = await this.getReusePromise(params);

      let confResponse = (reuseResponse.data as ConfDataResponse);
      params.confSessionId = confResponse.confSessionId;
      params.id = confResponse.rootConfId;

      // Resolve the data.
      return await this.getResolvedDataModel(reuseResponse, params);
    }

    // Handle revise case
    if (params.revise) {

      const reviseResponse = await this.getRevisePromise(params);

      let confResponse = (reviseResponse.data as ConfDataResponse);
      params.confSessionId = confResponse.confSessionId;
      params.id = confResponse.rootConfId;

      // Resolve the data.
      return await this.getResolvedDataModel(reviseResponse, params);

    }

  }

  /**
   * Returns the load configuration promise.
   * @param pageId
   * @param params
   */
  getLoadConfigurationPromise(params: ConfRouteParams): Promise<ApiResponse> {

    // Active view must be updated to store
    let clientType: RequestViews = this.pageStore.translateRouteToClientType(params.view);

    // Async request, but request Id must be returned.    
    let model = this.confStore.createRequest(clientType, { confSessionId: params.confSessionId });
    model.confSessionId = params.confSessionId;
    model.get = this.modelFactory.createRequestOrCommand<GetCommand>(GetCommand.name, { configurationId: params.id, extraArgs: Immutable.Map(params.extraArgs) });
    model.get.responseFormat = this.globalDataStore.getConfResponseFormat(clientType);


    return this.controller.getConfigurationData(model).toPromise<ApiResponse>();

  }

  /**
 * Creates configuration promise.
 * @param params
 */
  getCreateConfigurationPromise(params: ConfRouteParams): Promise<any> {

    let model = this.confStore.createRequest(RequestViews.Editor);
    model.create = this.modelFactory.createRequestOrCommand<CreateCommand>(CreateCommand.name, { productId: params.productId, confSessionId: params.confSessionId, confParams: Immutable.Map(params.confParams), extraArgs: Immutable.Map(params.extraArgs) });    
    model.create.responseFormat = this.globalDataStore.getConfResponseFormat(RequestViews.Editor);

    if (params.confSessionId)
      model.confSessionId = params.confSessionId;

    return this.controller.getConfigurationData(model).toPromise<ApiResponse>();
    
  }

  /**
   * Creates the promise for reuse.
   * @param params
   */
  getReusePromise(params: ConfRouteParams): Promise<any> {

    let model = this.confStore.createRequest(RequestViews.Editor);
    model.get = this.modelFactory.createRequestOrCommand<GetCommand>(GetCommand.name, { reuse: true, configurationId: params.reuse, extraArgs: Immutable.Map(params.extraArgs) });
    model.get.responseFormat = this.globalDataStore.getConfResponseFormat(RequestViews.Editor);

    return this.controller.getConfigurationData(model).toPromise<ApiResponse>();
  }

  /**
   * Creates the promise for revise.
   * @param params
   */
  getRevisePromise(params: ConfRouteParams): Promise<any> {

    let model = this.confStore.createRequest(RequestViews.Editor);
    model.get = this.modelFactory.createRequestOrCommand<GetCommand>(GetCommand.name, { revise: true, configurationId: params.revise, extraArgs: Immutable.Map(params.extraArgs) });
    model.get.responseFormat = this.globalDataStore.getConfResponseFormat(RequestViews.Editor);

    return this.controller.getConfigurationData(model).toPromise<ApiResponse>();
  }

  /**
   * Returns the resolver data model which contains configuration and product api response.
   * @param apiResponse
   * @param params
   */
  protected getResolvedDataModel(apiResponse: ApiResponse, params: ConfRouteParams): Promise<ConfDataResolveResponse> {

    return new Promise<ConfDataResolveResponse>((resolve, reject) => {

      let data: ConfDataResolveResponse = new ConfDataResolveResponse();
      if (params.productId || params.reuse || params.revise || !params.confSessionId)
        data.isFirstLoad = true;

      // Stores all product promises.
      let productsPromises: Promise<any>[] = [];
      let confResponse = apiResponse.data as ConfDataResponse;

      if (!confResponse || !confResponse.entities || confResponse.entities.size == 0) {
        resolve(data);
        return;
      }

      // In create case, only product id is available, read the confId from response.
      if (!params.id)
        params.id = confResponse.rootConfId;

      // Requested conf
      let conf: Conf = confResponse.entities.get(+params.id).get(+params.id) as Conf;

      // Set values.
      data.confData = apiResponse;
      data.conf = conf;
      data.confSessionId = confResponse.confSessionId;
      data.view = params.view;
      this.confSessionService.referralLink = confResponse.referralLink;

      let rootConfInfo: ConfInfo = confResponse.confInfos.entities.get(+conf.rootId);

      if (rootConfInfo.showChildrenInAccordionTabs && rootConfInfo.longId != conf.longId && data.view != RouteNames.Summary) {
        data.invalidateUrl = true;
        resolve(data);

        // Don't need to continue as requested configurationId is not a valid root active configuration.
        return;
      }

      // Request to load product data.      
      let productIds = [rootConfInfo.productId];
      let activeConfProductId = confResponse.confInfos.entities.get(+params.id)?.productId;
      if (activeConfProductId && rootConfInfo.productId != activeConfProductId)
        productIds.push(activeConfProductId);

      if (params.subConfId) {
        let subConf = confResponse.confInfos.entities.get(+params.subConfId);
        if (subConf && productIds.indexOf(activeConfProductId) < 0) {
          productIds.push(subConf.productId);
        }
      }

      // Load requested configuration's product data
      productsPromises.push(this.tabsDataResponse(productIds));
      
      Promise.all(productsPromises).then(responses => {        
        data.productData = responses;
        // Note: We have to set the accordion composite state before data merge with 'Store' to prevent component listenings.
        this.setShowChildrenInAccordionTabs(responses, rootConfInfo.productId);
        resolve(data)
      });
      
    });
  }

  public setShowChildrenInAccordionTabs(responses: any[], productId: number) {

    for (let res of responses) {

      if (res) {        
        let product = res.data.entities.get(productId);
        if (product && product.showChildrenInAccordionTabs) {
          this.confSessionService.setChildrenInAccordionTabs(true);
          return;
        }

      }

    }
  }

  public tabsDataResponse(productIds: number[]): Promise<ApiResponse> {

    // Is data already loaded?.
    if (this.isProductDataLoaded(productIds)) {
      return new Promise<any>(resolve => resolve(null));
    }

    let model = this.productStore.createProductRequestModel();
    model.product = this.modelFactory.createRequestOrCommand<ProductCommand>(ProductCommand.name, { ids: productIds, includeNonEffective: true });
    model.product.tab = this.modelFactory.createAny<TabOptions>(TabOptions.name, {});
    model.product.tab.visualObject = this.modelFactory.createAny<VisualObjectOptions>(VisualObjectOptions.name, {});  
    
    return this.productController.getProductData(model).toPromise();

  }

  public isProductDataLoaded(productIds: number[]): boolean {

    let isProductDataLoaded = false;
    productIds.forEach(pid => {

      let loaded = this.productStore.productTabsLoaded(pid);
      if (!loaded) {
        isProductDataLoaded = false;
        return;
      }

      isProductDataLoaded = true;
    });

    return isProductDataLoaded;
  }

}
