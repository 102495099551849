<div #visualObjectContainer *ngIf="confMultiChoiceValue">
  <div class="visual-object-margin">
    <multi-choice-group [view]="viewModel"
                        (onHelpClick)="onHelpClick($event)">

      <multi-choice-value *ngFor="let view of multiChoiceItemsViews"
                          [multiChoiceParamId]="multiChoiceParamId"
                          [view]="view"
                          (viewChanged)="onItemViewChanged($event)"
                          [configurationId]="configurationId"
                          [value]="view.value"
                          >
      </multi-choice-value>

    </multi-choice-group>
    <!-- TODO implement errors in dropdown and radio group -->
    <div *ngFor="let error of viewModel.errors" class="form-control-feedback d-block">{{error}}</div>
  </div>
</div>