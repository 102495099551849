import { Inject, Injectable } from "@angular/core";
import { Conf, Tab, Param } from "../../shared/models";
import { ConfRouteParams } from "../providers/confRouteParams";
import { ConfPageSessionService } from "../providers/confPageSessionService";
import { AbstractUIVisibilityService } from "./abstractUIVisibilityService";
import { RouteRedirector } from "../../shared/providers/routeRedirector";
import { TabDisplayStyle } from "../../shared/providers/tabDisplayStyle";
import { ProductDataStore } from "../../shared/providers/productData";
import { searchUrlQueryParam } from "../../../shared/utils/routeParamsUtils";

@Injectable()
export class ConfVisiblityService extends AbstractUIVisibilityService {
  
  constructor(private pageSession: ConfPageSessionService, private routeRedirector: RouteRedirector, private productStore: ProductDataStore) {
    super();
  }

  public isVisible(confId: number): boolean {

    if (this.pageSession.activeConfigurationId == confId)
      return true;

    let subConfId = searchUrlQueryParam(ConfRouteParams.SUB_CONF_ID);
    return +subConfId == confId;
  }

  public isRoot(confId: number): boolean {
    return this.pageSession.activeConfigurationId == confId;
  }

  public isParamInAccordion(visualObjectId: number): boolean {

    let param: Param = this.productStore.getEntity(visualObjectId);
    if (!param)
      return false;

    let tab: Tab = this.productStore.getEntity(param.tabId);
    return tab && tab.displayStyle == TabDisplayStyle.Accordion || tab.displayStyle == TabDisplayStyle.AccordionChildren || tab.displayStyle == TabDisplayStyle.AccordionHeaderTab;

  }

  isSubConfVisible(): boolean {
    let subConfId = searchUrlQueryParam(ConfRouteParams.SUB_CONF_ID);
    return !!subConfId;
  }

}