import { Injectable, Inject } from '@angular/core';
import { AppStore } from "../../state/appStore";
import { ConfDataController } from "./confDataController";
import { ConfDataRequest, ApiResponse} from "../../models";
import { BaseActionCreator } from '../../baseActionCreator';
import { AppAction } from '../../state/shared/appAction';
import { ConfDataActions } from '../../state/configurationData/confDataActions';
import { HttpAction } from '../../state/shared/httpAction';
import { RequestStatus } from '../../state/shared/requestStatus';
import { ActionStatus } from '../../state/shared/actionStatus';
import { Actions } from '../../state/shared/actions';
import { PushMessageSelection } from '../pushMessage/pushMessageSelection';

@Injectable()
export class ConfDataActionCreator extends BaseActionCreator {

  constructor(
    @Inject(ConfDataController) public confController: ConfDataController,
    @Inject(AppStore) public appStore: AppStore,
  ) {
    super(confController);
  }

  setActiveConfiguration(configurationId: number) {
    let appAction: AppAction<number> = {
      type: ConfDataActions.ACTIVE_CONFIGURATION,
      payload: configurationId,
    }
    return appAction;
  }

  dispatchCreateConfiguration = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.createConfiguration(requestModel)) as HttpAction<ApiResponse>;
  createConfiguration(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      ConfDataActions.CREATE_CONFIGURATION,

      (dispatch, state, callback) => {

        this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {

          // Dispatch configuration ready/loaded action.
          dispatch(this.configurationLoaded(configurationDataResult));

          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }
  
  dispatchImportIdentitiesHints = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.importIdentitiesHints(requestModel)) as HttpAction<ApiResponse>;
  importIdentitiesHints(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      ConfDataActions.IMPORT_IDENTITIES,

      (dispatch, state, callback) => {

        this.confController.postConfigurationData(requestModel).subscribe((response) => {

          dispatch({ type: ConfDataActions.IMPORT_IDENTITIES_RESULT, payload: response });

          callback(new ActionStatus(true, response, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchCreateChildConfiguration = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.createChildConfiguration(requestModel)) as HttpAction<ApiResponse>;
  createChildConfiguration(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      ConfDataActions.CREATE_CONFIGURATION,

      (dispatch, state, callback) => {

        this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {

          // Dispatch configuration ready/loaded action.
          dispatch(this.configurationLoaded(configurationDataResult));

          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchCreateDynamicIndexes = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.createDynamicIndexes(requestModel)) as HttpAction<ApiResponse>;
  createDynamicIndexes(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      ConfDataActions.CREATE_DYNAMIC_INDEXES,

      (dispatch, state, callback) => {

        this.confController.postConfigurationDataExpectOptionalDownload(requestModel).subscribe((configurationDataResult) => {

          // Dispatch configuration ready/loaded action.          
          dispatch(this.configurationLoaded(configurationDataResult));

          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        },
          (error) => { callback(new ActionStatus(true, error, RequestStatus.ERROR)); },
          () => { callback(new ActionStatus(true, null, RequestStatus.SUCCESS)); }
        )
      }
    );
  }

  dispatchJavaScriptAction = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.createJavaScriptAction(requestModel)) as HttpAction<ApiResponse>;
  createJavaScriptAction(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      ConfDataActions.JAVASCRIPT_ACTION,

      (dispatch, state, callback) => {

        this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {

          // Dispatch configuration ready/loaded action.
          dispatch(this.configurationLoaded(configurationDataResult));

          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchChangeConfigurationsOrder = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.reuseConfiguration(requestModel)) as HttpAction<ApiResponse>;
  changeConfigurationsOrder(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      ConfDataActions.ORDER_CHANGE_CONFIGURATION,

      (dispatch, state, callback) => {

        this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {
          // Dispatch configuration ready/loaded action.
          dispatch(this.configurationLoaded(configurationDataResult));

          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchReuseConfiguration = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.reuseConfiguration(requestModel)) as HttpAction<ApiResponse>;
  reuseConfiguration(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      ConfDataActions.REUSE_CONFIGURATION,

      (dispatch, state, callback) => {

        this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {
          // Dispatch configuration ready/loaded action.
          dispatch(this.configurationLoaded(configurationDataResult));

          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchReviseConfiguration = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.reviseConfiguration(requestModel)) as HttpAction<ApiResponse>;
  reviseConfiguration(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      ConfDataActions.REVISE_CONFIGURATION,

      (dispatch, state, callback) => {

        this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {
          // Dispatch configuration ready/loaded action.
          dispatch(this.configurationLoaded(configurationDataResult));

          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchDeleteConfiguration = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.deleteConfiguration(requestModel)) as HttpAction<ApiResponse>;
  deleteConfiguration(requestModel: ConfDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(
      ConfDataActions.DELETE_CONFIGURATION,
      (dispatch, state, callback) => {

        this.confController.postConfigurationData(requestModel).subscribe((apiResponse) => {

          dispatch(this.configurationLoaded(apiResponse));

          callback(new ActionStatus(true, apiResponse, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchChangeOwnership = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.changeOwnership(requestModel)) as HttpAction<ApiResponse>;
  changeOwnership(requestModel: ConfDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(
      ConfDataActions.DELETE_CONFIGURATION,
      (dispatch, state, callback) => {

        this.confController.postConfigurationData(requestModel).subscribe((apiResponse) => {

          dispatch(this.configurationLoaded(apiResponse));

          callback(new ActionStatus(true, apiResponse, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchRemoveConfiguratorSessions = (confSessionIds: number[]) => this.appStore.dispatch(this.removeConfiguratorSessions(confSessionIds)) as AppAction<number[]>;
  removeConfiguratorSessions(confSessionIds: number[]): AppAction<number[]> {
    return {
      type: Actions.REMOVE_CONFIGURATOR_SESSIONS,
      payload: confSessionIds
    };
  }

  dispatchCloseConfSession = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.closeConfSession(requestModel)) as HttpAction<ApiResponse>;
  closeConfSession(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      ConfDataActions.CLOSE_CONFIGURATOR_SESSION,

      (dispatch, state, callback) => {

        this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {

          // Dispatch remove configuration sessions from client side.
          dispatch(this.configurationLoaded(configurationDataResult));
          dispatch(this.removeConfiguratorSessions([requestModel.confSessionId]));

          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchMarkConfSessionForClosing = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.markConfSessionForClosing(requestModel)) as HttpAction<ApiResponse>;
  markConfSessionForClosing(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      ConfDataActions.MARK_CONFIGURATOR_SESSION_FOR_CLOSING,

      (dispatch, state, callback) => {

        this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {
                    
          dispatch(this.configurationLoaded(configurationDataResult));
          
          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchCopyConfiguration = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.copyConfiguration(requestModel)) as HttpAction<ApiResponse>;
  copyConfiguration(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      ConfDataActions.COPY_CONFIGURATION,

      (dispatch, state, callback) => {

        this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {

          // Dispatch configuration.
          dispatch(this.configurationLoaded(configurationDataResult));

          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchRequestCopyDestinations = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.requestCopyDestinations(requestModel)) as HttpAction<ApiResponse>;
  requestCopyDestinations(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      ConfDataActions.GET_COPY_CONFIGURATION_DESTINATIONS,

      (dispatch, state, callback) => {

        this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {

          // Dispatch configuration.
          dispatch(this.configurationLoaded(configurationDataResult));

          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchGetConfiguration = (requestModel: ConfDataRequest, storeSync: boolean = true) => this.appStore.dispatch(this.getConfiguration(requestModel, storeSync)) as HttpAction<ApiResponse>;
  getConfiguration(requestModel: ConfDataRequest, storeSync: boolean = true): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      ConfDataActions.GET_CONFIGURATION,

      (dispatch, state, callback) => {
        this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {

          if (storeSync)
            dispatch(this.configurationLoaded(configurationDataResult));

          // Succeeded
          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));

        })
      }
    );
  }

  dispatchEditConfiguration = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.editConfiguration(requestModel)) as HttpAction<ApiResponse>;
  editConfiguration(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      ConfDataActions.EDIT_CONFIGURATION,

      (dispatch, state, callback) => {
        this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {

          dispatch(this.configurationLoaded(configurationDataResult));

          // Succeeded
          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchContact = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.saveConfiguration(requestModel)) as HttpAction<ApiResponse>;
  contact(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(
      ConfDataActions.CONTACT,
      (dispatch, state, callback) => {
        return this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {
          dispatch(this.configurationLoaded(configurationDataResult));

          // Succeeded
          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchSaveConfiguration = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.saveConfiguration(requestModel)) as HttpAction<ApiResponse>;
  saveConfiguration(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(
      ConfDataActions.SAVE_CONFIGURATION,
      (dispatch, state, callback) => {
        return this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {
          dispatch(this.configurationLoaded(configurationDataResult));

          // Succeeded
          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchConfigurationLoaded = (apiResponse: ApiResponse) => this.appStore.dispatch(this.configurationLoaded(apiResponse)) as AppAction<ApiResponse>;
  configurationLoaded(apiResponse: ApiResponse): AppAction<ApiResponse> {
    return {
      type: Actions.CONFIGURATION_LOADED,
      payload: apiResponse
    };
  }

  dispatchSetConfigurationPrimitiveValue = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.setConfigurationPrimitiveValue(requestModel)) as HttpAction<ApiResponse>;
  public setConfigurationPrimitiveValue(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(
      ConfDataActions.SET_CONFIGURATION_VALUE,

      (dispatch, state, callback) => {
        return this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {
          dispatch(this.configurationLoaded(configurationDataResult));

          // Notify result complete.
          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchSetConfigurationStringValue = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.setConfigurationStringValue(requestModel)) as HttpAction<ApiResponse>;
  public setConfigurationStringValue(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(
      ConfDataActions.SET_CONFIGURATION_VALUE,

      (dispatch, state, callback) => {
        return this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {
          dispatch(this.configurationLoaded(configurationDataResult));

          // Notify result complete.
          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchSetConfigurationMultiSetValue = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.setConfigurationMultiSetValue(requestModel)) as HttpAction<ApiResponse>;
  public setConfigurationMultiSetValue(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(
      ConfDataActions.SET_CONFIGURATION_VALUE,

      (dispatch, state, callback) => {
        return this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {
          dispatch(this.configurationLoaded(configurationDataResult));

          // Notify result complete.
          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchSetConfigurationPropertyValue = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.setConfigurationPropertyValue(requestModel)) as HttpAction<ApiResponse>;
  public setConfigurationPropertyValue(requestModel: ConfDataRequest) {

    return this.createHttpAction<ApiResponse>(
      ConfDataActions.SET_CONFIGURATION_PROPERTY_VALUE,

      (dispatch, state, callback) => {
        return this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {
          dispatch(this.configurationLoaded(configurationDataResult))

          // Notify result complete.
          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchGetReadOnlyInfo = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.getReadOnlyInfo(requestModel)) as HttpAction<ApiResponse>;
  public getReadOnlyInfo(requestModel: ConfDataRequest) {

    return this.createHttpAction<ApiResponse>(
      ConfDataActions.GET_READ_ONLY_INFO,

      (dispatch, state, callback) => {
        return this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {
          dispatch(this.configurationLoaded(configurationDataResult))

          // Notify result complete.
          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchGetMandatoryInfo = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.getMandatoryInfo(requestModel)) as HttpAction<ApiResponse>;
  public getMandatoryInfo(requestModel: ConfDataRequest) {
    return this.createHttpAction<ApiResponse>(
      ConfDataActions.GET_MANDATORY_INFO,

      (dispatch, state, callback) => {
        return this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {
          dispatch(this.configurationLoaded(configurationDataResult))

          // Notify result complete.
          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchGetCodeFile = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.getCodeFile(requestModel)) as HttpAction<ApiResponse>;
  public getCodeFile(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(
      ConfDataActions.GET_CODE_FILE,

      (dispatch, state, callback) => {
        return this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {
          dispatch(this.configurationLoaded(configurationDataResult))

          // Notify result complete.
          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchGetPriceReport = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.getPriceReport(requestModel)) as HttpAction<ApiResponse>;
  public getPriceReport(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(
      ConfDataActions.GET_PRICE_REPORT,

      (dispatch, state, callback) => {
        return this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {
          dispatch(this.configurationLoaded(configurationDataResult))

          // Notify result complete.
          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchGetPriceSetting = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.getPriceSetting(requestModel)) as HttpAction<ApiResponse>;
  public getPriceSetting(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(
      ConfDataActions.GET_PRICE_SETTING,

      (dispatch, state, callback) => {
        return this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {
          dispatch(this.configurationLoaded(configurationDataResult))

          // Notify result complete.
          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchSetPriceSetting = (requestModel: ConfDataRequest) => this.appStore.dispatch(this.setPriceSetting(requestModel)) as HttpAction<ApiResponse>;
  public setPriceSetting(requestModel: ConfDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(
      ConfDataActions.SET_PRICE_SETTING,

      (dispatch, state, callback) => {
        return this.confController.postConfigurationData(requestModel).subscribe((configurationDataResult) => {
          dispatch(this.configurationLoaded(configurationDataResult))

          // Notify result complete.
          callback(new ActionStatus(true, configurationDataResult, RequestStatus.SUCCESS));
        })
      }
    );
  }

  dispatchValueChanged = (pushMessageSelection: PushMessageSelection) => this.appStore.dispatch(this.valueChanged(pushMessageSelection)) as AppAction<number[]>;
  valueChanged(pushMessageSelection): AppAction<PushMessageSelection> {
    return {
      type: Actions.FORM_SELECTION,
      payload: pushMessageSelection
    };
  }

  public createHttpAction<T>(actionType: any, executeAsync: any, payload?: T): HttpAction<T> {

    let httpAction: HttpAction<T> = {
      type: actionType,
      executeAsync: executeAsync,
      payload: payload
    }

    return httpAction;
  }

  dispatchConfData(response: ApiResponse): any {

    let action = this.createHttpAction<ApiResponse>(Actions.CONFIGURATION_LOADED, null, response);
    this.appStore.dispatch(action);
  }

}