import { Injectable, Inject } from '@angular/core';

import { AppStore } from "../../state/appStore";
import { AppAction, AppAsyncAction, HttpAction, ActionStatus, Actions } from "../../state";
import { PushMessageController } from "./pushMessageController";
import { PushMessageActions } from "../../state/pushMessage/pushMessageActions";
import { BaseActionCreator } from "../../baseActionCreator";
import { ApiResponse } from "../../models";
import { PushMessageSelection } from './pushMessageSelection';

@Injectable()
export class PushMessageActionCreator extends BaseActionCreator {

  constructor(    
    @Inject(AppStore) public appStore: AppStore,
    @Inject(PushMessageController) public pushMessageController: PushMessageController
    ) {
    super(pushMessageController);
  }
  
  dispatchSetServerPushMessage = (data: any) => this.appStore.dispatch(this.setServerPushMessage(data)) as HttpAction<ApiResponse>;
  setServerPushMessage(data: any): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      PushMessageActions.PUSH_MESSAGE,

      (dispatch, state, callback) => {

        return this.pushMessageController.getPushMessage(data).subscribe((pushMessageResult) => {

          //return (pushMessageResult) => {

          // Dispatch action type and results.
          dispatch({ type: PushMessageActions.PUSH_MESSAGE_LOADED, payload: pushMessageResult });

          // Update complete request status
          callback(new ActionStatus(true, pushMessageResult));
        })
      },
    );
  }

  dispatchValueChanged = (pushMessageSelection: PushMessageSelection) => this.appStore.dispatch(this.valueChanged(pushMessageSelection)) as AppAction<void>;
  valueChanged(pushMessageSelection): AppAction<PushMessageSelection> {
    return {
      type: Actions.PUSH_MESSAGE_SELECTION,
      payload: pushMessageSelection
    };
  }

  dispatchRefreshingChanged = (refreshing: boolean) => this.appStore.dispatch(this.refreshingChanged(refreshing)) as AppAction<void>;
  refreshingChanged(refreshing): AppAction<boolean> {
    return {
      type: Actions.PUSH_MESSAGE_REFRESHING,
      payload: refreshing
    };
  }

  dispatchRedirectingChanged = (redirecting: boolean) => this.appStore.dispatch(this.redirectingChanged(redirecting)) as AppAction<void>;
  redirectingChanged(redirecting): AppAction<boolean> {
    return {
      type: Actions.PUSH_MESSAGE_REDIRECTING,
      payload: redirecting
    };
  }
}