import * as Immutable from "immutable";

import { BaseEntity } from "../../baseEntity";
import { ConfInfo, UIElement } from "../../models";
import { AbstractConfigurationMessage } from "../../models/responses/messages/abstractConfigurationMessage";
import { ImmutableObject } from "../../../../shared/immutableObject";

export class ConfSessionData extends ImmutableObject {

  public readonly ENTITIES_BY_CONF_ID = "entitiesByConfId";
  protected readonly IS_CLOSED = "isClosed";
  protected readonly HAS_UNSAVED_CHANGES = "hasUnsavedChanges";
  public readonly CONF_SESSION_ID = "confSessionId";
  public readonly ROOT_CONF_ID = "rootConfId";
  public readonly MESSAGES = "messages";
  public readonly COMPOSITE_STRUCTURE = "compositeStructure";
  protected readonly UI_ELEMENTS = "uiElements";
  protected readonly VALUE_BY_ELEMENT_ID = "valueByElementId";

  get entitiesByConfId(): Immutable.Map<number, Immutable.Map<number, BaseEntity>> { return this.getInternalValue<Immutable.Map<number, Immutable.Map<number, BaseEntity>>>(this.ENTITIES_BY_CONF_ID); }
  setEntitiesByConfId(entitiesByConfId: Immutable.Map<number, Immutable.Map<number, BaseEntity>>): this { return this.setInternalValue(this.ENTITIES_BY_CONF_ID, entitiesByConfId); }  

  get isClosed(): boolean { return this.getInternalValue<boolean>(this.IS_CLOSED); }
  setIsClosed(isClosed: boolean): this { return this.setInternalValue(this.IS_CLOSED, isClosed); }

  get hasUnsavedChanges(): boolean { return this.getInternalValue<boolean>(this.HAS_UNSAVED_CHANGES); }
  setHasUnsavedChanges(hasUnsavedChanges: boolean): this { return this.setInternalValue(this.HAS_UNSAVED_CHANGES, hasUnsavedChanges); }

  get confSessionId(): number { return this.getInternalValue<number>(this.CONF_SESSION_ID); }
  setConfSessionId(confSessionId: number): this { return this.setInternalValue(this.CONF_SESSION_ID, confSessionId); }

  get rootConfId(): number { return this.getInternalValue<number>(this.ROOT_CONF_ID); }
  setRootConfId(rootConfId: number): this { return this.setInternalValue(this.ROOT_CONF_ID, rootConfId); }
  
  get messages(): Immutable.Map<string, Immutable.List<AbstractConfigurationMessage>> { return this.getInternalValue<Immutable.Map<string, Immutable.List<AbstractConfigurationMessage>>>(this.MESSAGES); }
  setMessages(messages: Immutable.Map<string, Immutable.List<AbstractConfigurationMessage>>): this { return this.setInternalValue(this.MESSAGES, messages); }

  get compositeStructure(): Immutable.Map<number, ConfInfo> { return this.getInternalValue<Immutable.Map<number, ConfInfo>>(this.COMPOSITE_STRUCTURE); }
  setCompositeStructure(compositeStructure: Immutable.Map<number, ConfInfo>): this { return this.setInternalValue(this.COMPOSITE_STRUCTURE, compositeStructure); }

  get uiElements(): Immutable.Map<number, UIElement> { return this.getInternalValue<Immutable.Map<number, UIElement>>(this.UI_ELEMENTS); }
  setUiElements(uiElements: Immutable.Map<number, UIElement>): this { return this.setInternalValue(this.UI_ELEMENTS, uiElements); }

  get valueByElementId(): Immutable.Map<string, string> { return this.getInternalValue<Immutable.Map<string, string>>(this.VALUE_BY_ELEMENT_ID); }
  setValueByElementId(valueByElementId: Immutable.Map<string, string>): this { return this.setInternalValue(this.VALUE_BY_ELEMENT_ID, valueByElementId); }
}