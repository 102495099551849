import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';

import { RouteRedirector } from "./routeRedirector";
import { AccountDataStore } from "./accountData/accountDataStore";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(public routerRedirector: RouteRedirector,
    public accountStore: AccountDataStore,
    )
  {}

  public redirect(state: RouterStateSnapshot) {
    // Make sure that user is logged out to prevent bugs when trying to log in.
    // Also makes sure that there is an WebSessionToken available.
    this.accountStore.logOutUser();
    this.routerRedirector.redirectToLogin(state.url);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.accountStore.isUserLoggedIn())
      return true;
    
    this.redirect(state);
    return false;
  }
}