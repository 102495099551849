import { Component, Inject, ChangeDetectorRef } from "@angular/core";
import * as Immutable from "immutable"
import { BaseSearchInputComponent } from "../baseSearchInputComponent";
import { InputViewModel } from "../../../../../../shared/components/shared";
import { ModelLabelService } from "../../../../../shared/providers/modelLabelService";
import { ProductDataStore } from "../../../../../shared/providers/productData";
import { Product, Assortment, ConfSearchProperty, MultiChoiceParam, ParamValue, ConfSearchParameter, State, LookupParam, Param, ProductFamily } from "../../../../../shared/models";
import { MultiSelectItem, ItemStateType } from "../../../../../../shared/components/multiSelectDropdown";
import { SearchCriteriaValueEventArgs, SearchCriteriaRegion, AttributeField } from "../../models";
import { SearchCriteriaDataProvider } from "../../providers/searchCriteriaDataProvider";
import { BaseEntity } from "../../../../../shared/baseEntity";
import { ConfSearchCriteria } from "../../../../../shared/models";
import { SearchCriteriaHelper } from "../../providers";
import { GlobalDataStore } from "../../../../../shared/providers/globalData";

@Component({
  selector: 'multi-select-input',
  templateUrl: './multiSelectInputComponent.html',
})
export class MultiSelectInputComponent extends BaseSearchInputComponent {

  public items: Immutable.List<MultiSelectItem> = Immutable.List<MultiSelectItem>();

  public selectedItems: Array<MultiSelectItem> = [];

  public refClassName: string;

  public emptyTitle: string;

  public checkedIcon: string = 'checkmark';

  public uncheckedIcon: string = '';

  public enableInputItemIcon: boolean = false;

  public removeUncheckedItem: boolean = true;

  public triState: boolean = false;

  public iconImageSet: string = 'green';

  constructor(
    protected globalDataStore: GlobalDataStore,
    protected productDataStore: ProductDataStore,
    @Inject(ModelLabelService) protected modelLabelService: ModelLabelService,
    @Inject(SearchCriteriaDataProvider) protected searchCriteriaDataProvider: SearchCriteriaDataProvider,

    protected searchCriteriaHelper: SearchCriteriaHelper,
    public cd: ChangeDetectorRef
  ) {
    super(searchCriteriaHelper);
  }

  ngOnInit(): void {

    this.refClassName = this.criteria.selectedAttribute.valueType;
    if (this.refClassName == Product.name) {

      this.createItemsFromProducts();
    }
    else if (this.refClassName == MultiChoiceParam.name) {

      this.emptyTitle = this.strings.SelectValue;

      let param = this.criteria.selectedAttribute.tag as MultiChoiceParam;

      let paramValues: ParamValue[] = param.paramValues.map(valueId => this.productDataStore.getEntity(valueId) as ParamValue).toArray();

      this.checkedIcon = 'checked';
      this.uncheckedIcon = 'unchecked';
      this.enableInputItemIcon = true;
      this.removeUncheckedItem = false;
      this.triState = true;
      this.iconImageSet = 'primary';

      this.createItems(paramValues);
    }
    else if (this.criteria.selectedAttribute.id == 'StateId') {

      this.globalDataStore.getEntities(State.name).subscribe((storeResponse) => {

        let states: Set<State> = new Set<State>();

        storeResponse.data.forEach(x => states.add(x as State));

        // Create multi select items.     
        this.createItems(Array.from(states));
      }).unsubscribeOn(this.unsubscribeSubject);

    }
    else if (this.refClassName == LookupParam.name) {

      let paramValues: Array<ParamValue> = [];

      Array.from(this.criteria.selectedAttribute.tag.paramValues).forEach((longId) => {
        paramValues.push(this.createParamValue(longId));
      });

      this.createItems(paramValues);

    }

    super.ngOnInit();
  }
   
  public createParamValue(paramValue: any): ParamValue {
    return this.productDataStore.getEntity(paramValue as number) as ParamValue;
  }

  public createItemsFromProducts(): void {
    if (this.refClassName != Product.name)
      return;

    this.emptyTitle = this.strings.SelectProduct;

    // Make promised call to fetch out all products.
    this.searchCriteriaDataProvider.getProductMultiSelectModels(this.globalDataStore.globalSettings.allowSearchForChildConfigurations).then(multiSelectModels => {

      let serverSelectedValuesMap = new Map<string, ConfSearchCriteria>();
      this.criteria.value.toArray().forEach(x => {

        serverSelectedValuesMap.set(x.value, x);

      });

      // Set the server value, if page is reloaded or saved search is opened.
      this.setSelectedState(multiSelectModels, serverSelectedValuesMap);

      //Create multi select items.
      this.items = Immutable.List<MultiSelectItem>(multiSelectModels);

      if (!this.cd['destroyed'])
        this.cd.detectChanges();

    });

  }

  setSelectedState(multiSelectModels: Array<MultiSelectItem>, serverValues: Map<string, ConfSearchCriteria>) {

    multiSelectModels.forEach(x => {

      if (serverValues.has(x.id)) {
        x.state = ItemStateType.Checked;
        x.icon = this.checkedIcon;
      }

      if (x.children)
        this.setSelectedState(x.children, serverValues);

    });

  }

  createItems(entities: BaseEntity[]): void {

    let map: Map<number, ConfSearchCriteria> = new Map<number, ConfSearchCriteria>();

    if (this.criteria.value) {
      if (this.criteria.region == SearchCriteriaRegion.ConfProperty) {

        // In productId case, conf.value contains the ProductId.
        this.criteria.value.forEach(conf => map.set(+conf.value, conf));
      }
      else if (this.criteria.region == SearchCriteriaRegion.Parameter) {

        this.criteria.value.forEach(conf => {

          if (conf instanceof ConfSearchParameter)
            map.set(+conf.paramValueId, conf);

        });

      }
    }

    let newItems = Immutable.List<MultiSelectItem>();

    entities.forEach(entity => {

      let item = this.createItem(entity)

      let oldItem = this.items.find(x => x.id == item.id);

      // Restore previous value
      if (!oldItem && map.has(entity.longId)) {

        // Handle multi choice values.
        if (this.criteria.region == SearchCriteriaRegion.Parameter)
          item.state = map.get(entity.longId).value != null && map.get(entity.longId).value.toLowerCase() == 'true' ? ItemStateType.Checked : ItemStateType.Unchecked;

        else item.state = ItemStateType.Checked; // Selected ProductIds

        if (item.state == ItemStateType.Checked)
          item.icon = this.checkedIcon;
        else if (item.state == ItemStateType.Unchecked)
          item.icon = this.uncheckedIcon;
      }

      newItems = oldItem ? newItems.push(oldItem) : newItems.push(item);
    });

    this.items = newItems;

    // check that the selected items are still part of the list
    let newSelectedItems: Array<MultiSelectItem> = [];

    this.selectedItems.forEach(x => {
      let item = this.items.find(y => y.id == x.id);
      if (item)
        newSelectedItems.push(item);
    });

    this.cd.detectChanges();
  }

  createItem(entity: BaseEntity): MultiSelectItem {

    return <MultiSelectItem>{
      title: this.modelLabelService.getLabel(entity),
      id: entity.longId.toString(),
      state: this.triState ? ItemStateType.Inderminate : ItemStateType.Unchecked,
      selectable: entity.className != ProductFamily.name
    };
  }

  public onSelectionChange(selectedItems: Array<MultiSelectItem>): void {
    // compare the arrays by checking their sizes and ids of items
    let different = false;
    if (this.selectedItems.length == selectedItems.length)
      this.selectedItems.forEach(x => {
        if (!selectedItems.find(y => y.id == x.id))
          different = true;
      })
    else
      different = true;

    if (different) {
      this.selectedItems = selectedItems;
      this.notifyValueChange(null);
    }
  }

  /** Returns true if the this criteria has a value, otherwise false. */
  public get hasValue(): boolean {
    return this.selectedItems.length > 0;
  }

  /** Returns the current user input value. */
  public get value(): any {
    return this.selectedItems.map(item => item.id);
  }

  /**
   * Returns true if the selected items contains atleast one child.
   * If there is no child, false is returned.
   * @returns
   */
  public isChildSelected(): boolean {
    return this.selectedItems.some(item => item.isChild);
  }

  public get valueAsSearchCriteria(): Array<ConfSearchCriteria> {

    switch (this.region) {

      case SearchCriteriaRegion.ConfProperty:
        {
          return this.selectedItems.map(item => this.searchCriteriaHelper.createConfSearchProperty(this.criteria.controlId, this.criteria.selectedAttribute.id, item.id));
        }

      case SearchCriteriaRegion.Parameter:
        {
          return this.selectedItems.map(item => this.searchCriteriaHelper.createConfSearchParameter(this.criteria.controlId, +this.criteria.selectedAttribute.id, this.getValueAsBoolean(item), +item.id));
        }

      default:
        return [];
    }
  }

  getValueAsBoolean(item: MultiSelectItem): boolean {

    switch (item.state) {

      case ItemStateType.Checked:
        return true;

      case ItemStateType.Unchecked:
        return false;

      default:
        return null;

    }

  }

}