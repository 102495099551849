import { UIElement } from "./uiElement";

export enum TextType {
  Paragraph,
  Span
}

export class UIText extends UIElement {

  protected readonly TITLE = "title";
  protected readonly TEXT_TYPE = "textType";

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get textType(): TextType { return this.getInternalValue<TextType>(this.TEXT_TYPE); }
  setTextType(textType: TextType): this { return this.setInternalValue(this.TEXT_TYPE, textType); }

}