import { NgModule } from "@angular/core";
import { PushMessageComponent } from "./pushMessageComponent";
import { FormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { UIElementComponent } from "./uiElementComponent";
import { PopupModule, TextBoxModule, CheckboxModule, ImageModule } from "../../../../shared/components";
import { SelectModule } from "../../../../shared/components/select";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PopupModule,
    SelectModule,
    TextBoxModule,
    CheckboxModule,
    ImageModule
    //SafeHtmlModule
  ],
  declarations: [
    PushMessageComponent,
    UIElementComponent
  ],
  providers: [
  ],
  exports: [
    PushMessageComponent,
    UIElementComponent
  ]  
})
export class PushMessageModule {

}