import { ImmutableObject } from "../../../../shared";
import { UIAction, UIElement } from "../entities";
import * as Immutable from "immutable"
import { BaseEntity } from "../../baseEntity";

export class PushMessageData extends ImmutableObject {
  protected readonly INTERACTION_SESSION_ID = "interactionSessionId";
  protected readonly START_UI_ACTION = "startUIAction";
  protected readonly UI_ACTIONS = "uiActions";
  protected readonly UI_ACTION_STRUCTURE_BY_ID = "uiActionStructureById";
  protected readonly UI_ELEMENTS = "uiElements";
  protected readonly EXTRA_ARGS = "extraArgs";
  protected readonly LONG_ID = "longId";
  protected readonly VALUES = "values";
  protected readonly CLIENT_REQUEST_ID = "clientRequestId";

  get interactionSessionId(): number { return this.getInternalValue<number>(this.INTERACTION_SESSION_ID); }
  setInteractionSessionId(interactionSessionId: number): this { return this.setInternalValue(this.INTERACTION_SESSION_ID, interactionSessionId); }

  get startUIAction(): string { return this.getInternalValue<string>(this.START_UI_ACTION); }
  setStartUIAction(startUIAction: string): this { return this.setInternalValue(this.START_UI_ACTION, startUIAction); }

  get uiActions(): Immutable.Map<number, UIAction> { return this.getInternalValue<Immutable.Map<number, UIAction>>(this.UI_ACTIONS); }
  setUiActions(uiActions: Immutable.Map<number, UIAction>): this { return this.setInternalValue(this.UI_ACTIONS, uiActions); }

  get uiElements(): Immutable.Map<number, UIElement> { return this.getInternalValue<Immutable.Map<number, UIElement>>(this.UI_ELEMENTS); }
  setUiElements(uiElements: Immutable.Map<number, UIElement>): this { return this.setInternalValue(this.UI_ELEMENTS, uiElements); }

  get extraArgs(): Immutable.Map<string, string> { return this.getInternalValue<Immutable.Map<string, string>>(this.EXTRA_ARGS); }
  setExtraArgs(extraArgs: Immutable.Map<string, string>): this { return this.setInternalValue(this.EXTRA_ARGS, extraArgs); }

  get uiActionStructureById(): Immutable.Map<string, Immutable.List<number>> { return this.getInternalValue(this.UI_ACTION_STRUCTURE_BY_ID); }
  setUiActionStructureById(uiActionStructureById: Immutable.Map<string, Immutable.List<number>>): this { return this.setInternalValue(this.UI_ACTION_STRUCTURE_BY_ID, uiActionStructureById); }

  get longId(): number { return this.getInternalValue<number>(this.LONG_ID); }
  setLongId(longId: number): this { return this.setInternalValue(this.LONG_ID, longId); }

  get values(): Immutable.Map<string, string> { return this.getInternalValue<Immutable.Map<string, string>>(this.VALUES); }
  setValues(values: Immutable.Map<string, string>): this { return this.setInternalValue(this.VALUES, values); }

  get clientRequestId(): number { return this.getInternalValue<number>(this.CLIENT_REQUEST_ID); }
  setClientRequestId(clientRequestId: number): this { return this.setInternalValue(this.CLIENT_REQUEST_ID, clientRequestId); }
}