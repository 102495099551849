<div class="flex-column d-flex" [style.height]="minHeight ? minHeight + 'px' : '100%'" [style.min-width.px]="maxWidth" [style.overflow]="scrollbar ? 'auto' : 'inherit'">
  <div class="align-middle px-4 text-uppercase row mx-0 tab-header-extra" [class.tab-header]="tab.title" *ngIf="showClose || tab.title" >
    <div class="h-100 col align-middle flex-column justify-content-center" *ngIf="tab.title" >
      <co-image  width="24" height="24" type="svg" isVault="true" [key]="tab.iconRelativeUrl" [style.visibility]="tab.iconRelativeUrl ? '' : 'hidden'"></co-image>
      <span class="title ellipsis">{{tab.title}}</span>
    </div>
    <div class="col-auto text-right" *ngIf="showClose" [class.w-100]="!tab.title">
      <a role="button" class="pl-3 pt-2" (click)="onClose.emit($event);">
        <co-image width="24px" height="24px" type="svg" imageSet="primary" key="delete"></co-image>
      </a>
    </div>
  </div>

  <div class="tab-body {{extraStyles}}">
    <visual-object-container *ngIf="domReady" [attr.id]="tab.longId" [tab]="tab"                             
                             [configurationId]="configurationId"></visual-object-container>
  </div>
</div>