import * as Immutable from "immutable";

import { ImmutableObject } from "../../../../shared/immutableObject";
import { PushData } from "./pushData";

export class PushMessageState extends ImmutableObject {

  protected readonly DATA_BY_SESSION_ID = "dataBySessionId";
  protected readonly REFRESHING = "refreshing";
  protected readonly REDIRECTING = "redirecting";

  get dataBySessionId(): Immutable.Map<number, PushData> { return this.getInternalValue<Immutable.Map<number, PushData>>(this.DATA_BY_SESSION_ID); }
  setDataBySessionId(dataBySessionId: Immutable.Map<number, PushData>): this { return this.setInternalValue(this.DATA_BY_SESSION_ID, dataBySessionId); }

  get refreshing(): boolean { return this.getInternalValue<boolean>(this.REFRESHING); }
  setRefreshing(refreshing: boolean): this { return this.setInternalValue(this.REFRESHING, refreshing); }

  get redirecting(): boolean { return this.getInternalValue<boolean>(this.REDIRECTING); }
  setRedirecting(redirecting: boolean): this { return this.setInternalValue(this.REDIRECTING, redirecting); }
}