import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { BaseInputComponent } from "../shared";
import { CustomDateParserFormatter } from './customDateParserFormatter';


@Component({
  selector: 'datepicker',
  templateUrl: './datepickerComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe]
})
export class DatepickerComponent extends BaseInputComponent {
  
  public selectedDate = new FormControl(new Date());
  public date: { year: number, month: number };
  public model: NgbDateStruct;
  
  navigation = 'select';  
  outsideDays = 'visible';

  @Input()
  public dateFormat: string;

  @Input()
  public weekNumbersEnabled: boolean = true;

  @ViewChild('inputDatapicker')
  public inputDatapicker: ElementRef;

  constructor(private datePipe: DatePipe, protected formatter: NgbDateParserFormatter) {
    super();    
  }
  
  ngOnInit() {

    // Default if not defined.
    if (!this.dateFormat)
      this.dateFormat = 'yyyy-MM-dd';
    
    if (this.view.value)
      this.setValue();
    
    else this.selectedDate.setValue(null);

    (this.formatter as CustomDateParserFormatter).dateFormat = this.dateFormat;

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['view'] && !changes['view'].firstChange) {
      this.setValue();
    }

  }
  
  processEvent($event, checkImmediate: boolean): void {

    this.notify(this.formatToDateString($event), checkImmediate);
      
  }

  setValue() {

    let date = moment(this.view.value?.replace(/-/g, "/"), this.dateFormat.toUpperCase());
    this.model = this.createDateModel(date?.date(), date?.month() + 1, date?.year());   

  }

  createDateModel(date: number, month: number, year: number): any {
    return { day: date, month: month, year: year };
  }

  notify(date: string, checkImmediate: boolean): void {

    // Remove highlighting now, otherwise it will wait for server response and cause flickering.
    this.view.highlight = false;

    if (checkImmediate && !this.immediate)
      return;

    this.notifyValueChange(this.view, date);
  }

  public formatToDateString(date): string {

    if (date == "")
      date = null;

    if (date != null) {

      let mDate = moment(date, this.dateFormat.toUpperCase());

      const jsDate: any = this.createDateModel(mDate.date(), mDate.month() + 1, mDate.year());
      return this.formatter.format(jsDate);

    }

    return date;
  }

  endDateChange(type: string, event: MatDatepickerInputEvent<Date>) {

    if (!event.value)
      this.notify((<any>event.targetElement).value, false);
    else
      this.notify(this.formatToDateString(event.value), false);
  }

  onDaySelect(ngbDate: any) {

    if (ngbDate) {
      const jsDate = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
      this.notify(this.formatToDateString(jsDate), false);
    }

  }

  openDatepicker(event, datepicker) {

    if (!datepicker.isOpen())
      datepicker.open();

  }

  valuechange(event) {

    if (event && event.target) {
      this.view.value = this.formatToDateString(event.target.value);
      this.notify(this.formatToDateString(event.target.value), false);
    }
    
  }

  closeFix(event, datepicker) {

    if (event.target.name == 'datepickerInput' || (datepicker && !datepicker.isOpen()))
      return;

    if (event.target.offsetParent == null || event.target.offsetParent.nodeName != "NGB-DATEPICKER")
      datepicker.close();
    
  }

}
