import { Component, Inject, ChangeDetectorRef, Input } from "@angular/core";

import { VisualizationDecoration, ConfVisualizationValue } from "../../../shared/models";
import { DecorationComponent } from "../../shared/decorationComponent";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { VisualObjectVisibilityService } from "../../shared";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { VisualObjectHelper } from "../../parameters/shared";

@Component({
  selector: 'visualization-decoration',
  templateUrl: './visualizationDecorationComponent.html'
})
export class VisualizationDecorationComponent extends DecorationComponent<VisualizationDecoration> {

  public confVisualizationValue: ConfVisualizationValue;
  public hasTitle: boolean = false;

  visualizationHeight: string;

  visualizationMaxHeight: string;

  styles: string = "visualization-body";

  @Input()
  public isEditor: boolean = true;

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    public visualObjectVisibilityService: VisualObjectVisibilityService,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    public globalDataStore: GlobalDataStore,
    public cdr: ChangeDetectorRef
  ) {
    super(confStore, confPageSessionService, visualObjectHelper);
  }

  ngOnInit() {

    if (this.visualObjectHelper.isVisualObjectInAccordion(this.decoration)) {

      this.visualizationHeight = this.decoration.height ? this.decoration.height + 'px' : this.uiSettings.configurator.decoration.minHeight;
      this.visualizationMaxHeight = '100%';
    }
    else
    {
      if (this.decoration.responsiveSize) {
        this.visualizationMaxHeight = '100%';
      }
      else this.visualizationMaxHeight = this.decoration.height ? this.decoration.height + 'px' : this.uiSettings.configurator.decoration.minHeight;

      this.visualizationHeight = '100%';
    }
    
    this.styles += this.hasTitle ? " py-2" : " no-gutters";
    super.ngOnInit();
  }

  public listenChanges(): void {

    this.hasTitle = this.showTitle && this.decoration.title && this.decoration.title.trim().length > 0;
    this.confVisualizationValue = this.getConfVisualizationValue();

    this.confStore.onConfigurationValueChange<ConfVisualizationValue>(this.configurationId, this.confSessionId, this.decoration.visualObjectId,
        (confVisualizationValue): void => {

          this.confVisualizationValue = confVisualizationValue;

        }).unsubscribeOn(this.unsubscribeSubject);
  }

  getConfVisualizationValue() {
    return this.confStore.getConfDataEntity<ConfVisualizationValue>(this.configurationId, this.confSessionId, this.decoration.visualObjectId);
  }

  public cancelScrollEvent() {
    return false;
  }
}