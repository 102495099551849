import { AbstractConfDataCommand } from "./abstractConfDataCommand";
import * as Immutable from "immutable";

export class JavaScriptCommand extends AbstractConfDataCommand
{
  sourceId: number;
  json: string;
  eventType: EventType;
}

export enum EventType {
  Clicked = 'Clicked',
  Dragged = 'Dragged',
  Dropped = 'Dropped'
}