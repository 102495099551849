import { Inject, Component, Input, ViewChild, SimpleChanges } from "@angular/core";
import * as Immutable from "immutable"

import { AbstractImmutableValueMessage } from "../../../shared/models/responses/messages/abstractImmutableValueMessage";
import { RuleInfo, ParamInfo } from "../../../shared/models/responses";
import { ParamValue, ConfInfo } from "../../../shared/models";
import { BaseComponent } from "../../../shared";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { ImageSets } from "../../../../shared/utils";

@Component({
  selector: 'immutable-value-popup',
  templateUrl: './immutableValuePopupComponent.html'
})
export class ImmutableValuePopupComponent extends AbstractPopupComponent {
  
  public message: AbstractImmutableValueMessage;

  public imageSet: string;

  @Input()
  public headerText: string;

  @Input()
  public caption: string;

  @Input()
  public description: string;

  @Input()
  public icon: string;  
  
  public restrictingParent: ConfInfo;
  public restrictingChildren: Immutable.List<ConfInfo>;

  ngOnInit() {
    this.imageSet = this.getImageSet(ImageSets.PopupHeader);
    super.ngOnInit();
  }

  public open(tag: any) {
    this.message = tag as AbstractImmutableValueMessage;
    this.setupConfRestrictions();
    this.showInternal();
  }

  getDisplayableParamValues(paramValues: ParamValue[]): ParamValue[] {
    let filteredParamValues: ParamValue[] = [];
    paramValues.forEach((a) => {
      if (a.obsolete)
        return;
      filteredParamValues.push(a);
    });

    return filteredParamValues;
  }

  public getParameterIcon(pInfo: ParamInfo): string {
    return pInfo.parameterType.toLowerCase();
  }

  public getRuleIcon(ruleInfo: RuleInfo): string {
    return ruleInfo.ruleType.toLowerCase();
  }

  public setupConfRestrictions() {
    // Check if prevented by child or parent.

    this.restrictingChildren = null;
    this.restrictingParent = null;

    if (this.message.confInfos == null || this.message.confInfos.isEmpty())
      return;

    let firstConf = this.message.confInfos.first();

    let restrictedByChild = firstConf.parentId === this.message.configurationId; // TODO is this the correct way?
    if (restrictedByChild) {
      this.restrictingChildren = this.message.confInfos;
    }
    else {
      this.restrictingParent = firstConf;
    }
  }

}