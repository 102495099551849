import { Component, Input, Inject, EventEmitter, Output } from "@angular/core";
import { BaseComponent } from "../..";
import { SvgActions } from "./svgActions";
import { ImageSets } from "../../../../shared/utils";

@Component({
  selector: 'graphics-icons',
  templateUrl: './graphicsControlIconsComponent.html',
})
export class GraphicsControlIconsComponent extends BaseComponent {

  public imageSet: string;

  @Output()
  action = new EventEmitter();

  ngOnInit() {
    this.imageSet = this.getImageSet(ImageSets.ConfiguratorDecorationGraphicsControl);
    super.ngOnInit();
  }

  zoomIn(): void {
    this.action.emit(SvgActions.Plus);
  }

  reset(): void {
    this.action.emit(SvgActions.Reset);
  }

  zoomOut(): void {
    this.action.emit(SvgActions.Minus);
  }

}