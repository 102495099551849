import { Directive, Component, NgZone, EventEmitter, ViewChild, ElementRef, ViewContainerRef, Input, HostListener } from "@angular/core";
import { PanZoomConfig } from "./panZoomConfig";
import { EmitterService, IEmitDataInfo } from "../../../configurator/shared";
import { SvgActions } from "./svgActions";
import { ManagedSubscription } from "../../../../shared/managedSubscription";
import { GraphicsHelper } from "./graphicsHelper";
import { BrowserInfo } from "../../../../shared/utils";
import { GlobalServiceLocator } from "../../../../shared/providers";
import { GlobalDataStore } from "../../providers/globalData";
var svgPanZoom = require('svg-pan-zoom');

@Directive({
  selector: '[panZoom]'
})
export class SvgPanZoomDirective {

  @Input()
  public panZoom: PanZoomConfig;

  private panZoomTiger: any;
  private subscription: ManagedSubscription;

  private autoBehavior = false;
  private zoom;

  constructor(private container: ElementRef, private emitterService: EmitterService, private graphicsHelper: GraphicsHelper, public browserInfo: BrowserInfo) {

  }

  ngOnInit() {

    this.autoBehavior = this.panZoom.responsive;

    this.subscription = this.emitterService.getMessage().subscribe((x: IEmitDataInfo<any>) => {

      if (this.panZoom.identifier != x.tag || !this.panZoomTiger && x.id != SvgActions.Render)
        return;

      switch (x.id) {

        case SvgActions.SetZoom:
          this.zoom = this.container?.nativeElement?.querySelector("#allSymbols")?.getCTM()?.a;
          break;

        case SvgActions.ClearZoom:
          this.resetZoomAndAutoBehavior();
          break;

        case SvgActions.Render:
          this.init();
          break;

        case SvgActions.DisablePan:
          this.panZoomTiger.disablePan();
          break;

        case SvgActions.EnablePan:
          this.panZoomTiger.enablePan();
          break;

        case SvgActions.Reset:
          this.panZoomTiger.newPosition = null;
          this.resetSvg();
          break;

        case SvgActions.Plus:
          this.panZoomTiger.zoomIn();

          // Sets initial origo position after zoom action
          if (!this.autoBehavior)
            this.panZoomTiger.pan(this.initialPosition);
          break;

        case SvgActions.Minus:
          this.panZoomTiger.zoomOut();

          // Sets initial origo position after zoom action
          if (!this.autoBehavior)
            this.panZoomTiger.pan(this.initialPosition);
          break;

      }

    });

  }

  protected init(): void {

    let zoomFactor = this.panZoomTiger ? this.panZoomTiger.getZoom() : this.graphicsHelper.getZoom(this.panZoom, this.container.nativeElement);
    let oldPosition = this.panZoomTiger ? this.panZoomTiger.getPan() : this.initialPosition;

    if (this.zoom) {
      this.autoBehavior = false;
      zoomFactor = this.zoom;
    }

    this.disposePanZoom();

    if (!this.container.nativeElement.firstElementChild || this.container.nativeElement.firstElementChild.childElementCount === 0) {
      this.resetZoomAndAutoBehavior();
      return;
    }

    // Let pan zoom pan and zoom by itself, or use the old setting decided by uisettings, pan zoom auto behavior (true) or existing behavior
    this.panZoomTiger = svgPanZoom(this.container.nativeElement.firstElementChild,
      {
        fit: this.autoBehavior,
        zoomEnabled: true,
        center: this.autoBehavior,
        minZoom: 0.01,
        customEventsHandler: {

          init: function (options) {

            this.listeners = {

              // Triggers when mouse up.
              click: function () {

                // This new position must be used when resizing.
                options.instance.newPosition = options.instance.getPan();

              }
            }

            for (let eventName in this.listeners) {
              options.svgElement.addEventListener(eventName, this.listeners[eventName])
            }
          }
          , destroy: function (options) {
            for (let eventName in this.listeners) {
              options.svgElement.removeEventListener(eventName, this.listeners[eventName])
            }
          }
        }

      });

    if (!this.autoBehavior) {
      this.panZoomTiger.zoom(zoomFactor);
      this.panZoomTiger.pan(oldPosition);
      this.panZoomTiger.newPosition = oldPosition;

      // Reset the auto behavior if disabled due to a graphics action
      this.resetZoomAndAutoBehavior();
    }

    // Set to visibible after applying pan zoom
    this.container.nativeElement.style.opacity = 1;

  }

  private resetZoomAndAutoBehavior(): void {
    this.autoBehavior = this.panZoom.responsive;
    this.zoom = null;
  }

  protected resetSvg(): void {
    if (!this.panZoomTiger)
      return;

    this.resetZoomAndAutoBehavior();

    if (this.autoBehavior) {
      this.panZoomTiger.resize(); // update SVG cached size and controls positions
      this.panZoomTiger.fit();
      this.panZoomTiger.center();
    }
    else {
      let zoomFactor = this.graphicsHelper.getZoom(this.panZoom, this.container.nativeElement);
      this.panZoomTiger.zoom(zoomFactor);
      this.panZoomTiger.pan(this.initialPosition);
    }

  }

  get initialPosition() {

    // Return new active position.
    if (this.panZoomTiger && this.panZoomTiger.newPosition)
      return this.panZoomTiger.newPosition;

    // Default position.
    let intialOrigoPosition = this.graphicsHelper.getPositionCoordates(this.panZoom, this.container.nativeElement);
    return { x: intialOrigoPosition.x + this.panZoom.initialX, y: intialOrigoPosition.y + this.panZoom.initialY };
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resetSvg();
  }

  ngOnDestroy() {

    if (this.subscription)
      this.subscription.unsubscribe();

    this.disposePanZoom();
  }

  // Causes performance issues if not disposed of manually
  public disposePanZoom() {
    if (this.panZoomTiger) {
      this.panZoomTiger.destroy();
      delete this.panZoomTiger;
    }
  }

}