<div #visualObjectContainer>
  <div class="visual-object-margin visualization-decoration decoration" [class.border-style]="!showTitle" [style.max-width.%]="'100'">
    <gridview>
      <gridview-header *ngIf="showTitle" classes="decoration-header mx-0">
        <header-columntemplate *ngIf="isEditor" size="auto" classes="header-image">
          <co-image width="24" height="24" type="svg" key="visualization" [imageSet]="imageSet"></co-image>
        </header-columntemplate>
        <header-columntemplate classes="header-title">
          {{decoration.title}}
        </header-columntemplate>
      </gridview-header>
        <gridview-row class="gridview-row-body" [classes]="styles" [style.height]="visualizationHeight" [style.max-height]="visualizationMaxHeight" >
          <columntemplate>
            <visualization-plugin *ngIf="confVisualizationValue" [decoration]="decoration" [confVisualizationValue]="confVisualizationValue" (wheel)="cancelScrollEvent()"></visualization-plugin>
          </columntemplate>
        </gridview-row>
    </gridview>
  </div>
</div>
