<accordion-configurations-tree *ngIf="showAccordionTabs && accordionCompositeMode && selectedTabId != configurationId"                               
                               [selectedConfId]="selectedTreeNodeId"
                               (nodeSelected)="onConfigurationNodeSelect($event)">
</accordion-configurations-tree> 

<accordion-tabs *ngIf="!showOverlay" [tabs]="tabs"
                [selectedTabId]="selectedTabId"
                [configurationId]="configurationId"
                [containerId]="containerId"
                (accordionComposite)="onAccordionComposite($event)"
                (selected)="selected.emit($event)"
                >
</accordion-tabs>

<!-- Accordion sub configuration tabs -->
<accordion-overlay *ngIf="showOverlay" [title]="subConfTitle">
  <ng-container *ngIf="subConfigurationTabs.length > 0">
    <accordion-tabs [tabs]="subConfigurationTabs"
                    [containerId]="containerId"
                    [configurationId]="activeSubConfigurationId"
                    [selectedTabId]="selectedSubTabId"
                    (selected)="selected.emit($event)">
    </accordion-tabs>
  </ng-container>
</accordion-overlay>
