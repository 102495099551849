<ng-container *ngIf="uiElement" [ngSwitch]="uiElement.className">
  <label *ngSwitchCase="'UILabel'" [class]="uiElement.cssClass" [style]="style" [for]="uiElement.forId" (click)="onClick(uiElement.id)">{{uiElement.title}}</label>
  <ng-container *ngSwitchCase="'UIGroup'">
    <div *ngIf="uiElement.includeDiv" [class]="uiElement.cssClass" [style]="style">
      <ui-element *ngFor="let uiElement2 of uiElement.uiElements" [uiElementId]="uiElement2" [sessionId]="sessionId" [elementStore]="elementStore" (action)="onAction($event)"></ui-element>
    </div>
    <ng-container *ngIf="!uiElement.includeDiv">
      <ui-element *ngFor="let uiElement2 of uiElement.uiElements" [uiElementId]="uiElement2" [class]="getCssClass(uiElement2)" [style]="uiElement.style" [sessionId]="sessionId" [elementStore]="elementStore" (action)="onAction($event)"></ui-element>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'UIInput'">
    <text-box *ngIf="uiElement.type=='text'" [rows]="rows" [cols]="cols" [class]="uiElement.cssClass" [style]="style" [view]="textBoxView" [hideLabel]="true" (onValueChange)="onTextValueChange($event)">
    </text-box>
    <check-box *ngIf="uiElement.type=='checkbox'" [view]="checkboxView" (onValueChange)="onCheckChanged($event)">
    </check-box>
    <input *ngIf="uiElement.type=='submit'" [id]="uiElement.renderId" [class]="uiElement.cssClass" [style]="style" [value]="uiElement.value" [type]="uiElement.type" (click)="onClick(uiElement.id)">
  </ng-container>
  <ng-container *ngSwitchCase="'UIText'" (click)="onClick(uiElement.id)">
    <p *ngIf="uiElement.textType==TextType.Paragraph" [class]="uiElement.cssClass" [style]="style" [ngSwitch]="uiElement.className">
      {{
uiElement.title
      }}
    </p>
    <span *ngIf="uiElement.textType==TextType.Span" [class]="uiElement.cssClass" [style]="style" [ngSwitch]="uiElement.className">{{uiElement.title}}</span>
  </ng-container>
  <a *ngSwitchCase="'UILink'" [class]="uiElement.cssClass" [style]="style" [href]="uiElement.href" [target]="uiElement.target" (click)="onClick(uiElement.title)">{{uiElement.title}}</a>
  <img *ngSwitchCase="'UIImage'" [class]="uiElement.cssClass" [style]="style" [src]="uiElement.src" [alt]="uiElement.alt" (click)="onClick(uiElement.id)" />
  <div *ngSwitchCase="'UISelect'" [class]="uiElement.cssClass" [style]="style">
    <select-option [view]="viewModel"
                   [itemsView]="itemsView"
                   [displayAsRadio]="uiElement.isRadioButton"
                   [displayStyle]="DISPLAY_STYLE_OPTIONS.Standard"
                   [selectedValueId]="selectedViewId"
                   (onValueChange)="onSelectValueChange($event)"
                   [enableHelp]="false">
    </select-option>
  </div>
  <div *ngSwitchDefault>
    No implementation for: {{uiElement.className}}
  </div>
</ng-container>