import { Component, Inject, Input } from "@angular/core";
import { FormDecoration, ConfFormValue, ConfFormControlValue, RequestViews, UIGroup, UIElement } from "../../../shared/models";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { DecorationComponent } from "../../shared/decorationComponent";
import { VisualObjectHelper } from "../../parameters/shared";
import { PushMessageSelection } from "../../../shared/providers/pushMessage/pushMessageSelection";
import { CodeValueModelBuilder } from "../../../shared/components/code/providers/codeValueModelBuilder";
import * as Immutable from "immutable";

@Component({
  selector: 'form-decoration',
  templateUrl: './formDecorationComponent.html'
})
export class FormDecorationComponent extends DecorationComponent<FormDecoration> {

  public confFormValue: ConfFormValue;
  public uiElementId: number;

  @Input()
  public showTitle = true;

  public showEditorMargin = true;
  public showSummaryMargin = true;

  @Input()
  public view = RequestViews.Editor;

  constructor(
    public confStore: ConfiguratorStore,
    public confPageSessionService: ConfPageSessionService,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,

    public builder: CodeValueModelBuilder,

  ) {
    super(confStore, confPageSessionService, visualObjectHelper);
  }

  ngOnInit() {

    this.confFormValue = this.confStore.getConfDataEntity<ConfFormValue>(this.configuration.longId, this.confSessionId, this.decoration.longId);

    this.update();
    super.ngOnInit();
  }

  public listenChanges(): void {
    this.confStore.onConfigurationValueChange(this.configurationId, this.confSessionId, this.decoration.longId, (value: ConfFormValue): void => {

      this.confFormValue = value;
      this.update();

    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public update() {

    this.uiElementId = this.confFormValue.uiElements.first();

    this.showTitle = this.view != RequestViews.Summary && this.decoration.title != null && this.decoration.title.length > 0;
    this.showEditorMargin = this.view == RequestViews.Editor && this.decoration.title != null && this.decoration.title.length > 0;
    this.showSummaryMargin = this.view == RequestViews.Summary;
  }

  public onAction(event: PushMessageSelection) {

    let triggerElement: any = this.confStore.getElements(this.confSessionId).find((a) => a.id == event.key);

    this.confStore.setElementValue(event);

    if (triggerElement && triggerElement.className == "UIInput" && triggerElement.type == "submit") {
      let valuesById = this.confStore.getElementValuesById(this.confSessionId);

      let decorationValueById = Immutable.Map<string, string>();

      let element = this.confStore.getElement(this.uiElementId, this.confSessionId);

      let elements = this.getAllElements(element);

      elements.forEach(element => {

        if (element.id && valuesById.has(element.id)) {
          decorationValueById = decorationValueById.set(element.id, valuesById.get(element.id));
        }

      });

      this.confStore.formControlSubmit(this.configurationId, this.confSessionId, this.decoration.visualObjectId, +triggerElement.id, decorationValueById, this.view);
    }

  }

  public getAllElements(rootElement: UIElement): UIElement[] {
    const allElements: UIElement[] = [];
    this.traverse(rootElement, allElements);
    return allElements;
  }

  public traverse(element: UIElement, allElements: UIElement[]) {
    allElements.push(element);

    if (element.className == "UIGroup") {
      let uiGroup = element as unknown as UIGroup;

      uiGroup.uiElements.forEach(a => {
        this.traverse(this.confStore.getElement(a, this.confSessionId), allElements);
      });

    }
  }
}