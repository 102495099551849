<backdrop-container>
  <regular-popup
                 [id]="id"
                 [title]="title"
                 [height]="height"
                 [minHeight]="height"
                 [width]="width"                                  
                 [isOpened]="visible"
                 [showHeader]="true"
                 [showFooter]="true"
                 [closeOnOutSideClick]="false"
                 [okButtonText]="okButtonText"
                 [cancelButtonText]="cancelButtonText"
                 (okClick)="onOkClick($event)"
                 (cancelClick)="onCancelClick($event)"
                 [cancelButton]="cancelButton"
                 [showCloseButton]="showCloseButton"
                 [okButton]="showOkButton">

    <div *ngIf="message" card-type="header">
      <div class="row py-1 m-0">
        <co-image *ngIf="message.icon" class="col-auto px-0 align-middle" width="48px" height="48px" type="svg" imageSet="{{imageSet}}" key="{{message.icon}}"></co-image>
        <div class="col modal-text-right" [class.no-border]="!message.icon" >
          <p *ngIf="message.headerText" class="header-text" [innerHtml]="message.headerText | safeHtml" ></p>
          <p *ngIf="message.caption" class="caption pt-2" [class.pt-2] [innerHtml]="message.caption | safeHtml" ></p>
          <p *ngIf="!showBodyContents && message.description" [class.pt-2]="message.caption == null || message.caption == ''" [innerHtml]="message.description | safeHtml" ></p>
        </div>
      </div>
    </div>
    <div *ngIf="showBodyContents && message" class="p-4" card-type="body">
      <p [innerHtml]="message.description | safeHtml"></p>
    </div>
  </regular-popup>
</backdrop-container>