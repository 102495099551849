import { Inject, Injectable } from '@angular/core';
import { RouterModule, DefaultUrlSerializer, UrlTree, UrlSerializer, Router } from '@angular/router';

import { AccountDataStore } from "./accountData/accountDataStore";
import { GlobalDataStore } from "./globalData/globalDataStore";
import { ValueSubscriptionManager } from "./valueSubscriptionManager";
import { ManagedSubject } from "../../../shared/managedSubject";
import { ManagedSubscription, SubscriptionOptions } from "../../../shared/managedSubscription";
import { AppActionCreator } from "./appActionCreator";
import { User, SystemInformation } from "../models";
import { StartupArguments } from "./startupArguments";
import { searchUrlQueryParam } from '../../../shared/utils/routeParamsUtils';
import { AuthenticationTokenTypes } from './authenticationTokenTypes';
import { BroadcastChannelService, BroadcastChannelMessageType } from './BroadcastChannelService';

@Injectable()
export class ApplicationLifeCycleService {

  protected userUpdateSubject: ManagedSubject<User>;
  protected reloadCacheSubject: ManagedSubject<void>;

  constructor(
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    @Inject(ValueSubscriptionManager) public valueSubscriptionManager: ValueSubscriptionManager,
    @Inject(AppActionCreator) public appActionCreator: AppActionCreator,
    @Inject(BroadcastChannelService) public broadcastChannelService: BroadcastChannelService,
    public startupArguments: StartupArguments
  )
  {
    this.userUpdateSubject = this.valueSubscriptionManager.createSubject<User>(null, false, 1, false);
    this.reloadCacheSubject = this.valueSubscriptionManager.createSubject<void>(null, false, 1, false);
  }

  beforeApplicationInitialize() {

    let managedSubject = this.valueSubscriptionManager.createSubject<boolean>(ManagedSubject.IGNORE_VALUE, true);
        
    this.syncToken().subscribe(result => {

      this.fetchGlobalDataWithUser(this.startupArguments.locale).toPromise().then(() => managedSubject.nextValue(true), () => managedSubject.nextValue(true));
            
    });

    return managedSubject;
  }

  syncToken() {

    let managedSubject = this.valueSubscriptionManager.createSubject<boolean>(ManagedSubject.IGNORE_VALUE, true);
    let gotAnswered = false;

    // If the response contains the access token, add it to SessionStorage.
    // Otherwise, check if SessionStorage has a token stored.
    // If not, then ask the other tabs for a token.
    // And if that gives no response, that means there is no active session. So continue without a token.
    let accessToken = searchUrlQueryParam(AuthenticationTokenTypes.AccessToken);
    if (accessToken) {
      this.accountStore.setAccessToken(accessToken);
      this.accountStore.removeWebSessionToken();

      managedSubject.nextValue(true);
    }
    else if (!this.accountStore.getAccessToken()) {
      // Listens for event only once. When triggered, it's removed.
      this.broadcastChannelService.broadcastChannel.addEventListener("message", (messageEvent: MessageEvent) => {

        gotAnswered = true;

        if (messageEvent.data.type == BroadcastChannelMessageType.UserLoginToken) {
          this.accountStore.setAccessToken(messageEvent.data.payload);
          managedSubject.nextValue(true);
        }
        else {
          managedSubject.nextValue(false);
        }
      }, { once: true });
      this.broadcastChannelService.send(BroadcastChannelMessageType.UserLoginTokenRequest);

      setTimeout(() => {

        if (gotAnswered)
          return;

        managedSubject.nextValue(false);

      }, 100);
    }
    else
      managedSubject.nextValue(false);

    return managedSubject;
  }

  onUserUpdated(subscriptionOptions: SubscriptionOptions<User>) {
    return this.userUpdateSubject.subscribe(subscriptionOptions);
  }

  reloadUserAndAppData(callback?: (user: User) => void) {
        
    this.appActionCreator.dispatchClearClientCache();
    this.fetchGlobalDataWithUser().subscribe(result => {

      if (result) {

        let user = this.accountStore.getUser();

        if (callback) callback(user);
        this.userUpdateSubject.nextValue(user);
      }
    });
  }
  
  reloadAppData(callback?: () => void) {

    this.appActionCreator.dispatchClearClientCache();
    this.fetchGlobalData().subscribe(result => {

      if (result) {

        let user = this.accountStore.getUser();

        if (callback) callback();
        this.userUpdateSubject.nextValue(user);
      }
    });
  }

  onCacheReloaded(subscriptionOptions: SubscriptionOptions<void>) {
    return this.reloadCacheSubject.subscribe(subscriptionOptions);
  }

  /**
   * Call after reloading of cache. Callback is called after cleanup of client cache
   * @param callback
   */
  cacheReloaded(callback?: Function) {

    this.appActionCreator.dispatchClearClientCache();
    this.fetchGlobalData().subscribe(result => {
      if (result) {
        if (callback) callback();
        this.reloadCacheSubject.nextValue(void(0));
      }
    })
  }

  protected fetchGlobalDataWithUser(locale?:string) {

    let managedSubject = this.valueSubscriptionManager.createSubject<boolean>(ManagedSubject.IGNORE_VALUE, true);

    // Get GlobalData and then fetch User
    this.fetchGlobalData(locale).subscribe(result => {

      if (result) {
        this.accountStore.getLoggedInUser().toPromise().then(() => managedSubject.nextValue(true), () => managedSubject.nextValue(false))
      }
      else {
        managedSubject.nextValue(false);
      }

    });

    return managedSubject;
  }

  // Get GlobalData such as Strings, GlobalSettings and WebSettings
  protected fetchGlobalData(locale?: string) {

    let allPromise = Promise.all([this.globalDataStore.getLocalizeData(locale, true).toPromise(),
    this.globalDataStore.getGlobalSettings(true).toPromise(),
    this.globalDataStore.getWebSettings(true).toPromise(),
    this.globalDataStore.getUISettings(true).toPromise(),
    this.globalDataStore.getEntities(SystemInformation.name, true).toPromise()]);

    let managedSubject = this.valueSubscriptionManager.createSubject<boolean>(ManagedSubject.IGNORE_VALUE, true);

    allPromise.then(() => managedSubject.nextValue(true), () => managedSubject.nextValue(false));

    return managedSubject;
  }
}