import {
  Component, Inject, ContentChildren, QueryList, ViewChildren,
  Output, EventEmitter, Input, SimpleChanges, ViewChild,
  ChangeDetectionStrategy, ChangeDetectorRef
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as Immutable from "immutable";

import { BaseComponent } from "../../../shared";
import { SearchCriteriaModel, AttributeField, SearchCriteriaRegion, SearchValueControlType, SearchCriteriaValueEventArgs } from "./models";
import { SearchCriteriaModelsProvider } from "./providers/searchCriteriaModelsProvider";
import { SearchCriteriaHelper } from "./providers/searchCriteriaHelper";
import { DateRangeCalculator } from "./providers/dateRangeCalculator";
import { SearchCriteriaDataProvider } from "./providers/searchCriteriaDataProvider";
import { SearchDataStore } from "../../../shared/providers/searchData";
import { SearchCriteriaComponent } from "./criteria/searchCriteriaComponent";
import { ModelFactory, RouteNames } from "../../../shared/providers";
import { ConfigurationSearch, ConfSearchCriteria, ConfDataProperty, Param, ConfSearchProperty, ConfSearchParameter, Product } from "../../../shared/models";
import { InputViewModel, NotificationService, NotificationInfo, NotificationType } from "../../../../shared/components";
import { ConfigurationSearchPopupComponent } from "../../popups";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { MultiSelectInputComponent } from "./criteria";
import { CoCheckboxComponent } from "../../../../shared/components/checkbox";
import { AccountDataStore } from "../../../shared/providers/accountData";

@Component({
  selector: 'search-filter',
  templateUrl: './searchFilterComponent.html',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [SearchCriteriaModelsProvider, SearchCriteriaHelper, SearchCriteriaDataProvider, DateRangeCalculator]
})
export class SearchFilterComponent extends BaseComponent {

  private groupedServerCriterias: Map<SearchCriteriaRegion, Map<string, Array<ConfSearchCriteria>>> = new Map<SearchCriteriaRegion, Map<string, Array<ConfSearchCriteria>>>();

  @Input()
  public configurationSearch: ConfigurationSearch;

  @Input()
  public searchSessionId: number;

  public static readonly CONF_PROPERTY_PRODUCT_ID = 'ProductId';

  @Output()
  performSearch = new EventEmitter();

  @Output()
  filterChanged = new EventEmitter();

  public confPropertyCriterias: Immutable.List<SearchCriteriaModel> = Immutable.List<SearchCriteriaModel>();
  public paramCriterias: Immutable.List<SearchCriteriaModel> = Immutable.List<SearchCriteriaModel>();

  public searchAttributes: Array<AttributeField> = [];

  public width: string = "100%";

  public saveSearchText: string;
  public saveSearchIcon: string;
  public canSaveSearch = true;

  public modelsReady = false;

  public showIncludeChildren = false;
  public includeChildrenView: InputViewModel;

  public showIncludePreviousRevisions = false;
  public includePreviousRevisionsView: InputViewModel;

  @ViewChildren(SearchCriteriaComponent)
  public criteriaComponents: QueryList<SearchCriteriaComponent>;

  @ViewChild(ConfigurationSearchPopupComponent)
  public saveSearchPopup: ConfigurationSearchPopupComponent;

  @ViewChild(CoCheckboxComponent)
  protected checkbox: CoCheckboxComponent;

  constructor(
    @Inject(SearchCriteriaModelsProvider) public modelsProvider: SearchCriteriaModelsProvider,
    @Inject(SearchCriteriaDataProvider) public searchCriteriaDataProvider: SearchCriteriaDataProvider,
    @Inject(SearchCriteriaHelper) public criteriaHelper: SearchCriteriaHelper,
    @Inject(SearchDataStore) public searchDataStore: SearchDataStore,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(ChangeDetectorRef) public cd: ChangeDetectorRef,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(ActivatedRoute) public activatedRoute: ActivatedRoute,
    @Inject(NotificationService) public notificationService: NotificationService,
    @Inject(AccountDataStore) public accountDataStore: AccountDataStore,
  ) {
    super();
  }

  trackCriteria(index, criteria: SearchCriteriaModel) {
    return criteria ? criteria.controlId + "_" + criteria.selectedAttribute?.id : undefined;
  }

  ngOnInit() {

    this.configurationSearch = this.configurationSearch || this.modelFactory.createAny<ConfigurationSearch>(ConfigurationSearch.name);

    this.setIncludeChildrenSearch();
    //// If parameters exist in sub routing It means server request is made, ngOnChanges must be called for subscequent requests and that method must update the criterias.
    //let isServerRequest = subRoute && subRoute.url && subRoute.url.length > 0;
    //if (isServerRequest) {      

    this.setLabels();  
    this.createIncludeChildrenView(this.configurationSearch && this.configurationSearch.searchChildren ? true : false);
    this.createIncludePreviousRevisionsView(this.configurationSearch && this.configurationSearch.includePreviousRevisions ? true : false);
  }

  ngOnChanges(changes: SimpleChanges): void {

    // This code is used to update after the user has saved a search.
    if (changes['configurationSearch']) {

      this.setLabels();

      // Don't need to repopulate on 'configurationSearch' data change
      if (this.modelsProvider.getCriteriasByRegion(SearchCriteriaRegion.ConfProperty).size > 0)
        return;

      this.updateConfSearch();

      if (this.configurationSearch && this.configurationSearch.searchCriterias) {
        this.updateCriterias();        
        this.createIncludeChildrenView(this.configurationSearch.searchChildren);
        this.createIncludePreviousRevisionsView(this.configurationSearch.includePreviousRevisions);
      }
      
      this.addDefaultCriterias(this.defaultCriteriaNames);
    }

  }

  protected createIncludeChildrenView(value: boolean) {

    this.includeChildrenView = new InputViewModel();
    this.includeChildrenView.value = value;
    this.includeChildrenView.title = this.strings.IncludeChildren;

  }

  protected createIncludePreviousRevisionsView(value: boolean) {
    this.showIncludePreviousRevisions = !this.globalSettings.disableRevisionManagement;
    this.includePreviousRevisionsView = new InputViewModel();
    this.includePreviousRevisionsView.value = value && this.showIncludePreviousRevisions;
    this.includePreviousRevisionsView.title = this.strings.IncludePreviousRevisions;
  }

  public setIncludeChildrenSearch(anyProductSelected: boolean = false) {

    let user = this.accountDataStore.getUser();
    this.showIncludeChildren = user ? user.systemAuthorization.canSearchChildren && this.globalSettings.allowSearchForChildConfigurations : false;

    if (!anyProductSelected)
      return;

    this.criteriaComponents.forEach(component => {

      if (component && component.inputComponent instanceof MultiSelectInputComponent && component.inputComponent.refClassName == Product.name) {
        this.includeChildrenView.value = component.inputComponent.isChildSelected();
        return;
      }

    });

    this.showIncludeChildren = false;

  }

  public updateConfSearch() {
    let result = this.searchDataStore.getSearchDataSession(this.searchSessionId);
    if (result) {
      this.configurationSearch = result.query;
    }
  }

  addDefaultCriterias(confPropertyNames: Array<string>): void {

    confPropertyNames.forEach((property, index) => {

      if (!this.modelsProvider.existsCriteriaAttribute(property, SearchCriteriaRegion.ConfProperty))
        this.modelsProvider.addCriteria(this.criteriaHelper.uniqueIdentifier(SearchCriteriaRegion.ConfProperty.toString()), property, this.confPropertySelectorAttributes, SearchCriteriaRegion.ConfProperty);

    });

    this.updateAllInternalCriterias();
  }

  updateCriterias() {

    if (!this.configurationSearch)
      return;

    // Format the existing results e.g  <ConfProperty, {productId: '123123123123, 21221231243', name: 'test'}>
    this.groupedServerCriterias = this.criteriaHelper.groupConfSearchCriteria(this.configurationSearch.searchCriterias || Immutable.List<ConfSearchCriteria>());

    // Restore criterias.
    if (this.groupedServerCriterias.size > 0) {

      let serverCriterias: Map<string, Array<ConfSearchCriteria>> = this.groupedServerCriterias.get(SearchCriteriaRegion.ConfProperty) || new Map<string, Array<ConfSearchCriteria>>();

      if (!this.modelsProvider.hasMatchingData(serverCriterias))
        this.modelsProvider.emptyCriterias();

      let searchConfProperties: Immutable.List<ConfDataProperty> = this.globalSettings.searchConfigurationAttributes;
      serverCriterias.forEach((confs, key) => {

        let confSearchCritier: ConfSearchCriteria = confs[0];

        if (searchConfProperties.find(x => x.name == confSearchCritier.name)) {

          // Create new criteria if not exists, It happens only If page is refreshed.
          if (!this.modelsProvider.exists(key, SearchCriteriaRegion.ConfProperty))
            this.modelsProvider.addCriteria(key, confSearchCritier.name, this.confPropertySelectorAttributes, SearchCriteriaRegion.ConfProperty, null, confs);

          // Set the value
          else this.modelsProvider.setValue(key, SearchCriteriaRegion.ConfProperty, confs);

        }
       
      });

    }

    this.updateAllInternalCriterias();
  }

  get confPropertySelectorAttributes(): Array<AttributeField> {

    let searchConfProperties: Immutable.List<ConfDataProperty> = this.globalSettings.searchConfigurationAttributes;
    let selectorAttributes: Array<AttributeField> = new Array<AttributeField>();

    // Create selector attributes for configuration properties.
    searchConfProperties.forEach(confProperty => {

      // All other configuration property values are dealt with primitive datatypes except productId.
      let valueType: string = confProperty.attributeType;
      let title: string = this.strings.getString(confProperty.title);

      if (confProperty.name == SearchFilterComponent.CONF_PROPERTY_PRODUCT_ID) {
        valueType = 'Product';
        //title = this.strings.Products;
      }

      selectorAttributes.push(this.criteriaHelper.getAttributeField(confProperty.name, title, SearchCriteriaRegion.ConfProperty, valueType, confProperty, false, confProperty.refClassName));

    });

    return selectorAttributes;
  }


  get defaultCriteriaNames(): Array<string> {

    const visibleAttributesCount = this.globalSettings.searchAttributesImmediatelyVisible;
    const visibleAttributes = this.globalSettings.searchConfigurationAttributes;

    let resultProperties: Array<ConfDataProperty>;
    if (visibleAttributes.size < visibleAttributesCount)
      resultProperties = visibleAttributes.toArray();
    else {
      resultProperties = visibleAttributes.slice(0, visibleAttributesCount).toArray();
    }

    return resultProperties.map(x => x.name);
  }

  protected get globalSettings() { return this.globalDataStore.getGlobalData().globalSettings; }

  public setLabels() {
    if (this.isExistingSearch) {
      this.saveSearchText = this.strings.EditThisSearch;
      this.saveSearchIcon = "heartfilled";
      this.canSaveSearch = this.configurationSearch.authorization.canEdit;
    }
    else {
      this.saveSearchText = this.strings.SaveThisSearch;
      this.saveSearchIcon = "heart";
      this.canSaveSearch = true;
    }
  }

  public get isExistingSearch(): boolean {
    return this.configurationSearch && this.configurationSearch.longId > 0;
  }

  public addCriteria(model: SearchCriteriaModel) {

    this.modelsProvider.clone(model);
    this.enableProductAttributeStateExcept(model, false);
    this.updateInternalCriterias(model.region);

  }

  /**
   * Removes the given search criteria model.
   * @param model
   */
  public removeCriteria(model: SearchCriteriaModel) {

    this.enableProductAttributeStateExcept(model, true);
    this.modelsProvider.removeCriteria(model);

    if (!this.modelsProvider.isAttributeSelected(SearchFilterComponent.CONF_PROPERTY_PRODUCT_ID, SearchCriteriaRegion.ConfProperty)) {
      this.modelsProvider.removeParameters();
      this.setIncludeChildrenSearch();
    }

    this.updateAllInternalCriterias();

  }

  enableProductAttributeStateExcept(except: SearchCriteriaModel, enable: boolean): void {

    // Enable the product selection from other criterias if the removing criteria has ProductId selected.
    if (except.selectedAttribute && except.selectedAttribute.id == SearchFilterComponent.CONF_PROPERTY_PRODUCT_ID) {
      this.modelsProvider.enableAttributeExcept(SearchFilterComponent.CONF_PROPERTY_PRODUCT_ID, except, enable);
    }

  }

  /**
   * Creates a ConfigurationSearch model for the current criterias and options.
   */
  public createConfigurationSearchModel(): ConfigurationSearch {

    this.modelsReady = true;
    let searchCriterias: ConfSearchCriteria[] = [];

    this.criteriaComponents.forEach(component => {

      component.value.forEach(x => {

        // Push values only if parameters(MultiChoice, Bool) or ConfigProperty has value.
        if ((x instanceof ConfSearchParameter && x.paramValueId != null && x.paramValueId > 0) || (x.value != null && x.value !== '' && !(!x.name && (x as ConfSearchParameter).parameterId < 1)))  
          searchCriterias.push(x);          

      });

    });

    let includeChidConfigurations = false;
    // If ParentId is null, then it would only search the root configurations,
    includeChidConfigurations = this.globalSettings.allowSearchForChildConfigurations && (this.includeChildrenView && this.includeChildrenView.value || !this.showIncludeChildren);

    // If child configurations are not included then parentId = null
    if (!includeChidConfigurations)
      searchCriterias.push(this.modelFactory.createAny<ConfSearchProperty>(ConfSearchProperty.name, { name: "ParentId", value: "null" }));

    if (!this.configurationSearch)
      this.configurationSearch = new ConfigurationSearch();

    this.configurationSearch = this.configurationSearch.setSearchCriterias(Immutable.List<ConfSearchCriteria>(searchCriterias));
    this.configurationSearch = this.configurationSearch.setSearchChildren(includeChidConfigurations);
    this.configurationSearch = this.configurationSearch.setIncludePreviousRevisions(this.includePreviousRevisionsView.value === "true");

    return this.configurationSearch;
  }

  public onValueChange($event: SearchCriteriaValueEventArgs) {
    this.executePostActionOnValueChange($event);
    //this.cd.markForCheck();
    //this.filterChanged.emit();
  }

  public onSelectorAttributeChanged($event: any) {

    // Active criteria & selected attribute name
    let criteria: SearchCriteriaModel = $event.criteria;
    let attributeName = $event.attributeName;

    // Prevent multiple products attribute selection.
    if (attributeName == SearchFilterComponent.CONF_PROPERTY_PRODUCT_ID || criteria.selectedAttribute.id == SearchFilterComponent.CONF_PROPERTY_PRODUCT_ID) {

      // Enable status for other criteria's attribute. If the <attributeName> is not 'ProductId', It means ProductId is no longer selected and it would 
      // make <ConfProperty.Product> enabled for all and vice versa.
      let enableStatus: boolean = attributeName != SearchFilterComponent.CONF_PROPERTY_PRODUCT_ID;
      this.modelsProvider.enableAttributeExcept(SearchFilterComponent.CONF_PROPERTY_PRODUCT_ID, criteria, enableStatus);
      this.updateInternalCriterias(SearchCriteriaRegion.ConfProperty);

    }
   
    // Update changed attribute.
    this.modelsProvider.updateSelectedAttributeField(attributeName, criteria.region, null, criteria.controlId);

    // Remove the previous selected attribute's value.
    this.modelsProvider.setValue(criteria.controlId, criteria.region, null);

    // Remove parameters criterias If productId is no longer selected.
    if (!this.modelsProvider.isAttributeSelected(SearchFilterComponent.CONF_PROPERTY_PRODUCT_ID, SearchCriteriaRegion.ConfProperty)) {
      this.modelsProvider.removeParameters();      
    }
    this.setIncludeChildrenSearch();

    // Update internal lists.
    this.updateAllInternalCriterias();
    this.filterChanged.emit();
    //this.cd.markForCheck();
  }

  protected updateAllInternalCriterias(): void {

    // System only support one product attribute selected at a time, If one product attribute is already selected then product attribute for rest of the criterias would be disabled.
    let attributeCriterias = this.modelsProvider.getCriteriasByRegion(SearchCriteriaRegion.ConfProperty);
    let productCriteria = attributeCriterias.filter(x => x.selectedAttribute.id == SearchFilterComponent.CONF_PROPERTY_PRODUCT_ID).first();
    if (productCriteria) {
      // Disable product attribute for each criteria except the one where the product attribute is selected.
      this.enableProductAttributeStateExcept(productCriteria, false);
    }

    this.setIncludeChildrenSearch();

    // As criterias are immutable, update the local data members.
    this.allRegions.forEach(region => this.updateInternalCriterias(region));
    
  }

  protected get allRegions(): Array<SearchCriteriaRegion> {
    return [SearchCriteriaRegion.ConfProperty, SearchCriteriaRegion.Parameter];
  }

  protected updateInternalCriterias(region: SearchCriteriaRegion): void {

    // Update the local list to reflect these changes on DOM.
    switch (region) {
      case SearchCriteriaRegion.ConfProperty:
        this.confPropertyCriterias = this.modelsProvider.getCriteriasByRegion(region);
        break;

      case SearchCriteriaRegion.Parameter:
        this.paramCriterias = this.modelsProvider.getCriteriasByRegion(region);
        break;
    }

    if (!this.cd['destroyed'])
      this.cd.detectChanges();
  }


  protected executePostActionOnAttributeChange(attributeName: string): void {

    //if (attributeName == 'ProductId' && this.paramCriterias.size == 0) {

    //  this.modelsProvider.addCriteria(this.criteriaHelper.uniqueIdentifier(Param.name), this.strings.SelectParameter, [this.emptyParamField], SearchCriteriaRegion.Parameter);
    //  this.updateInternalCriterias(SearchCriteriaRegion.Parameter);

    //}
  }

  protected executePostActionOnValueChange(event: SearchCriteriaValueEventArgs) {

    let selectorAttributeName = event.criteria.selectedAttribute.id;
    if (selectorAttributeName == SearchFilterComponent.CONF_PROPERTY_PRODUCT_ID) {

      // If no products are selected.
      if (event.value.length == 0) {
        this.modelsProvider.removeParameters();
        this.setIncludeChildrenSearch(false);
        this.updateAllInternalCriterias();
        return;
      }
      else
        this.setIncludeChildrenSearch(true);

      // Read matched parameters.
      this.searchCriteriaDataProvider.getMatchingParams(event.value).then(params => {

        // Sort the parameters.
        params = params.sort(function (a, b) {
          if (a.title < b.title) { return -1; }
          if (a.title > b.title) { return 1; }
          return 0;
        });

        // Create attribute fields.
        let selectorAttributes: Array<AttributeField> = new Array<AttributeField>(this.emptyParamField);
        params.forEach(param => selectorAttributes.push(this.criteriaHelper.getAttributeField(param.visualObjectId.toString(), param.title, SearchCriteriaRegion.Parameter, param.className, param, false, param.className)));

        this.modelsProvider.updateSelectorAttributesByRegion(SearchCriteriaRegion.Parameter, selectorAttributes);

        let paramServerCriterias = this.groupedServerCriterias.get(SearchCriteriaRegion.Parameter);
        if (paramServerCriterias && paramServerCriterias.size > 0) {

          paramServerCriterias.forEach((confs, index) => {

            let confParamCriteria: ConfSearchParameter = confs[0] as ConfSearchParameter;

            if (!this.modelsProvider.exists(index, SearchCriteriaRegion.Parameter))
              this.modelsProvider.addCriteria(index, this.strings.SelectParameter, selectorAttributes, SearchCriteriaRegion.Parameter);

            // TODO: Add, update and set values can be done in one go.
            this.modelsProvider.updateSelectedAttributeField(confParamCriteria.parameterId.toString(), SearchCriteriaRegion.Parameter, selectorAttributes, index);
            this.modelsProvider.setValue(index, SearchCriteriaRegion.Parameter, confs);
                        
          });
        }
        else {

          // Create parameter criteria, This part would only be executed if ConfProperty.ProductId and values are selected first time.
          let clientCriterias = this.modelsProvider.getCriteriasByRegion(SearchCriteriaRegion.Parameter);
          if (!clientCriterias || clientCriterias.size == 0) {
            this.modelsProvider.addCriteria(this.criteriaHelper.uniqueIdentifier(SearchCriteriaRegion.Parameter.toString()), this.strings.SelectParameter, selectorAttributes, SearchCriteriaRegion.Parameter);
          }
        }

        this.updateInternalCriterias(SearchCriteriaRegion.Parameter);
      });
    }
  }

  protected get emptyParamField() { return this.criteriaHelper.getAttributeField('-1', this.strings.SelectParameter, SearchCriteriaRegion.Parameter, null, null, true); };

  public search() {
    let confSearchModel = this.createConfigurationSearchModel();
    this.performSearch.emit(confSearchModel);
  }

  public saveSearch() {
    this.createConfigurationSearchModel();
    this.saveSearchPopup.show();
  }
}