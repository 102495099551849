export class DecorationUISettings {
  minWidth: string = "350px";
  minHeight: string = "200px";
  iconSize: string = "24px";
  attribute: AttributeUISettings[] = [];
  bom: BomUISettings = new BomUISettings();
  dataSelector: DataSelectorUISettings = new DataSelectorUISettings();
  price: PriceUISettings = new PriceUISettings();
  header: HeaderUISettings = new HeaderUISettings();
}

export class AttributeUISettings {
  minWidth: string = "300px"; // Decoration min width
  maxWidth: string; // Decoration max width
  cssStyle: string;
  titleSize?: number = 6; 
  valueSize?: number = 5;
  unitSize?: number = 1;
  decorationIds: number[]; // Settings for specific decorations 
  itemVerticalSpacing: "10px";
}

export class BomUISettings {
  minWidth: string = "700px";
  minDialogWidth: string = "800px";
}

export class DataSelectorUISettings {
  dialogWidth: string = "800px";
  filterInlineWidth: string = "150px";
  filterPopoverWidth: string = "320px";
}

export class PriceUISettings {
  minWidth: string = "350px";
  minHeight: string = "380px";
}

export class HeaderUISettings {
  minWidth: string = "350px";
}

export class ParameterUISettings {
  minWidth: string = "350px";
  minHeight: string = "350px";
  helpIconSize: string = "32px";
  iconSize: string = "24px";
  lookupValue: LookupValueUISettings = new LookupValueUISettings();
}

export class LookupValueUISettings {
  width: string = "50px";
  height: string = "50px";
}

export class StateUISettings {
  minPopupWidth: string = "750px";
  minPopupHeight: string = "500px";
}

export class TabUISettings {
  minAccordionTabWidth: string = "450px";  
  collapseAccordionAtWindowWidth: string = "";
  minSeparatedTabWidth: string = "400px";
  minTopSeparatedTabHeight: string = "150px";
  minBottomSeparatedTabHeight: string = "120px";
  keepAccordionTabsOpen: boolean = false;
}

export class ConfiguratorUISettings {
  decoration: DecorationUISettings = new DecorationUISettings();
  parameter: ParameterUISettings = new ParameterUISettings();
  state: StateUISettings = new StateUISettings();
  tab: TabUISettings = new TabUISettings();
}

export class AccountUISettings {
  logoType: 'png' | 'svg';  
  imageSet: string; // white, primary
  maxWidth: string;
}

export class AppHeaderUISettings {
  logoType: 'png' | 'svg';  
  imageSet: string; // white
  maxWidth: string;  
  logoTypeSecondLogo: 'png' | 'svg';
  imageSetSecondLogo: string; // white
  maxWidthSecondLogo: string;  
  showSecondLogo: boolean = false;  
}

export class SearchUISettings {
  columnSizes: number[] = [];
}

export class DatepickerUISettings {
  showWeekNumbers: boolean = false;
  firstDayOfWeek: string = "mo";
}

export class UISettings {
  account: AccountUISettings = new AccountUISettings();
  configurator: ConfiguratorUISettings = new ConfiguratorUISettings();
  appHeader: AppHeaderUISettings = new AppHeaderUISettings();
  search: SearchUISettings = new SearchUISettings();
  datepicker: DatepickerUISettings = new DatepickerUISettings();
}