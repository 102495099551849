import { ImmutableObject } from "../../../../shared/immutableObject";

export class ContactField extends ImmutableObject {

  protected readonly TITLE = "title";
  protected readonly DATA_TYPE = "dataType";
  protected readonly READ_ONLY = "readOnly";
  protected readonly MANDATORY = "mandatory";
  protected readonly IS_FIELD = "isField";

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }
    
  get dataType(): string { return this.getInternalValue<string>(this.DATA_TYPE); }
  setDataType(dataType: string): this { return this.setInternalValue(this.DATA_TYPE, dataType); }

  get readOnly(): boolean { return this.getInternalValue<boolean>(this.READ_ONLY); }
  setReadOnly(readOnly: boolean): this { return this.setInternalValue(this.READ_ONLY, readOnly); }

  get mandatory(): boolean { return this.getInternalValue<boolean>(this.MANDATORY); }
  setMandatory(mandatory: boolean): this { return this.setInternalValue(this.MANDATORY, mandatory); }

  get isField(): boolean { return this.getInternalValue<boolean>(this.IS_FIELD); }
  setIsField(isField: boolean): this { return this.setInternalValue(this.IS_FIELD, isField); }
}