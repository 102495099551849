<ng-container *ngIf="node">
  <li class="list-group-item" [class.highlight-selected]="highlightSelected" tabindex="0">
    <div class="row h-100">

      <div class="col pr-0">
        <span class="row h-100">

          <span class="col-auto px-0">
            <span *ngFor="let ii of indentation" class="tree-node-indentation"></span>
          </span>

          <span class="col align-middle px-0 hide-overflow" (click)="onNodeClick($event)">
            <!--TODO: Fix width height through input data-->
            <span class="px-0">
              <co-image type="svg" imageSet="{{imageSet}}" [key]="node.icon" [isVault]="node.isVault" [width]="'24'" [height]="'24'"></co-image>
            </span>
            <span title="{{node.title}}" class="title w-100" [class.selected]="selected">
              {{node.title}}
            </span>
          </span>
          <span class="col-auto action-icon pt-2" (click)="onActionIconClicked($event)" >
            <co-image type="svg" imageSet="{{imageSet}}" [key]="actionIcon" [width]="'20'" [height]="'20'"></co-image>
          </span>
        </span>
      </div>

    </div>
  </li>
  <tree-node-simple *ngFor="let child of node.children"
                    [node]="child"
                    [activeNodeId]="activeNodeId"                    
                    [imageSet]="imageSet"></tree-node-simple>

</ng-container>