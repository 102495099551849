import * as Immutable from "immutable";

import { ConfUIData } from "./confUIData";
import { PageConfIdentifier } from "./pageConfIdentifier";
import { CompositeUIData } from "./compositeUIData";
import { AccordionUIData } from "./accordionUIData";
import { PageUIData } from "./pageUIData";
import { RequestViews } from "../../models";

/**
 * Represents the page UI data for configurator.
 */
export class ConfiguratorPageUIData extends PageUIData {

  public readonly PAGE_CONF_IDENTIFIER = "pageConfIdentifier";

  // Configuration ui data by configuration Id.  
  public readonly CONF_UI_DATA_BY_ID = "confUIDataById";
  public readonly COMPOSITE_UI = "compositeUI";
  public readonly ACCORDION_UI = "accordionUI";  
  
  get compositeUI(): CompositeUIData { return this.getInternalValue<CompositeUIData>(this.COMPOSITE_UI); }
  setCompositeUI(compositeUI: CompositeUIData): this { return this.setInternalValue(this.COMPOSITE_UI, compositeUI); }

  get accordionUI(): AccordionUIData { return this.getInternalValue<AccordionUIData>(this.ACCORDION_UI); }
  setAccordionUI(accordionUI: AccordionUIData): this { return this.setInternalValue(this.ACCORDION_UI, accordionUI); }

  get confUIDataById(): Immutable.Map<number, ConfUIData> { return this.getInternalValue<Immutable.Map<number, ConfUIData>>(this.CONF_UI_DATA_BY_ID); }
  setConfUIDataById(confUIDataById: Immutable.Map<number, ConfUIData>): this { return this.setInternalValue(this.CONF_UI_DATA_BY_ID, confUIDataById); }

  get pageConfIdentifier(): PageConfIdentifier { return this.getInternalValue<PageConfIdentifier>(this.PAGE_CONF_IDENTIFIER); }
  setPageConfIdentifier(pageConfIdentifier: PageConfIdentifier): this { return this.setInternalValue(this.PAGE_CONF_IDENTIFIER, pageConfIdentifier); }

}