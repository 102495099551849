import { Injectable, Inject } from "@angular/core";
import { ConfiguratorStore } from "./configuratorStore";
import { ConfDataActionCreator } from "../../shared/providers/configurationData";
import { ConfiguratorUIStore } from "./configuratorUIStore";
import { Conf, Product } from "../../shared/models";
import { ConfiguratorPageUIData, PageConfIdentifier } from "../../shared/state";
import { ProductDataStore } from "../../shared/providers/productData";

@Injectable()
export class ConfPageSessionService {
  
  public referralLink: string;
  public activeRoute: string;  
  private _showChildrenInAccordionTabs: boolean = false;
  
  constructor(
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(ConfDataActionCreator) public confActionCreator: ConfDataActionCreator
  ) { }

  public get pageId(): number { return this.confUIStore.pageId; }

  public get dataLoaded(): boolean {

    if (!this.pageId)
      return false;

    let pageIdentifier: PageConfIdentifier = this.confUIStore.getConfiguratorPageUIState(this.pageId).pageConfIdentifier;

    return pageIdentifier && pageIdentifier.confSessionId != null && pageIdentifier.selectedConfId != null && pageIdentifier.selectedConfId == this.activeConfigurationId;

  }

  public get activeConfigurationId(): number {
    this.errorIfPageIdNotInitialized();

    return this.confUIStore.getConfiguratorPageUIState(this.pageId).pageConfIdentifier.selectedConfId;
  }

  public get activeConfiguration(): Conf {
    let confId: number = this.activeConfigurationId;

    if (!confId)
      return null;

    return this.confStore.entity<Conf>(this.confSessionId, confId, confId);
  }

  public get confSessionId(): number {
    this.errorIfPageIdNotInitialized();

    return this.confUIStore.getConfiguratorPageUIState(this.pageId).pageConfIdentifier.confSessionId;
  }

  public errorIfPageIdNotInitialized(): void {
    if (!this.pageId)
      throw new Error("Page id must be set at the time of page component initialization.");
  }

  public setPageId(pageId: number): void {
    this.confUIStore.setPageId(pageId);
  }

  public get showChildrenInAccordionTabs(): boolean {
    return this.confStore.getConfInfo(this.activeConfiguration.rootId, this.confSessionId)?.showChildrenInAccordionTabs;
  }
  

  public setChildrenInAccordionTabs(showChildrenInAccordionTabs: boolean) {
    this._showChildrenInAccordionTabs = showChildrenInAccordionTabs;
  }

  dispose(): void {
    this.confUIStore.setPageId(null);
    this.setChildrenInAccordionTabs(false);
    this.activeRoute = null;
  }

}