<div>
  <div *ngIf="displayScroller">
    <div class="scroller sc-left {{currentScrolledTab == 0 ? 'disabled' : ''}}" (click)="onScrollLeft($event)">
      <co-image width="24" height="24" type="svg" key="back" imageSet="{{imageSet}}"></co-image>
    </div>
    <div class="scroller sc-right {{enableRightScroller ? '' : 'disabled'}}" (click)="onScrollRight($event)">
      <co-image width="24" height="24" type="svg" key="forward" imageSet="{{imageSet}}"></co-image>
    </div>
  </div>

  <div class="tab-wrapper">
    <ul class="nav nav-tabs tab-color list" role="tablist" #tabsList>
      <li *ngFor="let model of models"
          class="nav-item"          
           [class.active-top]="position == 'top' && activeLinkId === model.id"
           [class.active-bottom]="position == 'bottom' && activeLinkId === model.id"
          [attr.id]="model.id"
          >
        <a (click)="selectTab($event, model)"
           role="button"
           class="nav-link align-middle flex-column justify-content-center">
          <co-image width="24" height="24" type="svg" isVault="true" [key]="model.icon" [style.visibility]="model.icon ? '' : 'hidden'"></co-image>
          <span class="ellipsis">{{model.title}}</span>
        </a>
      </li>
    </ul>
  </div>
</div>