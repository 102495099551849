import { Injectable, Inject } from '@angular/core';

import { AppStore, AccountDataActions, AppAsyncAction, HttpAction, ActionStatus, AppAction, PageActions, Actions, SearchDataActions } from "../../state";
import { SearchDataResponse } from "../../models/responses"
import { SearchDataRequest } from "../../models/requests/searchDataRequest";
import { SearchDataController } from "./searchDataController";
import { ApiResponse, KeyValue } from "../../models";
import { BaseActionCreator } from '../../baseActionCreator';

@Injectable()
export class SearchDataActionCreator extends BaseActionCreator {

  constructor(
    @Inject(AppStore) public appStore: AppStore,
    @Inject(SearchDataController) public searchDataController: SearchDataController
  ) {
    super(searchDataController);
  }

  dispatchGetRecentUserConf = (requestModel: SearchDataRequest) => this.appStore.dispatch(this.getRecentUserConf(requestModel)) as HttpAction<ApiResponse>;
  getRecentUserConf(requestModel: SearchDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(
      PageActions.SEARCH_RECENT_USER_CONF_REQUEST,
      (dispatch, state, callback) => {
        return this.searchDataController.getSearchData(requestModel).subscribe((searchResponse) => {
          dispatch({ type: Actions.SEARCH_CONFIGURATIONS_RESULT, payload: searchResponse });
          
          // Update complete request status
          callback(new ActionStatus(true, searchResponse));
        })
      });
  }

  dispatchGetRecentWorkGroupConf = (requestModel: SearchDataRequest) => this.appStore.dispatch(this.getRecentWorkGroupConf(requestModel)) as HttpAction<ApiResponse>;
  getRecentWorkGroupConf(requestModel: SearchDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(
      PageActions.SEARCH_RECENT_WORKGROUP_CONF_REQUEST,
      (dispatch, state, callback) => {
        return this.searchDataController.getSearchData(requestModel).subscribe((searchResponse) => {
          dispatch({ type: Actions.SEARCH_CONFIGURATIONS_RESULT, payload: searchResponse });

          // Update complete request status
          callback(new ActionStatus(true, searchResponse));
        })
      });
  }

  dispatchGetUnsavedUserConf = (requestModel: SearchDataRequest) => this.appStore.dispatch(this.getUnsavedUserConf(requestModel)) as HttpAction<ApiResponse>;
  getUnsavedUserConf(requestModel: SearchDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(
      PageActions.SEARCH_UNSAVED_USER_CONF_REQUEST,
      (dispatch, state, callback) => {
        return this.searchDataController.getSearchData(requestModel).subscribe((searchResponse) => {
          dispatch({ type: Actions.SEARCH_CONFIGURATIONS_RESULT, payload: searchResponse });

          // Update complete request status
          callback(new ActionStatus(true, searchResponse));
        })
      });
  }

  dispatchFetchSavedConfigurationSearches = (requestModel: SearchDataRequest) => this.appStore.dispatch(this.fetchSavedConfigurationSearches(requestModel)) as HttpAction<ApiResponse>;
  fetchSavedConfigurationSearches(requestModel: SearchDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(
      SearchDataActions.FETCH_SAVED_CONFIGURATION_SEARCHES,
      (dispatch, state, callback) => {
        return this.searchDataController.getSearchData(requestModel).subscribe((searchResponse) => {
          dispatch({ type: SearchDataActions.SAVED_CONFIGURATION_SEARCHES_LOADED, payload: searchResponse });

          // Update complete request status
          callback(new ActionStatus(true, searchResponse));
        })
      });
  }

  dispatchSearchConfigurations = (requestModel: SearchDataRequest) => this.appStore.dispatch(this.getSearchConfigurations(requestModel)) as HttpAction<ApiResponse>;
  getSearchConfigurations(requestModel: SearchDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(
      Actions.SEARCH_CONFIGURATIONS,
      (dispatch, state, callback) => {
        return this.searchDataController.getSearchData(requestModel).subscribe((searchResponse) => {
          dispatch({ type: Actions.SEARCH_CONFIGURATIONS_RESULT, payload: searchResponse });

          // Update complete request status
          callback(new ActionStatus(true, searchResponse));
        })
      });
  }

  dispatchSaveConfigurationSearch = (requestModel: SearchDataRequest) => this.appStore.dispatch(this.saveConfigurationSearch(requestModel)) as HttpAction<ApiResponse>;
  saveConfigurationSearch(requestModel: SearchDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(
      SearchDataActions.SAVE_CONFIGURATION_SEARCH,
      (dispatch, state, callback) => {
        return this.searchDataController.getSearchData(requestModel).subscribe((searchResponse) => {
          dispatch({ type: SearchDataActions.SAVED_CONFIGURATION_SEARCHES_LOADED, payload: searchResponse });

          // Update complete request status
          callback(new ActionStatus(true, searchResponse));
        })
      });
  }

  dispatchDeleteConfigurationSearch = (requestModel: SearchDataRequest) => this.appStore.dispatch(this.deleteConfigurationSearch(requestModel)) as HttpAction<ApiResponse>;
  deleteConfigurationSearch(requestModel: SearchDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(
      SearchDataActions.DELETE_CONFIGURATION_SEARCH,
      (dispatch, state, callback) => {
        return this.searchDataController.getSearchData(requestModel).subscribe((searchResponse) => {
          dispatch({ type: SearchDataActions.SAVED_CONFIGURATION_SEARCHES_LOADED, payload: searchResponse });

          // Update complete request status          
          callback(new ActionStatus(true, searchResponse));
        })
      });
  }

  

  public createHttpAction<T>(actionType: any, executeAsync: any, payload: T = null): HttpAction<T> {
    let httpAction: HttpAction<T> = {
      type: actionType,
      executeAsync: executeAsync,
      payload: payload
    }
    return httpAction;
  }
}