import { Injectable, Inject } from "@angular/core";
import { AppStore, StoreResponse, AppAction } from "../../shared/state";
import { PageUIStore } from "../../shared/providers/page/pageUIStore";
import { PageStore } from "../../shared/providers/page/pageStore";
import { PageActionCreator } from "../../shared/providers/page/pageActionCreator";
import { ReselectorService, ReselectorResult } from "../../shared/providers/reselectorService";
import { ConfiguratorPageUIData, PageConfIdentifier, PageActions, PageUIData, ConfUIData } from "../../shared/state";
import { ManagedSubscription, SubscriptionOptions } from "../../../shared/managedSubscription";
import { ManagedSubject } from "../../../shared/managedSubject";
import { AppStoreSubscriptionManager } from "../../shared/providers/appStoreSubscriptionManager";
import { RouteNames } from "../../shared/providers/routeNames";
import { CompositeUIData } from "../../shared/state/shared/compositeUIData";
import { AccordionUIData } from "../../shared/state/shared/accordionUIData";
import { ModelFactory } from "../../shared/providers/modelFactory";
import { ConfRouteParams } from "./confRouteParams";
import { GlobalDataStore } from "../../shared/providers/globalData";

@Injectable()
export class ConfiguratorUIStore extends PageUIStore {

  public _pageId: number;
  private _confParams: ConfRouteParams;
  private _isNextStateIncluded: boolean;
  private _includeMandatoryInfo: boolean;
  private _isMandatoryOpened: boolean = false;  

  constructor(
    @Inject(AppStore) public appStore: AppStore,
    @Inject(PageStore) public PageStore: PageStore,
    @Inject(PageActionCreator) public pageActionCreator: PageActionCreator,
    @Inject(ReselectorService) public reselectorService: ReselectorService,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(AppStoreSubscriptionManager) public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    public globalDataStore: GlobalDataStore
  ) {
    super(appStore, pageActionCreator, appStoreSubscriptionManager, modelFactory, globalDataStore);
  }

  public get pageId(): number { return this._pageId; }

  public setPageId(pageId: number): void {
    this._pageId = pageId;
  }

  public get confParams(): ConfRouteParams { return this._confParams; }
  public setConfParams(confParams: ConfRouteParams): void {
    this._confParams = confParams;
  }

  public get isMandatoryOpened(): boolean { return this._isMandatoryOpened; }
  public setIsMandatoryOpened(isMandatoryOpened: boolean): void {
    this._isMandatoryOpened = isMandatoryOpened;
  }

  public setSubConfId(subConfId: number): void {

    if (this._confParams)
      this._confParams.subConfId = subConfId;
  }

  // Keep remember the selected tabId, and select the same tab  as before configuration selection change.Note: It works only for same product's configurations.
  public selectedTabId: number;  

  /** Defines if mandatory info should be included in requests. */
  public get includeMandatoryInfo(): boolean {
    return this._includeMandatoryInfo;
  }

  public set includeMandatoryInfo(includeMandatoryInfo: boolean) {
    this._includeMandatoryInfo = includeMandatoryInfo;
  }

  public get isNextStateIncluded(): boolean {
    return this._isNextStateIncluded;
  }

  public set isNextStateIncluded(isNextStateIncluded: boolean) {
    this._isNextStateIncluded = isNextStateIncluded;
  }

  public createConfiguratorUI(): Promise<StoreResponse<any>> {
    let nextPageId: number = this.nextPageIdentifier();

    // Create request object
    let response: StoreResponse<ConfiguratorPageUIData> = new StoreResponse<ConfiguratorPageUIData>();

    // Subscribe to store and return the results on request complete.       
    let promiseResult = this.appStoreSubscriptionManager.createSubject((appStore) => {

      if (appStore.getState().uiData.configuratorUI.has(nextPageId.toString())) {

        response.requestId = nextPageId;
        response.data = appStore.getState().uiData.configuratorUI.get(nextPageId.toString());
        return response;
      }

      return ManagedSubject.IGNORE_VALUE;
    }, true).toPromise();

    this.pageActionCreator.dispatchAddConfiguratorPageUIData(nextPageId);

    return promiseResult;
  }

  public getConfiguratorPageUIState(pageId: number): ConfiguratorPageUIData {
    return this.appStore.getState().uiData.configuratorUI.get(pageId.toString());
  }

  setConfId(confId: number, pageId: number): void {
    let confData: ConfUIData = this.getConfUIState(confId, pageId);

    if (confData)
      return;

    confData = new ConfUIData();
    confData = confData.setConfId(confId);

    this.pageActionCreator.dispatchUpdateState(pageId, PageActions.UPDATE_CONF_UI_DATA, confData);
  }

  public setPageConfIdentifier(pageId: number, configurationId: number, sessionId: number): void {
    let pageConfIdentifier: PageConfIdentifier = new PageConfIdentifier();
    pageConfIdentifier = pageConfIdentifier.setConfSessionId(sessionId);
    pageConfIdentifier = pageConfIdentifier.setSelectedConfId(configurationId);
    this.pageActionCreator.dispatchUpdateState(pageId, PageActions.CONFIGURATION_SELECT, pageConfIdentifier);
  }

  public updateCompositeUIState(pageId: number, calcWidth: string, calcSticky: boolean, visible: boolean, sidebarExtended: boolean = false): void {
    let composite: CompositeUIData = new CompositeUIData();
    composite = composite.setCalcSticky(calcSticky);    
    composite = composite.setCalcWidth(calcWidth);
    composite = composite.setVisible(visible);
    composite = composite.setSidebarExtended(sidebarExtended);
    this.pageActionCreator.dispatchUpdateState(pageId, PageActions.COMPOSITE_UI_CHANGE, composite);
  }

  public updateAccordionUIState(pageId: number, calcSticky: boolean, visible: boolean, numberOfTabs: number): void {
    let accordion: AccordionUIData = new AccordionUIData();
    accordion = accordion.setCalcSticky(calcSticky);
    accordion = accordion.setVisible(visible);
    accordion = accordion.setTabsCount(numberOfTabs);

    this.pageActionCreator.dispatchUpdateState(pageId, PageActions.TABS_ACCORDION_UI_CHANGE, accordion);
  }


  public getConfUIState(confId: number, pageId): ConfUIData {
    let pageState = this.getPageUIState(pageId) as ConfiguratorPageUIData;

    if (pageState.confUIDataById.has(+confId)) {
      return pageState.confUIDataById.get(+confId);
    }

    return null;
  }

  public onCompositeCalcStickyChange(pageId: number, subscriptionOptions: SubscriptionOptions<boolean>): ManagedSubscription {
    if (!pageId)
      return;

    let activeConfMemorizer: () => boolean = this.reselectorService.createMemorizer1((state) =>
      state.uiData.configuratorUI.get(pageId.toString()).compositeUI, (input) => input.calcSticky
    );

    return this.createStoreSubscription<boolean>(pageId + '__calc_sticky_change', activeConfMemorizer, subscriptionOptions);
  }

  /**
 * TODO: We might need to move this method to SessionManagerService
 * @param pageId
 * @param subscriptionOptions
 */
  public onActiveConfChange(pageId: number, subscriptionOptions: SubscriptionOptions<number>): ManagedSubscription {
    if (!pageId)
      return;

    let activeConfMemorizer: () => number = this.reselectorService.createMemorizer1((state) => state.uiData.configuratorUI.get(pageId.toString()).pageConfIdentifier, (input) => input.selectedConfId);

    return this.createStoreSubscription<number>(pageId + '__active', activeConfMemorizer, subscriptionOptions);
  }

  /**
   * TODO: We might need to move this method to SessionManagerService
   * @param pageId
   * @param subscriptionOptions
   */
  public onSessionAvailable(pageId: number, subscriptionOptions: SubscriptionOptions<number>): ManagedSubscription {
    if (!pageId)
      return;

    let sessionConfMemorizer: () => number = this.reselectorService.createMemorizer1((state) => state.uiData.configuratorUI.get(pageId.toString()).pageConfIdentifier, (input) => input.confSessionId);
    return this.createStoreSubscription<number>(pageId + '__session_available', sessionConfMemorizer, subscriptionOptions);
  }

  public compositeUIChange(pageId: number, subscriptionOptions: SubscriptionOptions<CompositeUIData>): ManagedSubscription {
    let compositeMemorizer: () => CompositeUIData = this.reselectorService.createMemorizer<CompositeUIData>((state) => state.uiData.configuratorUI.get(pageId.toString()).compositeUI);

    return this.createStoreSubscription<CompositeUIData>(pageId + '__composite', compositeMemorizer, subscriptionOptions);
  }

  public getMemorizer(pageId: number): () => PageUIData {
    return this.reselectorService.createMemorizer((state) => state.uiData.configuratorUI.get(pageId.toString()));
  }

  public getActiveConfigurationMemorizer(pageId: number): () => number {
    return this.reselectorService.createMemorizer((state) => state.uiData.configuratorUI.get(pageId.toString()).pageConfIdentifier.selectedConfId);
  }

  public getPageUIState(pageId: number): PageUIData {
    return this.getConfiguratorPageUIState(pageId);
  }

}