import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit, ViewChild, ViewEncapsulation, NgZone } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { PopupIdentifiers, ConfRouteParams } from '../pages/configurator/providers';
import { IEmitDataInfo } from '../pages/configurator/shared';
import { BaseComponent, PageComponent } from "../pages/shared";
import { ApiException, ApiMessage, ApiMessageScope, ComplexSequentialPopups, RedirectMessage, RequestViews, User, UIAction, UIPopup, UIEventOperation, OperationType, UINotification, PushMessage, ServerPushMessage, LogoutMessage } from "../pages/shared/models";
import { ApiMessageProvider, HttpErrorCodeHandler, RouteRedirector, RouteNames, BroadcastChannelService, BroadcastChannelMessageType } from "../pages/shared/providers";
import { AccountDataStore, AccountDataMessageProvider } from "../pages/shared/providers/accountData";
import { ExceptionHandler } from "../pages/shared/providers/exceptionHandler";
import { GlobalDataStore } from '../pages/shared/providers/globalData';
import { MessageBoxComponent, MessageBoxConfig, PopupService } from "../shared/components";
import { NotificationInfo, NotificationService, NotificationType } from "../shared/components/notification";
import { ErrorCodes } from "../shared/errorCodes";
import { Exception } from "../shared/exception";
import { HttpException } from "../shared/httpException";
import { JsonConvert } from "../shared/providers/jsonConvert";
import { Routing } from "../shared/route/routeDecorator";
import { BreakPointAccessor } from "../shared/utils";
import { PushMessageStore, PushMessageController } from '../pages/shared/providers/pushMessage';
import * as Immutable from "immutable";
import { PushMessageSelection } from '../pages/shared/providers/pushMessage/pushMessageSelection';
import { PushMessageComponent } from '../pages/shared/components/push/pushMessageComponent';
import { SignalRService } from '../pages/shared/providers/pushMessage/signalRService';
import { HttpService } from "../shared/providers/httpService";
import { PageStore } from '../pages/shared/providers/page';
import { SessionStorageService } from '../pages/shared/providers/sessionStorageService';
import { SessionStorageData } from '../pages/shared/models/client/sessionStorageData';
import { AuthenticationTokenTypes } from '../pages/shared/providers/authenticationTokenTypes';
import { AccordionCompositeParams } from '../pages/configurator/components/tabs';
import { HttpRequestTrackerService } from '../pages/shared/providers/httpRequestTrackerService';

/*
 * App Component
 * Top Level Component
 */
@Routing({ path: '', redirectTo: '/start', pathMatch: 'full' })
@Component({
  selector: 'combinum-app',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './appComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends BaseComponent implements OnInit {

  @ViewChild(MessageBoxComponent)
  protected navigateMessageBox: MessageBoxComponent;

  @ViewChild('pushMessagePopup')
  private pushMessagePopup: PushMessageComponent;

  public isMobile: boolean;
  public isLimitedBrowserSupport: boolean = false;
  public limitedBrowserSupportInfo: string;
  public extraStyles: string = "";
  public notificationDetailString: string;
  public notificationMessageString: string;
  public blockUIStyle: string;
  public containerId: string;
  public isUserLoggedIn: boolean;
  public subscription: Subscription;
  public routeSubscription: Subscription;
  private pageComponent: PageComponent;
  private isReload: boolean = false;

  pushMessageQueue = [];

  public uiPopup: UIPopup;
  public sessionId: number;
  actionsById: Immutable.Map<string, UIAction[]>;

  constructor(
    @Inject(BreakPointAccessor) public breakPointAccessor: BreakPointAccessor,
    @Inject(ExceptionHandler) public exceptionHandler: ExceptionHandler,
    @Inject(NotificationService) public notificationService: NotificationService,
    @Inject(JsonConvert) public jsonConvert: JsonConvert,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    @Inject(ApiMessageProvider) public apiMessageProvider: ApiMessageProvider,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(SignalRService) public signalRService: SignalRService,
    @Inject(PushMessageStore) public pushMessageStore: PushMessageStore,
    @Inject(PushMessageController) public pushMessageController: PushMessageController,
    @Inject(PageStore) public pageStore: PageStore,
    @Inject(AccountDataMessageProvider) public accountDataMessageProvider: AccountDataMessageProvider,
    @Inject(BroadcastChannelService) public broadcastChannelService: BroadcastChannelService,
    @Inject(HttpRequestTrackerService) public httpRequestTrackerService: HttpRequestTrackerService,

    public zone: NgZone,

    // MsCrm ->
    @Inject(HttpService) public httpService: HttpService,
    // MsCrm <-
    public globalDataStore: GlobalDataStore,
    public sessionStorageService: SessionStorageService,
    public httpErrorCodeHandler: HttpErrorCodeHandler,
    public router: Router,
    public cd: ChangeDetectorRef,
    public activeRoute: ActivatedRoute,
    protected ngLocation: Location
  ) {
    super();

    this.routeSubscription = this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {

          this.blockUI();

          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {

          this.unblockUI();

          break;
        }
        default: {
          break;
        }
      }
    });

  }

  ngOnInit() {
        
    // fallback if the redirect url get lost
    let windowUrl = window.location.href;
    let activeUrl = '';
    if (!windowUrl.includes('#/account') && windowUrl.includes('#'))
      activeUrl = windowUrl.substring(window.location.href.indexOf('#') + 1)
    localStorage.setItem("cachedRedirectUrl", activeUrl);
    console.log('-------------->The redirect url is cached<--------------');
    // fallback code end

    let globalSettings = this.globalDataStore.globalSettings;
    this.isMobile = this.browserInfo.isMobile;

    this.isLimitedBrowserSupport = globalSettings.limitedBrowsersSupport.contains(this.browserInfo.name);
    this.limitedBrowserSupportInfo = this.format(this.strings.LimitedBrowserSupportInfo, this.browserInfo.fullName, this.browserInfo.getSupportedBrowsersInfo(globalSettings.limitedBrowsersSupport.toArray()));

    this.notificationDetailString = this.strings.Detail;
    this.notificationMessageString = this.strings.Message;

    this.listenOnException();
    this.listenOnHttpException();
    this.listenOnRedirectMessage();
    this.listenOnStorageChanged();
    this.listenOnUIBlockerStart();
    this.listenOnApiMessage();
    this.listenOnBroadcastChannelMessage();
    this.listenOnLogoutMessage();
    // MsCrm ->
    this.listenOnPopupMessage();
    // MsCrm <-

    this.breakPointAccessor.initialize();
    this.extraStyles = this.browserInfo.name;

    this.subscription = this.router.events.subscribe((val) => {

      if (this.isAppInsideIFrame) {
        let currentNavigation = this.router.getCurrentNavigation();
        if (currentNavigation) {

          if (currentNavigation.extras) {
            currentNavigation.extras.skipLocationChange = true;
          }
          else {
            currentNavigation.extras = { skipLocationChange: true };
          }
        }
      }

      this.isUserLoggedIn = this.accountStore.isUserLoggedIn();

      this.pushMessageStore.startListening();

      // If the user is not logged in, then header goes away and body contents starts from top position 0.
      this.containerId = this.isUserLoggedIn ? 'body-contents' : 'body-contents-clean';

      let user: User = this.accountStore.getUser();
      let isAnonymousMode = !user || user.systemAuthorization.hasAnonymousAccess;
      this.extraStyles = this.browserInfo.name + (isAnonymousMode ? ' anonymous' : '');

      this.cd.markForCheck();
    });

    this.signalRService.connecting.subscribe((data: number) => {

      let model = this.pushMessageStore.createRequest();

      model.tempConnectionId = data;

      this.signalRService.send(model);

    });

    this.signalRService.signalReceived.subscribe((data: any) => {

      this.pushMessageStore.setData(data).subscribe(async (storeResponse) => {

        this.pushMessageQueue.push(storeResponse.data);

        if (this.sessionId && this.sessionId != storeResponse.data.interactionSessionId) {

          // Only process new push messages or push messages from the same session, any unprocessed push messages will be called when closing popups etc.
          return;
        }

        await this.httpRequestTrackerService.waitForRequestCompletion(storeResponse.data.clientRequestId);
        this.processNextPushMessage();

      }).unsubscribeOn(this.unsubscribeSubject);

    });


  }

  private processNextPushMessage() {

    let prevSessionId = this.sessionId;

    this.sessionId = null;
    this.uiPopup = null;

    if (this.pushMessageQueue.length == 0)
      return;

    let message: ServerPushMessage = null;

    // Prioritize the same session id.
    if (prevSessionId) {
      let index = this.pushMessageQueue.findIndex(a => a.interactionSessionId == prevSessionId);

      if (index != -1) {
        message = this.pushMessageQueue[index];
        this.pushMessageQueue.splice(index, 1);
      }
    }

    // If empty, then get the first in the array.
    if (!message)
      message = this.pushMessageQueue.shift();

    this.sessionId = message.interactionSessionId;

    // Clear cache, for each new push message from server.
    // this.pushMessageStore.clear(this.sessionId);

    if (message) {
      let startAction = message.startUIAction;

      this.processAction(startAction, message.interactionSessionId);

      if (!this.uiPopup)
        this.processNextPushMessage();
    }
  }

  private processAction(action: string, interactionSessionId: number) {

    let actions = this.pushMessageStore.getUIActions(action, interactionSessionId)

    if (!actions)
      return;

    actions.forEach((x) => {

      let uiAction = this.pushMessageStore.getUIAction(x, interactionSessionId)

      if (!uiAction) {

        this.notificationService.notify(<NotificationInfo>{
          message: "Push message action '" + x + "' was not found.",
          type: NotificationType.Error,
          selfClose: false
        });

        return;
      }

      switch (uiAction.className) {
        case "UIPopup":
          this.uiPopup = uiAction as UIPopup;
          this.sessionId = interactionSessionId;
          this.showPopup();
          break;
        case "UIEventOperation":

          let operation = uiAction as UIEventOperation;

          if (operation.operationType == OperationType.ClientPushMessage) {

            let model = this.pushMessageStore.createRequest();

            let valuesById = this.pushMessageStore.getValuesBySessionId(interactionSessionId);

            model.interactionSessionId = this.sessionId;
            model.triggeringControlId = action;
            model.valuesById = valuesById;

            this.signalRService.send(model);
          }
          else if (operation.operationType == OperationType.ClosePopup) {
            (this.pushMessagePopup as PushMessageComponent).close();
            this.uiPopup = null;
          }
          else if (operation.operationType == OperationType.Redirect) {
            this.pushMessageStore.setRedirecting(true).subscribe((storeResponse) => {

              if (this.isExternalUrl(operation.url)) {
                this.routeRedirector.redirectByAbsolutUrl(operation.url, false);

                // Just in case, the url didn't redirect.
                this.pushMessageStore.setRedirecting(false);
              }
              else {
                this.zone.run(() => {

                  let routeArgs = null;

                  if (operation.routeArgs && operation.routeArgs.size > 0)
                    routeArgs = operation.routeArgs.toObject();

                  let queryParams = null;

                  let extras: any = null;

                  if (operation.queryParams && operation.queryParams.size > 0) {

                    extras = {};
                    extras.queryParams = operation.queryParams.toObject();
                  }

                  if (operation.replaceUrl)
                  {
                    if (!extras)
                      extras = {};

                    extras.replaceUrl = true;
                  }

                  if (operation.skipLocationChange) {
                    if (!extras)
                      extras = {};

                    extras.skipLocationChange = true;
                  }

                  this.routeRedirector.redirect([operation.url, routeArgs], extras).then((navigationSuccess: boolean) => {
                    // Restore the configurator redirect guard.
                    this.pushMessageStore.setRedirecting(false);
                  });
                });
              }

            }).unsubscribeOn(this.unsubscribeSubject);

          }
          else if (operation.operationType == OperationType.AccordionChildNavigation) {
            this.emitterService.send<any>(PopupIdentifiers.AccordionCompositeShowDetail, { confId: operation.confId, reload: operation.reload});
            this.routeRedirector.changeParamValue(ConfRouteParams.SUB_CONF_ID, operation.confId.toString());
          }
          else if (operation.operationType == OperationType.AccordionChildExit) {
            this.emitterService.send(PopupIdentifiers.AccordionCompositeHideDetail, new AccordionCompositeParams(operation.confId, false));
            this.routeRedirector.changeParamValue(ConfRouteParams.SUB_CONF_ID, null);
          }
          else if (operation.operationType == OperationType.Refresh) {
            this.pushMessageStore.setRefreshing(true).subscribe((storeResponse) => {
              window.location.reload(true);
            }).unsubscribeOn(this.unsubscribeSubject);
          }
          else if (operation.operationType == OperationType.Download) {
            this.httpService.downloadFile(operation.url);
          }

          break;
        case "UINotification":

          let notification = uiAction as UINotification;

          this.notificationService.notify(<NotificationInfo>{
            title: notification.title,
            message: notification.message,
            detail: notification.detail,
            rawInfo: notification.rawInfo,
            type: notification.type,
            selfClose: notification.selfClose,
            delay: notification.delay,
            identifier: notification.identifier,
            sync: notification.sync
          });

          this.cd.detectChanges();

          break;
        default:
          // 
          console.log("Action not implemented: " + x)
          break;
      }
    })

    // If there is no active popup, run a new push message.
    if (!this.uiPopup)
      this.processNextPushMessage();
  }

  isExternalUrl(url: string): boolean {
    return url.startsWith('http') || url.startsWith('www');
  }

  public onAction(event: PushMessageSelection) {

    if (event.key)
      this.pushMessageStore.setValue(event);

    if (event.triggerAction)
      this.processAction(event.key, event.sessionId);

  }

  public showPopup() {

    (this.pushMessagePopup as PushMessageComponent).show();
    this.cd.detectChanges();

  }

  private _isAppInsideIFrame: boolean = null;
  protected get isAppInsideIFrame() {

    if (this._isAppInsideIFrame != null)
      return this._isAppInsideIFrame;

    try {
      this._isAppInsideIFrame = window.self !== window.top;
    }
    catch {
      this._isAppInsideIFrame = true;
    }

    return this._isAppInsideIFrame;
  }

  public listenOnUIBlockerStart(): void {

    this.emitterService.getMessage().subscribe((info: IEmitDataInfo<boolean>) => {

      if (info.id == PopupIdentifiers.PlainUIBlock) {

        // If tag is true then start plain ui blocking without spinner.
        this.blockUIStyle = info.tag ? 'plain-ui-block' : '';
      }

    }).unsubscribeOn(this.unsubscribeSubject);

  }

  public listenOnException() {

    this.exceptionHandler.onException((exception: Exception) => {
      this.unblockUI();
      let type = NotificationType.Error;
      let title = this.strings.Error;
      let selfClose = false;
      let identifier = "error-message";
      let sync = false;

      // Handle error codes to show different messages      
      if (exception instanceof ApiException) {

        if (ErrorCodes.isSuppressNotification(exception.code))
          return;

        if (ErrorCodes.isInformationCode(exception.code)) {
          type = NotificationType.Info;
          title = this.strings.Info;
          selfClose = true;
          identifier = "error-info";
        }
        else if (ErrorCodes.isWarningCode(exception.code)) {
          type = NotificationType.Warning;
          title = this.strings.Warning;
          selfClose = true;
          identifier = "error-warning";
          sync = true;
        }
      }

      this.notificationService.notify(<NotificationInfo>{
        title: title,
        message: exception.message,
        detail: `${exception.url ? (exception.url + " - ") : ""}${exception.detail ? exception.detail : ""}`,
        rawInfo: exception.originalError,
        type: type,
        selfClose: selfClose,
        identifier: identifier,
        sync: sync
      });

      // If error is configuration not found then redirect to start
      if (exception instanceof ApiException) {
        if (ErrorCodes.SEARCH_SESSION_NOT_FOUND == exception.code || ErrorCodes.CONFIGURATION_NOT_FOUND == exception.code)
          this.routeRedirector.redirectToStart();
      }

    }, true);
  }

  public listenOnHttpException() {
    this.exceptionHandler.onHttpException((exception: HttpException) => {

      this.unblockUI();
      this.httpErrorCodeHandler.handle(exception);

      if (exception.errorResponse.status == 400) {
        let badRequestException = exception.exceptions.find((ex) => ex instanceof ApiException && ex.code == ErrorCodes.CLIENT_VERSION_INCOMPATIBLE) as ApiException;
        if (badRequestException)
          this.showMessageAndRunCallBack(badRequestException.message, null);
      }

    }, true);
  }

  public listenOnRedirectMessage() {
    this.apiMessageProvider.onMessages<RedirectMessage>(RedirectMessage.name,
      (messages) => {

        let redirectMessage = messages.first();

        // MsCrm ->
        if (redirectMessage.externalUrl) {
          if (redirectMessage.extraArgs && redirectMessage.extraArgs.get('upn')) {
            let upn = redirectMessage.extraArgs.get('upn');
            let url = escape(window.location.href);
            this.httpService.get('mscrm/registerLatestUrl?upn=' + upn + '&url=' + url).subscribe(
              _ => {
                window.location.href = redirectMessage.externalUrl;
              }
            );
          }
          return;
        }
        // MsCrm <-

        let queryString = "";
        if (redirectMessage.queryString) {
          queryString = "?" + redirectMessage.queryString;
        }

        let routeArgs = {};
        if (redirectMessage.parameters) {
          routeArgs = redirectMessage.parameters.toObject()
        }

        let view = redirectMessage.view.toLowerCase();
        if (view == RequestViews.None) {
          this.showMessageAndRunCallBack(redirectMessage.message, null);
          return;
        }
        else if (view == RequestViews.Summary || view == RequestViews.Editor) {
          view = "configurator/" + view;
        }

        let redirectUrl = `${view}/${queryString}`;
        this.showMessageAndRunCallBack(redirectMessage.message, () => { this.routeRedirector.redirect([redirectUrl, routeArgs]) });

      });
  }

  listenOnApiMessage() {
    this.apiMessageProvider.onMessages<ApiMessage>(ApiMessage.name, {
      next: (messages) => {
        let apiMessage = messages.first();

        // Close it automatically if scope is temporary.
        let selfClose = apiMessage.scope == ApiMessageScope.Temp;

        if (apiMessage.innerMessage) {
          this.notificationService.notify(<NotificationInfo>{ selfClose: selfClose, title: apiMessage.innerMessage.title, message: apiMessage.innerMessage.message, type: apiMessage.displayStyle ? apiMessage.displayStyle : NotificationType.Info });
        }

      }, listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  // MSCRM begin
  public listenOnPopupMessage() {
    this.apiMessageProvider.onMessages<ComplexSequentialPopups>(ComplexSequentialPopups.name,
      {
        next: (messages) => {
          messages.forEach(apiMessage => {

            if (apiMessage.popupSequences)
              this.popupService.open(PopupIdentifiers.MsCrmIntegrationPopup, apiMessage);
          });
        }
      });
  }
  // MSCRM end

  public listenOnStorageChanged() {
    this.sessionStorageService.onSessionStorageChanged((sessionStorageData: SessionStorageData) => {

      if (!this.accountStore.hasUserSession() && this.pageStore.activeRouteName != RouteNames.Login) {
        this.isUserLoggedIn = false;
        this.routeRedirector.redirect([RouteNames.Logout]);
      }

    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public listenOnBroadcastChannelMessage() {
    this.broadcastChannelService.broadcastChannel.addEventListener("message", (messageEvent: MessageEvent) => {

      if (!messageEvent.origin || messageEvent.origin != location.origin)
        return;

      switch (messageEvent.data.type) {
        case BroadcastChannelMessageType.ReloadCache:
          this.showMessageAndRunCallBack(this.strings.ProductDataUpdatedAndVerifyConfiguration, null);
          break;

        case BroadcastChannelMessageType.UserLoginTokenRequest:
          this.respondWithAccessToken();
          break;

        case BroadcastChannelMessageType.UserLoginToken:
          this.storeAccessToken(messageEvent.data.payload);
          break;

        case BroadcastChannelMessageType.LogOutUser:
          this.accountStore.endUserSession();
          break;

        default:
          // Should never go here.
          // Do nothing.
          break;
      }
    });
  }

  public listenOnLogoutMessage() {
    // Listen for logout message
    this.accountDataMessageProvider.onMessages<LogoutMessage>(LogoutMessage.name, {
      next: (messages) => {

        let logoutMessage = messages.get(0);
        if (logoutMessage.success)
          this.broadcastChannelService.send(BroadcastChannelMessageType.LogOutUser);
        else
          console.log(logoutMessage.errors);
      },
      listenNewEventsOnly: true

    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public respondWithAccessToken() {
    let token = this.accountStore.getAccessToken();
    if (token)
      this.broadcastChannelService.send(BroadcastChannelMessageType.UserLoginToken, token);
  }

  public storeAccessToken(token: string) {
    if (token)
      this.accountStore.setAccessToken(token);
  }

  public showMessageAndRunCallBack(message: string, callBack: () => void) {

    let isReload = !callBack;
    callBack = callBack || (() => {
      this.isReload = true;

      window.location.reload(true);

      this.isReload = false;
    });

    if (message && message.length > 0) {

      let info: MessageBoxConfig<() => void> = <MessageBoxConfig<() => void>>{
        description: message,
        icon: "info",
        tag: callBack
      }

      if (this.navigateMessageBox) {
        this.navigateMessageBox.title = this.navigateMessageBox.okButtonText = isReload ? this.strings.Reload : this.strings.Navigate;
        this.navigateMessageBox.show(info);
      }

    }
    else {
      callBack();
    }
  }

  public navigateMessageBoxOkClick($event: () => void) {
    if ($event)
      $event();
  }

  public onComponentActivate($component): void {
    if ($component && $component instanceof PageComponent)
      this.pageComponent = $component;
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload($event) {
    if (this.pageComponent)
      return this.pageComponent.onBeforeUnload($event, this.isReload);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.breakPointAccessor.initialize(event.target.innerWidth);
  }

  @HostListener('window:focus')
  onFocus(): void {
    // If the tab is focused, it might have been inactive.
    // Request for a token in case any other tabs are active to sync with them.
    this.broadcastChannelService.send(BroadcastChannelMessageType.UserLoginTokenRequest);
  }

  @HostListener('window:storage', ['$event'])
  initStorageEvent(event: StorageEvent) {
    // if storage event is triggered by sso
    if (event.key === AuthenticationTokenTypes.TokenBySSO && localStorage.getItem(AuthenticationTokenTypes.TokenBySSO) !== null) {
      let access_token = localStorage.getItem(AuthenticationTokenTypes.TokenBySSO);
      this.accountStore.setAccessToken(access_token);
      this.broadcastChannelService.send(BroadcastChannelMessageType.UserLoginToken, access_token);
      localStorage.removeItem(AuthenticationTokenTypes.TokenBySSO);
    }
  }

  ngOnDestroy() {

    if (this.subscription)
      this.subscription.unsubscribe();

    if (this.routeSubscription)
      this.routeSubscription.unsubscribe();

    super.ngOnDestroy();
  }

}