<div *ngIf="view != null" [class.disabled]="view.disabled" [style.width]="view.width" [style.max-width.%]="100" [style.min-width]="view.minWidth" >
  <label *ngIf="!hideLabel" class="static-label-above" for="{{view.id}}">{{view.title}}</label>
  <div class="input-container text-field {{getMoreStyles}}" [class.has-icon]="view.icon" [class.highlight]="view.highlight" [class.has-focus]="hasFocus">

    <span *ngIf="view.readOnly" class="read-only align-middle" [id]="view.id">
      <a role="button" (click)="readOnlyClick()"><co-image width="20" height="20" type="svg" key="lock" imageSet="{{imageSet}}"></co-image></a>
    </span>

    <icon [view]="view"></icon>
    <!-- <input name=.. doesn't work with ngModel! It's not set for some reason.. but name is needed for chrome autocomplete! -->
    <ng-container *ngIf="!rows; else multiline">
        <input *ngIf="useFormatting; else noFormatting"
               #textbox 
               class="input-title"
               [id]="view.id"
               [name]="view.name"
               [(ngModel)]="view.formattedValue"
               [readonly]="view.readOnly"
               [attr.autofocus]="autofocus ? '' : null"
               [type]="type"
               [step]="stepValue ? stepValue : ''"
               [autocomplete]="autocomplete"
               placeholder="{{view.placeholder}}"
               (keyup)="processEvent($event, true)"
               (keypress)="processKeyPress($event)"
               (change)="processEvent($event, false)"
               (blur)="view.formattedValue != view.value && transformValue($event)"
               (focus)="onFocus($event)"
               (focusout)="onTextboxFocusOut($event)"
               title="">
        <ng-template #noFormatting>
            <input #textbox class="input-title"
                   [id]="view.id"
                   [name]="view.name"
                   [(ngModel)]="view.value"
                   [readonly]="view.readOnly"
                   [attr.autofocus]="autofocus ? '' : null"
                   [type]="type"
                   [step]="stepValue ? stepValue : ''"
                   [autocomplete]="autocomplete"
                   [email]="type == 'email'"
                   placeholder="{{view.placeholder}}"
                   (keyup)="processEvent($event, true)"
                   (keypress)="processKeyPress($event)"
                   (change)="processEvent($event, false)"
                   (focus)="hasFocus = true"
                   (focusout)="hasFocus = false"
                   title="">
        </ng-template>
    </ng-container>
    <ng-template #multiline>
      <textarea class="input-title w-100"
                rows="{{rows}}"
                cols="{{cols}}"
                [(ngModel)]="view.value"
                [id]="view.id"
                [name]="view.name"
                [readonly]="view.readOnly"
                [attr.autofocus]="autofocus ? '' : null"
                placeholder="{{view.placeholder}}"
                (keyup)="processEvent($event, true)"
                (change)="processEvent($event, false)"
                (focus)="hasFocus = true"
                (focusout)="hasFocus = false"
                title=""></textarea>
    </ng-template>

    <help-link *ngIf="view.enableHelp"
               [view]="view"
               (click)="helpClick($event)"
               [imageSet]="helpImageSet"></help-link>
  </div>
  <!--<ng-container *ngIf="_errors && _errors.size > 0">-->
    <div *ngFor="let error of _errors" class="form-control-feedback d-block">{{error}}</div>
  <!--</ng-container>-->
</div>