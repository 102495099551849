import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpErrorResponse, HttpResponse, HttpEventType } from '@angular/common/http';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { Ioc, Register } from "../../../shared/ioc/iocdecorator";
import { ApiResponse, ApiException } from "../models"
import { ExceptionHandler } from "./exceptionHandler"
import { Exception } from "../../../shared/exception";
import { HttpException } from "../../../shared/httpException";
import { ModelFactory } from "./modelFactory";
import { HttpLifeCycleService, HttpStatus } from './httpLifeCycleService';
import { SignalRService } from './pushMessage/signalRService';
import { AccountDataStore } from './accountData/accountDataStore';
import { AuthenticationTokenTypes } from './authenticationTokenTypes';

@Injectable()
export class HttpRequestTrackerService {

  private activeRequests: { [requestId: number]: boolean } = {};

  private requestInProgressSubject: Subject<boolean> = new Subject<boolean>();

  private readonly MAX_REQUESTS = 100;

  constructor() { }

  isRequestInProgress(): Observable<boolean> {
    return this.requestInProgressSubject.asObservable();
  }

  createClientRequestId(): number {
    return new Date().valueOf() * 10000 + Math.floor(1000 + Math.random() * 9000);
  }

  addActiveRequest(): number {

    let requestId = this.createClientRequestId();

    if (Object.keys(this.activeRequests).length >= this.MAX_REQUESTS) {
      this.removeOldestRequest();
    }
    this.activeRequests[requestId] = true;

    return requestId;
  }

  removeActiveRequest(requestId: number): void {
    delete this.activeRequests[requestId];
    this.requestInProgressSubject.next(false); // Notify subscribers that request is no longer in progress
  }

  private removeOldestRequest(): void {
    const oldestRequestTime = Math.min(...Object.keys(this.activeRequests).map(Number));
    delete this.activeRequests[oldestRequestTime];
  }

  async waitForRequestCompletion(requestId?: number): Promise<void> {
    // If a specific requestId is provided, wait until it's no longer in progress
    if (requestId && this.activeRequests[requestId]) {
      await new Promise<void>((resolve) => {
        const subscription = this.isRequestInProgress().subscribe((inProgress) => {
          if (!this.activeRequests[requestId]) {
            subscription.unsubscribe(); // Unsubscribe once the request is complete
            resolve();
          }
        });
      });
    }
  }
}
