import { Inject, Component, Input, ChangeDetectorRef } from "@angular/core";
import * as Immutable from "immutable";
import { PriceReportMessage  } from "../../../shared/models/responses/messages";
import { PopupService } from "../../../../shared/components/popup";
import { ConfInfo, RequestViews } from "../../../shared/models";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { ConfiguratorStore, ConfPageSessionService, PopupIdentifiers, ConfMessageProvider } from "../../providers";
import { PriceReportRowView, PriceReportCellView } from "../../../shared/components/priceReport/priceReportRowView";
import { ManagedSubscription } from "../../../../shared";
import { ImageSets } from "../../../../shared/utils";

@Component({
  selector: 'price-list-report-popup',
  templateUrl: './priceListReportPopupComponent.html'
})
export class PriceListReportPopupComponent extends AbstractPopupComponent {

  public imageSet: string;
  
  public reports: Immutable.List<PriceReportMessage>;

  public errorReportsConfIds: Immutable.List<number>;

  public activeReport: PriceReportMessage;
  public activeReportConfId: number;
  public rowViews: PriceReportRowView[] = [];
  
  public reportTitle: string;
  public reportCategoryName: string;  

  /** Stores the parent-child-childchild... stack.
      The active conf report is on top with its parent directly beneath it.
      |------------|
      | childchild |
      |   child    |
      |   parent   |
      |------------|
  */
  public stack: Immutable.Stack<ConfInfo> = Immutable.Stack<ConfInfo>();

  protected readonly CATEGORY_STYLE: string = "category align-middle";
  protected readonly CHILD_STYLE: string = "child clickable align-middle";
  protected readonly ITEM_TITLE_STYLE: string = "item align-middle";
  protected readonly ITEM_PRICE_STYLE: string = "item text-right pr-4 align-middle text-right w-100";
  protected readonly TOTAL_STYLE: string = "total align-middle";
  protected readonly GRAND_TOTAL_STYLE: string = "grand-total align-middle";
  protected priceSubscription: ManagedSubscription

  constructor(
    @Inject(PopupService) public popupService: PopupService,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfMessageProvider) public confMessageProvider: ConfMessageProvider,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr, popupService);
  }

  ngOnInit(): void {
    this.id = PopupIdentifiers.PriceList;
    this.imageSet = this.getImageSet(ImageSets.PopupHeader);

    // Price report message
    // TODO: We might need to remove same listener from PriceListDecorationComponent
    this.priceSubscription = this.confMessageProvider.onMessagesRequest<PriceReportMessage>(this.storeSession.confSessionId, PriceReportMessage.name, {
      next: (messages: Immutable.List<PriceReportMessage>): void => {

        this.show(messages);

      },
      listenNewEventsOnly: true
    });

    super.ngOnInit();
  }

  public updateBreadcrumb(confId: number): void {
    if (!confId)
      return;

    let conf: ConfInfo = this.confStore.getConfInfo(confId, this.storeSession.confSessionId);
    this.stack = this.stack.unshift(conf);
    this.updateBreadcrumb(conf.parentId);
  }

  hasReportError(conf: ConfInfo) {
    return this.errorReportsConfIds.contains(conf.longId);
  }

  public open(tag: any) {
    this.show(tag);
  }

  show(reports: Immutable.List<PriceReportMessage>): void {
    // If activeReportInfo is not set yet, check to see if there are any reports available.
    // If there aren't, then just return and don't show anything.
    if (!this.activeReportConfId) {
      if (reports.count() > 0)
        this.activeReportConfId = reports.first().configurationId;
      else
        return;
    }

    this.reports = reports;
    this.stack = Immutable.Stack<ConfInfo>();
    this.update(this.activeReportConfId);    
    this.showInternal();
  }

  update(confId: number): void {

    let report = this.reports.find(x => x.configurationId === confId);
    if (!report) {
      let requestView = this.storeSession.confUIStore.PageStore.getActiveClientType();
      if (requestView == RequestViews.Editor)
        this.confStore.getPriceReport(this.storeSession.confSessionId, confId, requestView, false);

      return;
    }

    this.activeReport = report;
    
    this.errorReportsConfIds = Immutable.List<number>(this.reports.filter(x => x.warnings.size > 0).map(x => x.configurationId));
    let confInfo: ConfInfo = this.confStore.getConfInfo(confId, this.storeSession.confSessionId);

    // Set titles    
    this.reportCategoryName = this.activeReport.priceListCategory.text;
    this.reportTitle = confInfo.text;

    // Remove the old contents
    this.rowViews = [];
    
    // Make breadcrumb only If composite configurations exist
    let rootConfInfo: ConfInfo = this.confStore.getConfInfo(confInfo.rootId, this.storeSession.confSessionId);
    if (rootConfInfo.children.size > 0) {
      this.stack = this.stack.clear();
      this.updateBreadcrumb(this.activeReport.configurationId);
    }
  }

  onReportClicked(confId: number) {

    if (!confId)
      return;

    this.activeReportConfId = confId;
    this.update(confId);

  }

  public onPopupClosed(): void {

    this.activeReportConfId = null;
    super.onPopupClosed();

  }

  ngOnDestroy() {

    if (this.priceSubscription)
      this.priceSubscription.unsubscribe();

    super.ngOnDestroy();

  }

}