import * as Immutable from "immutable";
import { AbstractAccountMessage } from "./abstractAccountMessage";

export class LoginMessage extends AbstractAccountMessage {

  protected readonly SUCCESS = "success";
  protected readonly ERRORS = "errors";
  protected readonly IS_PASSWORD_EXPIRED = "isPasswordExpired";
  protected readonly PASSWORD_EXPIRY_WARNING = "passwordExpiryWarning";
  protected readonly MESSAGE = "message";
  protected readonly SHOW_LOGIN_CONFIRMATION = "showLoginConfirmation";
  protected readonly LOGIN_CONFIRMATION_MESSAGE = "loginConfirmationMessage";

  get success(): boolean { return this.getInternalValue<boolean>(this.SUCCESS); }
  setSuccess(success: boolean): this { return this.setInternalValue(this.SUCCESS, success); }

  get isPasswordExpired(): boolean { return this.getInternalValue<boolean>(this.IS_PASSWORD_EXPIRED); }
  setIsPasswordExpired(isPasswordExpired: boolean): this { return this.setInternalValue(this.IS_PASSWORD_EXPIRED, isPasswordExpired); }

  get passwordExpiryWarning(): string { return this.getInternalValue<string>(this.PASSWORD_EXPIRY_WARNING); }
  setPasswordExpiryWarning(passwordExpiryWarning: string): this { return this.setInternalValue(this.PASSWORD_EXPIRY_WARNING, passwordExpiryWarning); }

  get errors(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.ERRORS); }
  setErrors(errors: Immutable.List<string>): this { return this.setInternalValue(this.ERRORS, errors); }

  get message(): string { return this.getInternalValue<string>(this.MESSAGE); }
  setMessage(message: string): this { return this.setInternalValue(this.MESSAGE, message); }

  get showLoginConfirmation(): boolean { return this.getInternalValue<boolean>(this.SHOW_LOGIN_CONFIRMATION); }
  setShowLoginConfirmation(showLoginConfirmation: boolean): this { return this.setInternalValue(this.SHOW_LOGIN_CONFIRMATION, showLoginConfirmation); }

  get loginConfirmationMessage(): string { return this.getInternalValue<string>(this.LOGIN_CONFIRMATION_MESSAGE); }
  setLoginConfirmationMessage(loginConfirmationMessage: string): this { return this.setInternalValue(this.LOGIN_CONFIRMATION_MESSAGE, loginConfirmationMessage); }
}