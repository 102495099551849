import { Injectable } from "@angular/core";
import { schema } from "normalizr";

export class UIElementSchema {

  public uiPopupUIGroupSchema: schema.Entity = new schema.Entity('UIGroup', {}, { idAttribute: 'renderId' });
  public uiPopupUIInputSchema: schema.Entity = new schema.Entity('UIInput', {}, { idAttribute: 'renderId' });
  public uiPopupUIImageSchema: schema.Entity = new schema.Entity('UIImage', {}, { idAttribute: 'renderId' });
  public uiPopupUILabelSchema: schema.Entity = new schema.Entity('UILabel', {}, { idAttribute: 'renderId' });
  public uiPopupUILinkSchema: schema.Entity = new schema.Entity('UILink', {}, { idAttribute: 'renderId' });
  public uiPopupUISelectSchema: schema.Entity = new schema.Entity('UISelect', {}, { idAttribute: 'renderId' });
  public uiPopupUITextSchema: schema.Entity = new schema.Entity('UIText', {}, { idAttribute: 'renderId' });

  public uiElementSchemaType;

  constructor() {
    this.setupSchema();
  }

  public setupSchema(): void {

    this.uiElementSchemaType = this.GetElementSchemaType();

    this.uiPopupUIGroupSchema.define({
      uiElements: new schema.Array(this.uiElementSchemaType, 'className')
    });

  }

  public GetElementSchemaType() {
    return {
      UIGroup: this.uiPopupUIGroupSchema,
      UIImage: this.uiPopupUIImageSchema,
      UIInput: this.uiPopupUIInputSchema,
      UILabel: this.uiPopupUILabelSchema,
      UILink: this.uiPopupUILinkSchema,
      UISelect: this.uiPopupUISelectSchema,
      UIText: this.uiPopupUITextSchema
    };
  }

}