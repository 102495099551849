import { Injectable } from '@angular/core';
import { HttpService } from '../../../shared/providers';
import { BaseRequest, ApiResponse } from '../models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponseBuilder } from './apiResponseBuilder';

export abstract class BaseController {

  constructor(public httpService: HttpService, public apiResponseBuilder: ApiResponseBuilder) {

  }

  public buildResponseObject = map((response, index) => this.apiResponseBuilder.build(response));

  public getGenericDataUrl<REQ>(model: REQ) {
    return this.httpService.createUrl(this.getDefaultRequestUrl(), model);
  }

  public getDefaultData<REQ>(requestModel: REQ): Observable<any> {
    return this.httpService.get(this.getGenericDataUrl(requestModel), requestModel).pipe(this.buildResponseObject);
  }

  getDefaultRequestUrl(): string {
    return this.getControllerUrl() + '/request';
  }

  public abstract getControllerUrl(): string;
}