<div class="row mr-0 select-container {{view.helpImagePosition}}" >

  <dropdown class="col-auto pr-0 w-100" *ngIf="!displayAsRadio"
            [itemsView]="itemsView"
            [ngStyle]="controlStyle"
            [view]="view"
            [showEmptyValue]="true"
            (onValueChange)="onValueChanged($event)"
            (onReadOnly)="onReadOnly.emit($event)"
            (onDisabled)="onDisabled.emit($event)"
            (onHelpClick)="onHelpClick.emit($event)"
            [selectedViewId]="selectedValueId">
    
    <!-- Above of the dropdown -->
    <selected-help-image above-container *ngIf="view && view.helpImagePosition == 'above'" 
                          [ngStyle]="helpImageStyle" 
                          [groupModel]="view" 
                          [valueModel]="selectedValueModel"
                          class="{{view.helpImagePosition}} {{helpImageExtraClasses}}"
                          >
    </selected-help-image>

  </dropdown>

  <radio-group class="col-auto px-0 w-100" *ngIf="displayAsRadio"
               #radioGroup
               [view]="view"
               [itemsView]="itemsView"
               [ngStyle]="controlStyle"
               [displayStyle]="displayStyle"
               [orientation]="orientation"
               [selectedViewId]="selectedValueId"
               (onValueChange)="onValueChanged($event)"
               (onReadOnly)="onReadOnly.emit($event)"
               (onDisabled)="onDisabled.emit($event)"
               (onHelpClick)="onHelpClick.emit($event)">

    <!-- Above of the radio button values -->
    <selected-help-image above-container *ngIf="view && view.helpImagePosition == 'above'"
                         [ngStyle]="helpImageStyle"
                         [groupModel]="view"
                         [valueModel]="selectedValueModel"
                         class="{{view.helpImagePosition}} {{helpImageExtraClasses}}">
    </selected-help-image>

    <!-- Inline left && right -->
    <selected-help-image inline-container *ngIf="view && view.helpImagePosition == 'inline-left' || view.helpImagePosition == 'inline-right'"
                         [ngStyle]="helpImageStyle"
                         [groupModel]="view"
                         [valueModel]="selectedValueModel"
                         class="col {{view.helpImagePosition}} {{helpImageExtraClasses}}">
    </selected-help-image>

  </radio-group>

  <!-- Right of the radio button values -->
  <selected-help-image *ngIf="view && (view.helpImagePosition == 'right' || view.helpImagePosition == 'below')" 
                        [groupModel]="view" 
                        [valueModel]="selectedValueModel" 
                        class="help-image-inline-col {{view.helpImagePosition}} {{helpImageExtraClasses}}"
                        ></selected-help-image>
</div>