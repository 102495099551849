<div class="d-flex dropdown" [class.show]="show" [style.width]="'100%'" (click)="openMenu($event)">

  <div class="placeholder ellipsis col px-0">
    <div class="selected-values">
      <!--<carousel [items]="selectedItems"></carousel>-->
      <drag-scroll scrollbar-hidden="false" #dragScroll>
        <div class="inner-contents">
          <div *ngFor="let item of selectedItems" drag-scroll-item class="swiper-slide justify-content-center align-middle" role="button">

            <co-image [width]="'12px'" [height]="'12px'" imageSet="{{imageSet}}" type="svg" [key]="item.icon"></co-image>
            <!-- Slide contents -->
            {{item.title}}
            <co-image [width]="'12px'" [height]="'12px'" imageSet="red" (click)="removeItem($event, item)" role="button" type="svg" key="delete"></co-image>
          </div>

          <ng-container *ngIf="selectedItems.size == 0">
            <span>{{emptyTitle}}</span>
          </ng-container>
        </div>

      </drag-scroll>
    </div>
  </div>

  <button class="btn btn-secondary dropdown-toggle col-auto dropdown-toggle-split" [attr.aria-expanded]="show" data-toggle="dropdown" type="button" aria-haspopup="true" aria-expanded="false">
    <span class="sr-only">Toggle Dropdown</span>
  </button>

</div>