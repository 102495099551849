import { ConfRouteParams } from "../../pages/configurator/providers/confRouteParams";
import { ActivatedRouteSnapshot } from "@angular/router";

export function setRouteConfParamValue(params: ConfRouteParams, propertyName: string, value: string) {

  let handled: boolean = false;
  ConfRouteParams.ALL_PROPERTIES.forEach(key => {

    if (key.toLowerCase() == propertyName.toLowerCase()) {
      {
        params[key] = value;
        handled = true;
        return;
      }
    }
  });

  if (!handled) {
    params[propertyName] = value;
  }

}

export function extractRouteParams(destination: ConfRouteParams, route: ActivatedRouteSnapshot) {

  const confParamsName = ConfRouteParams.CONF_PARAMS.toLowerCase();
  const extraArgsName = ConfRouteParams.EXTRA_ARGS.toLowerCase();
  let params = route.params;
  Object.keys(params).forEach(key => {

    // Ignore confParams and extraArgs as they are handled outside the loop
    if (key == confParamsName || key == extraArgsName)
      return;

    setRouteConfParamValue(destination, key, params[key]);
  });

  if (route.paramMap.has(confParamsName) && route.paramMap.get(confParamsName).length > 0)
    destination.confParams = JSON.parse(route.paramMap.get(confParamsName));

  if (route.paramMap.has(extraArgsName) && route.paramMap.get(extraArgsName).length > 0)
    destination.extraArgs = JSON.parse(route.paramMap.get(extraArgsName));

}

export function searchUrlQueryParam(name: string, url: string = window.location.href) {

  url = url.replace(/#+/g, "");
  const firstIndexSemi = url.indexOf(";");
  url = url.replace(/;+/g, "&");
  const firstIndexAnd = url.indexOf("&");
  if (firstIndexSemi === firstIndexAnd)
    url = url.replace("&", "?");

  const parsedUrl = new URL(url);
  const searchParams = new URLSearchParams(parsedUrl.search);
  const lowerCaseKeyToMatch = name.toLowerCase();
  let result: string | null = null;

  searchParams.forEach((value, key) => {
    if (key.toLowerCase() === lowerCaseKeyToMatch) {
      result = value;
    }
  });

  return result;
}

