import { Injectable, Inject } from '@angular/core';
import * as Immutable from 'immutable';
import { AppStore, HttpAction, StoreResponse, SearchSessionData, SearchDataState } from "../../state"
import { SearchDataResponse, QuerySourceTypes } from "../../models/responses"
import { AppStoreSubscriptionManager } from "../appStoreSubscriptionManager";
import { ManagedSubject } from "../../../../shared/managedSubject";
import { BaseStore } from "../../state/baseStore";
import { SearchDataRequest } from "../../models/requests/searchDataRequest";
import { ModelFactory } from "../modelFactory";
import {
  RequestViews, PaginationArgument, ConfResponseFormat, SortingArgument, SearchCommand, ConfigurationSearch,
  ConfSearchProperty, ConfSearchCriteria, SavedSearchCommand, ApiResponse, KeyValue
} from "../../models";
import { SearchDataActionCreator } from "./searchDataActionCreator";
import { ConfiguratorStore } from "../../../configurator/providers";
import { GlobalDataStore } from "../globalData";
import { ManagedSubscription, SubscriptionOptions } from "../../../../shared/managedSubscription";
import { ReselectorService } from "../reselectorService";

@Injectable()
export class SearchDataStore extends BaseStore {

  constructor( @Inject(AppStore) public appStore: AppStore,
    @Inject(SearchDataActionCreator) public searchActionCreator: SearchDataActionCreator,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(ReselectorService) public reselectorService: ReselectorService,
    public globalDataStore: GlobalDataStore,
    public appStoreSubscriptionManager: AppStoreSubscriptionManager,
  ) {
    super(appStore, appStoreSubscriptionManager, modelFactory, searchActionCreator);
  }

  public searchSessionMemorizer(searchSessionId: number): () => SearchSessionData {
    return this.reselectorService.createMemorizer((state) => this.getSearchDataSession(searchSessionId));
  }

  public listenSearchSessionDataChange(searchSessionId: number, callBackOptions: SubscriptionOptions<SearchSessionData>): ManagedSubscription {
    return this.createStoreSubscription<SearchSessionData>("__searchSessionId_" + searchSessionId.toString(), this.searchSessionMemorizer(searchSessionId), callBackOptions);
  }

  public savedSearchesMemorizer(): () => Immutable.List<ConfigurationSearch> {
    return this.reselectorService.createMemorizer((state) => this.getSearchData().savedSearches);
  }

  public listenSavedSearchesChange(callBackOptions: SubscriptionOptions<Immutable.List<ConfigurationSearch>>): ManagedSubscription {
    return this.createStoreSubscription<Immutable.List<ConfigurationSearch>>("__savedSearches", this.savedSearchesMemorizer(), callBackOptions);
  }  

  public createStoreSubscription<T>(identifier: string, memorizer: () => T, subscriptionOptions: SubscriptionOptions<T>): ManagedSubscription {
    let managedSubject: ManagedSubject<T> = this.appStoreSubscriptionManager.getOrCreateStoreSubject(identifier, memorizer, false, 1, false);
    return managedSubject.subscribe(subscriptionOptions);
  }

  public createSearchRequest(client: RequestViews, searchSessionId?: number): SearchDataRequest {
    let result: SearchDataRequest = this.modelFactory.createRequestOrCommand<SearchDataRequest>(SearchDataRequest.name);
    result.client = client;
    result.search = this.modelFactory.createRequestOrCommand<SearchCommand>(SearchCommand.name);
    result.search.responseFormat = this.globalDataStore.getConfResponseFormat(client, true);

    if (searchSessionId)
      result.search.searchSessionId = searchSessionId;

    return result;
  }

  public createSavedSearchRequest(client: RequestViews): SearchDataRequest {
    let result: SearchDataRequest = this.modelFactory.createRequestOrCommand<SearchDataRequest>(SearchDataRequest.name);
    result.client = client;
    result.savedSearch = this.modelFactory.createRequestOrCommand<SavedSearchCommand>(SavedSearchCommand.name);

    return result;
  }

  public getSearchData(): SearchDataState {
    return this.appStore.getState().searchData;
  }

  public getConfigurationSearch(longId: number) {
    if (this.getSearchData().savedSearches) {
      return this.getSearchData().savedSearches.find(x => x.longId == longId);
    }

    return null;
  }

  public getSearchDataSession(searchSessionId: number) {
    if (!this.appStore.getState().searchData.dataBySessionId.has(searchSessionId))
      return null;

    return this.appStore.getState().searchData.dataBySessionId.get(searchSessionId);
  }

  public getUnsavedUserConf(userId: number, client: RequestViews): ManagedSubject<StoreResponse<SearchSessionData>> {
    let model: SearchDataRequest = this.createSearchRequest(client, null);

    model.search.pagination = this.modelFactory.createAny<PaginationArgument>(PaginationArgument.name, { pageIndex: 1 });
    model.search.querySourceType = QuerySourceTypes.Unsaved;

    let action: HttpAction<ApiResponse> = this.searchActionCreator.dispatchGetUnsavedUserConf(model);

    // Create request object
    let response: StoreResponse<SearchSessionData> = new StoreResponse<SearchSessionData>();

    return this.createAction(action, actionInfo => {
      let searchDataResponse = actionInfo.payload.data as SearchDataResponse;
      response.data = this.getSearchDataSession(searchDataResponse.searchResult.searchSessionId);
      return response;
    });
  }

  public getRecentUserConf(userId: number, client: RequestViews): ManagedSubject<StoreResponse<SearchSessionData>> {

    let model: SearchDataRequest = this.createSearchRequest(client, null);
    let confSearch = this.modelFactory.createAny<ConfigurationSearch>(ConfigurationSearch.name);
    let searchCriterias: ConfSearchCriteria[] = [];
    searchCriterias.push(this.modelFactory.createAny<ConfSearchProperty>(ConfSearchProperty.name, { name: "CreatedById", value: userId.toString() }));
    searchCriterias.push(this.modelFactory.createAny<ConfSearchProperty>(ConfSearchProperty.name, { name: "ParentId", value: "null" }));
    confSearch = confSearch.setSearchCriterias(Immutable.List<ConfSearchCriteria>(searchCriterias));

    model.search.query = confSearch;
    model.search.pagination = this.modelFactory.createAny<PaginationArgument>(PaginationArgument.name, { pageIndex: 1 });

    let action: HttpAction<ApiResponse> = this.searchActionCreator.dispatchGetRecentUserConf(model);

    // Create request object
    let response: StoreResponse<SearchSessionData> = new StoreResponse<SearchSessionData>();

    return this.createAction(action, actionInfo => {
      let searchDataResponse = actionInfo.payload.data as SearchDataResponse;
      response.data = this.getSearchDataSession(searchDataResponse.searchResult.searchSessionId);
      return response;
    });
  }

  public getRecentWorkGroupConf(workgroupId: number, client: RequestViews): ManagedSubject<StoreResponse<SearchSessionData>> {

    let model: SearchDataRequest = this.createSearchRequest(client, null);
    let confSearch = this.modelFactory.createAny<ConfigurationSearch>(ConfigurationSearch.name);
    let searchCriterias: ConfSearchCriteria[] = [];
    searchCriterias.push(this.modelFactory.createAny<ConfSearchProperty>(ConfSearchProperty.name, { name: "OwnedById", value: workgroupId.toString() }));
    searchCriterias.push(this.modelFactory.createAny<ConfSearchProperty>(ConfSearchProperty.name, { name: "ParentId", value: "null" }));
    confSearch = confSearch.setSearchCriterias(Immutable.List<ConfSearchCriteria>(searchCriterias));

    model.search.query = confSearch;
    model.search.pagination = this.modelFactory.createAny<PaginationArgument>(PaginationArgument.name, { pageIndex: 1 });

    let action: HttpAction<ApiResponse> = this.searchActionCreator.dispatchGetRecentWorkGroupConf(model);

    // Create request object
    let response: StoreResponse<SearchSessionData> = new StoreResponse<SearchSessionData>();

    return this.createAction(action, actionInfo => {

      let searchDataResponse = actionInfo.payload.data as SearchDataResponse;
      response.data = this.getSearchDataSession(searchDataResponse.searchResult.searchSessionId);
      return response;
    });
  }

  public queryConfs(configurationSearch: ConfigurationSearch, client: RequestViews, pageSize?: number, pageIndex?: number, sorting?: SortingArgument, searchSessionId?: number): ManagedSubject<StoreResponse<SearchSessionData>> {
    let model: SearchDataRequest = this.createSearchRequest(client, searchSessionId);

    model.search.query = configurationSearch;

    // Encode the query title to keep spaces.
    if (model.search.query && model.search.query.title)
      model.search.query = model.search.query.setTitle(model.search.query.title);

    model.search.pagination = pageSize != null ? this.modelFactory.createAny(PaginationArgument.name, { pageSize: pageSize, pageIndex: pageIndex || 1  }) : null;
    model.search.sorting = sorting;

    let action: HttpAction<ApiResponse> = this.searchActionCreator.dispatchSearchConfigurations(model);

    // Create request object
    let response: StoreResponse<SearchSessionData> = new StoreResponse<SearchSessionData>();

    return this.createAction(action, actionInfo => {

      let searchDataResponse = actionInfo.payload.data as SearchDataResponse;
      response.data = searchDataResponse && this.getSearchDataSession(searchDataResponse.searchResult.searchSessionId);
      return response;
    });
  }

  public saveConfigurationSearch(configurationSearch: ConfigurationSearch, client: RequestViews): ManagedSubject<StoreResponse<SearchSessionData>> {
    let model: SearchDataRequest = this.createSavedSearchRequest(client);

    model.savedSearch.save = configurationSearch;
    model.savedSearch.getAll = true;

    let action = this.searchActionCreator.dispatchSaveConfigurationSearch(model);

    // Create request object
    let response: StoreResponse<SearchSessionData> = new StoreResponse<SearchSessionData>();

    return this.createAction(action, actionInfo => {

      let searchDataResponse = actionInfo.payload.data as SearchDataResponse;    
      response.data = this.getSearchDataSession(searchDataResponse.searchResult.searchSessionId);
      return response;
    });
  }

  public getSavedConfigurationSearches(client: RequestViews): ManagedSubject<StoreResponse<Immutable.List<ConfigurationSearch>>> {
    let model: SearchDataRequest = this.createSavedSearchRequest(client);
    model.savedSearch.getAll = true;

    let action = this.searchActionCreator.dispatchFetchSavedConfigurationSearches(model);
    
    // Create request object
    let response: StoreResponse<Immutable.List<ConfigurationSearch>> = new StoreResponse<Immutable.List<ConfigurationSearch>>();

    return this.createAction(action, actionInfo => {
      response.data = this.getSearchData().savedSearches;
      return response;
    });
  }

  public deleteConfigurationSearch(client: RequestViews, configurationSearchId: number): ManagedSubject<StoreResponse<Immutable.List<ConfigurationSearch>>> {
    let model: SearchDataRequest = this.createSavedSearchRequest(client);
    model.savedSearch.delete = configurationSearchId;
    model.savedSearch.getAll = true; // Also get the updated results.

    let action = this.searchActionCreator.dispatchDeleteConfigurationSearch(model);

    // Create request object
    let response: StoreResponse<Immutable.List<ConfigurationSearch>> = new StoreResponse<Immutable.List<ConfigurationSearch>>();

    return this.createAction(action, actionInfo => {
      response.data = this.getSearchData().savedSearches;
      return response;
    });
  }    
}