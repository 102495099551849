import { Inject, Component, Input, ViewChild } from "@angular/core";
import * as Immutable from "immutable";
import { BaseComponent } from "../../shared";
import { ConfiguratorStore } from "../../configurator/providers";
import { SystemInformation } from "../../shared/models";
import { PopupService, MessageBoxConfig, MessageBoxComponent } from "../../../shared/components";
import { ImageSets } from "../../../shared/utils";

@Component({
  selector: 'system-information',
  templateUrl: './systemInformationComponent.html'
})
export class SystemInformationComponent extends BaseComponent {

  @Input()
  systemInformations: Immutable.List<SystemInformation>
  
  @ViewChild(MessageBoxComponent)
  protected systemInformationPopup: MessageBoxComponent;

  public imageSet: string;

  constructor(public popupService: PopupService) {
    super();
  }

  ngOnInit(): void {

    this.imageSet = this.getImageSet(ImageSets.StartConfiguration);
    super.ngOnInit();

  }

  showSystemInfoDetail(info: SystemInformation) {

    let messageConfig: MessageBoxConfig<SystemInformation> = <MessageBoxConfig<SystemInformation>>{
      headerText: info.title,
      caption: '<span class="published pr-2">' + this.strings.PublishedDate + ':</span>' + info.publishedDate,
      description: info.message,
      icon: "bell",
      tag: info
    }

    this.systemInformationPopup.show(messageConfig);
  }

}
