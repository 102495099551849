import { BaseEntity } from "../../baseEntity";

export class UIAction extends BaseEntity {
  protected readonly ID = "id";
  protected readonly RENDER_ID = "renderId";

  get id(): string { return this.getInternalValue<string>(this.ID); }
  setId(id: string): this { return this.setInternalValue(this.ID, id); }

  get renderId(): number { return this.getInternalValue<number>(this.RENDER_ID); }
  setRenderId(renderId: number): this { return this.setInternalValue(this.RENDER_ID, renderId); }
}