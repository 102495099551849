import { Component, ViewChild, ElementRef, ChangeDetectorRef, Input, Host, Inject, AfterContentInit, AfterViewInit, forwardRef, ChangeDetectionStrategy, SimpleChanges } from "@angular/core";
import { RadioButtonComponent } from "../radioButtonComponent";
import { BaseRadioStrategyComponent } from "./baseRadioStrategyComponent";
import { InputViewModel } from "../../shared";
import { ImageSets } from "../../../utils";

@Component({
  selector: 'standard-radio',
  templateUrl: './standardRadioStrategyComponent.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class StandardRadioStrategyComponent extends BaseRadioStrategyComponent {

  constructor(
    @Host() @Inject(forwardRef(() => RadioButtonComponent)) public button: RadioButtonComponent,
    public cdr: ChangeDetectorRef
  ) {
    super(button, cdr);
  }

  ngOnInit() {
    this.imageSet = this.getImageSet(ImageSets.ControlLock);
    super.ngOnInit();
  }

  public styles: string;

  @ViewChild('radioButton')
  inputControlRef: ElementRef;

  updateStyle() {
    if (this.inputControlRef != null && this.selected) {
      this.inputControlRef.nativeElement.checked = true;
    }

    // Disabled style is set at item level and readOnly at group level because of their scopes
    //this.disabledStyle = this.button.disabled ? "disabled" : "";
    this.updateRadioStyles();

    this.cdr.markForCheck();
  }

  public updateRadioStyles(): void {

    this.styles = 'option-input';

    if (this.selected)
    {
      this.styles += ' checked';
    }

    if (this.selected && this.button.browserInfo.isIE)
    {
      this.styles += '-ie';
    }    
    
  }

  protected setSelected(newValue?: any): void {

    super.setSelected(newValue);
    this.updateRadioStyles();

  }

}