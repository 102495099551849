<backdrop-container>
  <regular-popup *ngIf="visible"
                 [id]="id"
                 [title]="title"
                 [showFooter]="true"
                 [closeOnOk]="false"
                 [okButtonText]="strings.Ok"
                 [cancelButtonText]="strings.Cancel"
                 (okClick)="okClick()"
                 (onClosed)="onPopupClosed()"
                 width="75%">
    <div card-type="body" class="p-4 container-fluid">
      <div *ngFor="let viewList of viewsSorted" class="row">
        <ng-container *ngFor="let view of viewList">
          <div [ngSwitch]="view.tag.dataType" [class]="viewList.length > 1 ? 'col-md-6 pb-3' : 'col pb-3'">
            <text-box *ngSwitchCase="'String'"
                      [view]="view"
                      [errors]="errorsByView.get(view.id)">
            </text-box>
            <datepicker *ngSwitchCase="'Date'"
                        [view]="view"
                        [weekNumbersEnabled]="weekNumbersEnabled"
                        [dateFormat]="dateFormat"
                        [errors]="errorsByView.get(view.id)">
            </datepicker>
            <text-box *ngSwitchCase="'Multiline'" class="pt-3"
                      rows="3"
                      [view]="view"
                      [errors]="errorsByView.get(view.id)">
            </text-box>
            <text-box *ngSwitchCase="'Integer'"
                      [view]="view"
                      type="number"
                      integer="true"
                      [errors]="errorsByView.get(view.id)">
            </text-box>
            <text-box *ngSwitchCase="'Email'"
                      [view]="view"
                      type="email"
                      [errors]="errorsByView.get(view.id)">
            </text-box>
            <check-box *ngSwitchCase="'Boolean'"
                       [view]="view"
                       [errors]="errorsByView.get(view.id)">
            </check-box>
          </div>
        </ng-container>
      </div>
    </div>
  </regular-popup>
</backdrop-container>
