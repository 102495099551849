<action-box *ngIf="visible" textLeft="{{strings.Operations}}" [showHeader]="showHeader">
  <ng-container *ngFor="let item of items">
    <div class="menu-item justify-content-between {{item.name}} {{item.styles}}" title="{{item.text}}">

      <div class="row w-100 m-0 operation-item" (click)="onItemClick(item.name)" role="button">
        <div class="col-auto py-2 pr-0">
          <co-image width="24" height="24" type="svg" key="{{item.icon ? item.icon : item.name}}" imageSet="{{imageSet}}"></co-image>
        </div>
        <div class="col text-uppercase py-2 label">
          {{item.text}}
        </div>
      </div>
    </div>
  </ng-container>
</action-box>
<message-box (okClick)="deleteConf($event)" [title]="strings.Confirm" [okButtonText]="strings.Delete"></message-box>
<change-ownership-popup></change-ownership-popup>
<summary-print></summary-print>