import { Component, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotificationInfo, NotificationService, NotificationType } from "../../../shared/components";
import { GlobalServiceLocator } from "../../../shared/providers";
import { BaseComponent } from "../../shared";
import { AccountDataMessage, LoginMessage } from "../../shared/models";
import { ApplicationLifeCycleService, RouteRedirector } from "../../shared/providers";
import { AccountDataMessageProvider, AccountDataStore } from "../../shared/providers/accountData";
import { AccountUrlParams } from "./accountUrlParams";

@Component({ template: '' })
export abstract class BaseAccountMessageComponent extends BaseComponent {

  user: string;
  sessionKey: string;
  message: string;

  constructor(    
    public accountStore: AccountDataStore,
    public accountMessageProvider: AccountDataMessageProvider,    
    public redirector: RouteRedirector,
    public activatedRoute: ActivatedRoute,
    public cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {

    // Note! In password change case, user would be set, but In sign up case, It would be null until profile creation.
    this.user = this.activatedRoute.snapshot.paramMap.get(AccountUrlParams.USER);
    this.sessionKey = this.activatedRoute.snapshot.paramMap.get(AccountUrlParams.KEY);

    // Listen for login message
    this.accountMessageProvider.onMessages<AccountDataMessage>(AccountDataMessage.name, {
      next: (messages) => {

        this.unblockUI();

        let accountDataMessage = messages.get(0);
        
        // If the request is invalid then abort it.
        //if (passwordRecoveryMessage.abort) this.onAbort(passwordRecoveryMessage);

        // If succeed
        if (accountDataMessage.success)        
          this.onSuccess(accountDataMessage);
        

        // If error occured.
        else this.onError(accountDataMessage);

      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);


    // Listen for login message    
    let appLifeCycleService: ApplicationLifeCycleService = GlobalServiceLocator.injector.get(ApplicationLifeCycleService);
    this.accountMessageProvider.onMessages<LoginMessage>(LoginMessage.name, {
      next: (messages) => {

        this.unblockUI();

        let loginMessage = messages.get(0);
        if (loginMessage.success) {
          appLifeCycleService.reloadAppData(() => {

            this.accountStore.startUserSession();
            this.redirector.redirectToStart();

          });
        }
        else {
          this.onLoginFailed(loginMessage);
        }
      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);
    
  }

  onSuccess(message: AccountDataMessage): void {

  }

  onLoginFailed(message: LoginMessage): void {

    // Failed
    let notificationService: NotificationService = GlobalServiceLocator.injector.get(NotificationService);
    notificationService.notify(<NotificationInfo>{
      title: this.strings.Error,
      message: message.errors.size > 0 ? message.errors.get(0) : this.strings.SystemCouldNotLogYouOnMessage,
      type: NotificationType.Error,
      selfClose: false
    });

    this.redirector.redirectToLogin();

  }

  onError(messageDto: AccountDataMessage): void  {
    this.message = messageDto.message
    this.cd.detectChanges();
  }

  public createParamUrls(messageDto: AccountDataMessage): AccountUrlParams {

    let params = new AccountUrlParams();
    params.key = this.sessionKey;

    if (messageDto.username)
      params.user = messageDto.username;    

    return params;

  }
}