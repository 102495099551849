import { Component, ChangeDetectorRef, Input, OnInit, SimpleChanges } from "@angular/core";
import { InputViewModel } from "../../shared";
import { RadioButtonComponent } from '../radioButtonComponent';
import { BaseComponent } from "../../../../pages/shared/baseComponent";

@Component({ template: '' })
export abstract class BaseRadioStrategyComponent extends BaseComponent {

  public orientation: string = 'vertical';
  public groupView: InputViewModel;
  public imagePosition: string;
  public selected: boolean = false;
  public imageSet: string;

  @Input()
  public isDisabled: boolean = false;

  @Input()
  public isReadOnly: boolean = false;

  @Input()
  public itemView: InputViewModel;

  @Input()
  public selectedViewId: string;

  constructor(public button: RadioButtonComponent, public cdr: ChangeDetectorRef) {
    super();
    button.template = this;
  }

  public onClick($event): void {

    // Set the new value to button's view
    let newValue = this.button.view.value == null ? this.button.view.id : null;

    // If an existing selection is changed this prevents null value.
    if (this.button.view.value != null && this.selectedViewId != this.button.view.value)
      newValue = this.button.view.value;

    if (this.isValid(newValue))
      this.setSelected(newValue);
    
    this.button.radioGroup.processEvent(newValue, this.button.view)    

  }

  ngOnInit(): void {
    this.itemView = this.button.view;
    this.groupView = this.button.radioGroup.view;
    this.orientation = this.button.radioGroup.orientation;

    // TODO: Temporary code for demo
    // In vertical orientation, always set the imagePosition to left.    
    this.imagePosition = this.orientation == 'vertical' ? 'left' : 'top';

    this.setSelected(this.selectedViewId);
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['selectedViewId'] != null && !changes['selectedViewId'].firstChange) {
      this.setSelected(this.selectedViewId);
    }
  }

  protected setSelected(newValue?: any): void {   

    this.selectedViewId = newValue;
    this.selected = this.button.view.id == this.selectedViewId;

  }

  protected isValid(newValue: any): boolean {

    return !this.button.radioGroup.isReadOnly(newValue) && !this.button.radioGroup.isDisabled(newValue);

  }

  public updateStyle(): void { }

}