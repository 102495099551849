import { Inject, Component, Optional, Input } from "@angular/core";
import { BaseActionBoxComponent } from "../..";
import { ConfiguratorStore } from "../../../configurator/providers";
import { ProductDataStore } from "../../providers/productData";
import { CodeDecoration, FormDecoration, RequestViews } from "../../models";


@Component({
  selector: 'form-action-box',
  templateUrl: './formActionBoxComponent.html'
})
export class FormActionBoxComponent extends BaseActionBoxComponent {

  @Input()
  formDecoration: FormDecoration;

  actionBoxTitle: string;

  RequestViews = RequestViews;

  constructor(
    @Optional() @Inject(ConfiguratorStore) configuratorStore: ConfiguratorStore,
    @Inject(ProductDataStore) private productStore: ProductDataStore) {
    super(configuratorStore);
  }

  public setup(): void {

    let decoration: FormDecoration = this.productStore.getEntity<FormDecoration>(this.formDecoration.longId);
    this.actionBoxTitle = decoration.title ? decoration.title : this.strings.FormDecoration;
    
  }

  // TODO: Need to remove this
  public shouldBeVisible(): boolean {
    return true;
  }

}