import { Inject, Component, ChangeDetectorRef, Input, ComponentFactoryResolver, ViewChild, ChangeDetectionStrategy } from "@angular/core";
import { Unsubscribe } from 'redux';
import { LookupParam, ParamValue, ConfLookupValue, ConfUIItem } from "../../../shared/models";
import { BaseEntity } from "../../../shared/baseEntity";
import { DisplayStyle, Orientation, InputViewModel, ValueChangeEventArgs, RestrictValueChangeEventArgs, RadioGroupComponent, PopupService, Positions } from "../../../../shared/components";
import { VisualObjectViewModelFactory, VisualObjectHelper } from "../shared";
import { ParamComponent } from "../../shared/paramComponent";
import { ProductDataStore } from "../../../shared/providers/productData";
import { ConfiguratorStore } from "../../providers/configuratorStore";
import { ConfigurationSessionManager } from "../../providers/configurationSessionManager";
import { ParameterMandatoryService } from "../shared/parameterMandatoryService";
import { ConfPageSessionService, ConfMessageProvider, ConfiguratorUIStore } from "../../providers";
import { VisualObjectVisibilityService } from "../../shared";
import * as Immutable from 'immutable';

@Component({
  selector: 'lookup-param',
  templateUrl: './lookupParamComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LookupParamComponent extends ParamComponent<LookupParam> {

  paramValues: ParamValue[] = [];
  confLookupValue: ConfLookupValue;
  unsubscribeListener: Unsubscribe;

  itemsView: InputViewModel[] = [];

  displayStyle: any = DisplayStyle.Standard;
  orientation: any = Orientation.Vertical;

  // This property is added temporary until we introduce immutable views.
  selectedViewId: string;

  @ViewChild('radioGroup')
  private radioGroup: RadioGroupComponent;

  constructor(
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(ComponentFactoryResolver) public resolver: ComponentFactoryResolver,
    @Inject(ChangeDetectorRef) public cdr: ChangeDetectorRef,
    @Inject(VisualObjectViewModelFactory) public viewFactory: VisualObjectViewModelFactory,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(ParameterMandatoryService) public parameterMandatoryService: ParameterMandatoryService,
    @Inject(ConfMessageProvider) public messagesProvider: ConfMessageProvider,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    @Inject(VisualObjectVisibilityService) public visualObjectVisibilityService: VisualObjectVisibilityService,
  ) {
    super(confStore, confPageSessionService, popupService, visualObjectHelper, parameterMandatoryService, messagesProvider, cdr);
  }

  ngOnInit() {
    // Set orientation
    this.orientation = (this.parameter as LookupParam).orientation.toLowerCase().trim();

    // Create views
    this.createViewModels();    

    this.confStore.onLookupConfValueVisibilityChange(this.configurationId, this.confPageSessionService.confSessionId, this.parameter.longId, (uiItems: string): void => {

      let confLookupValue = this.confStore.getConfValue(this.configurationId, this.confPageSessionService.confSessionId, this.paramId);
      this.setValue(confLookupValue);

    }).unsubscribeOn(this.unsubscribeSubject);

    super.ngOnInit();
  }

  createViewModels(): void {
    let displayHelp = false;
    let lookupParam: LookupParam = this.parameter as LookupParam;

    // Create view for children
    this.parameter.paramValues.forEach(x => {
      let paramValue: ParamValue = this.getParamValue(x);
      if (paramValue.obsolete)
        return;

      let itemView = this.viewFactory.create(paramValue);

      // Set the param-value icons width/height 
      let hasIconWidth: boolean = lookupParam.paramValueIconWidth != null && lookupParam.paramValueIconWidth > 0;
      let hasIconHeight: boolean = lookupParam.paramValueIconHeight != null && lookupParam.paramValueIconHeight > 0;
      let isWidthOrHeightExists = hasIconWidth || hasIconHeight;

      let defaultIconWidth = isWidthOrHeightExists ? null : this.uiSettings.configurator.parameter.lookupValue.width;
      let defaultIconHeight = isWidthOrHeightExists ? null : this.uiSettings.configurator.parameter.lookupValue.height;

      itemView.iconWidth = hasIconWidth ? `${lookupParam.paramValueIconWidth}px` : defaultIconWidth;
      itemView.iconHeight = hasIconHeight ? `${lookupParam.paramValueIconHeight}px` : defaultIconHeight;
      itemView.hideIfDisabled = paramValue.hideIfDisabled || lookupParam.hideValuesIfDisabled;

      // Don't use help for items, parent will show it.
      displayHelp = displayHelp || itemView.enableHelp;
      itemView.enableHelp = false;
      itemView.helpImage = paramValue.helpImageRelativeUrl;

      this.itemsView.push(itemView);
    });

    // Create group view
    this.viewModel = this.viewFactory.create(this.parameter);

    if (this.parameter.placeholder)
      this.viewModel.placeholder = this.parameter.placeholder;

    // If there is no width defined then set the minimum to fix selected value on the right side of parameter.
    // minWidth is only needed if DisplaySelectedValue is set.
    if (!this.parameter.width || this.parameter.width == 0)
      this.viewModel.minWidth = this.visualObjectHelper.visualObjectWidth(this.parameter);

    this.viewModel.highlight = this.shouldHighlight();

    // Set height for everyone
    if (lookupParam.showHelpImageOfSelectedValue && lookupParam.selectedValueImageHeight)
      this.viewModel.helpImageHeight = `${lookupParam.selectedValueImageHeight}px`;

    // Above values
    if (lookupParam.showHelpImageOfSelectedValue == 1) {
      // In this case width would be same as parameter width. So don't need to set the width for help image.
      this.viewModel.helpImagePosition = Positions.Above;
    }

    // Right side of the parameter, width and height both is needed.
    // Right of values = 2
    // Inline right = 3
    // Inline left = 4
    // Below = 5
    else if (
      (
        lookupParam.showHelpImageOfSelectedValue == 2 ||
        lookupParam.showHelpImageOfSelectedValue == 3 ||
        lookupParam.showHelpImageOfSelectedValue == 4

      ) && lookupParam.selectedValueImageHeight && lookupParam.selectedValueImageWidth) {

      if (lookupParam.selectedValueImageWidth)
        this.viewModel.helpImageWidth = `${lookupParam.selectedValueImageWidth}px`;

    }

    this.viewModel.helpImagePosition = this.ImagePosition(lookupParam.showHelpImageOfSelectedValue);

    if (this.viewModel.helpImagePosition != Positions.None) {
      this.viewModel.widthIfSelectedImage = this.visualObjectHelper.getLookupWidthIfSelectedHelpImage(lookupParam) + 'px'; 
    }

    this.viewModel.enableHelp = this.viewModel.enableHelp || displayHelp;
    this.viewModel.enableExternalLink = this.viewModel.enableExternalLink && !displayHelp;

    // If any child view contains icon then use the ImageLeft or ImageAbove display style based on orientation.
    if (this.itemsView.filter(x => x.icon).length > 0) {
      this.displayStyle = DisplayStyle.Image;
    }

    // If any of the image has missing icon, then set the default no-image icon.
    if (this.displayStyle == DisplayStyle.Image)
      this.itemsView.forEach(item => {

        if (!item.icon) {
          item.icon = "noimage";
          item.isVaultIcon = false;
        }
      });
  }

  protected ImagePosition(helpImagePositionId: number): string {

    switch (helpImagePositionId) {

      case 1:
        return Positions.Above;

      case 2:
        return Positions.Right;

      case 3:
        return Positions.InlineRight;

      case 4:
        return Positions.InlineLeft;

      case 5:
        return Positions.Below;

      default:
        return Positions.None;

    }

  }

  public setValue(confValue: BaseEntity) {
    this.confLookupValue = confValue as ConfLookupValue;

    if (!this.confLookupValue)
      return;

    // Remove all readonly and disallowed states before setting it.
    this.viewFactory.setNormalState(this.itemsView);

    let selectedView = this.viewFactory.setValue(this.itemsView, String(this.confLookupValue.value), this.confLookupValue.value);

    // Group view is used in dropdown list to show the title of the selected value.
    this.viewModel.formattedValue = selectedView != null ? selectedView.title : "";
    this.viewModel.value = selectedView != null ? selectedView.id : null;
    this.selectedViewId = selectedView != null ? selectedView.id : null;

    // Set selected value icon for dropdowns
    if (!this.parameter.displayAsRadio && this.displayStyle == DisplayStyle.Image)
      if (selectedView != null)
        this.viewModel.icon = selectedView.icon;
      else
        this.viewModel.icon = this.parameter.iconRelativeUrl;

    // Disallow values
    let dissallowedValues: any = this.confLookupValue.disallowedParamValues;

    dissallowedValues.entrySeq().forEach(e => {
      // TODO: Make map strongly typed in model classes.
      let key: string = e[0];
      let value: boolean = Boolean(e[1]);

      this.itemsView = this.viewFactory.setDisabled(this.itemsView, key, value);
    });

    // HIDE (disable)
    this.parameter.paramValues.forEach(x => {
      let paramValue: ParamValue = this.getParamValue(x);

      if (!this.visualObjectVisibilityService.isParamValueVisible(paramValue, this.configuration))
        this.itemsView = this.viewFactory.setDisabled(this.itemsView, paramValue.longId + "", true);
    });

    // Set readOnly
    this.itemsView = this.viewFactory.setReadOnly(this.itemsView, String(this.confLookupValue.value), this.confLookupValue.isReadOnly);

    // In dropdown case, read only icon should be displayed at parent level, thats why the readonly status is set to group's view.
    this.viewModel.readOnly = this.confLookupValue.isReadOnly;   

    this.itemsView = [...this.itemsView];
    this.cd.markForCheck();

  }

  public setHighlight(highlight: boolean): void {
    this.viewModel.highlight = highlight;
    this.viewModel = { ... this.viewModel };
    this.cd.markForCheck();
  }

  getParamValues(): ParamValue[] {
    if (this.paramValues.length > 0 && this.paramValues.length === this.parameter.paramValues.count())
      return this.paramValues;

    this.parameter.paramValues.forEach((a) => {
      let paramValue: ParamValue = this.getParamValue(a);

      this.paramValues.push(paramValue);
    });

    return this.paramValues;
  }

  getParamValue(id: number): ParamValue {
    return this.productStore.getEntity(id) as ParamValue;
  }

  onValueChange($event: ValueChangeEventArgs): void {
    super.onValueChange($event);

    // Update value
    this.viewModel.value = $event.actionView.title;
    this.selectedViewId = $event.actionView.value ? String($event.actionView.value) : null;
    this.confStore.setConfValue(this.confLookupValue, this.confSessionId, +$event.actionView.value, this.confUIStore.includeMandatoryInfo, this.confUIStore.isNextStateIncluded);
  }

  onReadOnly($event: RestrictValueChangeEventArgs): void {
    // TODO: Currenty all messages are listened in configuratorComponent, The dispatch is only made here. Its better to return the subscription and show messages from here.
    this.confStore.getReadOnlyInfo(this.configurationId, this.confSessionId, this.confLookupValue, +$event.inValidValue);
  }

  onDisallowed($event: RestrictValueChangeEventArgs): void {
    this.confStore.setConfValue(this.confLookupValue, this.confSessionId, +$event.inValidValue, this.confUIStore.includeMandatoryInfo, this.confUIStore.isNextStateIncluded);
  }

  public getCurrentValue(): any {

    // If value is different then set it to ConfValue and return the change ConfValue to cause a value change trigger
    
    if (this.confLookupValue && (this.confLookupValue.value !== null ? this.confLookupValue.value : 0) !== +this.viewModel.value)
      return this.confLookupValue.setValue(+this.viewModel.value);

    return this.confLookupValue;
  }

  public setError(errors: Array<string>) {
    this.viewModel.errors = errors;
  }
}