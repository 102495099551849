<div *ngIf="view != null" class="checkbox" [class.disabled]="view.disabled" [style.width]="view.width" [style.max-width.%]="100" [style.min-width]="view.minWidth ? view.minWidth : '100%'" >

  <span *ngIf="view.readOnly" class="read-only align-middle" [id]="view.id">
    <a role="button" (click)="readOnlyClick()"><co-image width="20" height="20" type="svg" key="lock" imageSet="{{imageSet}}"></co-image></a>
  </span>
  
  <div class="static-label max-width align-middle {{checkbox.styleNames}}">
    <div class="row mx-0 w-100">
      <icon *ngIf="view.icon" [view]="view" class="col-auto px-0"></icon>
      <div class="col view-title {{view.icon ? 'px-2' : 'px-0'}}">
        <span *ngIf="view.title">{{view.title}}</span>
        <span class="ml-1"><help-link *ngIf="view.enableHelp" (click)="onHelpClick($event)" separator="false" [view]="view" [imageSet]="checkbox.helpImageSet"></help-link></span>
      </div>
      
      <div (click)="onClick($event)" class="square toggle-wrap horizontal {{stateString}} col-xs-auto" style="width: 50px;height: 22px;">
        <div class="checkbox-toggle">
          <div class="checkbox-toggle-handle">
            <span *ngIf="stateString == 'null'">?</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


