import { Inject, Component, ViewChild, Input, Output, EventEmitter, ElementRef, ChangeDetectorRef } from "@angular/core";
import * as Immutable from 'immutable';
import { ConfInfo, AttributeInfo, WarningAttributesMessage, PriceReportMessage } from "../../../shared/models";
import { RegularPopupComponent } from "../../../../shared/components/popup";
import { PageActionCreator, PageStore } from "../../../shared/providers/page";
import { RouteRedirector, RouteNames } from "../../../shared/providers";
import { InputViewModel, ValueChangeEventArgs } from "../../../../shared/components/shared";
import { ConfPageSessionService, ConfiguratorStore, PopupIdentifiers, ConfRouteParams, ConfMessageProvider } from "../../providers";
import { AccordionComponent, PopupService } from "../../../../shared/components";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { ClearedPricesMessage } from "../../../shared/models/responses/messages/clearedPricesMessage";
import { ManagedSubscription } from "../../../../shared";
import { ImageSets } from "../../../../shared/utils";

@Component({
  selector: 'warning-price-popup',
  templateUrl: './warningPricePopupComponent.html'
})
export class WarningPricePopupComponent extends AbstractPopupComponent {

  public clearedMessage: ClearedPricesMessage;

  public imageSet: string;
 
  @Output()
  onClosed = new EventEmitter();

  protected priceSubscription: ManagedSubscription

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,    
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(ConfMessageProvider) public confMessageProvider: ConfMessageProvider,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(PageStore) public pageStore: PageStore,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr, popupService);
  }

  ngOnInit() {
    this.id = PopupIdentifiers.WarningPricePopup;
    this.imageSet = this.getImageSet(ImageSets.PopupHeader);
    super.ngOnInit();
  }

  public open(tag: any) {
    this.clearedMessage = tag.get(0);
    this.showInternal();    
  }

  public onPopupClosed() {
    this.clearedMessage = null;
        
    super.onPopupClosed();
    this.onClosed.emit();
  }

  public onOkClick(event) {

    let requestType = this.storeSession.confUIStore.PageStore.getActiveClientType();
    this.confStore.getPriceReport(this.storeSession.confSessionId, this.clearedMessage.configurationId, requestType);

    // Price report message
    this.priceSubscription = this.confMessageProvider.onMessagesRequest<PriceReportMessage>(this.storeSession.confSessionId, PriceReportMessage.name, {
      next: (messages: Immutable.List<PriceReportMessage>): void => {

        this.popupService.open<Immutable.List<PriceReportMessage>>(PopupIdentifiers.PriceList, messages);
        this.priceSubscription.unsubscribe();

      },
      listenNewEventsOnly: true
    });

  }

}