import { ChangeDetectorRef, Component, Inject } from "@angular/core";
import { ProductDataStore } from "../../../shared/providers/productData";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { ListModelCacheService } from "../providers/listModelCacheService";
import { PopupService } from "../../../../shared/components/popup/popupService";
import { GlobalDataStore } from "../../../shared/providers/globalData/globalDataStore";
import { PopoverService } from "../../../../shared/components";
import { BaseDetailItemComponent } from "../shared/baseDetailItemComponent";
import { FormDecoration, ConfUIItem, GroupConfUIItem, RequestViews, PageLocation } from "../../../shared/models";

@Component({
  selector: 'form-detail',
  templateUrl: './formDetailComponent.html'
})
export class FormDetailComponent extends BaseDetailItemComponent {

  formDecorations: Array<FormDecoration>;
  actionBoxTitle: string;
  showInlineTitle: boolean = false;
  RequestViews = RequestViews;

  constructor(@Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    
  ) {
    super(confPageSessionService);
  }

  onDataReady(): void {

    this.formDecorations = [];
    this.actionBoxTitle = "";

    if (this.confUIItem) {
      if (this.confUIItem.items) {
        if (this.confUIItem.id) {
          this.addFormDecoration(this.confUIItem);
        }
        else
          this.confUIItem.items.forEach(uiItem => {
            this.addFormDecoration(uiItem);
          });
      }
    }

    this.visible = this.formDecorations.length > 0;
    if (!this.visible)
      return;

    this.showInlineTitle = this.formDecorations.length > 1;

    if (!this.showInlineTitle) {
      let firstDecoration = this.formDecorations[0];
      if (firstDecoration.title && firstDecoration.title.trim().length > 0)
        this.actionBoxTitle = firstDecoration.title;
      // For individually displayed decoration if there is no title then hide the whole header row
      else if (typeof this.confUIItem != typeof GroupConfUIItem)
        this.showHeader = false;
    }
  }

  addFormDecoration(x: ConfUIItem) {
    const visualObject = this.productStore.getEntity(x.id);

    if (visualObject instanceof FormDecoration) {

      let formDecoration = visualObject as FormDecoration;

      const displayStyle = formDecoration.pageLocation as PageLocation;

      if (displayStyle == PageLocation.Tab)
        this.formDecorations.push(formDecoration);
     
    }
  }

}