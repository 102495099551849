import * as Immutable from "immutable";
import { PushMessage } from "./pushMessage";

export class ServerPushMessage extends PushMessage {

  protected readonly START_UI_ACTION = "startUIAction";
  protected readonly UI_ACTION_STRUCTURE_BY_ID = "uiActionStructureById";
  
  get startUIAction(): string { return this.getInternalValue<string>(this.START_UI_ACTION); }
  setStartUIAction(startUIAction: string): this { return this.setInternalValue(this.START_UI_ACTION, startUIAction); }

  get uiActionStructureById(): Immutable.Map<string, Immutable.List<number>> { return this.getInternalValue<Immutable.Map<string, Immutable.List<number>>>(this.UI_ACTION_STRUCTURE_BY_ID); }
  setUiActionStructureById(uiActionStructureById: Immutable.Map<string, Immutable.List<number>>): this { return this.setInternalValue(this.UI_ACTION_STRUCTURE_BY_ID, uiActionStructureById); }

 }