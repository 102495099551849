import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";
import { Subject, Subscription, BehaviorSubject, ReplaySubject } from "rxjs";

import { BaseStore } from "../../state/baseStore";
import { Product, Tab, Assortment, ConfInfo, State, GlobalEntitiesCommand, ProductCommand, TabOptions, VisualObjectOptions, AssortmentCommand, PushMessage, ServerPushMessage, UIElement, UIAction } from "../../models";
import { BaseEntity } from "../../baseEntity";
import { StoreResponse } from "../../state/storeResponse";
import { PushMessageActionCreator } from "./pushMessageActionCreator";
import { AppStoreSubscriptionManager } from "../appStoreSubscriptionManager";
import { ValueSubscriptionManager } from "../valueSubscriptionManager";
import { ManagedSubject } from "../../../../shared/managedSubject";
import { GlobalDataStore } from "../globalData/globalDataStore";
import { ModelFactory } from "../modelFactory";
import { AppStore } from "../../state";
import { ClientPushMessage } from "../../models/requests/clientPushMessage";
import { PushMessageSelection } from "./pushMessageSelection";
import { ManagedSubscription, SubscriptionOptions } from "../../../../shared";
import { PushMessageMemorizer } from "./pushMessageMemorizer";
import { PushMessageData } from "../../models/entities/pushMessageData";
import { SignalRService } from "./signalRService";
import { AccountDataStore } from "../accountData";
import { ElementStore } from "../elementStore";

@Injectable()
export class PushMessageStore extends BaseStore implements ElementStore {
    
  constructor(
    @Inject(AppStore) protected appStore: AppStore,
    @Inject(GlobalDataStore) protected globalDataStore: GlobalDataStore,
    @Inject(PushMessageActionCreator) protected pushMessageActionCreator: PushMessageActionCreator,
    @Inject(AppStoreSubscriptionManager) protected appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(ValueSubscriptionManager) protected valueSubscriptionManager: ValueSubscriptionManager,
    @Inject(PushMessageMemorizer) protected memorizer: PushMessageMemorizer,
    @Inject(ModelFactory) protected modelFactory: ModelFactory,
    @Inject(SignalRService) protected signalRService: SignalRService,
    @Inject(AccountDataStore) protected accountDataStore: AccountDataStore
  ) {
    super(appStore, appStoreSubscriptionManager, modelFactory, pushMessageActionCreator);
  }

  /**
   * Fetches products without tabs.
   * @param productId
   */
  setData(data: any): ManagedSubject<StoreResponse<PushMessageData>> {
    // let model = this.createProductRequestModel();
    //model.product = this.modelFactory.createRequestOrCommand<ProductCommand>(ProductCommand.name, { ids: [productId] });
    let response: StoreResponse<PushMessageData> = new StoreResponse<PushMessageData>();

    let action = this.pushMessageActionCreator.dispatchSetServerPushMessage(data);

    // Create request object
    return this.createAction(action, actionInfo => {
      let serverPushMessage = actionInfo.payload.data as PushMessageData;

      if (serverPushMessage)
        response.data = serverPushMessage;

      return response;
    });

  }

  public getElementValue(id: string, sessionId: number) {
    let valuesBySessionId = this.getValuesBySessionId(sessionId);

    if (valuesBySessionId)
      return valuesBySessionId.get(id);

    return null;
  }

  public getValuesBySessionId(sessionId: number): Immutable.Map<string, string> {
    return this.appStore.getState().pushMessages.dataBySessionId.get(sessionId).values;
  }

  public clear(sessionId: number) {

    let selection = new PushMessageSelection();

    selection.sessionId = sessionId;
    selection.clearSession = true;

    this.pushMessageActionCreator.dispatchValueChanged(selection);
  }

  public setValue(pushMessageSelection: PushMessageSelection) {

    this.pushMessageActionCreator.dispatchValueChanged(pushMessageSelection);
  }

  public createRequest(): ClientPushMessage {
    return this.modelFactory.createRequestOrCommand<ClientPushMessage>(ClientPushMessage.name);
  }

  onElementChange(sessionId: number, elementId: number, callback: (uiElement: UIElement) => void, currentValueFunc?: () => any): ManagedSubscription {
    let memorizer: () => UIElement = this.memorizer.getElementMemorizer(sessionId, elementId, currentValueFunc);

    let identifier = `onElementChange_${sessionId}_${elementId}`;

    if (currentValueFunc)
      identifier += "_match_current_value";

    return this.createStoreSubscription<UIElement>(identifier, memorizer, callback);
  }

  onElementValueChange(sessionId: number, elementId: number, callback: (value: string) => void, currentValueFunc?: () => any): ManagedSubscription {
    let memorizer: () => string = this.memorizer.getElementValueMemorizer(sessionId, elementId, currentValueFunc);

    let identifier = `onElementValueChange_${sessionId}_${elementId}`;

    if (currentValueFunc)
      identifier += "_match_current_value";

    return this.createStoreSubscription<string>(identifier, memorizer, callback);
  }

  public getUIActions(id: string, sessionId: number): Immutable.List<number> {

    let actions = this.appStore.getState().pushMessages.dataBySessionId.get(sessionId).serverPushMessage.uiActionStructureById;

    return actions.get(id);
  }

  public getUIActionsBySessionId(sessionId: number): Immutable.Map<number, UIAction> {
    return this.appStore.getState().pushMessages.dataBySessionId.get(sessionId).uiActionById;
  }

  public getElementsBySessionId(sessionId: number): Immutable.Map<number, UIElement> {

    if (!this.appStore.getState().pushMessages.dataBySessionId)
      return null;

    if (!this.appStore.getState().pushMessages.dataBySessionId.has(sessionId))
      return null;

    return this.appStore.getState().pushMessages.dataBySessionId.get(sessionId).uiElementById;
  }

  public getUIAction(id: number, sessionId: number): UIAction {
    return this.getUIActionsBySessionId(sessionId)?.get(id);
  }

  public getElement(id: number, sessionId: number): UIElement {
    return this.getElementsBySessionId(sessionId)?.get(id);
  }

  public createStoreSubscription<T>(identifier: string, memorizer: () => T, subscriptionOptions: SubscriptionOptions<T>, oneTime = false) {
    let managedSubject: ManagedSubject<T> = this.appStoreSubscriptionManager.getOrCreateStoreSubject(identifier, memorizer, oneTime, 1, true);
    return managedSubject.subscribe(subscriptionOptions);
  }

  public startListening(): void {
    if (this.accountDataStore.isUserLoggedIn() && !this.signalRService.isConnected()) {
      this.signalRService.startConnection();
    }
  }

  public stopListening(): void {
    this.signalRService.stopConnection();
  }

  public isRedirecting() {
    return this.appStore.getState().pushMessages && this.appStore.getState().pushMessages.redirecting;
  }

  public isRefreshing() {
    return this.appStore.getState().pushMessages && this.appStore.getState().pushMessages.refreshing;
  }

  public setRefreshing(refreshing: boolean): ManagedSubject<void> {
    this.pushMessageActionCreator.dispatchRefreshingChanged(refreshing);

    // Create request object
    let response: StoreResponse<any> = new StoreResponse<any>();

    // Subscribe to store and return the results on request complete.    
    return this.appStoreSubscriptionManager.createSubject(() => {

      if (this.appStore.getState().pushMessages.refreshing) {

        return response;
      }

      return ManagedSubject.IGNORE_VALUE;
    }, true);
  }

  public setRedirecting(redirecting: boolean) {
    this.pushMessageActionCreator.dispatchRedirectingChanged(redirecting);

    // Create request object
    let response: StoreResponse<any> = new StoreResponse<any>();

    // Subscribe to store and return the results on request complete.    
    return this.appStoreSubscriptionManager.createSubject(() => {

      if (this.appStore.getState().pushMessages.redirecting) {

        return response;
      }

      return ManagedSubject.IGNORE_VALUE;
    }, true);
  }
}