import { Component, ChangeDetectorRef, Inject, Input, ChangeDetectionStrategy, ElementRef, Output, EventEmitter } from "@angular/core";
import { BaseComponent, UIComponent } from "../../../shared";
import { ManagedSubscription } from "../../../../shared";
import { ConfiguratorUIStore, ConfiguratorStore, ConfPageSessionService, PopupIdentifiers, ConfRouteParams } from "../../providers";
import { ProductDataStore } from "../../../shared/providers/productData";
import { TabVisibilityService, IEmitDataInfo } from "../../shared";
import { RouteRedirector } from "../../../shared/providers";
import { NotificationService } from "../../../../shared/components";
import { Tab, RequestViews, Product, Conf } from "../../../shared/models";
import { PageUIStore } from "../../../shared/providers/page";
import { AccordionCompositeParams } from "./accordionCompositeParams";
import { TabContainerComponent } from "./tabContainerComponent";
import { TabAvailabilityService } from ".";

@Component({
  selector: 'nested-accordion-tabs',
  templateUrl: './nestedAccordionTabsComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NestedAccordionTabsComponent extends TabContainerComponent {

  @Input()
  public tabs: Tab[] = [];

  @Input()
  public containerId: string;

  @Input()
  public selectedTabId: number;

  @Input()
  public configurationId: number;

  @Output() public selected: EventEmitter<any> = new EventEmitter();

  public accordionCompositeMode: boolean = false;

  public accordionOpener: boolean = true;

  public accordionTabsWidth: string;

  public showOverlay: boolean = false;
  public showAccordionTabs: boolean = false;
  public subConfigurationTabs: Tab[] = [];
  public activeSubConfigurationId: number;
  public subConfTitle: string;

  public selectedSubTabId: number;

  // selectedTreeNodeId can be different than activeSubConfigurationId, e.g If root node is selected then activeSubConfiguration would be null and
  // selectedTreeNodeId would refer to root node.
  public selectedTreeNodeId: number;
  protected messageSubscription: ManagedSubscription;

  constructor(
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ProductDataStore) public productDataStore: ProductDataStore,
    @Inject(TabVisibilityService) public tabVisibilityService: TabVisibilityService,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,    
    @Inject(NotificationService) public notificationService: NotificationService,
    @Inject(PageUIStore) public pageUIStore: PageUIStore,
    public tabAvailableService: TabAvailabilityService,
    public cd: ChangeDetectorRef,
    private ref: ElementRef
  ) {
    super(confUIStore, cd, tabAvailableService);
  }

  ngOnInit(): void {

    //this.confStore.onActiveConfChange((confId: number) => { this.configurationId = confId; });
    this.configurationId = this.storeSession.activeConfigurationId;

    const conf: Conf = this.storeSession.activeConfiguration;
    this.showAccordionTabs = this.confStore.getConfInfo(conf.rootId, this.storeSession.confSessionId).showChildrenInAccordionTabs;

    // Select the active configuration tree node.
    let routSubConfId: number = +this.routeRedirector.searchRouteParam(ConfRouteParams.SUB_CONF_ID);
    this.selectedTreeNodeId = routSubConfId ? routSubConfId : this.storeSession.activeConfigurationId;

    this.accordionCompositeMode = !!routSubConfId;

    // If page is reloaded and route contains sub active configuration, then show it.
    if (routSubConfId) {
      this.showSubConfigurationDetail(<IEmitDataInfo<AccordionCompositeParams>>{
        id: PopupIdentifiers.AccordionCompositeShowDetail,
        tag: new AccordionCompositeParams(routSubConfId, false)
      });
    }

    this.messageSubscription = this.emitterService.getMessage().subscribe((info: IEmitDataInfo<AccordionCompositeParams>) => {

      switch (info.id) {
        case PopupIdentifiers.AccordionCompositeShowDetail:
          // If root configuration in ManadatoryPopup is clicked, then remove the sub configuration detail.
          if (+info.tag.confId == this.storeSession.activeConfigurationId) {

            this.showOverlay = false;
            this.activeSubConfigurationId = null;
            this.selectedTreeNodeId = +info.tag.confId;
            this.confUIStore.setSubConfId(null);
            this.routeRedirector.changeParamValue(ConfRouteParams.SUB_CONF_ID, null);
            this.cd.markForCheck();
          }
          else {
            this.showSubConfigurationDetail(info);
          }

          break;

        case PopupIdentifiers.AccordionCompositeHideDetail:
          this.showOverlay = false;
          this.activeSubConfigurationId = null;

          // Push message can omit to set the confid.
          if (info.tag.confId)
            this.selectedTreeNodeId = +info.tag.confId;
          else
            this.selectedTreeNodeId = this.storeSession.activeConfigurationId;

          this.confUIStore.setSubConfId(null);
          this.routeRedirector.changeParamValue(ConfRouteParams.SUB_CONF_ID, null);
          // Load configuration on sub configuration close.
          this.confStore.loadConf(this.storeSession.activeConfigurationId, this.storeSession.confSessionId, RequestViews.Editor, this.confUIStore.isMandatoryOpened);
          this.cd.markForCheck();
          break;

        case PopupIdentifiers.ChangeSelectedTab:

          let subTab = this.subConfigurationTabs?.find(x => x.longId == +info.tag.confId)
          if (subTab)
            this.selectedSubTabId = subTab.longId;
          break;

      }
    });

    this.confStore.onConfigurationChange(this.configurationId, this.storeSession.confSessionId, (conf: Conf) => {

      this.updateTabs(false);      

    }).unsubscribeOn(this.unsubscribeSubject);

    super.ngOnInit();
  }

  updateTabs(reload: boolean) {

    let subConfProductId: number = this.confStore.getConfInfo(this.activeSubConfigurationId, this.storeSession.confSessionId)?.productId;
    if (!subConfProductId)
      return;

    this.productDataStore.getTabs(subConfProductId).subscribe(response => {

      let tabs: Tab[] = response.data;

      let reloadConf = () => {

        this.confStore.loadConf(this.activeSubConfigurationId, this.storeSession.confSessionId, RequestViews.Editor, this.confUIStore.isMandatoryOpened).then(response => {
          this.setSubConfigurationDetail(tabs);
        });

      }

      if (reload)
        reloadConf();

      let subConfiguration: Conf = this.confStore.getConf(this.activeSubConfigurationId, this.storeSession.confSessionId);
      if (subConfiguration) {
        this.setSubConfigurationDetail(tabs);
      }
      else reloadConf();
      
      this.showOverlay = true;
      this.cd.detectChanges();
    });
  }

  showSubConfigurationDetail(info: IEmitDataInfo<AccordionCompositeParams>) {

    this.accordionCompositeMode = true;
    this.subConfigurationTabs = [];
    this.activeSubConfigurationId = info.tag.confId;
    this.selectedTreeNodeId = this.activeSubConfigurationId;

    // Add sub configuration id in confRouteParams.
    this.confUIStore.setSubConfId(this.activeSubConfigurationId);

    let confInfo = this.confStore.getConfInfo(this.activeSubConfigurationId, this.storeSession.confSessionId);
    // As exception is thrown from server, it is not needed to handle it here.
    if (confInfo == null)
      return;

    this.updateTabs(info.tag.reload);
  }

  setSubConfigurationDetail(tabs: Tab[]) {

    let subConfiguration: Conf = this.confStore.getConf(this.activeSubConfigurationId, this.storeSession.confSessionId);
    if (!subConfiguration)
      return;

    let confInfo = this.confStore.getConfInfo(this.activeSubConfigurationId, this.storeSession.confSessionId);
    this.subConfTitle = confInfo.text;

    this.subConfigurationTabs = [];
    tabs.forEach(tab => {

      if (this.tabVisibilityService.isVisible(tab, subConfiguration))
        this.subConfigurationTabs.push(tab);

    });

    this.cd.markForCheck();
  }

  onConfigurationNodeSelect(event): void {
    this.showOverlay = false;
    /*this.selectedTreeNodeId = +event.tag; // Set the selected node id.*/
    this.routeRedirector.changeParamValue(ConfRouteParams.SUB_CONF_ID, null);
    event.preventDefault();
  }

  get pageId(): number {
    return this.storeSession.pageId;
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  public activateTab(tab: Tab) {
    
  }

  onAccordionComposite(event) {
    this.accordionCompositeMode = true;
  }

}