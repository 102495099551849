<bom-table [grid]="confBomValue.gridData"
           [columnSettings]="decoration.columnSettings"
           (selectionChanged)="selectRow($event)"></bom-table>
<pager [pagerModel]="pagerModel"       
       (pagerUpdated)="refreshGrid()"
       [refresh]="refreshPager"></pager>
<gridview classes="bom-controls text-center" *ngIf="showActionControls">
  <gridview-header *ngIf="allowManuallyAddedItems">
    <header-columntemplate classes="header-image clickable pt-0 align-middle col-auto"
                           [attr.data-target]="'#' +decoration.longId"
                           (click)="addBom()">
      <co-image width="24" height="24" type="svg" key="add" imageSet="{{imageSet}}"></co-image>
      <span>{{strings.Add}}</span>
    </header-columntemplate>
    <header-columntemplate classes="header-image clickable pt-0 align-middle col-auto"
                           [class.disabled]="selectedRow == null"
                           [attr.data-target]="'#' +decoration.longId"
                           (click)="editBom()">
      <co-image width="20" height="20" type="svg" key="edit" imageSet="{{imageSet}}"></co-image>
      <span>{{strings.Edit}}</span>
    </header-columntemplate>
    <header-columntemplate classes="header-image clickable pt-0 align-middle col-auto"
                           [class.disabled]="selectedRow == null"
                           (click)="removeBom()">
      <co-image width="20" height="20" type="svg" key="delete" imageSet="{{imageSet}}"></co-image>
      <span>{{strings.Delete}}</span>
    </header-columntemplate>
  </gridview-header>
</gridview>

<div *ngIf="displayChildConfigurations" class="mt-3">
  <gridview>
    <gridview-header classes="decoration-header">
      <header-columntemplate classes="header-image" size="auto">
        <co-image width="24" height="24" type="svg" key="configuration" imageSet="{{imageSet}}"></co-image>
      </header-columntemplate>
      <header-columntemplate classes="header-title">
        {{strings.ChildConfigurations}}
      </header-columntemplate>
    </gridview-header>
  </gridview>
  <bom-table [grid]="confBomValue.childrenGridData"
             [columnSettings]="decoration.childConfigurationColumnSettings"></bom-table>
</div>

<bom-grand-total class="d-block" [grid]="confBomValue.grandTotal"></bom-grand-total>

<bom-create-control *ngIf="addVisible" [isAllowOperationSequenceEdit]="decoration.isAllowOperationSequenceEdit" (closedEvent)="closedEvent($event)"></bom-create-control>
<bom-edit-control *ngIf="editVisible" [isAllowOperationSequenceEdit]="decoration.isAllowOperationSequenceEdit" (closedEvent)="closedEvent($event)"></bom-edit-control>
<bom-delete-popup *ngIf="deleteVisible" (onItemDeleted)="selectedRow = null" (closedEvent)="closedEvent($event)"></bom-delete-popup>

