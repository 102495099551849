<!--ReadOnly part - Apply the readOnly action on complete row-->
<div *ngIf="itemView" class="mx-0 w-100"
     [class.disabled]="isDisabled"
     role="button"
     (click)="(itemView && isReadOnly) && onClick($event)">

  <!-- Keep the Read only icon left when orientation is vertical -->
  <ng-container *ngTemplateOutlet="orientation == 'vertical' && readOnlyTemplate"></ng-container>

  <ng-container [ngSwitch]="imagePosition">

    <!--If the image is above of the text-->
    <ng-container *ngSwitchCase="'top'">
      
      <div class="radio-item d-flex justify-content-center">        
        <image-radio-style class="d-flex icon-box content-position"
                           [view]="itemView"
                           [selected]="selected"
                           (click)="onClick($event)"></image-radio-style>
        
        <!-- Display readOnly icon top of the image -->
        <ng-container *ngTemplateOutlet="orientation == 'horizontal' && readOnlyTemplate">          
        </ng-container>
      </div>

      <div [style.width]="itemView.iconWidth" class="title align-middle justify-content-center" (click)="onClick($event)">{{itemView.title}}</div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="row mx-0 px-0">
        
        <div class="col-auto radio-item px-0">          
          <image-radio-style class="d-flex icon-box content-position"
                             [view]="itemView"
                             [selected]="selected"
                             (click)="onClick($event)"></image-radio-style>
          <!-- Display readOnly icon top of the image -->
          <ng-container *ngTemplateOutlet="orientation == 'horizontal' && readOnlyTemplate"></ng-container>          
        </div>
        <div class="title col align-middle" (click)="onClick($event)">{{itemView.title}}</div>
      </div>
    </ng-container>

  </ng-container>
</div>

<ng-template #readOnlyTemplate>
  <!--Transparent circle is only shown in horizontal orientation-->       
  <span *ngIf="orientation == 'horizontal' && isReadOnly" class="{{orientation}} transparent-circle"></span>
  <span *ngIf="isReadOnly" class="read-only align-middle {{orientation}}" [id]="itemView.id">
    <co-image width="20" height="20" type="svg" imageSet="{{imageSet}}" key="lock"></co-image>
  </span>
</ng-template>