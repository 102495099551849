<div class="d-flex w-100 accordion-nodes-container mx-0" *ngIf="startingNodes.length > 0">  
    <tree-view-simple
                      [roots]="startingNodes"
                      [activeNodeId]="activeNodeId"
                      (selected)="onNodeSelected($event)"
                      [imageSet]="imageSet"
                      actionIcon="close-composite-tab"
                      (actionIconClicked)="closeConfigurationDetail($event)"
                      >
    </tree-view-simple>        
</div>