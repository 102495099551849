import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";
import { Subject, Subscription, BehaviorSubject, ReplaySubject } from "rxjs";

import { BaseStore } from "../../state/baseStore";
import { Product, Tab, Assortment, ConfInfo, State, GlobalEntitiesCommand, ProductCommand, TabOptions, VisualObjectOptions, AssortmentCommand } from "../../models";
import { BaseEntity } from "../../baseEntity";
import { StoreResponse } from "../../state/storeResponse";
import { ProductDataState, ActionInfo, RequestStatus, HttpAction, AppStore } from "../../state";
import { ProductDataActionCreator } from "./productDataActionCreator";
import { ProductDataResponse, ApiResponse } from "../../models/responses";
import { AppStoreSubscriptionManager } from "../appStoreSubscriptionManager";
import { ValueSubscriptionManager } from "../valueSubscriptionManager";
import { ManagedSubject } from "../../../../shared/managedSubject";
import { GlobalDataStore } from "../globalData/globalDataStore";
import { ProductDataRequest } from "../../models/requests/productDataRequest";
import { ModelFactory } from "../modelFactory";

@Injectable()
export class ProductDataStore extends BaseStore {
    
  constructor(
    @Inject(AppStore) protected appStore: AppStore,
    @Inject(GlobalDataStore) protected globalDataStore: GlobalDataStore,
    @Inject(ProductDataActionCreator) protected productActionCreator: ProductDataActionCreator,
    @Inject(AppStoreSubscriptionManager) protected appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(ValueSubscriptionManager) protected valueSubscriptionManager: ValueSubscriptionManager,
    @Inject(ModelFactory) protected modelFactory: ModelFactory,
  ) {
    super(appStore, appStoreSubscriptionManager, modelFactory, productActionCreator);
  }

  public getProductData(): ProductDataState {
    return this.appStore.getState().productData;
  }
    
  public getEntity<T extends BaseEntity>(id: number) {
    return this.getProductData().entities.get(+id) as T;
  }

  public createProductRequestModel(): ProductDataRequest {
    return this.modelFactory.createAny(ProductDataRequest.name) as ProductDataRequest;
  }

  public getProduct(productId: number, callback: (product: Product) => void): void {
    if (this.getProductData().entities.has(productId))
      callback(this.getEntity<Product>(productId));
    else
      this.fetchProduct(productId).subscribe(res => callback(res.data));
  }

  public getChildProducts(product: Product, filterChildren?: Array<number>): ManagedSubject<StoreResponse<Product[]>> {
    let subject = this.valueSubscriptionManager.createSubject(ManagedSubject.IGNORE_VALUE, true);

    // Create response object
    let response: StoreResponse<Product[]> = new StoreResponse<Product[]>();

    if (this.isChildProductsLoaded(product)) {
      // Set the tabs to data.

      let children: Product[];

      if (product.children) {

        let childrenIds = this.intersectOrDefault(product.children.toArray(), filterChildren);
        
        children = childrenIds.map(x => this.getEntity<Product>(x))

      }
      else
        children = [];

      response.data = children;
      subject.nextValue(response);
    }
    else {
      // Load the product data. It mostly happens when user opens the direct configurator url.
      this.fetchChildProducts(product).subscribe(res => {

        let childrenIds = this.intersectOrDefault(res.data.children.toArray(), filterChildren);

        response.data = childrenIds.map(x => this.getEntity<Product>(x));
        subject.nextValue(response);

      });
    }

    return subject;
  }

  protected intersectOrDefault(defaultArray: Array<number>, arr?: Array<number>) {

    if (!arr || arr.length == 0)
      return defaultArray;

    // Take the intersection.
    let childrenIds = arr.filter(id => defaultArray.indexOf(id) > -1);

    return childrenIds;
  }

  protected isChildProductsLoaded(product: Product): boolean {
    if (product.children.isEmpty())
      return true;

    // Check if all child products are loaded
    return product.children.every(childProductId => this.getProductData().entities.has(childProductId));
  }

  public getTabs(productId: number): ManagedSubject<StoreResponse<Tab[]>> {

    let subject = this.valueSubscriptionManager.createSubject(ManagedSubject.IGNORE_VALUE, true);

    // Create response object
    let response: StoreResponse<Tab[]> = new StoreResponse<Tab[]>();

    if (this.productTabsLoaded(productId)) {
      // Get product
      let product: Product = this.getEntity<Product>(productId);
      // Set the tabs to data.
      response.data = product.tabs.toArray().map(x => this.getEntity<Tab>(x));
      subject.nextValue(response);
    }
    else {
      // Load the product data. It mostly happens when user opens the direct configurator url.
      this.fetchProductTabs(productId).subscribe(res => {

        response.data = res.data.tabs.toArray().map(x => this.getEntity<Tab>(x));
        subject.nextValue(response);
      });
    }

    return subject;
  }

  public getLoadedTabs(productId: number): Tab[] {
    let tabs: Tab[] = [];
    let product: Product = this.getEntity<Product>(productId)
    product.tabs.forEach(tabId => { tabs.push(this.getEntity<Tab>(tabId)); });

    return tabs;
  }

  public productTabsLoaded(productId: number): boolean {
    // Get product
    let product: Product = this.getEntity<Product>(productId);
    if (!product)
      return false;

    if (product.tabs.isEmpty())
      return true;

    // We only need to check the first tab.
    return this.getProductData().entities.has(product.tabs.get(0));
  }


  /**
   * Fetches products without tabs.
   * @param productId
   */
  fetchProduct(productId: number): ManagedSubject<StoreResponse<Product>> {
    let model = this.createProductRequestModel();
    model.product = this.modelFactory.createRequestOrCommand<ProductCommand>(ProductCommand.name, { ids: [productId] });
    let action = this.productActionCreator.dispatchFetchProducts(model);

    // Create request object
    let response: StoreResponse<Product> = new StoreResponse<Product>();

    return this.createAction(action, () => {
      response.data = this.getEntity<Product>(productId);
      return response;
    });
  }

  /**
   * Fetches child products without tabs.
   * @param product
   */
  fetchChildProducts(product: Product): ManagedSubject<StoreResponse<Product>> {
    let model = this.createProductRequestModel();
    model.product = this.modelFactory.createRequestOrCommand<ProductCommand>(ProductCommand.name, { ids: product.children.toArray() });
    let action = this.productActionCreator.dispatchFetchProducts(model); 
    
    // Create request object
    let response: StoreResponse<Product> = new StoreResponse<Product>();

    return this.createAction(action, () => {
      response.data = this.getEntity<Product>(product.longId);
      return response;
    });
  }


  /**
   * Fetches multiple products data.
   * @param product
   */
  fetchProductsWidthData(productIds: Array<number>): ManagedSubject<StoreResponse<Array<Product>>> {

    let model = this.createProductRequestModel();
    model.product = this.modelFactory.createRequestOrCommand<ProductCommand>(ProductCommand.name, { ids: productIds });
    model.product.tab = this.modelFactory.createAny<TabOptions>(TabOptions.name, {});
    model.product.tab.visualObject = this.modelFactory.createAny<VisualObjectOptions>(VisualObjectOptions.name, {});  

    let action = this.productActionCreator.dispatchFetchProducts(model);  

    // Create request object
    let response: StoreResponse<Array<Product>> = new StoreResponse<Array<Product>>();

    return this.createAction(action, () => {
      response.data = this.getObjects<Product>(productIds, Product);
      return response;
    });
  }


  fetchProductsWithoutData(productIds: Array<number>): ManagedSubject<StoreResponse<Array<Product>>> {

    let model = this.createProductRequestModel();
    model.assortment = this.modelFactory.createRequestOrCommand<AssortmentCommand>(AssortmentCommand.name, { ids: productIds, productLevel: 10 });
    
    let action = this.productActionCreator.dispatchFetchProducts(model);

    // Create request object
    let response: StoreResponse<Array<Product>> = new StoreResponse<Array<Product>>();

    return this.createAction(action, () => {
      response.data = this.getObjects<Product>(productIds, Product);
      return response;
    });

  }

  /**
   * Fetches product with tabs.
   * @param productId
   */
  fetchProductTabs(productId: number): ManagedSubject<StoreResponse<Product>> {

    let model = this.createProductRequestModel();
    model.product = this.modelFactory.createRequestOrCommand<ProductCommand>(ProductCommand.name, { ids: [productId] });
    model.product.tab = this.modelFactory.createAny<TabOptions>(TabOptions.name, {});
    model.product.tab.visualObject = this.modelFactory.createAny<VisualObjectOptions>(VisualObjectOptions.name, {});  

    let action = this.productActionCreator.dispatchFetchProductTabs(model);

    // Create request object
    let response: StoreResponse<Product> = new StoreResponse<Product>();

    return this.createAction(action, () => {
      response.data = this.getEntity<Product>(productId);
      return response;
    });
  }

  /**
   * Fetches all roots assortments.
   */
  getRootAssortments(): ManagedSubject<StoreResponse<Assortment[]>> {

    // Create request object
    let response: StoreResponse<Assortment[]> = new StoreResponse<Assortment[]>();

    // Assortments are loaded ?
    if (this.getProductData().rootAssortments.size > 0) {
      response.data = this.getObjectsFromResultId<Assortment>(this.getProductData().rootAssortments);
      return this.valueSubscriptionManager.createSubject(response, true);
    }

    let model = this.createProductRequestModel();    
    model.assortment = this.modelFactory.createRequestOrCommand<AssortmentCommand>(AssortmentCommand.name, { isRoot: true, productLevel: 1, productFamilyLevel: 10 });
    
    let action = this.productActionCreator.dispatchRootAssortments(model);

    return this.createAction(action, () => {
      response.data = this.getObjectsFromResultId<Assortment>(this.getProductData().rootAssortments);
      return response;
    });
  }

  /**
   * Assortments by parentId.
   * @param parentId
   */
  getAssortments(parentId: number): ManagedSubject<StoreResponse<Assortment[]>> {
    // Create request object
    let response: StoreResponse<Assortment[]> = new StoreResponse<Assortment[]>();

    // Retreive parent assortment from store.
    let parentAssortment: Assortment = this.appStore.getState().productData.entities.get(+parentId) as Assortment;

    // Make direct return If store contains the children information.
    if (parentAssortment && parentAssortment.children.size > 0) {
      response.data = this.getObjectsFromResultId<Assortment>(parentAssortment.children);
      return this.valueSubscriptionManager.createSubject(response, true);
    }

    let model = this.createProductRequestModel();    
    model.assortment = this.modelFactory.createRequestOrCommand<AssortmentCommand>(AssortmentCommand.name, { isRoot: true, productLevel: 1, productFamilyLevel: 10, ids: [parentId] });
    
    let action = this.productActionCreator.dispatchAssortments(model);

    return this.createAction(action, () => {
      response.data = this.getObjectsFromResultId<Assortment>(parentAssortment.children);
      // TODO: Needed ?
      response.requestId = action.id;
      return response;
    });
  }

  hasCompositeStructure(rootProductId: number): boolean {
    let product: Product = this.getEntity<Product>(rootProductId);    
    return product.children.size > 0 && !product.showChildrenInAccordionTabs;
  }

}