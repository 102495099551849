import { Input, ViewChild, ElementRef, Component } from "@angular/core";
import { Subscription } from "rxjs";
import { Conf, ConfValue, Param, ConfValueErrorMessage, ConfUIItem } from "../../shared/models";
import { BaseEntity } from "../../shared/baseEntity";
import { ConfEventService, ConfEvent } from "../providers/confEventService";
import { InputViewModel, ValueChangeEventArgs, RestrictValueChangeEventArgs, PopupService } from "../../../shared/components";
import { UIEvent, UIEventService } from "../providers/uiEventService";
import { ConfigurationSessionManager } from "../providers/configurationSessionManager";
import { VisualObjectComponent } from "./visualObjectComponent";
import { ManagedSubscription } from "../../../shared/managedSubscription";
import { ParameterMandatoryService } from "../parameters/shared/parameterMandatoryService";
import { ConfiguratorStore, ConfPageSessionService, ConfMessageProvider } from "../providers";
import { AppState } from "../../shared/state";
import { ChangeDetectorRef } from "@angular/core";
import { VisualObjectHelper } from "../parameters/shared";

@Component({ template: '' })
export abstract class ParamComponent<T extends Param> extends VisualObjectComponent {

  public confChangeSubscription: ManagedSubscription;
  public errorSubscription: ManagedSubscription;
  public highlightChangeSubscription: Subscription;
  viewModel: InputViewModel;
  
  @Input()
  public parameter: T;

  constructor(public confStore: ConfiguratorStore,
    public confPageSessionService: ConfPageSessionService,
    public popupService: PopupService,
    public visualObjectHelper: VisualObjectHelper,
    public parameterMandatoryService: ParameterMandatoryService,
    public messagesProvider: ConfMessageProvider,
    public cd: ChangeDetectorRef
  ) {
    super(confStore, confPageSessionService, visualObjectHelper);
  }

  public listenChanges(): void {
    // Unsubscribe the old subscription.
    this.unsubscribe();

    this.confStore.onConfigurationValueChange(this.configurationId, this.confPageSessionService.confSessionId, this.paramId, (confValue: ConfValue): void => {
      this.setValue(confValue);

      // Might mlso need to highlight
      this.setHighlight(this.shouldHighlight());

    },
      (): any => this.getCurrentValue()
    ).unsubscribeOn(this.unsubscribeSubject);

    this.highlightChangeSubscription = this.parameterMandatoryService.getMessage().subscribe(() => {
      // Check if mandatory highlight
      this.setHighlight(this.shouldHighlight());
    });

    this.errorSubscription = this.messagesProvider.onMessagesRequest<ConfValueErrorMessage>(this.confSessionId, ConfValueErrorMessage.name, (x) => {
      // Flatten my error messages to a single array 
      let errorMessages = Array<string>();

      if (x)
        x.forEach(msg => {
          if (msg.confValueId === this.paramId) {
            errorMessages = errorMessages.concat(msg.message);
          }
        });

      this.setError(errorMessages);
    }, false);
  }

  onValueChange($event: ValueChangeEventArgs): void {
    this.setError([]);
  }

  onReadOnly($event: RestrictValueChangeEventArgs): void {
  }

  onDisallowed($event: RestrictValueChangeEventArgs): void {
  }

  onHelpClick($event): void {

    if (this.viewModel.enableExternalLink)
      return;

    this.popupService.open<number>("help-popup", this.parameter.longId);

  }

  /**
   *  Toggles highlighing for this parameter.
   * @param highlight
   */
  public setHighlight(highlight: boolean): void {
    this.viewModel.highlight = highlight;
    this.cd.markForCheck();
  }


  /**
   *  Returns the mandatory highlighting for this parameter.
   */
  public shouldHighlight(): boolean {
    return this.parameterMandatoryService.shouldHighlight(this.configurationId, this.paramId);
  }

  public setValue(confValue: BaseEntity): void {
    this.setError([]);
  }

  /**
     Returns the parameter Id. It can be overriden for param values.
  */
  public get paramId(): number {
    return this.parameter.visualObjectId;
  }

  public abstract getCurrentValue(): any;

  public abstract setError(errors: Array<string>): void;

  ngOnDestroy() {
    this.unsubscribe();
    super.ngOnDestroy();
  }

  unsubscribe() {
    if (this.confChangeSubscription)
      this.confChangeSubscription.unsubscribe();

    if (this.highlightChangeSubscription)
      this.highlightChangeSubscription.unsubscribe();

    if (this.errorSubscription)
      this.errorSubscription.unsubscribe();
  }
}