import { Inject, Component, ChangeDetectorRef } from "@angular/core";
import { RuleConfirmationMessage } from "../../../shared/models/responses/messages";
import { RuleInfo, ParamInfo } from "../../../shared/models/responses";
import { RuleActionCreator } from "./ruleActionCreator";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { ConfPageSessionService, PopupIdentifiers } from "../../providers";
import { ConfDataRequest, RuleCommand, ConfResponseFormat, RequestViews } from "../../../shared/models";
import { ModelFactory } from "../../../shared/providers";
import { PopupService } from "../../../../shared/components";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { ImageSets } from "../../../../shared/utils";

@Component({
  selector: 'rule-confirmation-popup',
  templateUrl: './ruleConfirmationPopupComponent.html'
})
export class RuleConfirmationPopupComponent extends AbstractPopupComponent {

  public message: RuleConfirmationMessage;

  public id: string = PopupIdentifiers.RuleConfirm;

  public imageSet: string;

  constructor(
    @Inject(RuleActionCreator) public ruleActionCreator: RuleActionCreator,
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    public globalDataStore: GlobalDataStore,
    public popupService: PopupService,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr, popupService);
  }

  ngOnInit() {
    this.imageSet = this.getImageSet(ImageSets.PopupHeader);
    super.ngOnInit();
  }

  public open(tag: any) {
    this.show(tag);
  }

  show(message: RuleConfirmationMessage): void {
    this.message = message;
    this.showInternal();
  }

  getParameterIcon(pInfo: ParamInfo): string {
    return pInfo.parameterType.toLowerCase();
  }

  getRuleIcon(ruleInfo: RuleInfo): string {
    return ruleInfo.ruleType.toLowerCase();
  }

  okButtonClicked() {
    this.ruleActionCreator.dispatchConfirmRuleExecution(this.createRuleExecutionModel(true));
  }

  cancelButtonClicked() {
    this.ruleActionCreator.dispatchConfirmRuleExecution(this.createRuleExecutionModel(false));
  }

  public createRuleExecutionModel(confirmation: boolean) {
    let model = this.modelFactory.createAny(ConfDataRequest.name) as ConfDataRequest;
    model.client = RequestViews.Editor;
    model.confSessionId = this.confPageSession.confSessionId;
    model.rule = this.modelFactory.createRequestOrCommand<RuleCommand>(RuleCommand.name, { ruleId: this.message.ruleInfo.longId, configurationId: this.message.configurationId, confirmation: confirmation });
    model.rule.responseFormat = this.globalDataStore.getConfResponseFormat(RequestViews.Editor);
    return model;
  }
}