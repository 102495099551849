import { Inject, Injectable } from "@angular/core"
import { normalize } from "normalizr";
import * as Immutable from "immutable"

import { BaseEntity } from "../../baseEntity"
import { AbstractConfigurationMessage } from "../../models/responses/messages"
import { ModelFactory } from "../modelFactory"
import { Ioc, Register } from "../../../../shared/ioc/iocdecorator"
import { ConfDataResponse } from "../../models/responses"
import { ConfInfoResult } from "../../models/responses"
import { ConfDataSchema } from "./confDataSchema"
import { NormalizerService } from "../../../../shared/providers/normalizer"
import { ConfInfo, UIElement, ConfFormValue } from "../../models";
import { Extension } from "typescript";
import { forEach } from "core-js/fn/array";

@Injectable()
export class ConfDataNormalizer {

  constructor(
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(ConfDataSchema) public configuratorSchema: ConfDataSchema,
    @Inject(NormalizerService) public normalizerService: NormalizerService
  ) { }

  normalizeJson(json: any): ConfDataResponse {

    let confResponse = new ConfDataResponse();
    let confInfoResult = new ConfInfoResult();

    confResponse = confResponse.setConfSessionId(json.confSessionId);
    confResponse = confResponse.setRootConfId(json.rootConfId);
    confResponse = confResponse.setIsClosed(json.isClosed);
    confResponse = confResponse.setReferralLink(json.referralLink);
    confResponse = confResponse.setHasUnsavedChanges(json.hasUnsavedChanges);
    confResponse = confResponse.setConfigurationIds(Immutable.List<number>());

    if (json.configurations && json.configurations.length > 0) {

      confResponse = confResponse.setConfigurationIds(Immutable.List<number>());
      let entities = Immutable.Map<number, Immutable.Map<number, BaseEntity>>();

      for (let i = 0; i < json.configurations.length; i++) {

        let jsonArray = new Array<Object>();
        jsonArray.push(json.configurations[i]);

        let normalizeOutput = this.normalizerService.normalizeJson(jsonArray, this.configuratorSchema.configurationSchemaArray, (className, data) => this.modelFactory.createEntity(className, data));
        confResponse = confResponse.setConfigurationIds(confResponse.configurationIds.push(normalizeOutput.resultIds[0]));

        let localEntities = Immutable.Map<number, BaseEntity>(normalizeOutput.entities);
        entities = entities.set(normalizeOutput.resultIds[0], localEntities);

        let uiElements = Immutable.Map<number, UIElement>();

        normalizeOutput.entities.forEach((value, key) => {

          if (value instanceof UIElement) {

            uiElements = uiElements.set(key, value);
          }

        });

        if (uiElements.count() > 0)
          confResponse = confResponse.setUiElements(uiElements);
      }

      confResponse = confResponse.setEntities(entities);
    }
    
    if (json.confInfos && json.confInfos.length > 0) {
      let normalizeOutput = this.normalizerService.normalizeJson(json.confInfos, this.configuratorSchema.confInfoSchemaArray, (className, data) => this.modelFactory.createEntity(className, data));
      confInfoResult = confInfoResult.setConfInfoIds(Immutable.List<number>(normalizeOutput.resultIds));
      confInfoResult = confInfoResult.setEntities(Immutable.Map<number, ConfInfo>(normalizeOutput.entities as Map<number, ConfInfo>));
    }

    if (json.messages && json.messages.length > 0) {
        confResponse = confResponse.setMessages(this.modelFactory.createArray<AbstractConfigurationMessage>(json.messages));
    }
    
    confResponse = confResponse.setConfInfos(confInfoResult);

    return confResponse;
  }

  mergeEntities(existingEntities: Map<number, BaseEntity>, newEntities: Map<number, BaseEntity>)
  {
      newEntities.forEach((newEntity: BaseEntity, key: number) => {
          existingEntities.set(key, newEntity);
      });
  }

}