import { AbstractAccountDataCommand } from "./abstractAccountDataCommand";

export class LoginCommand extends AbstractAccountDataCommand {
  username: string;
  password: string;
  userType: string;
  languageTitle: string;
  languageCode: string;
  recaptcha: string;
  logOutOtherUsers: boolean;
}