import * as Immutable from "immutable";
import { UIElement } from "./uiElement";
import { KeyValue } from "../responses/keyValue";

export class UISelect extends UIElement {
  protected readonly VALUE = "value";
  protected readonly TITLE_BY_VALUE = "titleByValue";
  protected readonly IS_RADIO_BUTTON = "isRadioButton";
  protected readonly READONLY = "readonly";

  get value(): string { return this.getInternalValue<string>(this.VALUE); }
  setValue(value: string): this { return this.setInternalValue(this.VALUE, value); }

  get titleByValue(): Immutable.List<KeyValue<string, string>> { return this.getInternalValue<Immutable.List<KeyValue<string, string>>>(this.TITLE_BY_VALUE); }
  setTitleByValue(titleByValue: Immutable.List<KeyValue<string, string>>): this { return this.setInternalValue(this.TITLE_BY_VALUE, titleByValue); }

  get isRadioButton(): boolean { return this.getInternalValue<boolean>(this.IS_RADIO_BUTTON); }
  setIsRadioButton(isRadioButton: boolean): this { return this.setInternalValue(this.IS_RADIO_BUTTON, isRadioButton); }

  get readonly(): boolean { return this.getInternalValue<boolean>(this.READONLY); }
  setReadonly(readonly: boolean): this { return this.setInternalValue(this.READONLY, readonly); }
}