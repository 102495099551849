<div class="system-informations">

  <h1 class="text-uppercase start-header">{{strings.SystemInformation}}</h1>
  <div class="contents-wrapper">
    <div *ngFor="let info of systemInformations" class="contents" role="button" (click)="showSystemInfoDetail(info)">
      <div class="system-info">

        <!-- Move title template to separate component -->
        <div class="row border-box ml-0">
          <div class="col pl-0">
            <h4 class="text-uppercase">{{info.title}}</h4>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col-auto px-1"><co-image width="40" height="40" imageSet="{{imageSet}}" type="svg" key="bell"></co-image></div>
          <div class="col description px-3" [innerHtml]="info.message | safeHtml"></div>
        </div>
        <div class="row published pt-1">
          <div class="col">
            <span class="title">{{strings.PublishedDate}}:</span><span class="value pl-2">{{info.publishedDate}}</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<message-box class="sytem-information-popup" #systemInformationPopup [height]="'500px'" [width]="'500px'" [title]="strings.SystemInformation" [showBodyContents]="true" [okButtonText]="" [cancelButton]="false" [showCloseButton]="false"></message-box>