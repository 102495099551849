<div #visualObjectContainer>
  <div class="visual-object-margin form-decoration decoration" [class.border-style]="!showTitle" [style.max-width.%]="'100'">

    <gridview>
      <gridview-header *ngIf="showTitle && !!this.confFormValue?.title" classes="decoration-header">
        <header-columntemplate size="auto" classes="header-image">
          <co-image width="24" height="24" type="svg" key="formdecoration" [imageSet]="imageSet"></co-image>
        </header-columntemplate>
        <header-columntemplate classes="header-title">
          {{decoration.title}}
        </header-columntemplate>
      </gridview-header>

      <div *ngIf="uiElementId" [ngClass]="{'pb-2': showEditorMargin, 'py-2': showSummaryMargin, 'pl-4': showSummaryMargin}">
        <ui-element (action)="onAction($event)" [uiElementId]="uiElementId" [sessionId]="confSessionId" [elementStore]="confStore"></ui-element>
      </div>

    </gridview>

  </div>
</div>