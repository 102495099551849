import * as Immutable from "immutable";

import { BaseEntity } from "../../baseEntity";
import { ConfFormControlValue } from "./confFormControlValue";
import { UIElement } from ".";

export class ConfFormValue extends BaseEntity {

  protected readonly CONTAINER_STYLE = "containerStyle";
  protected readonly TITLE = "title";
  protected readonly WIDTH = "width";
  protected readonly UI_ELEMENTS = "uiElements";

  get containerStyle(): string { return this.getInternalValue<string>(this.CONTAINER_STYLE); }
  setContainerStyle(containerStyle: string): this { return this.setInternalValue(this.CONTAINER_STYLE, containerStyle); }

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get width(): number { return this.getInternalValue<number>(this.WIDTH); }
  setWidth(width: number): this { return this.setInternalValue(this.WIDTH, width); }

  get uiElements(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.UI_ELEMENTS); }
  setUiElements(uiElements: Immutable.List<number>): this { return this.setInternalValue(this.UI_ELEMENTS, uiElements); }
}