<div [class.highlight]="view.highlight">
  <line-dropdown 
                 *ngIf="itemsView != null" 
                 [view]="view"
                 [disabled]="view.disabled" 
                 [menuHeight]="menuHeight"  
                 [itemsView]="itemsView"
                 [selectedViewId]="selectedViewId"
                 >
    <ng-content above-container select="[above-container]"></ng-content>
  </line-dropdown>

  <!--TODO implement standard-->
  <!--<standard-dropdown *ngIf="1 == 2 && itemsView != null" [itemsView]="itemsView">
    <ng-content></ng-content>
  </standard-dropdown>-->
</div>