import * as Immutable from "immutable";
import { UIAction } from "./uiAction";

export class UIPopup extends UIAction {

  protected readonly HEADER = "header";
  protected readonly ICON = "icon";
  protected readonly MODAL = "modal";
  protected readonly WIDTH = "width";
  protected readonly HEIGHT = "height";
  protected readonly UI_ELEMENTS = "uiElements";
  protected readonly LEAD_PARAGRAPH = "leadParagraph";
  protected readonly PARAGRAPH = "paragraph";
  protected readonly CSS_CLASS = "cssClass";

  get header(): string { return this.getInternalValue<string>(this.HEADER); }
  setHeader(header: string): this { return this.setInternalValue(this.HEADER, header); }

  get icon(): string { return this.getInternalValue<string>(this.ICON); }
  setIcon(icon: string): this { return this.setInternalValue(this.ICON, icon); }

  get modal(): boolean { return this.getInternalValue<boolean>(this.MODAL); }
  setModal(modal: boolean): this { return this.setInternalValue(this.MODAL, modal); }

  get width(): number { return this.getInternalValue<number>(this.WIDTH); }
  setWidth(width: number): this { return this.setInternalValue(this.WIDTH, width); }

  get height(): number { return this.getInternalValue<number>(this.HEIGHT); }
  setHeight(height: number): this { return this.setInternalValue(this.HEIGHT, height); }

  get uiElements(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.UI_ELEMENTS); }
  setUiElements(uiElements: Immutable.List<number>): this { return this.setInternalValue(this.UI_ELEMENTS, uiElements); }

  get leadParagraph(): string { return this.getInternalValue<string>(this.LEAD_PARAGRAPH); }
  setLeadParagraph(leadParagraph: string): this { return this.setInternalValue(this.LEAD_PARAGRAPH, leadParagraph); }

  get paragraph(): string { return this.getInternalValue<string>(this.PARAGRAPH); }
  setParagraph(paragraph: string): this { return this.setInternalValue(this.PARAGRAPH, paragraph); }

  get cssClass(): string { return this.getInternalValue<string>(this.CSS_CLASS); }
  setCssClass(cssClass: string): this { return this.setInternalValue(this.CSS_CLASS, cssClass); }
}