import { Component } from '@angular/core';
import { BaseComponent } from "../../../shared";


@Component({
  selector: 'product-button',
  templateUrl: './productButtonComponent.html'
})
export class ProductButtonComponent extends BaseComponent {

  public imageSet: string = "primary";
  public imageSetGreen: string = "green";

}