import { Inject, Injectable } from "@angular/core";
import { GlobalDataStore } from "../../../../../../core/pages/shared/providers/globalData";
import { NodeData, TreeDataProvider } from "../../../../../shared/components/treeView";
import { NodeActions, NodeEvent } from "../../../../../shared/components/treeView/treeEvents";
import { ManagedSubscription } from "../../../../../shared/managedSubscription";
import { ConfInfo } from "../../../../shared/models";
import { RouteNames, ValueSubscriptionManager } from "../../../../shared/providers";
import { PageStore } from "../../../../shared/providers/page/pageStore";
import { ConfiguratorStore, ConfiguratorUIStore, ConfPageSessionService } from "../../../providers";

@Injectable()
export class CompositeTreeDataProvider extends TreeDataProvider {

  public structureChangeSubscription: ManagedSubscription;
  public routeChangeSubscription: ManagedSubscription;
  public orderChangeSubscription: ManagedSubscription;
  public confInfoChangeSubscriptions: Array<ManagedSubscription> = [];

  constructor(
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService,
    @Inject(PageStore) public pageStore: PageStore,
    @Inject(ValueSubscriptionManager) public valueSubscriptionManager: ValueSubscriptionManager
  ) {
    super();
  }

  /**
   * Loads the root nodes.
   * @param callback
   */
  loadRoots(callback: (nodeData: NodeData[]) => void): void {
    // Unload all configuration changes before making a new tree.
    this.unsubscribeConfChanges();

    let roots: NodeData[] = [];
    let confInfo = this.confStore.getRootConfInfo(this.confPageSession.confSessionId);
    // Create node data.
    let node: NodeData = this.setupNode(confInfo);
    roots.push(node);

    // Subscribe for Root configuration changes
    this.subscribeConfInfoChanges(confInfo.longId);
    
    // Call back to tree component.
    callback(roots);
  }

  /**
   * Loads the children.
   * @param parent
   * @param callback
   */
  loadChildren(parent: NodeData, callback: (nodeData: NodeData[]) => void): void {
    let children: NodeData[] = [];    
    this.confStore.getChildConfInfos(parent.id, this.confPageSession.confSessionId).forEach(confInfo => {
      let node = this.setupNode(confInfo);
      children.push(node);

      // Subscribe each configuration change 
      this.subscribeConfInfoChanges(confInfo.longId);
    });

    callback(children);
  }

  /**
   * Sets up the tree nodes. 
   * @param info
   * @param parentId
   */
  public setupNode(info: ConfInfo) {

    let hasVaultIcon: boolean = info.iconRelativeUrl && info.iconRelativeUrl != "";

    // Note! non-effective is an empty selector which would help us to customize the non-effective node if needed.    
    let styles: string = info.isEffectiveProduct ? '' : 'non-effective';
   
    let nodeData: NodeData = this.createNode(
      info.longId,
      info.text,
      hasVaultIcon ? info.iconRelativeUrl : 'configuration',
      hasVaultIcon,
      info.parentId,
      info.rootOrder,
      info.allowedChildProductIds.size > 0,
      styles);    

    return nodeData;
  }

  /**
   * Should the node be expanded?
   * @param node
   */
  isNodeExpandable(node: NodeData): boolean {
    let info: ConfInfo = this.confStore.getConfInfo(node.id, this.confPageSession.confSessionId);

    if (info) {
      return info.children.count() > 0;
    }

    return false;
  }

  selectNodeById(id: number): void {    

    let event = <NodeEvent>{ node: this.get(id) };
    this.selectNode(event);    
  }

  /**
   * Activates the menu for tree node.
   * @param node
   */
  menuSupport(node: NodeData): boolean {
    // Only show the menu options for configurator.
    return this.pageStore.activeRouteName == RouteNames.Editor;
  }
  
  /**
 * Releases all the resources.
 */
  onDestroy() {
    // Unsubcribe for structure change
    if (this.structureChangeSubscription)
      this.structureChangeSubscription.unsubscribe();

    if (this.routeChangeSubscription)
      this.routeChangeSubscription.unsubscribe();

    if (this.orderChangeSubscription)
      this.orderChangeSubscription.unsubscribe();

    // Unsubscribe the changes for each configuration.
    this.unsubscribeConfChanges();
  }

  unsubscribeConfChanges(): void {
    while (this.confInfoChangeSubscriptions.length > 0) {
      let subscription = this.confInfoChangeSubscriptions.pop();
      subscription.unsubscribe();
    }
  }

  public subscribeConfInfoChanges(confId: number): void {
    // Subscription for data change e.g title
    this.confInfoChangeSubscriptions.push(this.confStore.onConfInfoChange(confId, this.confPageSession.confSessionId, (conf: ConfInfo): void => {

      // Send the message to update the node.
      this.setupNode(conf);
      let event = <NodeEvent>{ action: NodeActions.DataChanged, node: this.get(conf.longId) };
      this.sendMessage(event);

    }));
  }

  /**
   * Subscribes the composite size changes. 
   */
  subscribeSizeAndViewChange(): void {
  
    // Subscription for composite structure change.
    this.structureChangeSubscription = this.confStore.onCompositeStructureChange(this.confPageSession.confSessionId, (): void => {

      let event = <NodeEvent>{ action: NodeActions.TreeRefresh };

      // Send the message to refresh the tree.
      this.sendMessage(event);      
    });

    // Listen the store changes when active route updated there.
    this.routeChangeSubscription = this.pageStore.onActiveRouteNameChange((view: string): void => {
      if (!view)
        return;

      let event = <NodeEvent>{ action: NodeActions.TreeRefresh };

      // Send the message to refresh the tree.
      this.sendMessage(event);
    });

    // TODO: Need more investigation, sometimes confId is not the activeConfigurationId.
    this.orderChangeSubscription = this.confStore.onCompositeOrderChange(this.confPageSession.confSessionId, (): void => {

      let event = <NodeEvent>{ action: NodeActions.TreeRefresh };

      // Send the message to refresh the tree.
      this.sendMessage(event);
    });
  }

  public get childNodeIndentation(): number {
    return 28;
  }

  get showExpandCollapseIcon(): boolean {
    return this.globalDataStore.getGlobalData().globalSettings.showExpandCollapseIconsComposite;
  }

  get highlightSelectedNode(): boolean { return this.globalDataStore.getGlobalData().globalSettings.highlightSelectedConfiguration; }


  public get expandBehaviour(): number { return this.globalDataStore.getGlobalData().globalSettings.treeExpandBehaviour; }

}