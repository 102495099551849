// These imageSets values are same as sass imagesets
export enum ImageSets {
  StartConfiguration = '--start-configuration-image-set',
  StartConfigurationContextMenu = "--start-configuration-context-menu-image-set",
  MainMenu = '--main-menu-image-set',
  Control = '--control-image-set',
  ControlImageOptionChecked = "--control-image-option-checked-image-set",
  ControlIndicatorChecked = "--control-indicator-checked-image-set",
  ControlLock = "--control-lock-image-set",
  ConfiguratorDataSelectorPopupHeader = "--configurator-data-selector-popup-header-image-set",
  ConfiguratorDecoration = "--configurator-decoration-image-set",
  ConfiguratorDecorationGraphicsControl = "--configurator-decoration-graphics-control-image-set",
  ConfiguratorDecorationHeader = "--configurator-decoration-header-image-set",
  ConfiguratorBomActions = "--configurator-bom-actions-image-set",
  ConfiguratorBomPopupHeader = "--configurator-bom-popup-header-image-set",
  ConfiguratorTabNormal = "--configurator-tab-normal-image-set",
  Popup = "--popup-image-set",
  PopupHeader = "--popup-header-image-set",
  SummaryDetailHeader = "--summary-detail-header-image-set",
  SummaryDetailIcon = "--summary-detail-icon-image-set",
  SummaryActionDetail = "--summary-action-detail-image-set",
  SummaryActionHeader = "--summary-action-header-image-set",
  ConfiguratorTree = "--configurator-tree-image-set",
  SelectorTree = "--selector-tree-image-set",
  SidebarOpener = "--sidebar-opener-image-set",
  Help = "--control-help-image-set",
}