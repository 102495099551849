<div class="table-wrapper">
  <table class="table bom-table useBorder useHover text-center w-100">
    <thead class="input-font-style text-center">
      <tr>
        <th *ngFor="let column of columns"
            title="{{column.contents}}"
            class="{{column.classes}}"
            [ngStyle]="column.safeStyle">
          {{column.contents}}
        </th>
      </tr>
    </thead>
    <tbody>

      <tr *ngFor="let row of rows"
          [class]="row.classes + (row === selectedRow ? ' selected' : '')"
          (click)="selectRow(row)">
        <td *ngFor="let cell of row.cells" class="{{cell.classes}}" [class.selected]="cell.selected" [class.width]="cell.size" title="{{cell.contents}}" [ngStyle]="cell.safeStyle">
          <div *ngIf="cell.id == 'Icon' && cell.contents" class="w-100 h-100 text-center" [id]="cell.cellId" role="button" (click)="showImageRequest($event, cell)">
            <co-image width="24" height="24" type="svg" [key]="'show'" imageSet="primary"></co-image>
          </div>
          <span *ngIf="cell.id != 'Icon'" [innerHtml]="cell.contents | safeHtml" class="ellipsis-wrap">
          </span>
        </td>
      </tr>


    </tbody>
  </table>
  <div *ngIf="grid.warningMessage" class="grid-warning-message">{{grid.warningMessage}}</div>
</div>