import { Inject, Component, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { PopupService, InputViewModel } from "../../../../shared/components";
import { PopupIdentifiers, ConfPageSessionService } from "../../providers";
import { PageActionCreator } from "../../../shared/providers/page";
import { GlobalDataStore } from "../../../shared/providers/globalData/globalDataStore";
import { ContactField } from "../../../shared/models/responses/contactField";
import { List } from "immutable";
import { KeyValue } from "../../../shared/models/responses/keyValue";

@Component({
  selector: 'anonymous-contact-popup',
  templateUrl: './anonymousContactPopupComponent.html'
})
export class AnonymousContactPopupComponent extends AbstractPopupComponent {

  public id: string = PopupIdentifiers.AnonymousContact;

  public viewsSorted: Array<Array<InputViewModel>>;
  public views: Array<InputViewModel>;
  public errorsByView: Map<string, string[]> = new Map<string, string[]>();
  public fields: Array<ContactField> = Array<ContactField>(); 

  public title: string;
  public okButtonText: string;

  public weekNumbersEnabled: boolean = false;
  public dateFormat: string;

  @Output()
  onClosed = new EventEmitter();

  constructor(
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService,
    @Inject(PageActionCreator) public pageActionCreator: PageActionCreator,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,

    public cdr: ChangeDetectorRef
  ) {
    super(cdr, popupService);
  }

  public open(tag: Array<ContactField>) {
    this.fields = tag;

    this.show();
  }

  ngOnInit() {
    this.weekNumbersEnabled = this.uiSettings.datepicker.showWeekNumbers;
    this.dateFormat = this.globalDataStore.globalSettings.dateFormat;

    super.ngOnInit();
  }

  show(): void {

    let formLabelField = this.fields.find(x => x.dataType == "FormLabel");
    this.title = formLabelField?.title;
    this.okButtonText = this.strings.SendEmail;

    // Populate the views
    this.views = Array<InputViewModel>();
    this.viewsSorted = Array<Array<InputViewModel>>();

    let viewList = Array<InputViewModel>();
    this.viewsSorted.push(viewList);

    let i = 0;

    this.fields.forEach(field => {
      if (!field.isField)
        return;

      let viewModel = new InputViewModel(field.title);
      viewModel.tag = field;
      viewModel.id = i++ + "_" + field.title;

      // Create new row if viewList has two elements
      if (viewList.length > 1 || viewModel.tag.dataType == 'Multiline' || viewList.length > 0 && viewList[0].tag.dataType == 'Multiline') {
        viewList = Array<InputViewModel>();
        this.viewsSorted.push(viewList);
      }
      viewList.push(viewModel);

      this.views.push(viewModel);
      this.errorsByView.set(viewModel.id, Array<string>());
    });
   
    this.showInternal();
  }

  okClick() {

    if (!this.validateInfo())
      return;

    let keyValues: KeyValue<string, string>[] = [];

    let toEmailField = this.fields.find(x => x.dataType == "ToEmail");
    keyValues.push({ key: toEmailField.dataType, value: toEmailField.title });

    keyValues.push({ key: "FormLabel", value: this.title });

    for (let i = 0; i < this.views.length; i++)
      keyValues.push({ key: "_" + this.views[i].title, value: this.views[i].value });

    this.onClosed.emit({ fields: keyValues });
  }

  public validateInfo(): boolean {
    // Perform some validation on client side. Will also be done on server side.

    let isValidForm: boolean = true;

    this.views.forEach(view => {
      if ((view.tag as ContactField).mandatory && !view.value) {
        this.errorsByView.set(view.id, [this.strings.MandatoryFieldNotSet]);
        isValidForm = false;
      }
      else {
        this.errorsByView.set(view.id, []);
      }

      if ((view.tag as ContactField).dataType == "Email" && !view.isValid) {
        this.errorsByView.set(view.id, [this.strings.InvalidEmailAddress]);
        isValidForm = false;
      }

      if ((view.tag as ContactField).dataType == "Date" && !view.isValid) {
        this.errorsByView.set(view.id, [this.strings.NotAValidDate]);
        isValidForm = false;
      }
    });

    return isValidForm;
  }
}