import { Injectable, Inject } from '@angular/core';
import { HttpEventType, HttpResponse } from "@angular/common/http";

import { AppStore, AccountDataActions, AppAsyncAction, HttpAction, ActionStatus, AppAction, RequestStatus } from "../../state";
import { AccountDataController } from "../../providers/accountData/accountDataController";
import { AccountDataRequest, ApiResponse, AccountDataResponse, AttachmentCommand, ConfDataResponse, ConfDataRequest } from "../../models";
import { AttachmentsDataController } from "./attachmentsDataController";
import { BaseActionCreator } from '../../baseActionCreator';

@Injectable()
export class AttachmentsDataActionCreator extends BaseActionCreator {

  static CONF_ATTACHMENT_DELETE = "@CONF_ATTACHMENT_DELETE";
  static CONF_ATTACHMENT_DELETE_RESULT = "@CONF_ATTACHMENT_DELETE_RESULT";
  static CONF_ATTACHMENT_UPLOAD = "@CONF_ATTACHMENT_UPLOAD";
  static CONF_ATTACHMENT_UPLOAD_RESULT = "@CONF_ATTACHMENT_UPLOAD_RESULT";

  constructor(
    @Inject(AppStore) protected appStore: AppStore,
    @Inject(AttachmentsDataController) protected attachmentsDataController: AttachmentsDataController
  ) {
    super(attachmentsDataController);
  }

  dispatchDeleteAttachment = (model: ConfDataRequest) => this.appStore.dispatch(this.deleteAttachment(model)) as HttpAction<ConfDataResponse>;
  deleteAttachment(model: ConfDataRequest): HttpAction<ConfDataResponse> {

    return this.createHttpAction<ConfDataResponse>(

      AttachmentsDataActionCreator.CONF_ATTACHMENT_DELETE,

      (dispatch, state, callback) => {

        this.attachmentsDataController.deleteAttachment(model).subscribe((confResponse) => {

          dispatch({ type: AttachmentsDataActionCreator.CONF_ATTACHMENT_DELETE_RESULT, payload: confResponse });

          // Update complete request status
          callback(<ActionStatus>{ completed: true, payload: confResponse });
        })
      }
    );
  }

  dispatchUploadFile = (model: ConfDataRequest, file: File, progressCallback, errorCallback?: (error) => void) => this.appStore.dispatch(this.uploadFile(model, file, progressCallback, errorCallback));
  uploadFile(model: ConfDataRequest, file: File, progressCallback, errorCallback?: (error) => void) {
    return this.createHttpAction<ConfDataResponse>(

      AttachmentsDataActionCreator.CONF_ATTACHMENT_UPLOAD,

      (dispatch, state, callback) => {

        this.attachmentsDataController.uploadFile(model, file).subscribe(event => {

          // Check if done, otherwise call progress callback.
          if (event instanceof ApiResponse) {

            progressCallback({ type: HttpEventType.Response });

            dispatch({ type: AttachmentsDataActionCreator.CONF_ATTACHMENT_UPLOAD_RESULT, payload: event });
            // Update complete request status            
            callback(new ActionStatus(true, event));
          }
          else {
            progressCallback(event);
          }
        },
          error => {
            if (errorCallback)
              errorCallback(error);

            callback(new ActionStatus(true, error, RequestStatus.ERROR));
          }
        );
      }
    );
  }

  public createHttpAction<T>(actionType: any, executeAsync: any, payload: T = null): HttpAction<T> {
    let httpAction: HttpAction<T> = {
      type: actionType,
      executeAsync: executeAsync,
      payload: payload
    }

    return httpAction;
  }
}