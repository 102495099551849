import { Injectable } from "@angular/core";

import { GlobalDataNormalizer } from "./globalData/globalDataNormalizer";
import { AccountDataNormalizer } from "./accountData/accountDataNormalizer";
import { ProductDataNormalizer } from "./productData/productDataNormalizer";
import { SearchDataNormalizer } from "./searchData/searchDataNormalizer";
import { ConfDataNormalizer } from "./configurationData/confDataNormalizer";
import { ModelFactory } from "./modelFactory";
import {
  ApiResponse, ConfDataResponse, ProductDataResponse, GlobalDataResponse,
  SearchDataResponse, AccountDataResponse, WebSettingsResponse, ApiException, ApiMessage, UISettings, UISettingsResponse, ServerPushMessage
} from "../models";
import { PushMessageNormalizer } from "./pushMessage/pushMessageNormalizer";

@Injectable()
export class ApiResponseBuilder {

  constructor(public globalDataNormalizer: GlobalDataNormalizer,
    public accountNormalizer: AccountDataNormalizer,
    public productDataNormalizer: ProductDataNormalizer,
    public searchDataNormalizer: SearchDataNormalizer,
    public confDataNormalizer: ConfDataNormalizer,
    public pushMessageNormalizer: PushMessageNormalizer,
    public modelFactory: ModelFactory) {
  }

  build(rawObject: any) {

    let apiResponse = this.modelFactory.createAny<ApiResponse>(ApiResponse.name);

    if (rawObject.data && rawObject.data.className) {

      let dataClassName = rawObject.data.className;
      if (dataClassName == ConfDataResponse.name)
        apiResponse = apiResponse.setData(this.confDataNormalizer.normalizeJson(rawObject.data));
      else if (dataClassName == ProductDataResponse.name)
        apiResponse = apiResponse.setData(this.productDataNormalizer.normalizeJson(rawObject.data));
      else if (dataClassName == SearchDataResponse.name)
        apiResponse = apiResponse.setData(this.searchDataNormalizer.normalizeJson(rawObject.data));
      else if (dataClassName == AccountDataResponse.name)
        apiResponse = apiResponse.setData(this.accountNormalizer.normalizeJson(rawObject.data));
      else if (dataClassName == GlobalDataResponse.name)
        apiResponse = apiResponse.setData(this.globalDataNormalizer.normalizeJson(rawObject.data));
      else if (dataClassName == WebSettingsResponse.name)
        apiResponse = apiResponse.setData(this.modelFactory.createAny<WebSettingsResponse>(WebSettingsResponse.name, rawObject.data));
      else if (dataClassName == ServerPushMessage.name)
        apiResponse = apiResponse.setData(this.pushMessageNormalizer.normalizeJson(rawObject.data));
      else if (dataClassName == UISettingsResponse.name) {
        let uiSettingsJson = JSON.parse(rawObject.data.settings);

        let classNameResolver = (currentObj: any, key: string): string => {

          if (!currentObj)
            return;

          if (currentObj.className)
            return currentObj.className;

          let className = key;

          if (className.length > 0) {

            if (className.charAt(0) == className.charAt(0).toUpperCase())
              throw `Class name '${className}' should be in camel case`;

            className = className.charAt(0).toUpperCase() + className.slice(1) + 'UISettings'; // Change first letter to uppercase.
          }
          
          return className;
        }

        let uiSettingsModel = this.modelFactory.createAny<UISettings>(UISettings.name, uiSettingsJson, classNameResolver);
        rawObject.data.settings = uiSettingsModel;
        apiResponse = apiResponse.setData(this.modelFactory.createAny<UISettingsResponse>(UISettingsResponse.name, rawObject.data));
      }
      else
        apiResponse = apiResponse.setData(this.modelFactory.createAny(dataClassName, rawObject.data));

      if (rawObject.exceptions) {
        apiResponse = apiResponse.setExceptions(this.modelFactory.createArray<ApiException>(rawObject.exceptions));
      }

      if (rawObject.messages) {
        apiResponse = apiResponse.setMessages(this.modelFactory.createArray<ApiMessage>(rawObject.messages));
      }
    }
    else {
      apiResponse = this.modelFactory.createAny<ApiResponse>(ApiResponse.name, rawObject);
    }

    return apiResponse;
  }
}