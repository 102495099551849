import { combineReducers, Reducer } from 'redux';
import { Injectable, Inject } from '@angular/core';
import * as Immutable from "immutable";
import { ConfSessionData } from "../shared/confSessionData";
import { AppAction } from "../shared/appAction";
import { ConfDataResponse } from "../../models/responses";
import { ConfDataState } from "../shared/confDataState";
import { AbstractConfigurationMessage } from "../../models/responses/messages/abstractConfigurationMessage";
import { BaseEntity } from "../../baseEntity";
import { ConfInfo, SearchDataResponse, ApiResponse, Conf, ConfMultiChoiceValue, StoredConf, UIElement, UIInput, UISelect } from "../../models";
import { ConfDataActions } from "./confDataActions";
import { Actions } from "../shared/actions";
import { ValueSyncType } from '../../valueSyncType';
import { BaseSyncObject } from '../../baseSyncObject';
import { Array, Number, String, Map, Object } from 'core-js';
import { isNumeric } from '../../../../shared/utils';
import { PushMessageSelection } from '../../providers/pushMessage/pushMessageSelection';
import { forEach } from 'core-js/fn/dict';

interface IConfDataRepository {
  oldEntities: Immutable.Map<number, BaseEntity>;
  newEntities: Immutable.Map<number, BaseEntity>;
  result?: Immutable.Map<number, BaseEntity>;
}

@Injectable()
export class ConfDataReducer {

  constructor() { }

  public getReducer(): Reducer<ConfDataState> {
    return combineReducers<any>({
      dataBySessionId: this.getConfSessionDataReducer()
    });
  }

  getConfSessionDataReducer() {

    let defaultState: Immutable.Map<string, ConfSessionData> = Immutable.Map<string, ConfSessionData>();

    return (state = defaultState, action: AppAction<any>): Immutable.Map<string, ConfSessionData> => {

      if (action.type == Actions.CLEAR_CLIENT_CACHE) {
        return defaultState;
      }
      else if (action.type == Actions.REMOVE_CONFIGURATOR_SESSIONS) {

        let confSessionIds = action.payload as number[];
        // Filter map by removing the matching ConfSessionIds        
        let filterState = state.filter((value, key) => confSessionIds.indexOf(value.confSessionId) < 0).toMap();

        if (filterState.size != state.size)
          return filterState;

        return state;
      }
      else if (action.type == Actions.FORM_SELECTION) {

        if (!(action.payload instanceof PushMessageSelection))
          return state;

        let pushMessageSelection = action.payload as PushMessageSelection;

        let confSessionData = state.get(pushMessageSelection.sessionId.toString());

        if (pushMessageSelection.clearSession) {
          confSessionData = confSessionData.setValueByElementId(Immutable.Map<string, string>());
        }

        // Don't set a value without a key.
        if (pushMessageSelection.key) {

          if (!confSessionData.valueByElementId) {
            confSessionData = confSessionData.setValueByElementId(Immutable.Map<string, string>());
          }

          let newValue = confSessionData.valueByElementId.set(pushMessageSelection.key, pushMessageSelection.value);
          confSessionData = confSessionData.setValueByElementId(newValue);
        }

        state = state.set(pushMessageSelection.sessionId.toString(), confSessionData);
      }

      if (action.payload instanceof ApiResponse) {
        let subResponse = action.payload.data;

        if (subResponse instanceof ConfDataResponse) {
          state = this.updateConfSessionData(subResponse, state);
        }
        // If we have got ConfDataResponse from search results then update the state
        else if (action.type == Actions.SEARCH_CONFIGURATIONS_RESULT && subResponse instanceof SearchDataResponse) {

          if (subResponse.searchResult && subResponse.searchResult.confDataResponses) {

            subResponse.searchResult.confDataResponses.forEach(confDataResponse => {
              state = this.updateConfSessionData(confDataResponse, state);
            });
          }
        }
      }

      return state;
    }

  }

  updateConfSessionData(confDataResponse: ConfDataResponse, state: Immutable.Map<string, ConfSessionData>) {
    if (!state.has(confDataResponse.confSessionId.toString())) {
      let confSessionData = new ConfSessionData();

      if (confDataResponse.entities)
        confSessionData = confSessionData.setEntitiesByConfId(confDataResponse.entities);

      else confSessionData = confSessionData.setEntitiesByConfId(Immutable.Map<number, Immutable.Map<number, BaseEntity>>());

      confSessionData = confSessionData.setConfSessionId(confDataResponse.confSessionId);
      confSessionData = confSessionData.setRootConfId(confDataResponse.rootConfId);
      confSessionData = confSessionData.setIsClosed(confDataResponse.isClosed);
      confSessionData = confSessionData.setHasUnsavedChanges(confDataResponse.hasUnsavedChanges);
      confSessionData = confSessionData.setUiElements(confDataResponse.uiElements);

      if (confDataResponse.uiElements) {
        confSessionData = this.setFormDefualtValues(confSessionData, confDataResponse);
      }

      confSessionData = confSessionData.setMessages(this.createMessagesMap(confDataResponse.messages));
      confSessionData = confSessionData.setCompositeStructure(confDataResponse.confInfos.entities);
      state = state.set(confDataResponse.confSessionId.toString(), confSessionData);
    }
    else {

      // Existing session data.
      let confSessionData = state.get(confDataResponse.confSessionId.toString());

      if (confSessionData.hasUnsavedChanges != confDataResponse.hasUnsavedChanges)
        confSessionData = confSessionData.setHasUnsavedChanges(confDataResponse.hasUnsavedChanges);

      if (confSessionData.isClosed != confDataResponse.isClosed)
        confSessionData = confSessionData.setIsClosed(confDataResponse.isClosed);

      if (confDataResponse.configurationIds) {

        let entitiesByConfId = confSessionData.entitiesByConfId;
        confDataResponse.configurationIds.forEach(confId => {

          // Merge only the changes.   
          let confDataRepository: IConfDataRepository = <IConfDataRepository>{
            oldEntities: entitiesByConfId.get(confId),
            newEntities: confDataResponse.entities.get(confId)
          }
          this.initConfDataSynchronization(confDataRepository, confId);
          entitiesByConfId = entitiesByConfId.set(confId, confDataRepository.result);
        });

        confSessionData = confSessionData.setEntitiesByConfId(entitiesByConfId);
      }

      confSessionData = confSessionData.setMessages(this.createMessagesMap(confDataResponse.messages));

      // Merge only confInfo changes.
      let oldCompositeStructure = confSessionData.compositeStructure;
      let newCompositeStructure: Immutable.Map<number, ConfInfo> = Immutable.Map<number, ConfInfo>();
      // Loop through new composite structure, make comparison and merge the changes.

      if (confDataResponse.confInfos.entities) {
        confDataResponse.confInfos.entities.forEach(confInfo => {

          // Compare old and new confInfo.
          let oldConfInfo = oldCompositeStructure.get(confInfo.longId);
          let entity = this.equal(oldConfInfo, confInfo) ? oldConfInfo : confInfo;
          newCompositeStructure = newCompositeStructure.set(confInfo.longId, entity);
        });

        // As old composite structure might contain deleted confInfos, that's we have to replace old composite struture with new one.          
        confSessionData = confSessionData.setCompositeStructure(newCompositeStructure);
      }

      if (confDataResponse.uiElements) {

        let oldUiElements = confSessionData.uiElements;
        let newUiElements = confDataResponse.uiElements;

        if (!oldUiElements)
          oldUiElements = newUiElements;

        let data = this.mergeEntities(oldUiElements, newUiElements);

        confSessionData = confSessionData.setUiElements(data);

        confSessionData = this.setFormDefualtValues(confSessionData, confDataResponse);
      }

      state = state.set(confDataResponse.confSessionId.toString(), confSessionData);
    }

    return state;
  }

  private setFormDefualtValues(confSessionData: ConfSessionData, confDataResponse: ConfDataResponse) {
    if (!confSessionData.valueByElementId) {
      confSessionData = confSessionData.setValueByElementId(Immutable.Map<string, string>());
    }

    let values = confSessionData.valueByElementId;

    confDataResponse.uiElements.forEach(element => {

      let hasValue = false;

      if (element instanceof UIInput) {
        let input = (element as UIInput);

        if (input.type == "text" || input.type == "checkbox" || input.type == "submit")
          hasValue = true;

      }
      else if (element instanceof UISelect) {
        hasValue = true;
      }

      if (hasValue) {
        // Set default empty value.
        values = values.set(element.id, "");
      }

    });

    confSessionData = confSessionData.setValueByElementId(values);
    return confSessionData;
  }

  protected mergeEntities(existingEntities: Immutable.Map<number, UIElement>, newEntities: Immutable.Map<number, UIElement>): Immutable.Map<number, UIElement> {

    let resultedEntities = existingEntities;

    newEntities.forEach((newEntity: UIElement, key: number) => {

      // Convert to number, needed to make sure the key is number in runtime
      key = parseInt(key.toString());

      if (!existingEntities.has(key)) {
        resultedEntities = resultedEntities.set(key, newEntity);
      }
      else {
        let existingEntity = existingEntities.get(key);
        resultedEntities = resultedEntities.set(key, Object.assign(existingEntity, newEntity));
      }
    });

    return resultedEntities;
  }

  /**
   * Synchronizes server and client data.
   * @param repository
   * @param confId
   */
  initConfDataSynchronization(repository: IConfDataRepository, confId: number): void {

    // If oldEntities don't exists It means data is loaded first time.
    if (!repository.oldEntities) {
      repository.result = repository.newEntities;
      return;
    }

    // Grab the new configuration.
    let newConf: Conf = repository.newEntities.has(+confId) ? repository.newEntities.get(+confId) as Conf : null;
    if (!newConf) {
      // If new Configuration is mising then return empty list (rare scenario, it should not happen)
      repository.result = Immutable.Map<number, BaseEntity>();
      return;
    }

    let oldConf: Conf = repository.oldEntities.get(confId) as Conf;
    this.synchronizeConfDataInternal(repository, newConf, oldConf);
  }

  /**
   * Synchronizes the client and server conf data.
   * @param repository
   * @param newEntity
   */
  synchronizeConfDataInternal(repository: IConfDataRepository, newEntity: BaseEntity, oldEntity: BaseEntity, propertyName?: string): void {

    // Result map, Put the old entities in result and replace the the entities if they are different.
    if (!repository.result) {
      repository.result = repository.oldEntities;
    }

    // Get all merging properties 
    let mergingProperties: Array<string> = this.getMergingProperties(newEntity);
    if (mergingProperties && mergingProperties.length) {

      // Loop through each property and merge the data individually.
      mergingProperties.forEach(mergingPropertyName => {

        // Value could be array or immutable list. If true -> compare each entity belonging to list-ids.
        const newPropValue = newEntity ? newEntity.getInternalValue(mergingPropertyName) : null;
        const oldPropValue = oldEntity ? oldEntity.getInternalValue(mergingPropertyName) : null;

        // May new or old property value is null. It better to have array check on both.
        if (Array.isArray(newPropValue) || Array.isArray(oldPropValue) || newPropValue instanceof Immutable.List || oldPropValue instanceof Immutable.List) {

          // Compare normal array data
          this.handleArrayDataComparison(newEntity, oldEntity, repository, mergingPropertyName);
          oldEntity = this.extractUpdatedOrDefault(oldEntity, repository);

        }
        else {

          // Recursive call.
          // Handle Objects or normal properties.
          this.handleLinearDataComparison(repository, newEntity, oldEntity, mergingPropertyName);
          oldEntity = this.extractUpdatedOrDefault(oldEntity, repository);

        }

      });
    }
    else {

      // Handle non sync type objects.
      if (!this.equal(oldEntity, newEntity)) {
        this.merge(newEntity, oldEntity, propertyName, repository);
      }

    }

  }

  /**
   * Compares the array data, In this case propertyName is beloning to array data.
   * @param newEntity
   * @param repository
   * @param propertyName
   */
  handleArrayDataComparison(newEntity: BaseEntity, oldEntity: BaseEntity, repository: IConfDataRepository, propertyName: string): void {

    // New array data
    let newPropertyData: Array<any> = this.readArrayData(newEntity, propertyName);

    // Old array data
    let oldPropertyData: Array<any> = this.readArrayData(oldEntity, propertyName);

    // Does it contain referenced data?
    let isReferenceObject = isNumeric((newPropertyData && newPropertyData[0]) || (oldPropertyData && oldPropertyData[0]));
    // If new array has no data then remove the old data from corresponding property.
    if (!newPropertyData) {

      // if old array contains data then remove it
      if (oldPropertyData)
        this.removeOldEntities(oldPropertyData, repository);
    }

    // Merge array data only if it is not referenced objects or arrays are different
    if (!isReferenceObject || !this.equal(newPropertyData, oldPropertyData)) {
      this.merge(newEntity, oldEntity, propertyName, repository);
      oldEntity = this.extractUpdatedOrDefault(oldEntity, repository);
    }

    if (isReferenceObject && newPropertyData) {

      this.addNewEntities(newPropertyData, repository);

      if (oldPropertyData) {
        let deletedIds = oldPropertyData.filter(x => !newPropertyData.includes(x));
        this.removeOldEntities(deletedIds, repository);
      }

      newPropertyData.forEach(id => {

        // Get deep level referenced objects. e.g MultiSetValue
        let newArrayEntity: BaseEntity = repository.newEntities.get(+id);
        if (newArrayEntity) {

          let oldArrayEntity: BaseEntity;
          if (repository.oldEntities)
            oldArrayEntity = repository.oldEntities.get(+id);
          this.synchronizeConfDataInternal(repository, newArrayEntity, oldArrayEntity);

        }
      });
    }
  }

  /**
   * Compares the specified property data 
   * @param repository
   * @param newEntity
   * @param propertyName
   */
  handleLinearDataComparison(repository: IConfDataRepository, newEntity: BaseEntity, oldEntity: BaseEntity, propertyName: string): void {

    if (!(newEntity instanceof BaseEntity))
      throw 'Entity must be BaseEnity: ' + newEntity;

    // Update the property data
    let newPropertyValue = newEntity.getInternalValue(propertyName);
    let oldPropertyValue = oldEntity.getInternalValue(propertyName);

    if (newPropertyValue instanceof BaseEntity || oldPropertyValue instanceof BaseEntity) {
      this.synchronizeConfDataInternal(repository, newPropertyValue as BaseEntity, oldPropertyValue as BaseEntity, propertyName);
      return;
    }


    if (!this.equal(oldEntity, newEntity, propertyName)) {
      oldEntity = oldEntity.setInternalValue(propertyName, newEntity.getInternalValue(propertyName));
      repository.result = repository.result.set(oldEntity.longId, oldEntity);
    }

  }

  equal(oldObj: any, newObj: any, propertyName?: string): boolean {

    if (propertyName && oldObj && newObj)
      return JSON.stringify(oldObj[propertyName]) === JSON.stringify(newObj[propertyName]);

    return JSON.stringify(oldObj) === JSON.stringify(newObj);

  }

  readArrayData(entity: BaseEntity, propertyName): Array<any> {

    if (!entity)
      return null;

    let propertyValue = entity.getInternalValue(propertyName);

    if (propertyValue instanceof Immutable.List)
      return (propertyValue as Immutable.List<any>).toArray();

    if (Array.isArray(propertyValue))
      return propertyValue as Array<any>;


    return null;
  }

  /**
   * Merges the specified property data into result. 
   * @param newEntity
   * @param mergingPropertyName
   * @param repository
   */
  merge(newEntity: BaseEntity, oldEntity: BaseEntity, mergingPropertyName: string, repository: IConfDataRepository) {

    // Special case for StoredConf.
    if (newEntity instanceof StoredConf) {

      // Get old or the updated conf.
      let conf = this.extractUpdatedOrDefaultEntity(newEntity.longId, repository);
      let newConf = repository.newEntities.get(newEntity.longId);

      if (!this.equal(conf, newConf, mergingPropertyName)) {
        conf = conf.setInternalValue(mergingPropertyName, newEntity);

        repository.result = repository.result.set(conf.longId, conf);
      }

      return;
    }

    // Put the new entity into result if no old entity found.    
    if (!oldEntity) {
      repository.result = repository.result.set(newEntity.longId, newEntity);
      return;
    }

    // If property name is not defined then add the new entity into result.
    if (!mergingPropertyName) {
      repository.result = repository.result.set(newEntity.longId, newEntity);
      return;
    }

    // merge property
    // Copy new specific property data to old object
    oldEntity = oldEntity.setInternalValue(mergingPropertyName, newEntity.getInternalValue(mergingPropertyName))
    repository.result = repository.result.set(oldEntity.longId, oldEntity);

  }


  removeOldEntities(oldEntities: Array<any>, repository: IConfDataRepository): void {

    if (!oldEntities)
      return;

    oldEntities.forEach(x => {

      if (x instanceof BaseEntity)
        repository.result = repository.result.remove(x.longId);
      else
        repository.result = repository.result.remove(x);

    });

  }

  addNewEntities(newEntities: Array<any>, repository: IConfDataRepository): void {

    if (!newEntities)
      return;

    newEntities.forEach(x => {

      if (x instanceof BaseEntity || x.longId != null) {
        if (!repository.result.has(x.longId))
          repository.result = repository.result.set(x.longId, x);
      }

      else if (isNumeric(x) && repository.newEntities.has(+x)) {
        if (!repository.result.has(x))
          repository.result = repository.result.set(x, repository.newEntities.get(+x));
      }
    });
  }


  /**
   * Returns the updated entity or default old one.
   * @param id
   * @param repository
   */
  extractUpdatedOrDefaultEntity(id: number, repository: IConfDataRepository): BaseEntity {

    if (repository.result && repository.result.has(+id))
      return repository.result.get(+id);

    return repository.oldEntities.get(id);

  }

  extractUpdatedOrDefault(oldEntity: BaseEntity, repository: IConfDataRepository) {

    if (oldEntity == null)
      return;

    return this.extractUpdatedOrDefaultEntity(oldEntity.longId, repository);

  }

  /**
   * Is it allowed to have deep level merge for the provided entity.
   * @param entity
   */
  allowDeepLevelMerge(entity: BaseEntity): boolean {

    if (entity instanceof ConfMultiChoiceValue)
      return true;

    return false;
  }

  /**
   * Returns the properties whose data must be synchronized.
   * @param newObj
   */
  getMergingProperties(newObj: BaseEntity): Array<string> {


    let list: Array<string> = [];

    if (newObj instanceof BaseSyncObject) {
      newObj.syncTypeByProperty.forEach((value, key) => {

        if (value == ValueSyncType.Replace)
          list.push(key);

      });
    }

    return list;
  }

  createMessagesMap(messages: Immutable.List<AbstractConfigurationMessage>): Immutable.Map<string, Immutable.List<AbstractConfigurationMessage>> {

    let messagesMap = Immutable.Map<string, Immutable.List<AbstractConfigurationMessage>>();

    if (messages && messages.size > 0) {
      messages.forEach((message) => {

        if (!messagesMap.has(message.className))
          messagesMap = messagesMap.set(message.className, Immutable.List<AbstractConfigurationMessage>());

        let messagesList = messagesMap.get(message.className);
        messagesMap = messagesMap.set(message.className, messagesList.push(message));
      });
    }

    return messagesMap;
  }
}