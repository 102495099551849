import { Injectable, Inject } from '@angular/core';

import { AppStore } from "../../state/appStore";
import { AppAction, AppAsyncAction, HttpAction, ActionStatus } from "../../state";
import { ProductDataResponse } from "../../models/responses";
import { ProductDataController } from "./productDataController";
import { ProductDataActions } from "../../state/productData/productDataActions";
import { BaseActionCreator } from "../../baseActionCreator";
import { ProductDataRequest } from "../../models/requests/productDataRequest";
import { ApiResponse } from "../../models";

@Injectable()
export class ProductDataActionCreator extends BaseActionCreator {

  constructor(    
    @Inject(AppStore) public appStore: AppStore,
    @Inject(ProductDataController) public productDataController: ProductDataController
    ) {
    super(productDataController);
  }



  dispatchFetchProductTabs = (requestModel: ProductDataRequest) => this.appStore.dispatch(this.fetchProductTabs(requestModel)) as HttpAction<ApiResponse>;
  fetchProductTabs(requestModel: ProductDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse> (

      ProductDataActions.FETCH_PRODUCT_TABS,

      (dispatch, state, callback) => {

        return this.productDataController.getProductData(requestModel).subscribe((productDataResult) => {

          // Dispatch action type and results.
          dispatch({ type: ProductDataActions.PRODUCT_LINKED_DATA_LOADED, payload: productDataResult });
          
          // Update complete request status
          callback(new ActionStatus(true, productDataResult));
        })

      });    
  }
  
  dispatchFetchProducts = (requestModel: ProductDataRequest) => this.appStore.dispatch(this.fetchProducts(requestModel)) as HttpAction<ApiResponse>;
  fetchProducts(requestModel: ProductDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      ProductDataActions.FETCH_PRODUCTS,

      (dispatch, state, callback) => {

        return this.productDataController.getProductData(requestModel).subscribe((productDataResult) => {

          // Dispatch action type and results.
          dispatch({ type: ProductDataActions.PRODUCT_LINKED_DATA_LOADED, payload: productDataResult });

          // Update complete request status
          callback(new ActionStatus(true, productDataResult));
        })

      });
  }

  dispatchRootAssortments = (requestModel: ProductDataRequest) => this.appStore.dispatch(this.getRootAssortments(requestModel)) as HttpAction<ApiResponse>;
  getRootAssortments(requestModel: ProductDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse> (

      ProductDataActions.FETCH_ROOT_ASSORTMENTS,

      (dispatch, state, callback) => {
        return this.productDataController.getProductData(requestModel).subscribe((productDataResult) => {
          
          // Dispatch action type and product results.
          dispatch({ type: ProductDataActions.ROOT_ASSORTMENTS_LOADED, payload: productDataResult });

          callback(new ActionStatus(true, productDataResult));
        })
      }
    );    
  }

  dispatchAssortments = (requestModel: ProductDataRequest) => this.appStore.dispatch(this.getAssortments(requestModel)) as HttpAction<ApiResponse>;
  getAssortments(requestModel: ProductDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      ProductDataActions.FETCH_ASSORTMENTS,

      (dispatch, state, callback) => {
        return this.productDataController.getProductData(requestModel).subscribe((productDataResult) => {

          // Dispatch action type and product results.
          dispatch({ type: ProductDataActions.FETCH_ASSORTMENTS, payload: productDataResult });
                    
          callback(new ActionStatus(true, productDataResult));
        })
      }
    );
  }

  dispatchProductData(arr: ApiResponse[]): any {

    arr.forEach(data => {
      
      let action = this.createHttpAction<ApiResponse>(ProductDataActions.PRODUCT_LINKED_DATA_LOADED, null, data);
      this.appStore.dispatch(action);

    })
  }

}