<action-box *ngIf="visible" class="detail-item {{extraStyles}}" iconLeft="formdecoration" textLeft="{{actionBoxTitle}}">
  <ng-container *ngIf="contentVisible">
    <form-decoration *ngFor="let form of formDecorations"
                     [configurationId]="configurationId"
                     [decoration]="form"
                     [id]="form.longId"
                     [showTitle]="false"
                     [view]="RequestViews.Summary"></form-decoration>
  </ng-container>
  <div card-type="control" *ngIf="isPrintView">
    <print-checkbox (onValueChange)="onPrintValueChange($event)"></print-checkbox>
  </div>
</action-box>