import { Injectable, forwardRef, Inject } from '@angular/core';
import { ConfDataActionCreator } from "../../providers/configurationData/confDataActionCreator";
import { ConfDataResponse } from "../../models/responses";
import { ConfDataRequest } from "../../models";
import { StateController } from "./stateController";
import { BaseActionCreator } from '../../baseActionCreator';
import { HttpAction } from '../../state/shared/httpAction';
import { ActionStatus } from '../../state/shared/actionStatus';
import { StateActions } from './stateActions';

@Injectable()
export class StateActionCreator extends BaseActionCreator {

  constructor(
    @Inject(forwardRef(() => StateController)) public stateController: StateController,
    @Inject(forwardRef(() => ConfDataActionCreator)) public configurationActionCreator: ConfDataActionCreator
  ) {
    super(stateController);
  }

  promote(model: ConfDataRequest): HttpAction<ConfDataResponse> {
    let asyncAction = <HttpAction<ConfDataResponse>>{
      type: StateActions.PROMOTE,
      executeAsync: (dispatch, state, callback) => {
        return this.stateController.promote(model)
          .subscribe(configurationDataResult => {
            dispatch(this.configurationActionCreator.configurationLoaded(configurationDataResult));

            callback(new ActionStatus(true, configurationDataResult));
          });
      }
    }
    return asyncAction;
  }

  demote(model: ConfDataRequest): HttpAction<ConfDataResponse> {
    let asyncAction = <HttpAction<ConfDataResponse>>{
      type: StateActions.DEMOTE,
      executeAsync: (dispatch, state, callback) => {
        return this.stateController.demote(model)
          .subscribe(configurationDataResult => {
            dispatch(this.configurationActionCreator.configurationLoaded(configurationDataResult));

            callback(new ActionStatus(true, configurationDataResult));
          });
      }
    }
    return asyncAction;
  }
}
