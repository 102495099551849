import { Injectable } from "@angular/core";
import { ModelFactory } from "./modelFactory";
import { JsonConvert } from "../../../shared/providers/jsonConvert";
import { ManagedSubject } from "../../../shared/managedSubject";
import { ValueSubscriptionManager } from "./valueSubscriptionManager";
import { SubscriptionOptions, ManagedSubscription } from "../../../shared/managedSubscription";
import { AuthenticationTokenTypes } from "./authenticationTokenTypes";
import { SessionStorageData } from "../models/client/sessionStorageData";


@Injectable()
export class SessionStorageService {

  public sessionStorageData: SessionStorageData;

  public constructor(
    public modelFactory: ModelFactory,
    public jsonConvert: JsonConvert,
    public valueSubscriptionManager: ValueSubscriptionManager
  ) {
  }

  private _sessionStorageSubject: ManagedSubject<SessionStorageData>;
  public get sessionStorageSubject(): ManagedSubject<SessionStorageData> {

    if (!this._sessionStorageSubject) {
      this._sessionStorageSubject = this.valueSubscriptionManager.createSubject<SessionStorageData>(ManagedSubject.IGNORE_VALUE, false, 1, false);
    }

    return this._sessionStorageSubject;
  }

  public get key() {
    return `${location.origin}${location.pathname}`;
  }

  public createSessionStorageData(value: string) {
    
      if (value == null || value == undefined || value.trim().length == 0)
        return this.modelFactory.createAny<SessionStorageData>(SessionStorageData.name, {  });
      else
        return this.modelFactory.createAny<SessionStorageData>(SessionStorageData.name, JSON.parse(value)); 
  }

  public onSessionStorageChanged(subscriptionOptions: SubscriptionOptions<SessionStorageData>): ManagedSubscription {
    return this.sessionStorageSubject.subscribe(subscriptionOptions);
  }

  public setData(sessionStorageData: SessionStorageData) {

    if (sessionStorageData == null || sessionStorageData == undefined)
      sessionStorageData = this.createSessionStorageData(null);

    this.storeAndTrigger(sessionStorageData);
  }
  
  protected storeAndTrigger(sessionStorageData: SessionStorageData) {

    this.sessionStorageData = sessionStorageData;
    
    sessionStorage.setItem(this.key, this.jsonConvert.stringify(this.sessionStorageData));

    this.sessionStorageSubject.nextValue(this.getData());
  }

  public getData() {
    if (!this.sessionStorageData)
      this.sessionStorageData = this.createSessionStorageData(sessionStorage.getItem(this.key));

    return this.sessionStorageData;
  }

  public addTokenToUrl(url: string): string {
    if (!url)
      return null;

    let accessToken = this.getData()?.accessToken;
    if (!accessToken)
      return url;

    let token_param = `${AuthenticationTokenTypes.AccessToken}=${accessToken}`
    url += ((url.includes('?') ? '&' : '?') + token_param);

    return url;
  }
}