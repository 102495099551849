
<backdrop-container *ngIf="modal">
  <ng-container *ngTemplateOutlet="popupTemplate"></ng-container>
</backdrop-container>

<ng-container *ngIf="!modal">
  <ng-container *ngTemplateOutlet="popupTemplate"></ng-container>
</ng-container>

<ng-template #popupTemplate>
  <regular-popup *ngIf="visible"
                 [title]="title"
                 [id]="'push-message'"
                 [width]="width"
                 [minHeight]="height"
                 [height]="height"
                 [showCloseButton]="false"
                 closeOnOutSideClick="false"
                 referenceContainerId="configuratorContainer"
                 [draggable]="!modal"
                 [showHeader]="true"
                 [showFooter]="false"
                 [class]="cssClass">

    <div card-type="header">
      <div class="row py-1 m-0">
        <co-image class="col-auto px-0 align-middle" width="48px" height="48px" type="svg" imageSet="{{imageSet}}" key="{{icon}}"></co-image>
        <div class="col modal-text-right">
          <p *ngIf="true" class="lead-paragraph">{{leadParagraph}}</p>
          <p *ngIf="true" class="paragraph">{{paragraph}}</p>
        </div>
      </div>
    </div>

    <div card-type="body">
      <div *ngIf="uiPopup">
        <div *ngIf="uiPopup.uiElements && uiPopup.uiElements.count() > 0">

          <ui-element (action)="onAction($event)" [class]="getCssClass(uiElementId)" [style]="uiElement.style" [uiElementId]="uiElementId" [elementStore]="pushMessageStore" [sessionId]="sessionId"></ui-element>

        </div>
      </div>
    </div>
  </regular-popup>
</ng-template>