export class ConfDataActions {
  static GET_CONFIGURATION = "@GET_CONFIGURATION";
  static EDIT_CONFIGURATION = "@EDIT_CONFIGURATION";
  static ACTIVE_CONFIGURATION = "@ACTIVE_CONFIGURATION";
  static CREATE_CONFIGURATION = "@CREATE_CONFIGURATION";
  static CREATE_DYNAMIC_INDEXES = "@CREATE_DYNAMIC_INDEXES";
  static JAVASCRIPT_ACTION = "@JAVASCRIPT_ACTION";
  static REUSE_CONFIGURATION = "@REUSE_CONFIGURATION";
  static REVISE_CONFIGURATION = "@REVISE_CONFIGURATION";
  static SAVE_CONFIGURATION = "@SAVE_CONFIGURATION";
  static CONTACT = "@CONTACT";
  static SET_CONFIGURATION_VALUE = "@SET_CONFIGURATION_VALUE";
  static SET_CONFIGURATION_PROPERTY_VALUE = "@SET_CONFIGURATION_PROPERTY_VALUE";
  static GET_DISALLOWED_INFO = "@GET_DISALLOWED_INFO";
  static GET_READ_ONLY_INFO = "@GET_READ_ONLY_INFO";
  static GET_MANDATORY_INFO = "@GET_MANDATORY_INFO";
  static GET_CODE_FILE = "@GET_CODE_FILE";
  static GET_PRICE_REPORT = "@GET_PRICE_REPORT";
  static GET_PRICE_SETTING = "@GET_PRICE_SETTING";
  static SET_PRICE_SETTING = "@SET_PRICE_SETTING";
  static DELETE_CONFIGURATION = "@DELETE_CONFIGURATION";
  static COPY_CONFIGURATION = "@COPY_CONFIGURATION";
  static CLOSE_CONFIGURATOR_SESSION = "@CLOSE_CONFIGURATOR_SESSION";
  static MARK_CONFIGURATOR_SESSION_FOR_CLOSING = "@MARK_CONFIGURATOR_SESSION_FOR_CLOSING";
  static GET_COPY_CONFIGURATION_DESTINATIONS = "@GET_COPY_CONFIGURATION_DESTINATIONS";  
  static ORDER_CHANGE_CONFIGURATION = "@ORDER_CHANGE_CONFIGURATION";
  static IMPORT_IDENTITIES = "@IMPORT_IDENTITIES";
  static IMPORT_IDENTITIES_RESULT = "@IMPORT_IDENTITIES_RESULT";
}