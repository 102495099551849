<div *ngIf="parent.view != null" [class.disabled]="disabled" class="dropdown line-dropdown" [style.width]="parent.view.width" [style.max-width.%]="100">
  <label *ngIf="parent.view.title" class="static-label {{parent.view.labelPosition}}">{{parent.view.title}}</label>

  <ng-content select="[above-container]"></ng-content>

  <div #lineDropdown class="input-container text-field control-border-b " [class.has-focus]="parent.hasFocus">
    <span *ngIf="parent.view.readOnly" class="read-only align-middle">
      <a role="button" (click)="readOnlyClick()"><co-image width="20" height="20" type="svg" key="lock" imageSet="{{imageSet}}"></co-image></a>
    </span>
    <div class="d-flex w-100 hide-overflow dropdownMenuButton dropdown-input" data-toggle="dropdown" [class.readonly]="parent.view.readOnly" (keyup)="handleKeyup($event)">
      <icon *ngIf="parent.view.icon" [view]="parent.view"></icon>

      <!-- Selected value text -->
      <button #selectButton class="btn btn-secondary btn-text hide-overflow" [class.text-center]="parent.view.showIconAsSelected" type="button" aria-haspopup="true" tabindex="-1" aria-expanded="false" (focus)="parent.hasFocus = true" (focusout)="parent.hasFocus = false">
        <div *ngIf="!parent.view.showIconAsSelected" class="ellipsis placeholder" title="{{parent.view.formattedValue}}">{{parent.view.formattedValue}}</div>
        <co-image *ngIf="parent.view.showIconAsSelected" width="20" height="20" type="svg" [imageSet]="parent.view.iconImageSet" [key]="selectedIconKey"></co-image>
        <span *ngIf="showPlaceholder" class="custom-placeholder">{{parent.view.placeholder}}</span>
      </button>
      <!-- End selected value text -->
      <button *ngIf="!parent.collapseIcon" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" type="button" aria-haspopup="true" aria-expanded="false">
        <span class="sr-only">Toggle Dropdown</span>  <!--TODO localize ToggleDropdown-->
      </button>
      <div *ngIf="parent.collapseIcon" class="col-auto menu-item align-middle mobile-inline-flex" role="button">
        <co-image type="svg" key="{{expanded ? parent.expandIcon : parent.collapseIcon}}" [width]="'16'" [height]="'16'"></co-image>
      </div>
    </div>
    <help-link *ngIf="parent.view.enableHelp" [view]="parent.view" (click)="onHelpClick($event)" [imageSet]="parent.helpImageSet"></help-link>
    <div class="dropdown-menu" [style.height]="menuHeight" [style.width]="menuWidth" (keyup)="handleKeyup($event)">
      <line-dropdown-item *ngFor="let vw of itemsView" [view]="vw" [class.selected-dropdown-item]="parent.view.id == vw.id"></line-dropdown-item>
    </div>
  </div>
</div>