import { Component, Input, ComponentFactoryResolver, Inject } from "@angular/core";
import { ConfBoolValue, BoolParam } from "../../../shared/models";
import { BaseEntity } from "../../../shared/baseEntity";
import { InputViewModel, PopupService, ValueChangeEventArgs, RestrictValueChangeEventArgs } from "../../../../shared/components";
import { VisualObjectViewModelFactory } from "../shared/visualObjectViewModelFactory";
import { ParamComponent } from "../../shared/paramComponent";
import { ConfigurationSessionManager } from "../../providers/configurationSessionManager";
import { HelpPopupComponent } from "../../popups";
import { ParameterMandatoryService } from "../shared/parameterMandatoryService";
import { ConfiguratorStore, ConfPageSessionService, ConfMessageProvider, ConfiguratorUIStore } from "../../providers";
import { ChangeDetectorRef } from "@angular/core";
import { VisualObjectHelper } from "../shared";

@Component({
  selector: 'bool-param',
  templateUrl: './boolParamComponent.html'
})
export class BoolParamComponent extends ParamComponent<BoolParam> {

  viewModel: InputViewModel;
  confBoolValue: ConfBoolValue;

  constructor(
    @Inject(ComponentFactoryResolver) public resolver: ComponentFactoryResolver,
    @Inject(VisualObjectViewModelFactory) public viewFactory: VisualObjectViewModelFactory,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(ParameterMandatoryService) public parameterMandatoryService: ParameterMandatoryService,
    @Inject(ConfiguratorUIStore) public configuratorUIStore: ConfiguratorUIStore,
    @Inject(ConfMessageProvider) public messagesProvider: ConfMessageProvider,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    public cd: ChangeDetectorRef
  ) {
    super(confStore, confPageSessionService, popupService, visualObjectHelper, parameterMandatoryService, messagesProvider, cd);
  }

  ngOnInit() {
    this.viewModel = this.viewFactory.create(this.parameter);
    // Set value on load.
    this.setValue(this.confStore.getConfValue(this.configuration.longId, this.confPageSessionService.confSessionId, this.parameter.visualObjectId));

    super.ngOnInit();
  }

  public setValue(confValue: BaseEntity) {
    this.confBoolValue = confValue as ConfBoolValue;

    if (!this.confBoolValue)
      return;

    // Remove all readonly and disallowed states.
    this.viewFactory.setNormalState([this.viewModel]);

    this.viewFactory.setValue([this.viewModel], String(this.confBoolValue.longId), this.confBoolValue.value);

    // Disallow values        
    if (this.confBoolValue.isDisallowed)
      this.viewModel = this.viewFactory.setDisabled([this.viewModel], String(this.confBoolValue.longId), true)[0];

    // Set readOnly
    if (this.confBoolValue.isReadOnly)
      this.viewModel = this.viewFactory.setReadOnly([this.viewModel], String(this.confBoolValue.longId), true)[0];
  }

  onValueChange($event: ValueChangeEventArgs): void {
    super.onValueChange($event);

    // Update value
    this.confStore.setConfValue(this.confBoolValue, this.confPageSessionService.confSessionId, $event.value, this.configuratorUIStore.includeMandatoryInfo, this.configuratorUIStore.isNextStateIncluded);
  }

  onReadOnly($event: RestrictValueChangeEventArgs): void {
    this.confStore.getReadOnlyInfo(this.configurationId, this.confSessionId, this.confBoolValue, +$event.inValidValue);
  }

  onDisallowed($event: RestrictValueChangeEventArgs): void {
    this.confStore.setConfValue(this.confBoolValue, this.confPageSessionService.confSessionId, $event.inValidValue, this.configuratorUIStore.includeMandatoryInfo, this.configuratorUIStore.isNextStateIncluded);
  }

  public getCurrentValue(): any {

    // If value is different then set it to ConfValue and return the change ConfValue to cause a value change trigger
    if (this.confBoolValue && this.confBoolValue.value != this.viewModel.value)
      return this.confBoolValue.setValue(this.viewModel.value);

    return this.confBoolValue;    
  }

  public setError(errors: Array<string>) {
    this.viewModel.errors = errors;
  }
}