import { ImmutableObject } from "../../../../shared/immutableObject";

// Used for persisting client specific data
export class SessionStorageData extends ImmutableObject {

  protected readonly USERNAME = "username";
  protected readonly ACCESS_TOKEN = "ACCESS_TOKEN";
  protected readonly WEB_SESSION_TOKEN = "WEB_SESSION_TOKEN";

  get username(): string { return this.getInternalValue<string>(this.USERNAME); }
  setUsername(username: string) { return this.setInternalValue(this.USERNAME, username); }

  get accessToken(): string { return this.getInternalValue<string>(this.ACCESS_TOKEN); }
  setAccessToken(accessToken: string) { return this.setInternalValue(this.ACCESS_TOKEN, accessToken); }

  get webSessionToken(): string { return this.getInternalValue<string>(this.WEB_SESSION_TOKEN); }
  setWebSessionToken(webSessionToken: string) { return this.setInternalValue(this.WEB_SESSION_TOKEN, webSessionToken); }
}