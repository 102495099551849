import { Inject, Injectable } from '@angular/core';
import { ApiResponse, ComparisonDataRequest } from '../../../../../core/pages/shared/models';
import { Actions, ActionStatus, AppStore, HttpAction, PageActions } from '../../../../../core/pages/shared/state';
import { ComparisonDataController } from './comparisonDataController';
import { BaseActionCreator } from '../../../shared';



@Injectable()
export class ComparisonDataActionCreator extends BaseActionCreator {

  constructor(
    @Inject(AppStore) public appStore: AppStore,
    @Inject(ComparisonDataController) public comparisonDataController: ComparisonDataController
  ) {
    super(comparisonDataController);
  }

  dispatchGetComparisonConfs = (requestModel: ComparisonDataRequest) => this.appStore.dispatch(this.getComparisonConfs(requestModel)) as HttpAction<ApiResponse>;
  getComparisonConfs(requestModel: ComparisonDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(
      PageActions.COMPARISON_CONFS_REQUEST,
      (dispatch, state, callback) => {
        return this.comparisonDataController.getComparisonData(requestModel).subscribe((comparisonResponse) => {
          dispatch({ type: Actions.COMPARISON_CONFIGURATIONS_RESULT, payload: comparisonResponse });

          // Update complete request status
          callback(new ActionStatus(true, comparisonResponse));
        })
      });
  }
  
  public createHttpAction<T>(actionType: any, executeAsync: any, payload: T = null): HttpAction<T> {
    let httpAction: HttpAction<T> = {
      type: actionType,
      executeAsync: executeAsync,
      payload: payload
    }
    return httpAction;
  }
}