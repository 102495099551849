export enum ItemStateType {
  Checked = 'checked',
  Unchecked = 'unchecked',
  Inderminate = 'inderminate'
}

export interface MultiSelectItem {
  id: string;
  state: ItemStateType;
  title: string;
  icon?: string;
  parentId?: string;
  selectable?: boolean;
  isChild?: boolean;
  children?: Array<MultiSelectItem>;

}