import { Component, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, ViewChild, ElementRef } from "@angular/core";
import { InputViewModel, BaseInputComponent } from "../shared";

@Component({
  selector: 'text-box',
  templateUrl: './textBoxComponent.html'
})
export class TextBoxComponent extends BaseInputComponent {
  
  @Input()
  public autofocus: false;

  /** Defines multiline input #rows. */
  @Input()
  public rows: number = 0;

  /** Defines multiline input #cols. */
  @Input()
  public cols: number = 20;

  @Input()
  public type: string = "text";

  @Input()
  public autocomplete: string = "Off";

  @Input()
  public stepValue: number = 0;

  @Output()
  public onEnterKey = new EventEmitter();

  @Input()
  public useFormatting = false;

  @Input()
  public integer = false;

  @Input()
  public hideLabel = false;

  getMoreStyles: string = "";
  
  @ViewChild('textbox')
  public textbox: ElementRef;
  
  constructor(public cd: ChangeDetectorRef) { super(); }

  processEvent($event, checkImmediate: boolean): void {

    if (this.type == 'email')
      this.view.isValid = this.textbox?.nativeElement?.validity?.valid == true;

    // Emit enter key
    if ($event instanceof KeyboardEvent && ($event as KeyboardEvent).keyCode == 13) {
      this.clearErrors();
      this.onEnterKey.emit($event);
      return;
    }

    // Remove highlighting now, otherwise it will wait for server response and cause flickering.
    this.view.highlight = false;

    if (checkImmediate && !this.immediate)
      return;

    if (this.notifyIfReadOnly(this.view, $event.target.value))
      return;

    if (this.notifyIfDisabled(this.view, $event.target.value))
      return;

    this.clearErrors();

    this.notifyValueChange(this.view, $event.target.value);
  }

  processKeyPress(event: KeyboardEvent) {

    if (this.type !== "number")
      return;

    let pattern = /(?:)/;

    // Bug fix in Firefox, input of letters in numerical textbox only clears on the first attempt.
    // No commas in integer inputs.
    if(this.integer)
      pattern = /[e\+\-0-9]/i;
    else
      pattern = /[e.,\+\-0-9]/i;
    
    if (!pattern.test(event.key)) {
      // invalid character numerical character.
      event.preventDefault();
    }
  }

  ngOnInit(): void {
    this.getMoreStyles = this.type === "number" && !this.stepValue ? " hide-spinner" : "";
    this.getMoreStyles += this.rows ? " control-border-y" : " control-border-b";
  }
  
  //ngOnChanges(changes: SimpleChanges): void {
  //  if (changes['errors']) {
  //    if (this._errors) {
  //      console.log("Lenght is ", this._errors.size);
  //      console.log(this._errors);
  //    }

  //    this.cd.detectChanges();
  //  }
  //}

  public clearErrors() {
    // Clear any errors.
    this._errors.clear(); // = [];
    if (this.view)
      this.view.errors = [];
  }

  ngAfterViewInit() {
    this.view.iconImageSet = this.imageSet;
  }

  transformValue(event) {
    event.target.value = this.view.formattedValue;
  }

  onFocus(event) {

    if (this.view.formattedValue && this.view.formattedValue != this.view.value) {
      event.target.value = this.view.value;
    }

    this.hasFocus = true;

  }

  onTextboxFocusOut(event) {

    this.hasFocus = false

    if (this.view.formattedValue && this.view.formattedValue != this.view.value) {
      this.transformValue(event);
    }
    
    this.onFocusOut.emit(event);
  }

  update() {

    if (!this.textbox)
      return;

    if (this.view.formattedValue)
      this.textbox.nativeElement.value = this.view.formattedValue;
    else this.textbox.nativeElement.value = this.view.value;

  }

  nativeElementValue(): string {
    return this.textbox.nativeElement.value;
  }

}