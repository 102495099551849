import { Component, Inject, Input, ChangeDetectorRef, ChangeDetectionStrategy, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { Subscription } from "rxjs";
import { ConfMultiChoiceValue, ConfMultiSetValue, ConfValue, ConfValueErrorMessage } from "../../../shared/models";
import { ValueChangeEventArgs, RestrictValueChangeEventArgs, PopupService } from "../../../../shared/components";
import { VisualObjectViewModelFactory, VisualObjectHelper } from "../shared";
import { MultiChoiceItemViewModel } from "../../../../shared/components/multiChoice";
import { ProductDataStore } from "../../../shared/providers/productData";
import { ConfiguratorStore } from "../../providers/configuratorStore";
import { ParameterMandatoryService } from "../shared/parameterMandatoryService";
import { ConfPageSessionService, ConfMessageProvider, ConfiguratorUIStore } from "../../providers";
import { VisualObjectComponent } from "../../shared/visualObjectComponent";
import { ManagedSubscription } from "../../../../shared/managedSubscription";

@Component({
  selector: 'multi-choice-value',
  templateUrl: './multiChoiceValueComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiChoiceValueComponent extends VisualObjectComponent {

  public confChangeSubscription: ManagedSubscription;
  public errorSubscription: ManagedSubscription;
  public highlightChangeSubscription: Subscription;

  @Output()
  public viewChanged: EventEmitter<MultiChoiceItemViewModel> = new EventEmitter<MultiChoiceItemViewModel>();

  @Input()
  public view: MultiChoiceItemViewModel;

  @Input()
  public multiChoiceParamId: number;

  // Used to trigger change detection of view on value change
  @Input()
  public value: boolean;

  public confMultiSetValue: ConfMultiSetValue;

  // Store highlighting locally.. There's some problems for MultiChoiceValue which makes it not highlight in composite otherwise. TODO
  public highlight = false;

  constructor(
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(VisualObjectViewModelFactory) public viewFactory: VisualObjectViewModelFactory,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(ParameterMandatoryService) public parameterMandatoryService: ParameterMandatoryService,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    @Inject(ConfMessageProvider) public messagesProvider: ConfMessageProvider,
    public cd: ChangeDetectorRef
  ) {
    super(confStore, confPageSessionService, visualObjectHelper);
  }

  onValueChange($event: ValueChangeEventArgs): void {
    this.setError([]);

    if ($event.actionView) {
      // Update value
      this.confStore.setConfValue(this.confMultiChoice, this.confSessionId, $event.actionView.value, this.confUIStore.includeMandatoryInfo, this.confUIStore.isNextStateIncluded, +$event.actionView.id);
    }
  }

  onReadOnly(event: RestrictValueChangeEventArgs): void {
    this.confStore.getReadOnlyInfo(this.configurationId, this.confSessionId, this.confMultiChoice, +event.actionView.id);
  }

  onDisallowed(event: RestrictValueChangeEventArgs): void {
    if (event.actionView) {
      // Update value
      this.confStore.setConfValue(this.confMultiChoice, this.confSessionId, event.inValidValue, this.confUIStore.includeMandatoryInfo, this.confUIStore.isNextStateIncluded, +event.actionView.id);
    }
  }

  public get confMultiChoice(): ConfMultiChoiceValue {
    return this.confStore.getConfValue(this.configurationId, this.confSessionId, this.multiChoiceParamId) as ConfMultiChoiceValue;
  }

  // Returns the multi
  public get paramId(): number {
    return Number(this.view.id);
  }

  public shouldHighlight(): boolean {
    // Override and store highlighting locally.. There's some problems for MultiChoiceValue which makes it not highlight in composite otherwise. TODO
    this.highlight = this.parameterMandatoryService.shouldHighlight(this.configurationId, +this.view.id);
    return this.highlight;
  }

  public setHighlight(highlight: boolean): void {
    this.view.highlight = highlight;
    this.viewChanged.emit(this.view);
  }

  public getCurrentValue(): any {

    // If value is different then set it to ConfValue and return the change ConfValue to cause a value change trigger
    if (this.confMultiSetValue && this.confMultiSetValue.value != this.view.value)
      return this.confMultiSetValue.setValue(this.view.value);

    return this.confMultiSetValue;
  }

  public setError(errors: Array<string>) {
    if (this.view) {
      this.view.errors = errors;
      this.viewChanged.emit(this.view);
    }
  }

  public listenChanges(): void {
    // Unsubscribe the old subscription.
    this.unsubscribe();

    this.confStore.onConfigurationValueChange(this.configurationId, this.confPageSessionService.confSessionId, this.paramId, (confValue: ConfValue): void => {

      // Might mlso need to highlight
      this.setHighlight(this.shouldHighlight());

    },
      (): any => this.getCurrentValue()
    ).unsubscribeOn(this.unsubscribeSubject);

    this.highlightChangeSubscription = this.parameterMandatoryService.getMessage().subscribe(() => {
      // Check if mandatory highlight
      this.setHighlight(this.shouldHighlight());
    });

    this.errorSubscription = this.messagesProvider.onMessagesRequest<ConfValueErrorMessage>(this.confSessionId, ConfValueErrorMessage.name, (x) => {
      // Flatten my error messages to a single array 
      let errorMessages = Array<string>();
      if (x)
        x.forEach(msg => {
          if (msg.confValueId === this.paramId) {
            errorMessages = errorMessages.concat(msg.message);
          }
        });

      this.setError(errorMessages);
    }, false);
  }

  ngOnDestroy() {
    this.unsubscribe();

    super.ngOnDestroy();
  }

  unsubscribe() {
    if (this.confChangeSubscription)
      this.confChangeSubscription.unsubscribe();

    if (this.highlightChangeSubscription)
      this.highlightChangeSubscription.unsubscribe();

    if (this.errorSubscription)
      this.errorSubscription.unsubscribe();
  }
}