import { Inject, Injectable } from "@angular/core";
import { NormalizerService } from "../../../../shared/providers/normalizer";
import { ModelFactory } from "../modelFactory";
import * as Immutable from "immutable";
import { PushMessageData } from "../../models/entities/pushMessageData";
import { PushMessageSchema } from "./pushMessageSchema";
import { UIAction, UIElement, UIPopup, UIInput, UISelect } from "../../models";
import { BaseEntity } from "../../baseEntity";

@Injectable()
export class PushMessageNormalizer {

  constructor(
    @Inject(NormalizerService) public normalizerService: NormalizerService,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(PushMessageSchema) public pushMessageSchema: PushMessageSchema
  ) { }

  normalizeJson(json: any) {
    
    let pushMessageData = new PushMessageData();
    let uiActions = Immutable.Map<number, UIAction>();
    let uiActionStructureById = Immutable.Map<string, Immutable.List<number>>();
    let uiElements = Immutable.Map<number, UIElement>();
    let values = Immutable.Map<string, string>();

    pushMessageData = pushMessageData.setInteractionSessionId(json.interactionSessionId);
    pushMessageData = pushMessageData.setExtraArgs(json.extraArgs);
    pushMessageData = pushMessageData.setLongId(json.longId);
    pushMessageData = pushMessageData.setStartUIAction(json.startUIAction);
    pushMessageData = pushMessageData.setClientRequestId(json.clientRequestId);

    for (let property in json.uiActionsById) {

      let currentUIActionIds = Immutable.List<number>();

      let currentProperty: Immutable.List<UIAction> = json.uiActionsById[property];
      currentProperty.forEach(uiAction => {

        let jsonArray = new Array<Object>();
        jsonArray.push(uiAction);

        let normalizeOutput = this.normalizerService.normalizeJson(jsonArray, this.pushMessageSchema.uiActionSchemaArray, (className, data) => this.modelFactory.createEntity(className, data));

        normalizeOutput.entities.forEach((value, key) => {

          if (value instanceof UIAction) {

            uiActions = uiActions.set(key, value);
            currentUIActionIds = currentUIActionIds.push(key);
          }
          else if (value instanceof UIElement) {

            uiElements = uiElements.set(key, value);

            if (value instanceof UIInput) {
              let input = (value as UIInput);

              if (input.type == "text" || input.type == "checkbox")
                values = values.set(input.id, input.value);

            }
            else if (value instanceof UISelect) {

              values = values.set(value.id, value.value);

            }
          }

        });
      });

      uiActionStructureById = uiActionStructureById.set(property, currentUIActionIds);

    }

    pushMessageData = pushMessageData.setUiActions(uiActions);
    pushMessageData = pushMessageData.setUiActionStructureById(uiActionStructureById);
    pushMessageData = pushMessageData.setUiElements(uiElements);
    pushMessageData = pushMessageData.setValues(values);

    return pushMessageData;

  }
}