<backdrop-container>
  <regular-popup *ngIf="visible"
                 [title]="strings.RuleConfirmPopup_ConfirmMessage"
                 [id]="id"
                 [width]="'500px'"
                 [showHeader]="true"
                 [showFooter]="true"
                 closeOnOutSideClick="false"
                 [okButtonText]="strings.Yes"
                 [cancelButtonText]="strings.No"
                 (okClick)="okButtonClicked()"
                 (cancelClick)="cancelButtonClicked()"
                 (onClosed)="onPopupClosed()">
    <!--Header part-->
    <div card-type="header" *ngIf="message != null">
      <div class="row flex-nowrap">
        <div class="d-flex align-middle px-2">
          <co-image key="approval" width="32px" height="32px" imageSet="{{imageSet}}"></co-image>
        </div>
        <div class="d-flex modal-text-right align-middle ml-0 pl-2">
          <p><em>{{strings.RulesWillSetOrTriedToSetValuesBelow}}</em></p>
        </div>
      </div>
    </div>

    <!--Body part-->
    <div card-type="body" class="disallowed-body" *ngIf="message != null">

      <rule-params-info [ruleInfo]="message.ruleInfo" [popupId]="id" [configurationId]="message.configurationId"></rule-params-info>

    </div>
  </regular-popup>
</backdrop-container>