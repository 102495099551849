import * as Immutable from "immutable";
import { Decoration } from "./decoration";

export class FormDecoration extends Decoration {
  
  protected readonly SHOW_IN_SUMMARY = "showInSummary";
  protected readonly SHOW_IN_CONFIGURATOR = "showInConfigurator";
  protected readonly SUMMARY_SORT_ORDER = "summarySortOrder";
  protected readonly PAGE_LOCATION = "pageLocation";

  get showInSummary(): boolean { return this.getInternalValue<boolean>(this.SHOW_IN_SUMMARY); }
  setShowInSummary(showInSummary: boolean): this { return this.setInternalValue(this.SHOW_IN_SUMMARY, showInSummary); }

  get showInConfigurator(): boolean { return this.getInternalValue<boolean>(this.SHOW_IN_CONFIGURATOR); }
  setShowInConfigurator(showInConfigurator: boolean): this { return this.setInternalValue(this.SHOW_IN_CONFIGURATOR, showInConfigurator); }

  get summarySortOrder(): number { return this.getInternalValue<number>(this.SUMMARY_SORT_ORDER); }
  setSummarySortOrder(summarySortOrder: number): this { return this.setInternalValue(this.SUMMARY_SORT_ORDER, summarySortOrder); }

  get pageLocation(): string { return this.getInternalValue<string>(this.PAGE_LOCATION); }
  setPageLocation(pageLocation: string): this { return this.setInternalValue(this.PAGE_LOCATION, pageLocation); }
}

export enum PageLocation {
  Tab = 'Tab',
  SummaryNewActionPanel = 'SummaryNewActionPanel'
}