<div class="row mx-0 w-100 node-style diff-back-{{differncyLevel}} {{compactMode ? 'compact' : ''}}" id="{{templateNode.id}}" (mouseout)="onMouseLeave($event)">

  <span *ngIf="!compactMode && !templateNode.isEmpty" class="col-auto p-1 align-middle" [style.min-width.px]="'40'">
    <co-image [key]="templateNode.icon" type="svg" class="d-block" width="40" height="40" [imageSet]="imageSet"></co-image>
  </span>
  <div class="col hide-overflow link-detail">
    <div>
      <span class="title">{{compactMode ? templateNode.compact : templateNode.text}}</span>
    </div>
    <div *ngIf="!compactMode" class="description" [innerHtml]="templateNode.description"></div>
  </div>
  <div *ngIf="!templateNode.isEmpty" class="col-auto align-middle my-2" [class.drill-down]="!loading" role="button" (click)="!loading && select.emit(templateNode)">
    <co-image *ngIf="!loading" key="stepinto" type="svg" width="20" height="20" [imageSet]="imageSet"></co-image>
    <inline-spinner classes="pl-2" *ngIf="loading"></inline-spinner>
  </div>  
</div>