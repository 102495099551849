import { Injectable } from "@angular/core";
import { BaseRequest } from "./models";
import { BaseController } from "./providers/baseController";
import { ActionStatus } from "./state/shared/actionStatus";
import { HttpAction } from "./state/shared/httpAction";
import { Actions } from "./state/shared/actions";
import { AppAction } from "./state";

@Injectable()
export abstract class BaseActionCreator {

  constructor(public baseController: BaseController){}

  public createHttpAction<T>(actionType: any, executeAsync: any, payload: T = null): HttpAction<T> {

    let httpAction: HttpAction<T> = {
      type: actionType,
      executeAsync: executeAsync,
      payload: payload
    }

    return httpAction;
  }

  sendDefaultDataAction<REQUEST, RESPONSE>(model: REQUEST): HttpAction<RESPONSE> {

    let asyncAction = <HttpAction<RESPONSE>>{
      type: Actions.DEFAULT_DATA_ACCESS,
      executeAsync: (dispatch, state, callback) => {
        return this.baseController.getDefaultData(model)
          .subscribe(result => {

            if (dispatch) {
              dispatch(this.afterDefaultAction(result));
              callback(new ActionStatus(true, result));
            }
            else
              callback(new ActionStatus(true, result));
          }, (error) => callback(new ActionStatus(false, error)));
      }
    }
    return asyncAction;
  }
    
  afterDefaultAction(apiResponse: any): AppAction<any> {
    return {
      type: Actions.CONFIGURATION_LOADED,
      payload: apiResponse
    };
  }

  afterServerResponse(result) {

  }

}