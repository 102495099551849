import { Injectable, Inject } from "@angular/core"
import { ReselectorService } from "../reselectorService"
import { AppState } from "../../state/shared/appState";
import * as Immutable from "immutable";
import { UIAction, UIElement, UIGroup, UIInput, UISelect } from "../../models";

@Injectable()
export class PushMessageMemorizer {
	constructor(@Inject(ReselectorService) public reselectorService: ReselectorService) {

	}

	public hasSessionData(sessionId: number, state: AppState): boolean {
		return state.pushMessages.dataBySessionId.has(sessionId);
	}

	/**
	 * UIElement memorizer
	 */
	public getElementMemorizer(sessionId: number, elementId: number, currentElementFunc?: () => any): () => UIElement {
		return this.reselectorService.createMemorizer2((state) => {
			if (!this.hasSessionData(sessionId, state))
				return null;

			if (!elementId)
				return null;

			let serverPushMessage = state.pushMessages.dataBySessionId.get(sessionId);
			if (!serverPushMessage)
				return null;

			let element = this.getElement(state, elementId, sessionId);
			if (!element)
				return null;

			return element;
		},
			(state) => {
				if (currentElementFunc)
					return currentElementFunc();

				return null;
			},
			(state) => state
		);
	}

	private getElement(state: AppState, elementId: number, sessionId: number): UIElement {
		return state.pushMessages.dataBySessionId.get(sessionId)?.uiElementById?.get(elementId) as UIElement;
	}

/**
 * UIElement value memorizer
 */
	public getElementValueMemorizer(sessionId: number, elementId: number, currentElementFunc?: () => any): () => string {
		return this.reselectorService.createMemorizer2((state) => {
			if (!this.hasSessionData(sessionId, state))
				return null;

			if (!elementId)
				return null;

			let serverPushMessage = state.pushMessages.dataBySessionId.get(sessionId);
			if (!serverPushMessage)
				return null;

			let element = this.getElement(state, elementId, sessionId);
			if (!element)
				return null;

			let hasValue = false;

			if (element instanceof UIInput) {
				let input = (element as UIInput);

				if (input.type == "text" || input.type == "checkbox")
					hasValue = true;

			}
			else if (element instanceof UISelect) {
				hasValue = true;
			}

			if (!hasValue)
				return null;

			// Should not ignore null.
			let elementValue = this.getElementValue(state, element.id, sessionId);
			if (!elementValue)
				return "";

			return elementValue;
		},
			(state) => {
				if (currentElementFunc)
					return currentElementFunc();

				return null;
			},
			(state) => state
		);
	}

	private getElementValue(state: AppState, elementId: string, sessionId: number): string {
		return state.pushMessages.dataBySessionId.get(sessionId)?.values?.get(elementId) as string;
	}

}