import { Inject, Component, Input, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import * as Immutable from "immutable";

import { ConfigurationSessionManager } from "../../../configurator/providers/configurationSessionManager";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { ConfHistory, Conf, RefObject } from "../../models"; 
import { ProductDataStore } from "../../providers/productData";
import { ConfiguratorStore, ConfPageSessionService } from "../../../configurator/providers";

@Component({
  selector: 'state-history-popup',
  templateUrl: './stateHistoryPopupComponent.html'
})
export class StateHistoryPopupComponent extends AbstractPopupComponent {

  public id: string = "state-history-popup";
  public width: string;
  public height: string;

  public histories: Array<HistoryModel>;

  @Output()
  public onClosed = new EventEmitter();

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,    
    public cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.width = this.uiSettings.configurator.state.minPopupWidth;
    this.height = this.uiSettings.configurator.state.minPopupHeight;

    this.visible = true;
    super.ngOnInit();
  }

  show(): void {
    this.histories = [];
    let conf: Conf = this.confStore.getConf(this.confPageSessionService.activeConfigurationId, this.confPageSessionService.confSessionId);

    let histories = conf.histories;
    
    histories.forEach(history => {
      let model: HistoryModel = {
        createdBy: history.createdBy,
        creationDate: history.creationDate,
        note: history.note,
        fromText: history.fromState ? history.fromState?.text : history.fromOwnership?.text,
        toText: history.toState ? history.toState?.text : history.toOwnership?.text
      }
      this.histories.push(model);
    });

    this.showInternal();    
  }
}

export class HistoryModel {
  fromText: string;
  toText: string;

  createdBy: RefObject;
  creationDate: string;

  note: string;
}