import { UIAction } from "./uiAction";
import { NotificationType } from "../../../../shared/components/notification/notificationInfo";

export class UINotification extends UIAction {

  protected readonly TITLE = "title";
  protected readonly MESSAGE = "message";
  protected readonly DETAIL = "detail";
  protected readonly RAW_INFO = "rawInfo";
  protected readonly NOTIFICATION_TYPE = "notificationType";
  protected readonly SELF_CLOSE = "selfClose";
  protected readonly DELAY = "delay";
  protected readonly IDENTIFIER = "identifier";
  protected readonly SYNC = "sync";
  protected readonly TYPE = "type";

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get message(): string { return this.getInternalValue<string>(this.MESSAGE); }
  setMessage(message: string): this { return this.setInternalValue(this.MESSAGE, message); }

  get detail(): string { return this.getInternalValue<string>(this.DETAIL); }
  setDetail(detail: string): this { return this.setInternalValue(this.DETAIL, detail); }

  get rawInfo(): string { return this.getInternalValue<string>(this.RAW_INFO); }
  setRawInfo(rawInfo: string): this { return this.setInternalValue(this.RAW_INFO, rawInfo); }

  get type(): string { return this.getInternalValue<string>(this.TYPE); }
  setType(type: string): this { return this.setInternalValue(this.TYPE, type); }

  get selfClose(): boolean { return this.getInternalValue<boolean>(this.SELF_CLOSE); }
  setSelfClose(selfClose: boolean): this { return this.setInternalValue(this.SELF_CLOSE, selfClose); }

  get delay(): number { return this.getInternalValue<number>(this.DELAY); }
  setDelay(delay: number): this { return this.setInternalValue(this.DELAY, delay); }

  get identifier(): string { return this.getInternalValue<string>(this.IDENTIFIER); }
  setIdentifier(identifier: string): this { return this.setInternalValue(this.IDENTIFIER, identifier); }

  get sync(): boolean { return this.getInternalValue<boolean>(this.SYNC); }
  setSync(sync: boolean): this { return this.setInternalValue(this.SYNC, sync); }
}