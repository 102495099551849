// Represents the notification positions
export enum NotificationPositionTypes {
  RightBottom = 'right-bottom',
  LeftBottom = 'left-bottom',
  LeftTop = 'left-top',
  RightTop = 'right-top'
}

export enum NotificationType {
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error',
  Info = 'Info'
}

export class NotificationInfo {

  public title: string;
  public message: string;
  public rows: NotificationRow[] = [];
  public detail?: string;
  public rawInfo?: string;

  // Represents notification type, It could be success, error, warning etc.
  public type: NotificationType;

  // System removes the notifications automatically after a while. 
  // If you want it to be closed by user then make it 'false'.
  public selfClose: boolean = true;

  // If selfClose is true then this option overrides the default setting.
  public delay?: number;

  // If <selfClose> is 'true' then system would show the contents in existing one.
  public identifier?: string;

  // Synchronizes the messages belonging to same type
  public sync: boolean = false; 
  
}

export class NotificationRow {
  public classes = "";
  public cells: NotificationCell[] = [];
}

export class NotificationCell {
  public message = "";
  public classes = "";
}

export class NotificationConfig {

  public corner = NotificationPositionTypes.RightBottom;
  public delay: number = 5000;
  public width: number = 400;
  public sound: boolean = false;

}

// This modal class encapsulates message information and notiying status, which is used internally in notification html template. 
export class Notification {

  // Message information.
  public info: NotificationInfo;

  public notify: boolean = false;

  public timeout: any;

  public detailSelected: boolean;

  public rawSelected: boolean;

  public timestamp: number;
}