import { Injectable, Inject } from "@angular/core";
import { SearchValueControlType, SearchCriteriaRegion, AttributeField } from "../models";
import { GlobalDataStore } from "../../../../shared/providers/globalData";
import { AccountDataStore } from "../../../../shared/providers/accountData";
import { ProductDataStore } from "../../../../shared/providers/productData";
import { Product, Assortment, Param, Tab, VisualObject, ProductFamily } from "../../../../shared/models";
import { MultiSelectItem, ItemStateType } from "../../../../../shared/components/multiSelectDropdown";
import { BaseEntity } from "../../../../shared/baseEntity";
import { ModelLabelService } from "../../../../shared/providers/modelLabelService";

@Injectable()
export class SearchCriteriaDataProvider {

  constructor(
    protected globalDataStore: GlobalDataStore,
    protected accountDataStore: AccountDataStore,
    protected productDataStore: ProductDataStore,  
    protected modelLabelService: ModelLabelService
  ) {}


  /**
   * Returns all products.
   * */
  public getProductMultiSelectModels(showChildren: boolean = true): Promise<Array<MultiSelectItem>> {

    return new Promise<Array<MultiSelectItem>>(resolve => {

      // Promise for root assortments.
      let rootAssortmentsPromise = this.productDataStore.getRootAssortments().toPromise();

      const isTreeStructureData = this.globalDataStore.globalSettings.groupProductsByFamilyInFilterControl;

      if (showChildren) {
        // Response is null when data is retreived from cache. This is the bug in our system when we convert the 'subject' to 'Promise' It does not transfer the data to promise.
        // that's why we are not reading data from 'response'
        // Promise for missing ids.
        let missingIdsPromise = rootAssortmentsPromise.then(response => this.getMissingProductIds(this.productDataStore.getProductData().rootAssortments.toArray()));

        // Promise to retreive the missing products.
        let fetchMissingProductsPromise = missingIdsPromise.then(ids => this.productDataStore.fetchProductsWithoutData(ids).toPromise());

        // All promises.
        Promise.all([rootAssortmentsPromise, missingIdsPromise, fetchMissingProductsPromise]).then(x => {

          // Get all products.
          let rootIds = this.productDataStore.getProductData().rootAssortments.toArray();
          let assortments = this.getAllAssortmentsData(rootIds, isTreeStructureData);
          resolve(assortments);

        });
      }
      else {
        rootAssortmentsPromise.then(x => {

          // Get all products.
          let rootIds = this.productDataStore.getProductData().rootAssortments.toArray();
          let assortments = this.getAllAssortmentsData(rootIds, isTreeStructureData);
          resolve(assortments);

        });

      }
    });
  }

  /**
   * Returns all products from cached data.
   * @param rootIds
   */
  protected getAllAssortmentsData(rootIds: number[], includeProductFamilies: boolean = false): Array<MultiSelectItem> {

    let models: MultiSelectItem[] = [];
    rootIds.forEach(assortmentId => {

      let assortment: Assortment = this.productDataStore.getEntity<Assortment>(assortmentId);

      let item: MultiSelectItem = this.convertToModel(assortment);

      if (includeProductFamilies || assortment.className == Product.name) {
        models.push(item);
      }

      if (includeProductFamilies)
        item.children = this.getChildProductsModels(assortment, includeProductFamilies);

      else {
        
        let children = this.getChildProductsModels(assortment, includeProductFamilies);
        models.push(...children);
      }

    });

    return this.sort(models);
  }

  protected sort(models: MultiSelectItem[]): MultiSelectItem[] {

    let result: MultiSelectItem[]
    let uniqueItems: Set<MultiSelectItem> = new Set<MultiSelectItem>();
    models.forEach(item => uniqueItems.add(item));

    // Convet back to array to array
    result = Array.from(uniqueItems);

    // Sort the products.
    result = result.sort(function (a, b) {
      if (a.title.toUpperCase() < b.title.toUpperCase()) { return -1; }
      if (a.title.toUpperCase() > b.title.toUpperCase()) { return 1; }
      return 0;
    });

    return result;
  }

  /**
   * Returns child products.
   * @param assortment
   */
  protected getChildProductsModels(assortment: Assortment, includeProductFamilies: boolean) {

    let assortmentsModels: MultiSelectItem[] = [];

    if (assortment.children && assortment.children.size > 0) {
      assortment.children.forEach(id => {
        let childAssortment = this.productDataStore.getEntity<Assortment>(id);
        if (childAssortment) {

          let childModel = this.convertToModel(childAssortment);

          childModel.isChild = assortment instanceof Product && childAssortment instanceof Product;

          // If product families are included then we will show the entire tree including all children.
          if (includeProductFamilies || childAssortment.className == Product.name) {
            assortmentsModels.push(childModel);
          }
          
          if (includeProductFamilies) {
            childModel.children = this.getChildProductsModels(childAssortment, includeProductFamilies);
          }
          else assortmentsModels.push(...this.getChildProductsModels(childAssortment, includeProductFamilies));

        }
      });
    }

    return assortmentsModels;
  }

  /**
   * Returns only missing product ids which are not available in store.
   * @param assortmentIds
   */
  protected getMissingProductIds(assortmentIds: number[]): Array<number> {

    let productIds: number[] = [];
    assortmentIds.forEach(id =>
    {
      let assortment = this.productDataStore.getEntity<Assortment>(id);
      productIds.push(...this.getMissingChildrenIds(assortment));
    });

    return productIds;
  }

  /**
   * Returns missing child product ids which are not available in store.
   * @param assortment
   */
  protected getMissingChildrenIds(assortment: Assortment) {

    let children: number[] = [];

    if (assortment.children && assortment.children.size > 0) {

      assortment.children.forEach(id => {

        let childAssortment = this.productDataStore.getEntity<Assortment>(id);
        if (!childAssortment)
          children.push(id);

        else children.push(...this.getMissingChildrenIds(childAssortment));

      });
    }

    return children;
  }

  getMatchingParams(productIds: Array<number>): Promise<Array<Param>> {

    return new Promise<Array<Param>>(resolve => {

    let params = new Array<Param>();

      if (productIds.length == 0) {
        resolve(params);
        return;
      }

      this.getParamsByProduct(productIds).then(paramsByProd => {

        if (paramsByProd.size == 1) {

          // As it contains only one list, return the same list.
          resolve(paramsByProd.values().next().value);
        }
        else {

          let productKey: number = this.getSmallestSetOfParamsProduct(paramsByProd);

          // Smallest set of params.
          let params: Array<Param> = paramsByProd.get(+productKey);

          let matchedParams: Array<Param> = new Array<Param>();

          params.forEach(param => {

            if (this.existsInEachProduct(param, paramsByProd, productKey)) {
              matchedParams.push(param);
            }

          });
          resolve(matchedParams);
        }
      });
    });
  }

  existsInEachProduct(param: Param, paramsByProd: Map<number, Array<Param>>, ignoreKey: number): boolean {

    for (let key of Array.from(paramsByProd.keys())) {

      if (ignoreKey == +key)
        continue;

      let params = paramsByProd.get(+key);
      if (this.exists(param, params))
        return true;

    }

    return false;
  }

  /**
   * Returns the productKey having smallest set of parameters in the dictionary.
   * @param paramsByProd
   */
  getSmallestSetOfParamsProduct(paramsByProd: Map<number, Array<Param>>): number {

    let keys = Array.from(paramsByProd.keys());
    let optimizedKey: any = keys.pop();

    for (let key of keys) {

      let smallestArraySize = paramsByProd.get(optimizedKey).length;
      let currentArraySize = paramsByProd.get(+key).length;
      if (currentArraySize < smallestArraySize)
        optimizedKey = key; 
    }

    return optimizedKey;
  }

  exists(which: Param, list: Array<Param>): boolean {
    return list.findIndex(p => p.visualObjectId == which.visualObjectId) >= 0;
  }

  getParamsByProduct(productIds: Array<number>): Promise<Map<number, Array<Param>>> {

    return new Promise<Map<number, Array<Param>>>(resolve => {
      
      this.productDataStore.fetchProductsWidthData(productIds).subscribe(storeResponse => {

        let mapData = new Map<number, Array<Param>>();

        // Add all product params
        storeResponse.data.map(product => mapData.set(product.longId, this.getAllParams(product)));

        resolve(mapData);
      });

    });
  }

  getAllParams(product: Product): Array<Param> {

    let params = new Array<Param>();
    product.tabs.map(tabId => params.push(...this.getParamsByTabId(tabId)));

    return params;
  }

  getParamsByTabId(tabId: number): Array<Param> {

    let tab = this.productDataStore.getEntity<Tab>(tabId);
    let params = new Array<Param>();
    tab.visualObjects.forEach(id => {

      let visualObject = this.productDataStore.getEntity<VisualObject>(id);
      if (visualObject instanceof Param)
        params.push(visualObject);

    });

    return params;
  }

  convertToModel(entity: Assortment): MultiSelectItem {

      return <MultiSelectItem>{

        title: this.modelLabelService.getLabel(entity),
        id: entity.longId.toString(),
        state: ItemStateType.Unchecked,
        selectable: entity.className != ProductFamily.name,
      };
  }

}