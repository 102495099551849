import { BaseEntity } from "../../baseEntity";

export class UIElement extends BaseEntity  {

  protected readonly ID = "id";
  protected readonly WIDTH = "width";
  protected readonly HEIGHT = "height";
  protected readonly STYLE = "style";
  protected readonly CSS_CLASS = "cssClass";
  protected readonly INCLUDE_DIV = "includeDiv";
  protected readonly RENDER_ID = "renderId";

  get renderId(): number { return this.getInternalValue<number>(this.RENDER_ID); }
  setRenderId(renderId: number): this { return this.setInternalValue(this.RENDER_ID, renderId); }

  get id(): string { return this.getInternalValue<string>(this.ID); }
  setId(id: string): this { return this.setInternalValue(this.ID, id); }

  get width(): number { return this.getInternalValue<number>(this.WIDTH); }
  setWidth(width: number): this { return this.setInternalValue(this.WIDTH, width); }

  get height(): number { return this.getInternalValue<number>(this.HEIGHT); }
  setHeight(height: number): this { return this.setInternalValue(this.HEIGHT, height); }

  get style(): string { return this.getInternalValue<string>(this.STYLE); }
  setStyle(style: string): this { return this.setInternalValue(this.STYLE, style); }

  get cssClass(): string { return this.getInternalValue<string>(this.CSS_CLASS); }
  setCssClass(cssClass: string): this { return this.setInternalValue(this.CSS_CLASS, cssClass); }

  get includeDiv(): boolean { return this.getInternalValue<boolean>(this.INCLUDE_DIV); }
  setIncludeDiv(includeDiv: boolean): this { return this.setInternalValue(this.INCLUDE_DIV, includeDiv); }
}