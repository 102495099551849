<div #container class="d-flex flex-fill" [class.tab-composite-confs]="showTabComposite">

  <!--[containerId]="containerReferencedId"-->
  <tab-composite-configurations *ngIf="showTabComposite"
                                [configurationId]="configurationId"
                                [tab]="tab"></tab-composite-configurations>

  <div *ngIf="!isFlexContainer" class="container-fluid">
    <div class="row align-items-start">
      <ng-container *ngTemplateOutlet="containerTemplate"></ng-container>
    </div>
  </div>

  <div *ngIf="isFlexContainer" class="full-visual-object-container">
    <ng-container *ngTemplateOutlet="containerTemplate"></ng-container>
  </div>

</div>

<ng-template #containerTemplate>
  <ng-container *ngFor="let gr of groups; trackBy: trackGroup">
    <!--Render the visual object-->
    <visual-object-group attr.id="{{gr.id}}"
                         [group]="gr"
                         [configurationId]="configurationId"
                         [service]="visualObjectService"
                         class="{{gr.cssClass}} col-auto"                         
                         [visualStyle]="gr.visualObject"
                         attr.name="{{gr.name}}">
    </visual-object-group>
    <div *ngIf="visualObjectService.get(gr.id)?.lineBreak" class="visual-object-line-break w-100" name="line-break"></div>
  </ng-container>
</ng-template>