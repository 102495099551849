<div *ngIf="itemView && !isReadOnly" class="pl-0 container" [class.disabled]="isDisabled" 
     attr.disabled-status="{{isDisabled}}" 
     attr.id="{{itemView.id}}"
     >
  <ng-container *ngTemplateOutlet="contentsTemplate"></ng-container>
</div>

<div *ngIf="itemView && isReadOnly" class="pl-0 container" [class.disabled]="isDisabled" 
     attr.disabled-status="{{isDisabled}}" 
     attr.id="{{itemView.id}}"
     role="button" (click)="onClick($event)">
  <ng-container *ngTemplateOutlet="contentsTemplate"></ng-container>
</div>

<ng-template #contentsTemplate>
  <span *ngIf="isReadOnly" class="read-only">
    <co-image width="20" height="20" imageSet="{{imageSet}}" type="svg" key="lock"></co-image>
  </span>

  <div [id]="itemView.id" class="row input-value-color max-width align-middle mx-0 px-0 flex-nowrap">
    <div class="col-auto option-input radio p-0 ml-0 {{styles}}" (click)="onClick($event)"></div>
    <label class="col-auto input-value-color pl-1" for="{{itemView.id}}" role="button" (click)="onClick($event)">{{itemView.title}}</label>
  </div>
</ng-template>
