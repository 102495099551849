<regular-popup *ngIf="visible"
               [id]="id"
               [title]="strings.PriceListNotification"
               [showFooter]="true"
               [showHeader]="true"
               [draggable]="false"
               referenceContainerId="configuratorContainer"
               [okButton]="true"
               [okButtonText]="strings.PriceList"
               [cancelButton]="false"
               (okClick)="onOkClick($event)"
               (onCloseStart)="onPopupClosed()">

  <div card-type="header" class="clear-message-header">    
    <div class="row mt-0">
      <div class="col-auto">
        <co-image key="warning-dotted" width="56px" imageSet="{{imageSet}}" height="56px"> </co-image>
      </div>
      <div class="col report-title align-middle">
        {{strings.PriceItemValueCleared}}
      </div>
    </div>
  </div>

  <div card-type="body" class="price-report-warnings">

    <div *ngIf="clearedMessage" >
      <div *ngFor="let entity of clearedMessage.titleAndValues" class="row px-4 pt-4">
        <div class="col">{{entity.key}}</div>
        <div class="col text-right">{{entity.value}}</div>
      </div>
    </div>

  </div>

</regular-popup>
