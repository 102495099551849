<backdrop-container (onClosed)="onPopupClosed()">
  <side-bar-popup [id]="id"
                  [title]="strings.BillOfMaterials"
                  width="500px"
                  [showHeader]="true"
                  [showFooter]="true"
                  position="right"
                  [okButtonText]="strings.Ok"
                  [cancelButtonText]="strings.Cancel"
                  (okClick)="onOkClick()"
                  (cancelClick)="onCancelClick()">

    <div card-type="header">
      <gridview [style.width]="'100%'" classes="center-content">
        <gridview-row>
          <columntemplate size="10" classes="align-self-center">
            <h4 class="modal-title">{{strings.CreateBOM}}</h4>
          </columntemplate>
        </gridview-row>
      </gridview>
    </div>

    <div card-type="body" class="pt-4 px-5">
      <autocomplete #autocomplete
                    [items]="items"
                    [autoCompleteView]="autoCompleteView"
                    (filterChanged)="onFilterChanged($event)"
                    (selectionChanged)="onSelectionChanged($event)"></autocomplete>
      <text-box [view]="itemNoView"></text-box>
      <dropdown [view]="revisionView"
                [itemsView]="revisionItemViews"></dropdown>
      <text-box [view]="quantityView" type="number"></text-box>
      <dropdown [showEmptyValue]="'true'"
                [view]="quantityUnitView"
                [itemsView]="quantityUnitItemViews"
                [selectedViewId]="quantityUnitView.value"></dropdown>
      <dropdown *ngIf="isAllowOperationSequenceEdit"
                [showEmptyValue]="'true'"
                [view]="operationSequenceView"
                [itemsView]="operationSequenceItemViews"></dropdown>
    </div>
  </side-bar-popup>
</backdrop-container>
