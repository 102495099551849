import { Inject, Injectable } from "@angular/core";
import { ApiResponse, ComparisonCommand, ComparisonDataRequest, ComparisonDataResponse, RequestViews } from "../../../../../core/pages/shared/models";
import { AppStoreSubscriptionManager, ModelFactory } from "../../../../../core/pages/shared/providers";
import { AppStore, BaseStore, ComparisonResult, ComparisonSessionData, HttpAction, StoreResponse } from "../../../../../core/pages/shared/state";
import { ManagedSubject } from "../../../../../core/shared";
import { ComparisonDataActionCreator } from "./comparisonDataActionCreator";
import { ComparisonHelper } from "./comparisonHelper";
import { GlobalDataStore } from "../../../shared/providers/globalData";

@Injectable()
export class ComparisonDataStore extends BaseStore {

  constructor(@Inject(AppStore) public appStore: AppStore,
    @Inject(ComparisonDataActionCreator) public comparisonActionCreator: ComparisonDataActionCreator,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(AppStoreSubscriptionManager) public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(ComparisonHelper) public helper: ComparisonHelper,
    public globalDataStore: GlobalDataStore    
  ) {
    super(appStore, appStoreSubscriptionManager, modelFactory, comparisonActionCreator);
  }

  /**
   * Returns comparison results by confIds.
   * @param selectedConfIds
   * @param sessionId
   */
  public getComparisonResults(selectedConfIds: Array<number>, sessionId: number, path: string): ManagedSubject<StoreResponse<ComparisonResult>> {

    let model: ComparisonDataRequest = this.createComparisonRequest(sessionId);
    model.comparison.startConfIds = selectedConfIds;
    model.comparison.includeDetails = true;
    model.comparison.path = path;

    return this.getComparisonSessionResponse(model);

  }

  /**
   * Updates the child configurations order.
   * @param confIds
   * @param sessionId
   */
  public updateChildConfsOrder(childConfsOrder: Array<Array<number>>, startConfIds: Array<number>, path: string, sessionId: number): ManagedSubject<StoreResponse<ComparisonResult>> {

    let model: ComparisonDataRequest = this.createComparisonRequest(sessionId);
    model.comparison.startConfIds = startConfIds;
    model.comparison.includeDetails = false;
    model.comparison.calculateDifferency = false;
    model.comparison.path = path;
    model.comparison.childConfsOrder = childConfsOrder;

    return this.getComparisonSessionResponse(model);

  }

  /**
   * Returns the differency level for child configurations.
   * @param childConfsOrder
   * @param confIds
   * @param sessionId
   */
  public loadConfsDifferncyLevel(confIds: Array<number>, path: string, sessionId: number): ManagedSubject<StoreResponse<ComparisonResult>> {

    let model: ComparisonDataRequest = this.createComparisonRequest(sessionId);
    model.comparison.startConfIds = confIds;
    model.comparison.path = path;
    model.comparison.includeDetails = false;    
    model.comparison.calculateDifferency = true;

    return this.getComparisonSessionResponse(model);
  }

  public getComparisonSessionResponse(model: ComparisonDataRequest): ManagedSubject<StoreResponse<ComparisonResult>> {

    let response: StoreResponse<ComparisonResult> = new StoreResponse<ComparisonResult>();

    let action: HttpAction<ApiResponse> = this.comparisonActionCreator.dispatchGetComparisonConfs(model);

    // Create request object
    return this.createAction(action, actionInfo => {
      let comparisonDataResponse = actionInfo.payload.data as ComparisonDataResponse;

      if (comparisonDataResponse)
        response.data = this.getComparisonSession(comparisonDataResponse.path, comparisonDataResponse.comparisonSessionId);

      return response;
    });

  }
  
  getComparisonSessionData(comparisonSessionId: number): ComparisonSessionData {
    return this.appStore.getState().comparisonData.dataBySessionId.get(comparisonSessionId);
  }

  getComparisonSession(path: string, comparisonSessionId: number): ComparisonResult {

    let data = this.getComparisonSessionData(comparisonSessionId);
    if (!data)
      return;
    
    return data.comparisonResultByPath.get(path);

  }

  hasComparisonResult(confIds: Array<number>, sessionId: number, path: string): boolean {

    if (!sessionId)
      return false;

    let data: ComparisonSessionData = this.getComparisonSessionData(sessionId);

    if (!data)
      return false;

    // Grab the corresponding session.
    let comparisonSession: ComparisonResult = data.comparisonResultByPath.get(path);

    if (!comparisonSession)
      return false;

    // Convert to number because passed array is not number.
    let arr = confIds.map(x => Number(x));

    let hasSameConfIds = JSON.stringify(comparisonSession.confIds.toArray()) == JSON.stringify(arr);

    // Return true if comparison results found.
    return comparisonSession && hasSameConfIds && !!comparisonSession.detail;
  }

  createComparisonRequest(sessionId: number): ComparisonDataRequest {

    let result: ComparisonDataRequest = this.modelFactory.createRequestOrCommand<ComparisonDataRequest>(ComparisonDataRequest.name);
    result.comparisonSessionId = sessionId;    
    result.client = RequestViews.Compare;
    result.comparison = this.modelFactory.createRequestOrCommand<ComparisonCommand>(ComparisonCommand.name);
    
    return result;
  }

}