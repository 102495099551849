import * as Immutable from "immutable";

import { SearchSessionData } from "./searchSessionData";
import { ImmutableObject } from "../../../../shared/immutableObject";
import { ConfigurationSearch, AbstractSearchDataMessage, ServerPushMessage, UIAction, UIElement } from "../../models";
import { BaseEntity } from "../../baseEntity";

export class PushData extends ImmutableObject {

  protected readonly VALUES = "values";
  protected readonly SERVER_PUSH_MESSAGE = "serverPushMessage";
  protected readonly UI_ACTION_BY_ID = "uiActionById";
  protected readonly UI_ELEMENT_BY_ID = "uiElementById";

  get values(): Immutable.Map<string, string> { return this.getInternalValue <Immutable.Map<string, string>>(this.VALUES); }
  setValues(values: Immutable.Map<string, string>): this { return this.setInternalValue(this.VALUES, values); }

  get serverPushMessage(): ServerPushMessage { return this.getInternalValue<ServerPushMessage>(this.SERVER_PUSH_MESSAGE); }
  setServerPushMessage(serverPushMessage: ServerPushMessage): this { return this.setInternalValue(this.SERVER_PUSH_MESSAGE, serverPushMessage); }

  get uiActionById(): Immutable.Map<number, UIAction> { return this.getInternalValue<Immutable.Map<number, UIAction>>(this.UI_ACTION_BY_ID); }
  setUiActionById(uiActionsById: Immutable.Map<number, UIAction>): this { return this.setInternalValue(this.UI_ACTION_BY_ID, uiActionsById); }

  get uiElementById(): Immutable.Map<number, UIElement> { return this.getInternalValue<Immutable.Map<number, UIElement>>(this.UI_ELEMENT_BY_ID); }
  setUiElementById(uiElementsById: Immutable.Map<number, UIElement>): this { return this.setInternalValue(this.UI_ELEMENT_BY_ID, uiElementsById); }

}