import { Injectable } from "@angular/core";
import { ConfUIItem, CodeDisplayStyles, CodeDecoration, FormDecoration, PageLocation } from "../../../models";
import { SummaryDisplayStyles } from "../../../../configurator/summary/prices/SummaryDisplayStyle";
import * as Immutable from "immutable";
import { ProductDataStore } from "../../../providers/productData";
import { ConfPageSessionService } from "../../../../configurator/providers";

@Injectable()
export class FormValueModelBuilder {

  constructor(
    public productStore: ProductDataStore) {
  }

  /**
   * Returns the list of values for each decoration.   
   * Note!  if <confUIItem> is the instance of <GroupConfUIItem> then list must contain the multiple decorations models.
   * @param confUIItem
   * @param isEditor
   * @param filterDisplayStyles
   */
  public build(confUIItem: ConfUIItem, isDecoration: boolean): FormDecoration {

    let decoration = this.productStore.getEntity<FormDecoration>(confUIItem.id);

    const displayStyle = decoration.pageLocation as PageLocation;

    if (!isDecoration && displayStyle == PageLocation.SummaryNewActionPanel)
      return decoration;

    return null;
  }

  addFormDecoration(formDecorations: Array<FormDecoration>, x: ConfUIItem) {
    const visualObject = this.productStore.getEntity(x.id);
    if (visualObject instanceof FormDecoration) {
      formDecorations.push(visualObject);
    }
  }

}