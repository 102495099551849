import { UIElement } from "./uiElement";

export class UIImage extends UIElement {

  protected readonly SRC = "src";
  protected readonly ALT = "alt";

  get src(): string { return this.getInternalValue<string>(this.SRC); }
  setSrc(src: string): this { return this.setInternalValue(this.SRC, src); }

  get alt(): string { return this.getInternalValue<string>(this.ALT); }
  setAlt(alt: string): this { return this.setInternalValue(this.ALT, alt); }

}