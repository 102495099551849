<ng-container *ngFor="let item of items">

  <div [ngSwitch]="item.itemName">

    <conf-properties-detail *ngSwitchCase="'PropertyDecoration'" [confUIItem]="item" [isPrintView]="isPrintView"></conf-properties-detail>

    <tab-detail *ngSwitchCase="'Tab'" [isPrintView]="isPrintView" [confUIItem]="item"></tab-detail>

    <bom-detail *ngSwitchCase="'BomDecoration'" [confUIItem]="item" [isPrintView]="isPrintView"></bom-detail>

    <data-selector-detail *ngSwitchCase="'DataSelector'" [confUIItem]="item" [isPrintView]="isPrintView"></data-selector-detail>

    <attributes-detail *ngSwitchCase="'AttributeDecoration'" [confUIItem]="item" [isPrintView]="isPrintView"></attributes-detail>

    <graphics-detail *ngSwitchCase="'GraphicsDecoration'" [confUIItem]="item" [isPrintView]="isPrintView"></graphics-detail>

    <prices-detail *ngSwitchCase="'PriceListDecoration'" [confUIItem]="item" [isPrintView]="isPrintView"></prices-detail>

    <documents-detail *ngSwitchCase="'DocumentsDecoration'" [confUIItem]="item" [isPrintView]="isPrintView"></documents-detail>

    <code-detail *ngSwitchCase="'CodeDecoration'" [confUIItem]="item" [isPrintView]="isPrintView"></code-detail>

    <visualization-detail *ngSwitchCase="'VisualizationDecoration'" [confUIItem]="item" [isPrintView]="isPrintView"></visualization-detail>

    <image-detail *ngSwitchCase="'ImageDecoration'" [confUIItem]="item" [isPrintView]="isPrintView"></image-detail>

    <form-detail *ngSwitchCase="'FormDecoration'" [confUIItem]="item" [isPrintView]="isPrintView"></form-detail>

  </div>

</ng-container>