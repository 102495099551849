import { Inject, Component, ChangeDetectorRef } from "@angular/core";
import * as Immutable from "immutable";

import { ProductDataStore } from "../../../shared/providers/productData";
import { RouteRedirector } from "../../../shared/providers";
import { ClearedParamMessage, ClearedParamInfo } from "../../../shared/models/responses/messages";
import { ConfigurationSessionManager, ConfiguratorStore, ConfPageSessionService, ConfRouteParams, PopupIdentifiers } from "../../providers";
import { BaseComponent } from "../../../shared";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { ConfInfo } from "../../../shared/models";
import { PopupService } from "../../../../shared/components";
import { ImageSets } from "../../../../shared/utils";

@Component({
  selector: 'cleared-popup',
  templateUrl: './clearedPopupComponent.html'
})
export class ClearedPopupComponent extends AbstractPopupComponent {
  public clearedValueModels: Array<ClearedConfigurationModel>;

  public id = PopupIdentifiers.ClearValue;

  public imageSet: string;

  constructor(
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(PopupService) public popupService: PopupService,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr, popupService);
  }

  ngOnInit() {
    this.imageSet = this.getImageSet(ImageSets.Popup);
    super.ngOnInit();
  }

  public open(tag: any) {
    this.show(tag);
  }

  show(messages: Immutable.List<ClearedParamMessage>): void {
    this.clearedValueModels = [];

    // Setup a model for each message (aka for each configuration) with its cleared values.
    messages.forEach(message => {
      let clearedValueModel = new ClearedConfigurationModel();
      clearedValueModel.conf = this.confStore.getConfInfo(message.configurationId, this.storeSession.confSessionId);
      clearedValueModel.isActiveConf = this.storeSession.activeConfigurationId === message.configurationId;
      clearedValueModel.clearedParamInfos = message.clearedParamInfos.toArray();
      this.clearedValueModels.push(clearedValueModel);
    });

    this.showInternal();
  }

  public onAccordionOpen(configurationId: number) {
    // Accordion expand will route to the clicked configuration 
    this.routeRedirector.redirectToEditor(<ConfRouteParams>{ id: configurationId, confSessionId: this.storeSession.confSessionId })
  }

  public onPopupClosed() {
    this.clearedValueModels = null;
    super.onPopupClosed();
  }
}

export class ClearedConfigurationModel {
  conf: ConfInfo;
  isActiveConf = false;
  clearedParamInfos: Array<ClearedParamInfo> = [];
}